import { useDownloadFile } from '@/composables/useDownloadFile'

const downloadFile = useDownloadFile()

const baseUrl = '/Documents_Espace_Client/DEMO/FichierVierge.csv'
export default (axiosInstance) => {
  const getInterimCSV = async function () {
    try {
      const result = await axiosInstance.get(baseUrl)
      const blobFile = new Blob([result.data], { type: 'text/csv' })
      downloadFile(blobFile, 'Import.csv')
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    getInterimCSV,
  }
}
