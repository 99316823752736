import * as Sentry from '@sentry/vue'

function formatPermissionsWanted(permissionsWanted?: string | string[]): string[] {
  if (permissionsWanted == null) {
    Sentry.captureException(new Error('hasPermission: permissionsWanted is undefined'))
    return []
  }

  if (typeof permissionsWanted === 'string') {
    return [permissionsWanted]
  }

  return permissionsWanted
}

export function hasPermission(permissionsGranted: string[]): (permissionsWanted?: string | string[]) => boolean {
  return (permissionsWanted?: string | string[]): boolean => {
    const permissionsWantedArray = formatPermissionsWanted(permissionsWanted)

    if (permissionsWantedArray.length === 0) {
      return true
    }

    const requiredPermissions: string[] = []
    const excludedPermissions: string[] = []
    const somePermissions: string[] = []

    permissionsWantedArray.forEach((permission) => {
      if (permission.endsWith('*')) {
        requiredPermissions.push(permission.substring(0, permission.length - 1))
        return
      }

      if (permission.endsWith('!')) {
        excludedPermissions.push(permission.substring(0, permission.length - 1))
        return
      }

      somePermissions.push(permission)
    })

    if (requiredPermissions.find((permission) => !permissionsGranted.includes(permission)) != null) {
      return false
    }

    if (excludedPermissions.find((permission) => permissionsGranted.includes(permission)) != null) {
      return false
    }

    if (
      somePermissions.length === 0 ||
      somePermissions.find((permission) => permissionsGranted.includes(permission)) != null
    ) {
      return true
    }

    return false
  }
}
