import { Result } from '@badrap/result'
import { ref } from 'vue'
import type { BenefitsCalculatorImportStep, BenefitsCalculatorStepBuilder } from '../types'
import { useBenefitsCalculatorStore } from '..'
import { ActionType } from '@/types/Action'
import { useAction } from '@/composables/useAction'
import { ImportStatus } from '@/components/Cart/Step/Import/workflow'
import { fetchSeparator, parseCSV } from '@/utils/csv'
import { useDownloadFile } from '@/composables/useDownloadFile'
import { useI18n } from '@/composables/useI18n'
import type { ImportField } from '@/types/Import'

export interface CompanyMealsHeaders {
  matricule: string
  'nombre passages': string
}

const csvNames: Record<string, keyof CompanyMealsHeaders> = {
  '009_MATRICULE_BENEFICIAIRE': 'matricule',
  BENEFITS_CALCULATOR_COLUMN_COMPANY_MEAL_COUNT: 'nombre passages',
}

const step: BenefitsCalculatorStepBuilder<BenefitsCalculatorImportStep<CompanyMealsHeaders>> = {
  config: {
    id: 'restauration-entreprise',
    component: () => import('@/views/BenefitsCalculator/Step.vue'),
    canSkip: true,
  },
  useStep(cfStep) {
    const fields: ImportField[] = cfStep.fields.map<ImportField>((cfField) => ({
      ...cfField,
      csvName: csvNames[cfField.fcmsName].toUpperCase(),
      required: true,
    }))

    const filename = ref('Beneficiaires.csv')
    const file = ref<File>()
    const fileContent = ref<CompanyMealsHeaders[] | null>(null)
    const status = ref(ImportStatus.Default)
    const errors = ref<string[]>([])

    const store = useBenefitsCalculatorStore()
    const i18n = useI18n()

    return {
      fields,
      filename,
      file,
      fileContent,
      status,
      errors,
      downloadTemplateAction: useAction({
        id: 'download-template',
        name: 'cart.import.download',
        icon: 'document-download',
        type: ActionType.Default,
        refresh: false,
        async execute() {
          const downloadFile = useDownloadFile()
          const blobFile = new Blob([fields.map((field) => field.csvName).join(';') ?? ''], {
            type: 'text/csv',
          })

          downloadFile(blobFile, i18n.t('benefitsCalculator.templateFilename').toString())
          return Result.ok(true)
        },
      }),
      importAction: useAction({
        id: 'import-file',
        name: 'cart.button.importCsv',
        type: ActionType.Default,
        refresh: false,
        async execute() {
          if (file.value == null) {
            return Result.err(new Error('cart.import.errors.fileNotExist'))
          }

          const resultSeparator = await fetchSeparator(file.value)
          if (resultSeparator.isErr) {
            return Result.err(resultSeparator.error)
          }

          status.value = ImportStatus.Importing
          filename.value = file.value.name
          fileContent.value = null
          errors.value = []

          const result = await parseCSV<CompanyMealsHeaders>(file.value)

          if (result.isErr) {
            status.value = ImportStatus.Error
            return Result.err(result.error)
          }

          fileContent.value = result.value

          fileContent.value.forEach((line, index) => {
            if (!line.matricule || !line['nombre passages']) {
              errors.value.push(i18n.t('benefitsCalculator.errors.lineBadFormat', { number: index + 1 }).toString())
              return
            }

            const companyMeal = Number.parseFloat(line['nombre passages'].replace(',', '.'))
            if (isNaN(companyMeal) || companyMeal < 0) {
              errors.value.push(
                i18n.t('benefitsCalculator.errors.companyMealBadFormat', { number: index + 1 }).toString(),
              )
              return
            }
          })

          if (errors.value.length > 0) {
            status.value = ImportStatus.Error
            return Result.err(new Error('cart.import.errors.fileNotExist'))
          }

          status.value = ImportStatus.Success

          return Result.ok(true)
        },
      }),
      saveAction: useAction({
        id: 'save',
        name: 'confirm',
        type: ActionType.Default,
        refresh: false,
        async execute() {
          if (fileContent.value == null) {
            return Result.err(new Error('cart.import.errors.fileNotExist'))
          }

          if (status.value !== ImportStatus.Success) {
            return Result.err(new Error('cart.import.errors.fileNotExist'))
          }

          fileContent.value.forEach((line) => {
            const item = store.items.find((item) => item.beneficiary.registrationNumber === line.matricule)

            if (!item) {
              return
            }

            item.companyMeals = Number.parseFloat(line['nombre passages'].replace(',', '.'))
          })
          return Result.ok(true)
        },
      }),
    }
  },
}

export default step
