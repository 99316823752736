import type { RouteConfig } from 'vue-router'
import { useBenefitsCalculatorStore } from '@/store/benefitsCalculator'
import { useStore as useToasterStore } from '@/store/toast'
import { accessor } from '@/store'
import { ProductCode } from '@/variables/ProductCode'
import { AlertType } from '@/types/Alert'

const DefaultRoot = () => import('@/views/DefaultRouter/Root/index.vue')

export const router: RouteConfig = {
  path: '/calculateur-de-droits',
  component: DefaultRoot,
  children: [
    {
      path: ':id?/:tab?',
      component: () => import('@/views/BenefitsCalculator/index.vue'),
      beforeEnter: async (to, _, next) => {
        const productClient = accessor.session.clientProducts[ProductCode.CARTE_TICKET_RESTAURANT]
        if (!productClient || !productClient.clientOptions.rightsComputeFeatureAllowed) {
          const toastStore = useToasterStore()
          toastStore.push({
            title: 'Calculateur de droits',
            description: "Vous n'avez pas les droits nécessaire pour accéder à cette page.",
            type: AlertType.DANGER,
            autoHide: false,
          })
          next('/')
          return
        }

        const benefitsCalculatorStore = useBenefitsCalculatorStore()
        const result = await benefitsCalculatorStore.fetchBenefitsCalculator()
        if (result.isErr) {
          console.log(result.error)
          return next('/404')
        }

        if (to.params.id == null) {
          benefitsCalculatorStore.reset()
          return next(`/calculateur-de-droits/${result.value.steps[0].id}`)
        }

        if (to.params.id !== result.value.steps[0].id && benefitsCalculatorStore.items.length === 0) {
          benefitsCalculatorStore.reset()
          return next(`/calculateur-de-droits/${result.value.steps[0].id}`)
        }

        if (result.value.steps.find((step) => step.id == to.params.id) == null) {
          return next('/404')
        }

        next()
      },
      props: (route) => {
        const { id, tab } = route.params

        return {
          id,
          tab,
        }
      },
    },
  ],
}
