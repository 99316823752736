export default {
  install(_vue, { jsonDico }) {
    _vue.prototype.$lang = function (key) {
      const val = jsonDico[key]
      if (val) {
        return val
      }
      console.warn(`key ${key} doesn't exist in trad`)
    }
  },
}
