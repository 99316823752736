import { Result } from '@badrap/result'
import type { Asset, ContentfulClientApi, Entry } from 'contentful'
import type { TypeAlertSkeleton, TypeCtaSkeleton, TypeLayoutSkeleton } from '../generated'
import { mapMediaToLocal } from '../types/CFMedia'
import type { CFTarget } from '../types/CFTarget'
import { mapCtasToLocal } from '../types/CFCta'
import { mapAlertToLocal } from '../types/CFAlert'
import type { CFLayout } from '../types/CFLayout'

export default (contentfulInstance: ContentfulClientApi<undefined>) => {
  const fetchLayout = async () => {
    try {
      const result = await contentfulInstance.getEntries<TypeLayoutSkeleton>({
        content_type: 'layout',
        'fields.target': 'EC',
      })
      return Result.ok(mapLayoutsToLocal(result.items)[0])
    } catch (error) {
      return Result.err(error as Error)
    }
  }

  return {
    fetchLayout,
  }
}

export function mapLayoutsToLocal(items: Entry<TypeLayoutSkeleton, undefined, string>[]): CFLayout[] {
  return items.map((item) => mapLayoutToLocal(item))
}

export function mapLayoutToLocal(item: Entry<TypeLayoutSkeleton, undefined, string>): CFLayout {
  return {
    target: item.fields.target as CFTarget,
    background: mapMediaToLocal(item.fields.background as Asset<undefined, string>),
    footerCtas: mapCtasToLocal(item.fields.footerCtas as Entry<TypeCtaSkeleton, undefined, string>[]),
    alert: item.fields.alert ? mapAlertToLocal(item.fields.alert as Entry<TypeAlertSkeleton, undefined, string>) : null,
    maintenanceAlert: mapAlertToLocal(item.fields.maintenanceAlert as Entry<TypeAlertSkeleton, undefined, string>),
    maintenanceImage: mapMediaToLocal(item.fields.maintenanceImage as Asset<undefined, string>),
  }
}
