export default {
  lead: {
    title: 'Me faire rappeler',
    digitalization:
      'Afin de passer à la carte, un conseiller va prendre le relai.<br/>Renseignez vos informations pour être recontacté rapidement.',
    companyInformations: 'Informations de votre entreprise :',
    contactInformations: 'Informations pour vous recontacter :',
    successMessage: 'Votre demande est validée, nous vous contacterons rapidement',
    errorMessage: "Une erreur est survenue lors de l'envoi du formulaire",
    errors: {
      companyMemberCount: {
        required: "L'effectif est requis",
        maxLength: 'La longueur maximale autorisée est de 6',
      },
      companyType: {
        required: "Le type de l'entreprise est requis",
      },
      lastName: {
        required: 'Le nom est requis',
      },
      firstName: {
        required: 'Le prénom est requis',
      },
      email: {
        required: "L'email est requis",
        email: 'Cet email a un format invalide',
      },
      phone: {
        required: 'Le numéro de téléphone est requis',
        phoneFormat: 'Mauvais format du numéro de téléphone',
      },
      service: {
        required: 'Le service est requis',
      },
      function: {
        required: 'La fonction est requise',
      },
    },
  },
}
