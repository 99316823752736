import { Result } from '@badrap/result'
import type { Asset, ContentfulClientApi, Entry } from 'contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { INLINES } from '@contentful/rich-text-types'
import type { TypeCtaSkeleton, TypeModalSkeleton } from '../generated'
import { mapMediaToLocal } from '../types/CFMedia'
import { mapCtaToLocal } from '../types/CFCta'
import type { CFModal } from '../types/CFModal'

export default (contentfulInstance: ContentfulClientApi<undefined>) => {
  const fetchModals = async () => {
    try {
      const result = await contentfulInstance.getEntries<TypeModalSkeleton>({
        content_type: 'modal',
      })
      return Result.ok(mapModalsToLocal(result.items))
    } catch (error) {
      return Result.err(error as Error)
    }
  }

  const fetchModal = async (id: string) => {
    try {
      const result = await contentfulInstance.getEntries<TypeModalSkeleton>({
        content_type: 'modal',
        'fields.id': id,
      })
      const items = mapModalsToLocal(result.items)

      if (items.length === 0) {
        return Result.err(new Error('Modal not found'))
      }
      return Result.ok(items[0])
    } catch (error) {
      return Result.err(error as Error)
    }
  }

  return {
    fetchModals,
    fetchModal,
  }
}

export function mapModalsToLocal(items: Entry<TypeModalSkeleton, undefined, string>[]): CFModal[] {
  return items.map((item) => mapModalToLocal(item))
}

export function mapModalToLocal(item: Entry<TypeModalSkeleton, undefined, string>): CFModal {
  return {
    ...item.fields,
    version: item.fields.version,
    description: item.fields.description
      ? documentToHtmlString(item.fields.description, {
          renderNode: {
            [INLINES.HYPERLINK]: (node, next) => {
              return `<a class="btn-link with-underline" target="_blank" href=${node.data.uri}>${next(
                node.content,
              )}</a>`
            },
          },
        })
      : null,
    conditionsToAccept: item.fields.conditionsToAccept
      ? documentToHtmlString(item.fields.conditionsToAccept, {
          renderNode: {
            [INLINES.HYPERLINK]: (node, next) => {
              return `<a class="btn-link with-underline" target="_blank" href=${node.data.uri}>${next(
                node.content,
              )}</a>`
            },
            [INLINES.ASSET_HYPERLINK]: (node, next) => {
              const media = mapMediaToLocal(node.data.target as Asset<undefined, string>)
              return `<a class="btn-link title-small with-underline" target="_blank" href=${media.src}>${next(
                node.content,
              )}</a>`
            },
          },
        })
      : null,
    image: item.fields.image ? mapMediaToLocal(item.fields.image as Asset<undefined, string>) : null,
    mainCta: item.fields.mainCta
      ? mapCtaToLocal(item.fields.mainCta as Entry<TypeCtaSkeleton, undefined, string>)
      : null,
  }
}
