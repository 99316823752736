import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Result } from '@badrap/result'
import type { Pagination } from '../common/types'
import urlReplacer from '../utils/urlReplacer'
import type { ApiAxiosError } from '../common/errors'
import { DematerialisationStatus, type GetInvoicesParams, type Invoice } from './types'
import { ApiInvoicesError, ApiInvoicesNotFoundError, ApiInvoicesErrorType } from './errors'

const baseUrl = 'api/:version/invoices'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
})

export default (axiosInstance: AxiosInstance) => {
  const getInvoices = async (params: GetInvoicesParams) => {
    try {
      const result = await axiosInstance.get<Pagination<Invoice>>(getBaseUrl(), { params })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(error as ApiAxiosError)
    }
  }

  const downloadOrSendInvoice = async (
    reference: string,
    dematerialisationStatus: DematerialisationStatus.Download | DematerialisationStatus.Email,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<Result<any, ApiInvoicesError>> => {
    const config: AxiosRequestConfig = {}

    if (dematerialisationStatus === DematerialisationStatus.Download) {
      config.responseType = 'blob'
    }

    try {
      const result = await axiosInstance.get(`${getBaseUrl()}/${reference}`, config)
      return Result.ok(result.data)
    } catch (error) {
      const _error = error as ApiAxiosError
      const statusCode = _error.response?.status ?? -1

      switch (statusCode) {
        case 404:
          return Result.err(new ApiInvoicesNotFoundError(_error))

        default:
          return Result.err(new ApiInvoicesError(ApiInvoicesErrorType.UNKNOWN, _error))
      }
    }
  }

  return {
    getInvoices,
    downloadOrSendInvoice,
  }
}
