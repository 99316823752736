import type { Route } from 'vue-router'
import type { TagEvent } from '../events'
import type { TrackingProbe } from './types'

export function log(): TrackingProbe {
  return {
    setMetaData: (metadata: Record<string, unknown>) => {
      console.log('LOG/CONFIG', metadata)
    },
    trackPageView: (to: Route, from: Route | undefined) => {
      console.log('LOG/PAGE_VIEW', to, from)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackEvent: (event: TagEvent, route: Route) => {
      console.log('LOG/EVENT', event, route)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    captureException: (error: Error, data: Record<string, unknown>, route: Route) => {
      console.error('LOG/EXCEPTION', error, data, route)
    },
  }
}
