import { Result } from '@badrap/result'
import * as Sentry from '@sentry/vue'
import { getterTree, actionTree, mutationTree } from 'typed-vuex'
import type { AxiosError } from 'axios'
import { accessor } from '..'
import { useMigrationStore } from '../migration'
import { useClientDataStore } from '../clientData'
import {
  anonymizeEmail,
  mapUserToLocal,
  type PermissionDetails,
  type ProductReferencial,
  type Right,
  type SessionState,
  type User,
} from './model'
import { hasPermission } from './permissionChecker'
import apiV3 from '@/services/apiV3'
import apiV4 from '@/services/apiV4'
import api from '@/services/api'
import type { ClientProducts } from '@/services/products/types'
import type { Universe } from '@/services/scopes/types'
import type { Account, Contract, Product } from '@/services/session/types'
import { Role } from '@/types/Role'
import { getInLocalStorage, setInLocalStorage } from '@/utils/localStorage'
import { universByProductCode, type UniversJson } from '@/assets/constantes/Univers'
import { ProductCode } from '@/variables/ProductCode'
import { useStore as useValueOfTitleStore } from '@/store/valueOfTitle'
import type { ArticleCode } from '@/variables/ArticleCode'
import { noVisuelImage } from '@/services/utils/product'
import { fetchImageFromClick } from '@/utils/image'
import contentful from '@/services/contentful'
import type { CFUniverse } from '@/services/contentful/universe/types'

export const namespaced = true
export const DIVISON = 'division'
export const IS_SUBSTITUTE = 'isSubstitute'

export const state: () => SessionState = () => ({
  division: getInLocalStorage<string>(DIVISON),
  isSubstitute: getInLocalStorage<boolean>(IS_SUBSTITUTE) ?? false,
  isAdmin: false,
  user: null,
  right: null,
  accounts: [],
  contracts: [],
  scopes: [],
  clientProducts: {},
  loading: false,
  univers: [],
  productReferencials: null,
  juridic: null,
  cfUniverse: [],
})

export const getters = getterTree(state, {
  loading: (state) => state.loading,
  division: (state) => state.division,
  isSubstitute: (state) => state.isSubstitute,
  isAdmin: (state) => {
    return !!state.user?.rights.find((data) => data.roleId === Role.ADMIN_EDENRED)
  },
  user: (state) => state.user,
  right: (state) => state.right,
  divisionsCount: (state) => state.user?.rights.length ?? 0,
  accounts: (state) => state.accounts,
  contracts: (state) => state.contracts,
  hasActiveContract: (state) => (codes: ProductCode[]) =>
    !!state.contracts.find((contract) => contract.isActive && codes.includes(contract.product.code)),
  hasContract: (state) => (codes: ProductCode[]) =>
    !!state.contracts.find((contract) => codes.includes(contract.product.code)),
  clientProducts: (state) => state.clientProducts,
  isUTREnabled: (_state, getters) => {
    if (getters.hasPermission(['TR__TRA-V1', 'TR__CTR-V1', 'TR__Mixte'])) {
      return false
    }

    return !!getters.getPermissions.find(
      (permission: string) => permission.includes('TR__') && !permission.includes('V1'),
    )
  },
  getScopes: (state) => state.scopes,
  getPermissions: (state) => {
    const permissions: string[] = []

    state.scopes.forEach((universe) => {
      permissions.push(universe.tag)

      if (universe.categories == null) {
        return
      }

      universe.categories.forEach((category) => {
        permissions.push(category.tag)

        if (category.functions == null) {
          return
        }

        category.functions.forEach((categoryFunction) => {
          permissions.push(categoryFunction.tag)
        })
      })
    })

    return permissions
  },
  getPermissionsDetails: (state) => {
    const permissions: PermissionDetails[] = []

    state.scopes.forEach((universe) => {
      permissions.push({
        tag: universe.tag,
      })

      if (universe.categories == null) {
        return
      }

      universe.categories.forEach((category) => {
        permissions.push({
          tag: category.tag,
          productCode: category.productCode,
          articleCode: category.articleCode,
        })

        if (category.functions == null) {
          return
        }

        category.functions.forEach((categoryFunction) => {
          permissions.push({
            tag: categoryFunction.tag,
            productCode: categoryFunction.productCode,
            articleCode: categoryFunction.articleCode,
          })
        })
      })
    })

    return permissions
  },
  getProductReferencials: (state) => (productCode: ProductCode, articleCode: ArticleCode | null, forRoute: string) =>
    state.productReferencials?.find(
      (productReferencial) =>
        productReferencial.productCode === productCode &&
        (productReferencial.articleCode ?? 0) === articleCode &&
        productReferencial.pages.includes(forRoute),
    ),
  getProductForRoute: (state) => (route: string, isActive?: boolean) => {
    return state.contracts.reduce<Product[]>((r, contract) => {
      if (isActive != null && isActive !== contract.isActive) {
        return r
      }

      const referencial = state.productReferencials?.find(
        (referencial) => referencial.productCode === contract.product.code && referencial.pages.includes(route),
      )
      if (referencial) {
        r.push(contract.product)
      }
      return r
    }, [])
  },
  getProductImg:
    (state) =>
    (productCode: ProductCode, articleCode: ArticleCode | null, isMixed = false) => {
      const univers = universByProductCode[productCode]
      const productImage = state.univers
        .find((uni) => uni.univers === univers)
        ?.product.find(
          (product) =>
            product.code === productCode &&
            product.articleCode === articleCode &&
            ((!isMixed && product.options?.isMixed !== true) || product.options?.isMixed === isMixed),
        )?.card.productImage

      return productImage ? fetchImageFromClick(productImage) : noVisuelImage
    },
  hasPermission: (_state, getters) => {
    return hasPermission(getters.getPermissions)
  },
})

export const mutations = mutationTree(state, {
  setLoading(state, loading: boolean) {
    state.loading = loading
  },
  setDivision(state, division: string | null) {
    state.division = division
    setInLocalStorage<string>(DIVISON, division)

    if (state.division == null || state.user == null) {
      Sentry.setUser(null)
    } else {
      Sentry.setUser({
        id: state.user.exc_sid,
        email: anonymizeEmail(state.user.email),
        division: state.division,
      })
    }
  },

  setIsSubstitute(state, isSubstitute: boolean | null) {
    state.isSubstitute = isSubstitute ?? false
    setInLocalStorage<boolean>(IS_SUBSTITUTE, isSubstitute)
  },

  setUser(state, user: User | null) {
    state.user = user
  },

  setRight(state, right: Right | null) {
    state.right = right
  },

  setAccounts(state, accounts: Account[]) {
    state.accounts = accounts
  },

  setContracts(state, contracts: Contract[]) {
    state.contracts = contracts
  },

  setScopes(state, scopes: Universe[]) {
    state.scopes = scopes
  },

  setClientProducts(state, clientProducts: ClientProducts) {
    state.clientProducts = clientProducts
  },

  setUnivers(state, univers: UniversJson[]) {
    state.univers = univers
  },

  setClientProductsCTRMillesimeEnabled(state, enabled: boolean) {
    const CTRCode = ProductCode.CARTE_TICKET_RESTAURANT
    if (
      state.clientProducts[CTRCode] &&
      state.clientProducts[CTRCode].clientOptions &&
      state.clientProducts[CTRCode].clientOptions.automaticMillesimeChange
    ) {
      state.clientProducts[CTRCode].clientOptions.automaticMillesimeChange.enabled = enabled
    }
  },

  setProductReferencials(state, productReferencials: ProductReferencial[]) {
    state.productReferencials = productReferencials
  },

  setCFUniverse(state, universe: CFUniverse[]) {
    state.cfUniverse = universe
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    async fetchSession({ commit, dispatch }) {
      const valueOfTitleStore = useValueOfTitleStore()
      const migrationStore = useMigrationStore()
      const clientDataStore = useClientDataStore()

      if (!accessor.auth.isLoggedIn || !accessor.auth.token) {
        await valueOfTitleStore.fetchConfigs()
        return Result.ok(true)
      }

      const userResult = mapUserToLocal(accessor.auth.token.accessToken)
      if (userResult.isErr) {
        return userResult
      }

      commit('setUser', userResult.value)
      await dispatch('loadRight')

      const result = await Promise.all([
        apiV3.session.getContracts(),
        apiV4.scopes.getScopes(),
        apiV3.session.getAccounts(),
        apiV4.products.getProducts(),
        valueOfTitleStore.fetchConfigs(),
        migrationStore.fetchInformations(),
        clientDataStore.getClientData(),
      ])

      const value = result.find((value) => !value.isOk)
      if (value) {
        return value
      }

      try {
        const univers = [
          await api.getClickProxy('Documents_Espace_Client/Produits/Univers/TR/univers-v5.20.json'),
          await api.getClickProxy('Documents_Espace_Client/Produits/Univers/TK/univers-v5.19.json'),
          await api.getClickProxy('Documents_Espace_Client/Produits/Univers/TS/univers.json'),
        ]
        commit('setUnivers', univers)
      } catch (error) {
        return Result.err(error as AxiosError)
      }

      const resultUniverse = await contentful.universe.fetchUniverses()
      if (resultUniverse.isOk) {
        commit('setCFUniverse', resultUniverse.value)
      } else {
        return Result.err(resultUniverse.error)
      }

      commit('setContracts', result[0].isOk ? result[0].value : [])
      commit('setScopes', result[1].isOk ? result[1].value : [])
      commit('setAccounts', result[2].isOk ? result[2].value : [])
      commit('setClientProducts', result[3].isOk ? result[3].value : {})

      return Result.ok(true)
    },

    async loadRight({ state, commit }) {
      if (!state.user) {
        return
      }
      const user = state.user

      let right: Right = user.rights[0]

      if (!state.division) {
        commit('setIsSubstitute', false)
        commit('setRight', right)
        commit('setDivision', right.division)
        return
      }

      if (state.isSubstitute) {
        const result = await apiV3.session.getSubstituteClient()
        const rht = user.rights.find((right) => right.roleId === Role.ADMIN_EDENRED)

        if (result.isOk && rht) {
          commit('setRight', {
            ...right,
            division: state.division,
            companyName: result.value.name,
          })
          return
        }
      }

      const rht = user.rights.find((right) => right.division === state.division)
      if (rht) {
        right = rht
      }

      commit('setIsSubstitute', false)
      commit('setRight', right)
      commit('setDivision', right.division)
    },

    async fetchReferenceProduct({ state, commit }) {
      if (state.productReferencials !== null) {
        return Result.ok(state.productReferencials)
      }

      try {
        const productReferencials = await api.getClickProxy('Documents_Espace_Client/Produits/products.json')
        commit('setProductReferencials', productReferencials)

        return Result.ok(productReferencials)
      } catch (error) {
        return Result.err(error as Error)
      }
    },

    clearSession({ commit }) {
      commit('setDivision', null)
      commit('setIsSubstitute', null)
      commit('setUser', null)
      commit('setRight', null)
      commit('setContracts', [])
      commit('setAccounts', [])
      commit('setClientProducts', {})

      const migrationStore = useMigrationStore()
      migrationStore.resetState()

      const clientDataStore = useClientDataStore()
      clientDataStore.resetState()

      Sentry.configureScope((scope) => scope.setUser(null))
    },
  },
)
