import { type ApiAxiosError, ApiError } from '../common/errors'
import { UNKNOWN_ERROR, UNKNOWN_ERROR_MESSAGE } from '@/types/ResultError'

export const errorMessage: Record<string, string> = {
  'Order.Activate000': "La commande n'existe pas",
  'Order.Activate001': 'Commande en envoi benef',
  'Order.Activate002': 'Code produit inexistant sur le contrat',
  'Order.Activate004': 'Produit non supporté',
  'Order.Activate005': 'order_acknowledgment non valorisé à true',
  'Order.Activate006': "Un des colis n'est pas encore éligible à l'activation",
  'Order.Activate007': 'Identifiant application non renseigné',
  'Order.Activate008': "Toutes les cartes de la commande sont activées ou en cours d'activation",
  'Order.Activate009':
    'Les cartes de votre commande ont été déclarées perdues, endommagées ou volées. Pour activer les cartes réémises, merci de nous contacter au 0 821 23 24 25 (0,12cts/min + prix appel).',
}

export function getError(key: string): string {
  return errorMessage[key] ?? UNKNOWN_ERROR_MESSAGE
}

export class ApiCardsActivationError extends ApiError {
  constructor(error: ApiAxiosError) {
    super(error)

    this.name = 'ApiCardsActivationError'
    this.code = error.response?.data.title ?? UNKNOWN_ERROR
    this.message = getError(this.code)
  }
}
