import _findIndex from 'lodash/findIndex'
import { deliveryPointsPagination } from './utils/deliveryPointsPagination.js'
import { getSortedPL } from './utils/FiltresPL.js'
import { getPdist } from './utils/FlitresPDistByPL.js'
import api from '@/services/api'
import apiV4 from '@/services/apiV4'

export const state = () => ({
  DeliveryPoints: deliveryPointsPagination,
  AllDeliveryPoints: null,
  DeliveryChoice: '',
  SelectedDeliveryPoint: null,
  deliveryPointsLoaded: false,
})

export const getters = {
  DeliveryPoints(state) {
    return state.Customer.DeliveryPoints.items
  },
  AllDeliveryPoints: (state) => state.Customer.AllDeliveryPoints,
  DeliveryPointIndexByReference: (state) => (reference) =>
    state.Customer.DeliveryPoints.items.findIndex((pl) => pl.reference === reference),
  DeliveryPointByReference: (state) => (reference) =>
    state.Customer.DeliveryPoints.items.find((pl) => pl.reference === reference),
  PaginationDeliveryPoints(state) {
    return state.Customer.DeliveryPoints
  },

  DeliveryChoice(state) {
    return state.Customer.DeliveryChoice
  },
  SelectedDeliveryPoints(state) {
    return state.Customer && state.Customer.SelectedDeliveryPoint
  },
  getFilteredPlNew: (state, getters) => (deliveryPoints) => {
    if (!deliveryPoints) {
      return null
    }
    return getters.getFilterPlFormList(deliveryPoints)
  },
  getFilteredPl(state, getters) {
    const deliveryPoints = [...getters.DeliveryPoints]
    return getters.getFilterPlFormList(deliveryPoints)
  },
  getFilteredPlByReference: (state, getters) => (reference) =>
    getters.getFilteredPl.find((pl) => pl.value === reference),
  getPDistFilteredByPL: (state, getters) => (reference) => {
    const deliveryPoint = getters.DeliveryPointByReference(reference)
    const distributionPointMandatory = getters.getDistributionPointMandatory
    return getPdist(deliveryPoint, distributionPointMandatory)
  },
  isDeliveryPointsLoaded: (state) => state?.Customer?.deliveryPointsLoaded,
  getFilterPlFormList: (state, getters) => (plList) => {
    if (!plList?.length) {
      return []
    }
    const distributionPointMandatory = getters.getDistributionPointMandatory
    const deliveryChoice = getters.DeliveryChoice
    return getSortedPL(distributionPointMandatory, deliveryChoice, plList)
  },
  getDeliveryPointByRef: (state, getters) => (deliveryPointRef) =>
    getters.DeliveryPoints.find((pl) => pl.reference === deliveryPointRef),
}

export const mutations = {
  SET_ALL_DELIVPOINTS(state, payload) {
    state.Customer.AllDeliveryPoints = payload
  },
  SET_DELIVERY_POINTS(state, payload) {
    state.Customer.DeliveryPoints = payload
  },
  SET_DISTRIBUTION_POINTS(state, distributionPoints) {
    if (!state.Customer?.DeliveryPoints?.items.length) {
      return
    }
    const idx = _findIndex(state.Customer.DeliveryPoints.items, (pl) => pl.id === distributionPoints?.Id)
    if (idx !== -1) {
      state.Customer.DeliveryPoints.items[idx].distributionPoints = distributionPoints.DistributionPoints
    }
  },
  RESET_DELIVERY_POINTS(state) {
    state.Customer.DeliveryPoints.items = []
  },
  SET_DELIVERY_CHOICE(state, payload) {
    state.Customer.DeliveryChoice = payload
  },
  SET_SELECTED_DELIVERY_POINT(state, payload) {
    state.Customer.SelectedDeliveryPoint = payload
  },
  SET_DELIVERY_POINT_IS_LOADED(state, payload) {
    state.Customer.deliveryPointsLoaded = payload
  },
}

export const actions = {
  async getDeliveryPointsOnDemand({ state, commit }, payload) {
    try {
      commit('SET_DELIVERY_POINT_IS_LOADED', false)
      const prodCode = payload?.productCode || state.ProductCode
      const deliveryPointsPaginationFiltered = {
        pageSize: deliveryPointsPagination?.pageSize,
        page: deliveryPointsPagination?.page,
        filter: deliveryPointsPagination?.filter,
        sort: deliveryPointsPagination?.sort,
        sortDirection: deliveryPointsPagination?.sortDirection,
      }
      const params = Object.assign({}, deliveryPointsPaginationFiltered, {
        productCode: prodCode,
        deliveryPointsType: payload?.deliveryPointsType,
      })
      const data = await apiV4.deliveryPoints.getDeliveryPointsListV4(params)
      commit('SET_DELIVERY_POINTS', data.data)
      commit('SET_DELIVERY_POINT_IS_LOADED', true)
      return data.items
    } catch (e) {
      commit('SET_DELIVERY_POINT_IS_LOADED', true)
      throw e
    }
  },

  async setSelectedDeliveryPoint({ dispatch, commit }, { deliveryPoint, needPdist, productCode }) {
    if (needPdist && deliveryPoint) {
      await dispatch('getPdistOfPl', { deliveryPoint, productCode })
    }
    commit('SET_SELECTED_DELIVERY_POINT', deliveryPoint?.value ? deliveryPoint.value : deliveryPoint)
  },

  async getPdistOfPl({ state, getters, commit }, payload) {
    const pl = getters.DeliveryPointByReference(payload.deliveryPoint.value)
    if (!pl?.distributionPoints) {
      const productCode = payload.productCode ? payload.productCode : state.ProductCode
      const pdist = await api.getDeliveryPointDetails(productCode, payload.deliveryPoint.id)
      commit('SET_DISTRIBUTION_POINTS', pdist)
    }
  },

  setDeliveryChoice({ commit }, deliveryChoice) {
    commit('SET_DELIVERY_CHOICE', deliveryChoice)
  },

  setAllDelivPoints({ commit }, payload) {
    commit('SET_ALL_DELIVPOINTS', payload)
  },

  resetDeliveryPoints({ commit }) {
    commit('RESET_DELIVERY_POINTS')
  },
}
