export function useDownloadFile() {
  return (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.setAttribute('style', 'display: none')

    document.body.appendChild(a)

    a.href = url
    a.target = '_blank'
    a.download = fileName
    a.click()

    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }
}

export function useDownloadCSVFile() {
  return (lines: string[], fileName: string) => {
    const blobFile = new Blob(['\uFEFF' + lines.join('\n')], {
      type: 'text/csv; charset=utf-8',
    })

    const downloadFile = useDownloadFile()
    downloadFile(blobFile, fileName)
  }
}
