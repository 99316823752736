import type { Result } from '@badrap/result'
import type { Ref } from 'vue'

export enum ActionType {
  Step = 'step',
  Popin = 'popin',
  Drawer = 'drawer',
  Default = 'default',
}

export interface ActionConstructor<T = boolean> {
  id: string
  type: ActionType
  icon?: string
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: Record<string, any>
  refresh: boolean
  execute?(): Promise<Result<T, Error>>
}

export interface Action<T = boolean> {
  id: string
  icon?: string
  name: string
  loading: Ref<boolean>
  disabled: Ref<boolean>
  execute(): Promise<Result<T, Error>>
}
