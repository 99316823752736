import { CartItemType, type CartItem, type Message } from '@/services/carts/types'
import { useStore as useCartStore } from '@/store/cart/store'
import type { ProductLightItemTracking, ProductItemTracking } from '@/store/tracking/events/commerce'
import type { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'

export function hasMessage(message: Message): boolean {
  return !!(message.line1 || message.line2 || message.line3)
}

export function useProductLightItemTracking(
  productCode: ProductCode,
  articleCode: ArticleCode | null = null,
  isNominative = false,
): ProductLightItemTracking | null {
  const cartStore = useCartStore()
  const product = cartStore.product(productCode)
  if (product == null) {
    return null
  }

  const article = cartStore.article(productCode, articleCode)
  let title
  if (article) {
    title = article.name
  } else {
    title = product.name
  }

  return {
    item_id: productCode.toString(),
    item_name: product.name,
    item_brand: product.universe.label,
    item_category: title,
    item_typology: isNominative ? 'nominatif' : 'anonyme',
  }
}

export function useKSProductItemTracking(
  productCode: ProductCode,
  articleCode: ArticleCode | null,
  isNominative: boolean,
  item: CartItem,
): ProductItemTracking | null {
  const product = useProductLightItemTracking(productCode, articleCode, isNominative)

  if (product == null) {
    return null
  }

  return {
    ...product,
    item_category2: item.eventCode,
    item_category3: item.itemType,
    item_variant: hasMessage(item.message),
    quantity: item.itemType !== CartItemType.Booklet ? item.totalTitleQuantities : item.packagingQuantity,
    price: (item.itemType !== CartItemType.Booklet ? item.compositions[0].titleValue : item.totalTitleAmount) / 100,
  }
}

export function useCKSProductItemTracking(
  productCode: ProductCode,
  articleCode: ArticleCode | null,
  isNominative: boolean,
  item: CartItem,
): ProductItemTracking | null {
  const product = useProductLightItemTracking(productCode, articleCode, isNominative)

  if (product == null) {
    return null
  }

  return {
    ...product,
    item_category2: item.eventCode,
    item_category3: item.itemType,
    item_variant: hasMessage(item.message),
    quantity: item.packagingQuantity,
    price: item.totalTitleAmount / 100,
  }
}

export function useKSCProductItemTracking(
  productCode: ProductCode,
  articleCode: ArticleCode | null,
  isNominative: boolean,
  item: CartItem,
): ProductItemTracking | null {
  const product = useProductLightItemTracking(productCode, articleCode, isNominative)

  if (product == null) {
    return null
  }

  return {
    ...product,
    item_category2: item.eventCode,
    item_category3: item.itemType,
    item_variant: hasMessage(item.message),
    quantity: 1,
    price: item.totalAmount / 100,
  }
}

export function useTrProductItemTracking(
  productCode: ProductCode,
  articleCode: ArticleCode | null,
  isNominative: boolean,
  item: CartItem,
): ProductItemTracking | null {
  const product = useProductLightItemTracking(productCode, articleCode, isNominative)

  if (product == null) {
    return null
  }

  return {
    ...product,
    item_category2: item.eventCode?.toString(),
    item_category3: item.itemType,
    quantity: item.packagingQuantity,
    price: item.totalTitleAmount / 100,
  }
}

export function useProductItemTracking(
  productCode: ProductCode,
  articleCode: ArticleCode | null = null,
  isNominative: boolean,
  item: CartItem,
): ProductItemTracking | null {
  const product = useProductLightItemTracking(productCode, articleCode, isNominative)

  if (product == null) {
    return null
  }

  if (productCode === ProductCode.KADEOS) {
    return {
      ...product,
      ...useKSProductItemTracking(productCode, articleCode, isNominative, item),
    }
  } else if (productCode === ProductCode.CARTE_KADEOS) {
    return {
      ...product,
      ...useCKSProductItemTracking(productCode, articleCode, isNominative, item),
    }
  } else if (
    productCode === ProductCode.TICKET_RESTAURANT ||
    productCode === ProductCode.TICKET_RESTAURANT_BENEVOLES_VOLONTAIRE ||
    productCode === ProductCode.CARTE_TICKET_RESTAURANT
  ) {
    return {
      ...product,
      ...useTrProductItemTracking(productCode, articleCode, isNominative, item),
    }
  } else if (productCode === ProductCode.KADEOS_CONNECT) {
    return {
      ...product,
      ...useKSCProductItemTracking(productCode, articleCode, isNominative, item),
    }
  }

  return product
}
