import { Result } from '@badrap/result'
import type { ContentfulClientApi } from 'contentful'
import type { TypeBenefitsCalculatorSkeleton } from './generated'
import { mapBenefitsCalculatorToLocal } from './types/CFBenefitsCalculator'

export default (contentfulInstance: ContentfulClientApi<undefined>) => {
  const fetchBenefitsCalculator = async () => {
    try {
      const result = await contentfulInstance.getEntries<TypeBenefitsCalculatorSkeleton>({
        content_type: 'benefitsCalculator',
        include: 10,
      })
      if (result.items.length === 0) {
        return Result.err(new Error('No benefits calculator'))
      }
      return Result.ok(mapBenefitsCalculatorToLocal(result.items[0]))
    } catch (error) {
      return Result.err(error as Error)
    }
  }

  return {
    fetchBenefitsCalculator,
  }
}
