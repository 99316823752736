export default (axiosInstance) => {
  const getDatasFiltered = async (url, params) => {
    try {
      const result = await axiosInstance.get(url, { params })
      return result
    } catch (error) {
      return error.response
    }
  }

  return {
    getDatasFiltered,
  }
}
