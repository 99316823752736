import type { FilteredRequest, PaginatedRequest, SortRequest } from '../types'
import type { Address } from '@/types/Address'
import type { Comma } from '@/utils/csv'
import type { ProductCode } from '@/variables/ProductCode'

export interface PaginationParams extends SortRequest, PaginatedRequest, FilteredRequest {
  deliveryPointId?: number
  displayed?: boolean
  outsideOfCartId?: number | null
}

export interface Beneficiary {
  id: number
  civility: Civility | null
  registrationNumber: string
  name: string
  firstName: string
  birthdate: string
  email: string | null
  address: Address | null
  cellPhone: string | null
  operationalConfigurations: OperationalConfiguration[] | null
  dateLastUpdate: string | null
  lastUpdateAuthor: string | null
  displayed: boolean
}

export interface BeneficiaryParcel {
  parcelId: number
  orderTrackingNumber: string | null
  orderReference: string | null
  orderDate: string | null
  productCode: ProductCode
  carrierUrl: string
  deliveryStatus: string
  loadingStatus: string
  titleQuantity: number
  orderedAmount: number
}

export interface BeneficiaryCard {
  cardReference: string | null
  productCode: ProductCode | null
  cardStatus: string | null
  dateStatus: string | null
  dateEnd: string | null
}

export enum Civility {
  NotSet = 0,
  Sir = 1,
  Madam = 2,
  Miss = 3,
  Doctor = 5,
  Professor = 6,
  Master = 12,
}

export enum OperationalConfigurationStatus {
  Active = 'Active',
  Disable = 'Disable',
}

export interface OperationalConfiguration {
  beneficiaryId: number | null
  productCode: ProductCode
  employersContribution: number | null
  facialValue: number | null
  deliveryPointRef: string | null
  distributionPointRef: string | null
  status: OperationalConfigurationStatus | null
  nonWorkingDayAllowed: boolean | null
}

export interface CommonBeneficiaryRequest {
  civility?: Civility | null
  registrationNumber: string
  name: string
  firstName: string
  birthdate: string
  email?: string | null
  cellPhone?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipCode?: string | null
  city?: string | null
  country?: string | null
  operationalConfigurations?: OperationalConfiguration[] | null
  nonWorkingDayAllowed?: boolean | null
}

export interface PatchBeneficiaryRequest extends CommonBeneficiaryRequest {
  displayed: boolean
}

export interface PostBeneficiaryRequest extends CommonBeneficiaryRequest {
  productCode: ProductCode
}

export type BeneficiaryRequest = PatchBeneficiaryRequest & PostBeneficiaryRequest

export interface PostImportRequestBody {
  fileUpload: File
  separator: Comma
}

export interface ImportConflict {
  id: number
  beneficiaryId: number | null
  resolved: boolean
  registrationNumber: string | null
  name: string | null
  firstName: string | null
  birthdate: string | null
  email: string | null
  dateLastUpdate: string | null
  lastUpdateAuthor: string | null
}

export interface ImportDuplicate {
  id: number
  registrationNumber: string | null
  name: string | null
  firstName: string | null
  birthdate: string | null
  email: string | null
  dateLastUpdate: string | null
  lastUpdateAuthor: string | null
}
