import type { AxiosError } from 'axios'
import { type ApiAxiosError, ApiError } from '../common/errors'
import { UNKNOWN_ERROR, UNKNOWN_ERROR_MESSAGE } from '@/types/ResultError'

export const errorMessage: Record<string, string> = {
  FACIAL_VALUE_DUPLICATE: 'La valeur du titre existe déjà',
}

export type ApiFacialValueAxiosError = AxiosError<ApiAxiosError & { Message?: string }>

export function getError(key: string): string {
  return errorMessage[key] ?? UNKNOWN_ERROR_MESSAGE
}

export class ApiFacialValueError extends ApiError {
  constructor(error: ApiAxiosError) {
    super(error)

    const e = error as ApiFacialValueAxiosError
    if (this.code === UNKNOWN_ERROR && e.response?.data.Message) {
      this.code = e.response?.data.Message
    }
    this.name = 'ApiFacialValueError'
    this.message = getError(this.code)
  }
}
