import type { Entry } from 'contentful'
import type { TypeCtaSkeleton, TypeImportFieldSkeleton } from '../generated'
import { mapCtaToLocal } from '../types/CFCta'
import type { CFCta } from './CFCta'
import type { ProductCode } from '@/variables/ProductCode'
import { documentToHtmlString } from '@/utils/richTextHtmlRenderer'

export type CFImportField = {
  fcmsName: string
  label: string
  description: string
  examples?: string[] | null
  cta?: CFCta | null
  productCode?: ProductCode
}

export function mapImportFieldsToLocal(items: Entry<TypeImportFieldSkeleton, undefined, string>[]): CFImportField[] {
  return items.map((item) => ({
    ...item.fields,
    examples: item.fields.examples,
    description: documentToHtmlString(item.fields.description),
    cta: item.fields.cta ? mapCtaToLocal(item.fields.cta as Entry<TypeCtaSkeleton, undefined, string>) : null,
    productCode: item.fields.productCode as ProductCode,
  }))
}
