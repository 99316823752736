import { ProductCode } from './ProductCode'

const UNIVERS_NAMES = {
  TK: 'KADEOS',
  TR: 'TICKET RESTAURANT',
  TC: 'TICKET CESU',
  TS: 'TICKET SERVICE',
  TFP: 'TICKT FLEET PRO',
}

const UNIVERS_MAP = {
  [UNIVERS_NAMES.TK]: [
    ProductCode.KADEOS,
    ProductCode.KADEOS_CONNECT,
    ProductCode.KADEOS_UNIVERSEL,
    ProductCode.CARTE_KADEOS,
    ProductCode.KADEOS_CONNECT,
  ],
  [UNIVERS_NAMES.TR]: [
    ProductCode.TICKET_RESTAURANT,
    ProductCode.TICKET_RESTAURANT_BENEVOLES_VOLONTAIRE,
    ProductCode.CARTE_TICKET_RESTAURANT,
    ProductCode.BETTERWAY,
  ],
  [UNIVERS_NAMES.TC]: [ProductCode.TICKET_CESU],
  [UNIVERS_NAMES.TFP]: [ProductCode.TICKET_FLEETPRO],
  [UNIVERS_NAMES.TS]: [ProductCode.TICKET_SERVICE, ProductCode.TICKET_CAP],
}

export { UNIVERS_NAMES, UNIVERS_MAP }
