import { Result } from '@badrap/result'
import type { AxiosError, AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import type { Pagination } from '../common/types'
import { CartsError, CartsPaymentsError } from './errors'
import {
  type CartV1,
  type CartItemBeneficiaryV1,
  type BeneficiariesImportResultRequest,
  type CreateCartItemRequestV1,
  type CreateCartRequestV1,
  type DeliveryDatesEstimation,
  type FetchCartItemsParams,
  type CommonCartItemRequest,
  type PatchImportBeneficiary,
  type Pricing,
  type PaymentMeanV1,
  type PaymentsResponse,
  type PaymentRequestBody,
  type CartItem,
  v1PaymentMeanToV2,
  v2PaymentMeanToV1,
} from './types'
import cartsV2Api from './cartsV2Api'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = 'api/:version/carts/:cartId'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
  cartId: '',
})

export default (axiosInstance: AxiosInstance) => {
  const createV1 = async (datas: CreateCartRequestV1) => {
    try {
      const result = await axiosInstance.post<CartV1>(getBaseUrl(), datas)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const fetchV1 = async (cartId: number) => {
    try {
      const result = await axiosInstance.get<CartV1>(getBaseUrl({ cartId }))
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.message
    }
  }

  const getBeneficiariesByStatusV1 = async (cartId: number, params: BeneficiariesImportResultRequest) => {
    try {
      const result = await axiosInstance.get<Pagination<CartItemBeneficiaryV1>>(
        `${getBaseUrl({ cartId })}/import/beneficiaries`,
        { params },
      )
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const patchBeneficiaryV1 = async (cartId: number, beneficiaryId: number, params: PatchImportBeneficiary) => {
    try {
      const result = await axiosInstance.patch(
        `${getBaseUrl({ cartId })}/import/beneficiaries/${beneficiaryId}`,
        params,
      )
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const getBeneficiaryDuplicatesV1 = async (cartId: number, beneficiaryId: number) => {
    try {
      const result = await axiosInstance.get<CartItemBeneficiaryV1[]>(
        `${getBaseUrl({ cartId })}/import/beneficiaries/${beneficiaryId}/duplicates`,
      )
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const fetchItemsV1 = async (cartId: number, params: FetchCartItemsParams) => {
    try {
      const result = await axiosInstance.get<Pagination<CartItem>>(`${getBaseUrl({ cartId })}/items`, { params })
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const createItemV1 = async (cartId: number, item: CreateCartItemRequestV1) => {
    try {
      const result = await axiosInstance.post(`${getBaseUrl({ cartId })}/items`, item)
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const deleteItemV1 = async (cartId: number, itemId: number) => {
    try {
      const result = await axiosInstance.delete(`${getBaseUrl({ cartId })}/items/${itemId}`)
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const patchItemV1 = async (cartId: number, itemId: number, patch: CommonCartItemRequest) => {
    try {
      const result = await axiosInstance.patch(`${getBaseUrl({ cartId })}/items/${itemId}`, patch)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getItemsCountV1 = async (cartId: number) => {
    try {
      const result = await axiosInstance.head(`${getBaseUrl({ cartId })}/items`)
      return parseInt(result.headers['x-total-count'] || '', 10)
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const getPricingV1 = async (cartId: number, readOnly = false) => {
    if (!cartId) {
      return
    }

    try {
      const result = await axiosInstance.get<Pricing>(`${getBaseUrl({ cartId })}/pricing`, { params: { readOnly } })
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const getDeliveryEstimationV1 = async (cartId: number) => {
    try {
      const result = await axiosInstance.get<DeliveryDatesEstimation>(`${getBaseUrl({ cartId })}/deliveryestimation`)
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const getAllOpenBasketsV1 = async (params: {
    productCode: number
    articleCode?: number
    page?: number
    pageSize?: number
  }) => {
    try {
      const result = await axiosInstance.get<CartV1[]>(getBaseUrl(), { params })
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const getValidationUrlV1 = () => {
    return `${getBaseUrl()}/payments/validation`
  }

  const fetchItemsCount = async (cartId: number) => {
    try {
      const result = await axiosInstance.head(`${getBaseUrl({ cartId })}/items`)
      return Result.ok(parseInt(result.headers['x-total-count'] || '', 10))
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const fetchPricingV1 = async (cartId: number, readOnly = false) => {
    try {
      const result = await axiosInstance.get<Pricing>(`${getBaseUrl({ cartId })}/pricing`, { params: { readOnly } })
      return result.data
    } catch (error) {
      throw new CartsError(error as ApiAxiosError)
    }
  }

  const fetchDeliveryEstimationV1 = async (cartId: number) => {
    try {
      const result = await axiosInstance.get<DeliveryDatesEstimation>(`${getBaseUrl({ cartId })}/deliveryestimation`)
      return result.data
    } catch (error) {
      throw new CartsError(error as ApiAxiosError)
    }
  }

  const getPaymentMeans = async (cartId: number) => {
    try {
      const result = await axiosInstance.get<PaymentMeanV1[]>(`${getBaseUrl({ cartId })}/paymentMeans`)
      return result.data.map((paymentMean) => v1PaymentMeanToV2[paymentMean])
    } catch (error) {
      const _error = error as ApiAxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const postPaymentsV1 = async (cartId: number, body: PaymentRequestBody) => {
    try {
      const result = await axiosInstance.post<PaymentsResponse>(`${getBaseUrl({ cartId })}/payments`, {
        ...body,
        paymentMean: v2PaymentMeanToV1[body.paymentMean],
      })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsPaymentsError(error as ApiAxiosError))
    }
  }

  return {
    createV1,
    fetchV1,
    getBeneficiariesByStatusV1,
    getBeneficiaryDuplicatesV1,
    patchBeneficiaryV1,
    fetchItemsV1,
    createItemV1,
    deleteItemV1,
    patchItemV1,
    getItemsCountV1,
    getPricingV1,
    getDeliveryEstimationV1,
    getAllOpenBasketsV1,
    getValidationUrlV1,
    fetchItemsCount,
    fetchPricingV1,
    fetchDeliveryEstimationV1,
    getPaymentMeans,
    postPaymentsV1,
    ...cartsV2Api(axiosInstance),
  }
}
