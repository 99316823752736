import * as parameters from '@/parameters'
import { PartnerReference } from '@/services/products/types'
import { accessor } from '@/store'
import { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'

export function fetchTerms(productCode: ProductCode, articleCode: ArticleCode | null, isMixed = false): string | null {
  if (isMixed) {
    return `${parameters.clickUrl}Documents_Espace_Client/CGV/CGV_Edenred_Mixte.pdf`
  }

  const partnerReference = accessor.session.clientProducts[productCode]?.partnerReference

  switch (productCode) {
    case ProductCode.TICKET_RESTAURANT:
      return `${parameters.clickUrl}Documents_Espace_Client/CGV/CGV_Edenred_TR.pdf`

    case ProductCode.CARTE_TICKET_RESTAURANT: {
      if (articleCode === ArticleCode.CARTE_TICKET_RESTAURANT_FLEX) {
        return `${parameters.clickUrl}Documents_Espace_Client/CGV/CGV_Edenred_CTR_Flex.pdf`
      } else if (partnerReference && [PartnerReference.CMC, PartnerReference.CIC].includes(partnerReference)) {
        return `${parameters.clickUrl}Documents_Espace_Client/CGV/CGV CMCCIC 765229_765228.pdf`
      } else {
        return `${parameters.clickUrl}Documents_Espace_Client/CGV/CGV_Edenred_CTR3.pdf`
      }
    }

    case ProductCode.KADEOS_CONNECT:
      return `${parameters.clickUrl}Documents_Espace_Client/CGV/CGV_Edenred_KSC.pdf`

    case ProductCode.CARTE_KADEOS: {
      if (
        articleCode === ArticleCode.CARTE_KADEOS_ZENITH_CE ||
        articleCode === ArticleCode.CARTE_KADEOS_ZENITH_DE ||
        articleCode === ArticleCode.CARTE_KADEOS_CULTURE
      ) {
        return `${parameters.clickUrl}Documents_Espace_Client/Kadeos/Carte/CGV_Zenith_Culture.pdf`
      }
      break
    }

    case ProductCode.KADEOS:
      return `${parameters.clickUrl}Documents_Espace_Client/Kadeos/Cheque/CGV.pdf`
  }

  return null
}
