import { accessor } from '..'
import basketModel from './models/basket.json'
import millesimeFormater from './utils/millesimeFormater'
import api from '@/services/api'
import apiV4 from '@/services/apiV4'
import paymentMeans from '@/variables/paymentMeansConstantes.json'
import { UNIVERS_MAP, UNIVERS_NAMES } from '@/variables/productUniversByProductCode'

const namespaced = true

const persistent = true

const BASKET_KEY = 'basket'
const ITEMS_KEY = 'items'
const VALORISATION_KEY = 'valorisation'
const ORDER_KEY = 'order'
const MILLESIME_KEY = 'millesime'
const PAYMENT_MEANS = 'paymentMeans'
const CONFIG_PROD = 'productConfiguration'

const state = () => ({
  [CONFIG_PROD]: null,
  [BASKET_KEY]: {
    ...basketModel,
  },
  [ITEMS_KEY]: [],
  [VALORISATION_KEY]: null,
  [ORDER_KEY]: null,
  isLoaded: {
    [BASKET_KEY]: true,
    [ITEMS_KEY]: true,
    [VALORISATION_KEY]: true,
    [ORDER_KEY]: true,
    [MILLESIME_KEY]: true,
    [PAYMENT_MEANS]: true,
  },
  [MILLESIME_KEY]: {
    productCode: null,
    data: null,
  },
  [PAYMENT_MEANS]: [],
})

const getters = {
  getReferenceIsMandatory: (state) => state[CONFIG_PROD]?.orderSettings.orderReferenceIsMandatory,
  getOrderReferenceFixed: (state) => state[CONFIG_PROD]?.orderSettings.defaultOrderReference,
  isInterimProduct: (state) => state[CONFIG_PROD]?.clientOptions?.multiloadingAllowed || false,
  getBasket: (state) => state[BASKET_KEY],
  getBasketId: (state) => state[BASKET_KEY]?.id,
  getReferenceCommande: (state) => state[BASKET_KEY]?.referenceCommande,
  getProductCode: (state) => state[BASKET_KEY]?.productCode,
  getArticleCode: (state) => state[BASKET_KEY]?.articleCode || 0,
  getPreorderId: (state) => state[BASKET_KEY]?.preorderRedcartId,
  getIsXsell: (state) => state[BASKET_KEY]?.isXSell,
  getConfiguration: (state) => state[BASKET_KEY]?.configurationTunnel,
  getDeliveryPointRef: (state) => state[BASKET_KEY]?.configurationTunnel?.defaultDeliveryPointRef,
  getFacialValue: (state) => state[BASKET_KEY]?.configurationTunnel?.defaultTitleValue,
  getEmployersContribution: (state) => state[BASKET_KEY]?.configurationTunnel?.defaultEmployersContribution,
  getIsMultiPL: (state) => state[BASKET_KEY]?.configurationTunnel?.isMultiPL,
  getIsNominatif: (state) => state[BASKET_KEY]?.configurationTunnel?.isNominatif,
  getIsMultiLoading: (state) => state[BASKET_KEY]?.configurationTunnel?.isMultiLoading,
  getFormatSaisie: (state) => state[BASKET_KEY]?.configurationTunnel?.formatSaisie,
  getEvent: (state) => state[BASKET_KEY]?.configurationTunnel?.defaultEvent,
  getIsNewBasket: (state) => {
    const configKeys = Object.keys(state[BASKET_KEY]?.configurationTunnel || {})
    const configValues = configKeys.filter((key) => !state[BASKET_KEY]?.configurationTunnel[key])
    return configKeys.length === configValues
  },
  getDivisionCode: (state) => state[BASKET_KEY]?.divisionCode,
  getItems: (state) => state[ITEMS_KEY],
  getValorisation: (state) => state[VALORISATION_KEY],
  getIsLoadedBasket: (state) => state.isLoaded[BASKET_KEY],
  getIsLoadedValorisation: (state) => state.isLoaded[VALORISATION_KEY],
  getIsLoadedOrder: (state) => state.isLoaded[ORDER_KEY],
  getIsLoadedItems: (state) => state.isLoaded[ITEMS_KEY],
  getIsLoadedMillesime: (state) => state.isLoaded[MILLESIME_KEY],
  getIsLoadedPaymentMeans: (state) => state.isLoaded[PAYMENT_MEANS],
  getMillesime: (state) => state[MILLESIME_KEY].data,
  getDeliveryDate: (state) => state[BASKET_KEY]?.deliveryDate,
  getPaymentMeans: (state) => state[PAYMENT_MEANS],
  // TODO: ne sera jamais sur le panier, mais sur l'order
  getPaymentType: (state) => state[ORDER_KEY]?.Payment?.Method,
  getIsRenew: (state) => state[BASKET_KEY]?.isARenewal,
  getBasketStatus: (state) => state[BASKET_KEY].status,
}

const actions = {
  async getProductConfigurations({ commit }, productCode) {
    try {
      const res = await apiV4.products.getProductConfiguration(productCode)

      if (res.isOk) {
        commit('SET_PRODUCT_CONFIG', res.value)
      } else {
        throw res.error
      }
    } catch (error) {
      console.error('error during getProductConfigurations', error)
    }
  },
  async createBasket({ getters, commit, dispatch }, patch) {
    if (patch?.setFormatSaisie) {
      dispatch('setFormatSaisie', 0)
    }
    if (patch?.setEmployersContribution) {
      dispatch('setEmployersContribution', 1)
    }

    const objNewBasket = {
      productCode: getters.getProductCode,
      ...(getters.getArticleCode !== 0 &&
        getters.getArticleCode && {
          articleCode: getters.getArticleCode,
        }),
      configurationTunnel: getters.getConfiguration,
    }

    try {
      const basket = await apiV4.carts.createV1(objNewBasket) // create basketV4
      await dispatch('fetchBasket', basket.id) // pour dupliquer les infos sur basketV4 et orderV3
      commit('SET_CURRENT_ORDER', basket) // a supp plus tard V3 (le SET_CURRENT_BASKET est fait dans le fetchBasket)
    } catch (error) {
      console.error('error during createBasket', error)
    }
  },
  setProductCode({ commit }, payload) {
    commit('SET_BASKET_PRODUCT_CODE', payload)
  },
  setArticleCode({ commit }, payload) {
    commit('SET_BASKET_ARTICLE_CODE', payload)
  },
  setPreorderId({ commit }, payload) {
    commit('SET_BASKET_PREORDER_ID', payload)
  },
  setIsXsell({ commit }, payload) {
    commit('SET_BASKET_IS_XSELL', payload)
  },
  setDeliveryPointRef({ commit }, payload) {
    commit('SET_BASKET_DELIVERY_POINT_REF', payload)
  },
  setFacialValue({ commit }, payload) {
    commit('SET_BASKET_FACIAL_VALUE', payload)
  },
  setIsMultiPL({ commit }, payload) {
    commit('SET_BASKET_IS_MULTI_PL', payload)
  },
  setEmployersContribution({ commit }, payload) {
    commit('SET_BASKET_EMPLOYERS_CONTRIBUTION', payload)
  },
  setEvent({ commit }, payload) {
    commit('SET_BASKET_EVENT', payload)
  },
  setIsNominatif({ commit }, payload) {
    commit('SET_BASKET_IS_NOMINATIF', payload)
  },
  setFormatSaisie({ commit }, payload) {
    commit('SET_BASKET_FORMAT_SAISIE', payload)
  },
  setReferenceCommande({ commit }, payload) {
    commit('SET_BASKET_REFERENCE', payload)
    commit('SET_ORDER_REFERENCE', payload)
  },
  async setDeliveryDate({ commit, state }, payload) {
    try {
      const updatedOrder = await api.patchOrder(state[BASKET_KEY].id, { DeliveryDate: payload })
      commit('SET_BASKET_DELIVERY_DATE', updatedOrder?.DeliveryDate)
      commit('SET_CURRENT_ORDER', updatedOrder)
    } catch (error) {
      console.error('error during update delivery date', error)
    }
  },
  updateOrder({ commit }, payload) {
    commit('UPDATE_CURRENT_ORDER', payload)
  },
  async updatePaymentMethod({ commit, state }, { method, returnUrls, paymentToken }) {
    if (!state[BASKET_KEY]?.id) {
      throw new Error('mise à jour de la méthode de paiement impossible sans identifiant panier')
    }

    try {
      commit('SET_IS_LOADED', { type: ORDER_KEY, value: false })

      const isTransfertImediat = method === paymentMeans.VIREMENT_IMMEDIAT.id
      const result = await apiV4.carts.postPaymentsV1(state[BASKET_KEY].id, {
        paymentMean: method,
        paymentToken,
        successRedirectUrl: `${returnUrls.success}${isTransfertImediat ? '?virement=true' : ''}`,
        cancelRedirectUrl: `${returnUrls.cancel}${isTransfertImediat ? '&virement=true' : ''}`,
      })

      if (result.isErr) {
        throw result.error
      }

      const { paymentMean, redirectionUrl } = result.value
      commit('SET_ORDER_PAYMENT', { Method: paymentMean, redirectionUrl })

      return result.value
    } finally {
      commit('SET_IS_LOADED', { type: ORDER_KEY, value: true })
    }
  },
  resetBasket({ commit }) {
    commit('SET_BASKET_PREORDER_ID', null)
    commit('SET_BASKET_ID', null)
    commit('SET_BASKET_REFERENCE', null)
    commit('SET_BASKET_CREATION_DATE', null)
    commit('SET_BASKET_FORMAT_SAISIE', null)
  },
  // fetchs
  async fetchBasket({ commit, dispatch }, payload) {
    try {
      commit('SET_IS_LOADED', { type: BASKET_KEY, value: false })
      const basket = await apiV4.carts.fetchV1(payload)
      commit('SET_CURRENT_BASKET', basket)
      await dispatch('fetchMillesime')
    } finally {
      commit('SET_IS_LOADED', { type: BASKET_KEY, value: true })
    }
  },
  async fetchOrder({ commit }, payload) {
    try {
      commit('SET_IS_LOADED', { type: ORDER_KEY, value: false })
      const order = await api.getOrder(payload)
      commit('SET_CURRENT_ORDER', order)
      commit('SET_VALORISATION', order.Pricing)
    } finally {
      commit('SET_IS_LOADED', { type: ORDER_KEY, value: true })
    }
  },
  async fetchMillesime({ commit, state }) {
    const basketProductCode = state[BASKET_KEY].productCode
    if (state[MILLESIME_KEY].data && state[MILLESIME_KEY].productCode === basketProductCode) {
      return
    }
    let millesimeInfo
    commit('SET_IS_LOADED', { type: MILLESIME_KEY, value: false })
    try {
      if (UNIVERS_MAP[UNIVERS_NAMES.TK].includes(basketProductCode)) {
        const json = await api.getClickProxy('Documents_Espace_Client/Commande/text-millesime.json')
        millesimeInfo = json['kadeos-text-millesime']
      } else {
        const result = accessor.session.clientProducts[basketProductCode]?.orderSettings.millesime
        millesimeInfo = millesimeFormater(result)
      }
      commit('SET_PRODUCT_MILLESIME', {
        productCode: basketProductCode,
        data: millesimeInfo,
      })
    } catch (error) {
      console.error('error during fetch millesime', error)
    } finally {
      commit('SET_IS_LOADED', { type: MILLESIME_KEY, value: true })
    }
  },
  async fetchPaymentMeans({ commit, state }) {
    try {
      commit('SET_IS_LOADED', { type: PAYMENT_MEANS, value: false })
      const paymentMeans = await apiV4.carts.getPaymentMeans(state[BASKET_KEY].id)
      commit('SET_PAYMENT_MEANS', paymentMeans)
    } catch (error) {
      console.error('error during fetch payment means', error)
    } finally {
      commit('SET_IS_LOADED', { type: PAYMENT_MEANS, value: true })
    }
  },
  async fetchValorisation({ commit, state }, readOnly = false) {
    try {
      commit('SET_IS_LOADED', { type: VALORISATION_KEY, value: false })
      if (!state[BASKET_KEY].id) {
        return
      }
      const response = await apiV4.carts.getPricingV1(state[BASKET_KEY].id, readOnly)
      commit('SET_VALORISATION', response)
      return response
    } catch {
      commit('SET_VALORISATION', null)
      throw new Error('error during valorisation')
    } finally {
      commit('SET_IS_LOADED', { type: VALORISATION_KEY, value: true })
    }
  },
  async fetchItems({ commit, state }) {
    if (!state[BASKET_KEY].id) {
      return
    }
    try {
      commit('SET_IS_LOADED', { type: ITEMS_KEY, value: false })
      const count = (await apiV4.carts.getItemsCountV1(state[BASKET_KEY].id)) || 0
      const items = (await apiV4.carts.fetchItemsV1(state[BASKET_KEY].id, { pageSize: count })).data.items
      commit('SET_ITEMS', items)
    } finally {
      commit('SET_IS_LOADED', { type: ITEMS_KEY, value: true })
    }
  },
  async renewOrder({ commit, dispatch }, basketId) {
    if (!basketId) {
      throw new Error('renew order not allow without order Id')
    }
    try {
      const renewed = await api.getRenewOrder(basketId)
      // TODO: à changer quand le renew sera sur la V4
      commit('SET_CURRENT_ORDER', renewed)
      await dispatch('fetchBasket', renewed.Id)
      return renewed
    } catch (error) {
      console.error('error during renew order', error)
      throw error
    }
  },
  // store init/clear
  initializeState({ commit }, payload) {
    commit('SET_STORE', payload)
  },
  clearBasket({ commit }) {
    commit('CLEAR_STORE')
  },
  async addItem({ commit, getters }, { item }) {
    const basketId = getters.getBasketId
    if (!basketId) {
      throw new Error('addItem not allowed without basketId')
    }
    try {
      const itemAdded = await apiV4.carts.createItemV1(basketId, item)
      commit('ADD_ITEM', itemAdded.data)
    } catch (error) {
      console.error('error during addItem', error)
      throw error
    }
  },
  async updateItem({ commit, getters }, { itemId, patch }) {
    const basketId = getters.getBasketId
    if (!basketId) {
      throw new Error('UpdateItem not allowed without basketId')
    }
    try {
      const updatedItem = await apiV4.carts.patchItemV1(basketId, itemId, patch)
      commit('UPDATE_ITEM', updatedItem)
    } catch (error) {
      console.error('error during updateItem', error)
      throw error
    }
  },
  async deleteItem({ commit, getters }, { itemId }) {
    const basketId = getters.getBasketId
    if (!basketId) {
      throw new Error('DeleteItem not allowed without basketId')
    }
    try {
      await apiV4.carts.deleteItemV1(basketId, itemId)
      // TODO: à changer quand le renew sera sur la V4
      commit('DELETE_ITEM', itemId)
    } catch (error) {
      console.error('error during deleteItem', error)
      throw error
    }
  },
}

const mutations = {
  SET_PRODUCT_CONFIG(state, payload) {
    state[CONFIG_PROD] = payload
  },
  UPDATE_ITEM(state, itemPatch) {
    const idxItem = state[ITEMS_KEY].findIndex((item) => item.id === itemPatch.id)
    if (idxItem != -1) {
      state[ITEMS_KEY].splice(idxItem, 1, itemPatch)
    }
  },
  DELETE_ITEM(state, itemId) {
    state[ITEMS_KEY] = state[ITEMS_KEY].filter((item) => item.id != itemId)
  },
  ADD_ITEM(state, item) {
    state[ITEMS_KEY].push(item)
  },
  SET_BASKET_PRODUCT_CODE(state, payload) {
    state[BASKET_KEY].productCode = payload
  },
  SET_BASKET_ARTICLE_CODE(state, payload) {
    state[BASKET_KEY].articleCode = payload
  },
  SET_BASKET_PREORDER_ID(state, payload) {
    state[BASKET_KEY].preorderRedcartId = payload
  },
  SET_BASKET_ID(state, payload) {
    state[BASKET_KEY].preorderRedcartId = payload
  },
  SET_BASKET_REFERENCE(state, payload) {
    state[BASKET_KEY].referenceCommande = payload
  },
  SET_BASKET_CREATION_DATE(state, payload) {
    state[BASKET_KEY].creationDate = payload
  },
  SET_BASKET_IS_XSELL(state, payload) {
    state[BASKET_KEY].isXSell = payload
  },
  SET_BASKET_DELIVERY_POINT_REF(state, payload) {
    state[BASKET_KEY].configurationTunnel.defaultDeliveryPointRef = payload
  },
  SET_BASKET_IS_MULTI_PL(state, payload) {
    state[BASKET_KEY].configurationTunnel.isMultiPL = payload
  },
  SET_BASKET_FACIAL_VALUE(state, payload) {
    state[BASKET_KEY].configurationTunnel = {
      ...state[BASKET_KEY].configurationTunnel,
      defaultTitleValue: payload,
    }
  },
  SET_BASKET_IS_NOMINATIF(state, payload) {
    state[BASKET_KEY].configurationTunnel.isNominatif = payload
  },
  SET_BASKET_EVENT(state, payload) {
    state[BASKET_KEY].configurationTunnel.defaultEvent = payload
  },
  SET_BASKET_EMPLOYERS_CONTRIBUTION(state, payload) {
    state[BASKET_KEY].configurationTunnel.defaultEmployersContribution = payload
  },
  SET_BASKET_FORMAT_SAISIE(state, payload) {
    state[BASKET_KEY].configurationTunnel.formatSaisie = payload
  },
  SET_CURRENT_BASKET(state, payload) {
    state[BASKET_KEY] = Object.assign({}, basketModel, payload)
  },
  SET_CURRENT_ORDER(state, payload) {
    state[ORDER_KEY] = payload
  },
  SET_ORDER_PAYMENT(state, payload) {
    state[ORDER_KEY].Payment = payload
  },
  SET_VALORISATION(state, payload) {
    state[VALORISATION_KEY] = payload
  },
  SET_ITEMS(state, payload) {
    state[ITEMS_KEY] = payload
  },
  SET_IS_LOADED(state, { type, value }) {
    state.isLoaded[type] = value
  },
  SET_ORDER_REFERENCE(state, payload) {
    state[ORDER_KEY].Reference = payload
  },
  SET_PRODUCT_MILLESIME(state, payload) {
    state[MILLESIME_KEY] = payload
  },
  SET_BASKET_DELIVERY_DATE(state, payload) {
    state[BASKET_KEY].deliveryDate = payload
  },
  SET_PAYMENT_MEANS(state, payload) {
    state[PAYMENT_MEANS] = payload
  },
  CLEAR_STORE(state) {
    state[BASKET_KEY] = Object.assign({}, basketModel)
    state[ITEMS_KEY] = []
    state[VALORISATION_KEY] = null
    state[ORDER_KEY] = null
    state[PAYMENT_MEANS] = null
  },
  SET_STORE(state, payload) {
    Object.entries(payload)
      .filter(([key]) => !!key)
      .forEach(([key, value]) => {
        state[key] = value
      })
  },
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
  persistent,
}

export { state, getters, mutations, actions, persistent, namespaced }
