import type { ComputedRef, Ref } from 'vue'
import type { CartItem } from '@/services/carts/types'
import { type ModuleConfig, ModuleType } from '@/store/cart/model'
import type { Action } from '@/types/Action'

export const moduleConfig: ModuleConfig = {
  id: 'missing-emails',
  type: ModuleType.Drawer,
  component: () => import('@/components/Cart/Module/MissingEmails/index.vue'),
}

export interface MissingEmailsRecord {
  totalItemsWithoutEmail: number
  itemsWithoutEmail: CartItem[]
}

export interface MissingEmailsForm {
  itemsWithoutEmail: CartItem[]
}

export interface MissingEmailsWorkflow {
  isImport: ComputedRef<boolean>
  totalItemsWithoutEmail: number
  state: Ref<MissingEmailsForm>
  submitAction: Action
  reImportAction: Action
  skipAction: Action
}
