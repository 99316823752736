import commonsCartWorkflow from '../CKS/index'
import stepBasket from './steps/basket'
import stepImport from './steps/import'
import stepPayment from './steps/payment'
import clearCart from '@/store/cart/cartWorkflows/common/module/clearCart'
import setting from '@/store/cart/cartWorkflows/common/module/settings/KSC'
import type { CartWorkflow, FrontCart } from '@/store/cart/model'
import { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'

const steps = commonsCartWorkflow.steps.map((step) => {
  if (step.config.id === stepBasket.config.id) {
    return stepBasket
  } else if (step.config.id === stepPayment.config.id) {
    return stepPayment
  }

  return step
})

steps.push(stepImport)

const cartWorkflow: CartWorkflow = {
  ...commonsCartWorkflow,
  steps,
  modules: [...commonsCartWorkflow.modules, clearCart, setting],
  isValid(cart: FrontCart) {
    return cart.meta.productCode === ProductCode.KADEOS_CONNECT && cart.meta.articleCode === ArticleCode.KADEOS_CONNECT
  },
}

export default cartWorkflow
