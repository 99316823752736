import type { RouteConfig } from 'vue-router'
import { useStore as useFeatureFlagStore } from '@/store/featureFlag'
import { FeatureFlag } from '@/store/featureFlag/model'

export const router: RouteConfig = {
  path: 'factures-avoirs',
  meta: {
    title: 'Factures et avoirs',
    breadcrumb: 'Factures et avoirs',
    howTo: 'consulter-factures-avoirs-comment-faire',
  },
  component: () => {
    const store = useFeatureFlagStore()

    if (store.isEnabled(FeatureFlag.NewInvoicesPage)) {
      return import('@/views/Invoices/index.vue')
    } else {
      return import('@/views/Admin/Invoices/InvoicesView.vue')
    }
  },

  children: [
    {
      path: '',
      component: () => import('@/views/Invoices/Invoices/index.vue'),
    },
    {
      path: 'avoirs',
      meta: {
        breadcrumb: 'Avoirs',
        title: 'Avoirs',
      },
      component: () => import('@/views/Invoices/CreditNotes/index.vue'),
    },
  ],
}
