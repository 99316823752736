import type { PluginObject } from 'vue'
import apiV3 from '@/services/apiV3'

const APIV3Plugin: PluginObject<Record<string, never>> = {
  install(_Vue) {
    _Vue.prototype.$apiV3 = apiV3
  },
}

export default APIV3Plugin

declare module 'vue/types/vue' {
  interface Vue {
    $apiV3: typeof apiV3
  }
}
