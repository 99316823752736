import { Result } from '@badrap/result'
import { parse } from 'papaparse'

export type Comma = ',' | ';'
export const CSV_SEPARATORS = [',', ';']

// From: https://github.com/Inist-CNRS/node-csv-string
export const detectSeparator = (input: string): Comma => {
  const idx = CSV_SEPARATORS.map((separator) => input.indexOf(separator)).reduce((prev, cur) =>
    prev === -1 || (cur !== -1 && cur < prev) ? cur : prev,
  )
  return (input[idx] || ',') as Comma
}

export function fetchSeparator(file?: File | null): Promise<Result<Comma>> {
  return new Promise((resolve) => {
    if (file == null) {
      return resolve(Result.err(new Error('csv.errors.fileNotExist')))
    }

    const reader = new FileReader()
    reader.onload = (event) => {
      const headerString = event.target?.result as string

      const separator = detectSeparator(headerString)

      if (separator === null) {
        return resolve(Result.err(new Error('csv.errors.separatorNotExist')))
      } else if (!CSV_SEPARATORS.includes(separator)) {
        return resolve(Result.err(new Error('csv.errors.wrongSeparator')))
      }

      return resolve(Result.ok(separator))
    }
    // par defaut le charset est en utf-8, dans ce cas utf-8 -> ok, utf-8 sans BOM -> ok, ansi -> ko
    // on specifie le charset en windows-1252, dans ce cas utf-8 -> ok, utf-8 sans BOM -> ko, ansi -> ok
    reader.readAsText(file, 'Windows-1252')
  })
}

export function parseCSV<T = unknown>(file?: File | null, enforceLowercaseHeaders = true): Promise<Result<T[]>> {
  return new Promise((resolve) => {
    if (file == null) {
      resolve(Result.err(new Error('csv.errors.fileNotExist')))
      return
    }

    parse<T>(file, {
      header: true,
      skipEmptyLines: true,
      transformHeader(header) {
        return enforceLowercaseHeaders ? header.toLocaleLowerCase() : header
      },
      complete(results) {
        if (results.errors.length > 0) {
          resolve(Result.err(new Error('csv.errors.parseError')))
          return
        }

        resolve(Result.ok(results.data))
      },
    })
  })
}
