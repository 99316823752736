import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = '/v2/orders/:orderId/beneficiaries'

const getBaseUrl = urlReplacer(baseUrl, {
  orderId: '',
})

export default (axiosInstance) => {
  const headBenefOutOfOrder = async () => {
    try {
      const result = await axiosInstance.head('/v2/employees')
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getAnalysisBeneficariesDuplicated = async (orderId, status) => {
    try {
      const result = await axiosInstance.get(`${getBaseUrl({ orderId })}?beneficiaryStatus=${status}&pageSize=1`)
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getAnalysisBeneficariesError = async (orderId, beneficiaryId) => {
    try {
      const result = await axiosInstance.get(`${getBaseUrl({ orderId })}/duplicated?beneficiaryId=${beneficiaryId}`)
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const addBeneficary = async (orderId, id) => {
    try {
      const result = await axiosInstance.patch(`${getBaseUrl({ orderId })}/duplicates/${id}`, {
        Status: 1,
      })
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const mergeBeneficary = async (orderId, idItem, idMerge) => {
    try {
      const result = await axiosInstance.patch(`${getBaseUrl({ orderId })}/duplicates/${idItem}`, {
        Status: 4,
        EmployeeId: idMerge,
      })
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getBenefOutOfOrder = async (orderId) => {
    try {
      const result = await axiosInstance.get(`${getBaseUrl({ orderId })}/excluded`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getBenefInOrder = async (orderId) => {
    try {
      const result = await axiosInstance.get(`${getBaseUrl({ orderId })}/included`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getBenefWithoutAddresses = async (orderId) => {
    try {
      const result = await axiosInstance.get(`${getBaseUrl({ orderId })}/noAddresses`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const postNewBeneficiaryInOrder = async (orderId, benef) => {
    try {
      const result = await axiosInstance.post(`${getBaseUrl({ orderId })}`, benef)
      return { status: 200, data: result.data }
    } catch (error) {
      if (error.response.status === 400) {
        return {
          status: error.response.status,
          data: error.response.data.Data,
          errorCode: error.response.data.ErrorCode,
        }
      } else {
        throw error
      }
    }
  }

  const forcePostNewBeneficiaryInOrder = async (orderId, benef) => {
    try {
      const result = await axiosInstance.post(`${getBaseUrl({ orderId })}?forceCreation=true`, benef)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const updateBeneficiaries = async (orderId, benefId, patch) => {
    try {
      const result = await axiosInstance.patch(`${getBaseUrl({ orderId })}/${benefId}`, patch)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  // arrayId = [1,2,3,4] ou all, objChampsModifies = { key: value }
  const massUpdateBeneficiaries = async (orderId, arrayId, objChampsModifies) => {
    try {
      const result = await axiosInstance.patch(`${getBaseUrl({ orderId })}/$batch`, {
        subset: arrayId,
        Payload: objChampsModifies,
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  // subsetId = 1,2,3,4 ou all
  const massDeleteBeneficiariesIncluded = async (orderId, subsetId) => {
    try {
      const result = await axiosInstance.delete(`${getBaseUrl({ orderId })}/included/$batch`, {
        data: {
          Subset: subsetId,
        },
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const putBenefInOrder = async (orderId, benef) => {
    try {
      const result = await axiosInstance.put(`${getBaseUrl({ orderId })}/included/${benef.Id}`, benef)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const putMassBenefInOrder = async (orderId, benefList) => {
    try {
      const result = await axiosInstance.put(`${getBaseUrl({ orderId })}/included/$batch`, benefList)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const deleteBenefInOrder = async (orderId, benef) => {
    try {
      const result = await axiosInstance.delete(`${getBaseUrl({ orderId })}/included/${benef.Id}`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const deleteBenefOrder = async (orderId, beneficiaryId) => {
    try {
      const result = await axiosInstance.delete(`${getBaseUrl({ orderId })}/${beneficiaryId}`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const deleteMassBenefOrder = async (orderId, idsBenef) => {
    try {
      const result = await axiosInstance.delete(`${getBaseUrl({ orderId })}/$batch`, {
        data: idsBenef,
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getBeneficiaries = async (filter, page, pageSize) => {
    try {
      const result = await axiosInstance.get('/v2/employees', {
        params: {
          filter,
          page,
          pageSize,
        },
      })
      return { data: result.data, count: result.headers['x-total-count'] }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getOrderBeneficiaries = async (orderId, params) => {
    return axiosInstance.get(`/v2/orders/${orderId}/beneficiaries`, { params })
  }

  const getBeneficiaryInOrder = async function (orderId, beneficiaryId) {
    try {
      const result = await axiosInstance.get(`${getBaseUrl({ orderId })}/included/${beneficiaryId}`)
      return result.status
    } catch (error) {
      console.error(error)
      // en attentedent que ITECH ne nous catch pas les 404
      // result = e.response.status
      return 12345789 // <- je soufre
    }
  }

  return {
    getOrderBeneficiaries,
    headBenefOutOfOrder,
    getAnalysisBeneficariesDuplicated,
    getAnalysisBeneficariesError,
    addBeneficary,
    mergeBeneficary,
    getBenefOutOfOrder,
    getBenefInOrder,
    getBenefWithoutAddresses,
    postNewBeneficiaryInOrder,
    forcePostNewBeneficiaryInOrder,
    updateBeneficiaries,
    massUpdateBeneficiaries,
    massDeleteBeneficiariesIncluded,
    putBenefInOrder,
    putMassBenefInOrder,
    deleteBenefInOrder,
    deleteBenefOrder,
    deleteMassBenefOrder,
    getBeneficiaries,
    getBeneficiaryInOrder,
  }
}
