import imageByTag from './utils/imageByTag.json'
import { prdtName } from './utils/productNameMapper.js'
import familyOfUseRefJson from '@/assets/Referentiel_produits/familyOfUse.json'
import productsNewRefJson from '@/assets/Referentiel_produits/products.json'
import api from '@/services/api'
import articleNameByCode from '@/variables/articleNameByCode'
import productNameByCode from '@/variables/productNameByCode'
import PRODUCT_TYPE_BY_CODE from '@/variables/productTypeByCode'
import apiV4 from '@/services/apiV4'

const namespaced = true

const state = () => ({
  ProductCode: null,
  ProductConfiguration: {},
  referentielProduits: null,
  referentielFamilyOfUse: null,
})

const getters = {
  getDistributionPointMandatory: (state) =>
    state.ProductConfiguration?.orderSettings?.distributionPointMandatory ?? false,
  ProductCode(state) {
    return state.Order.ProductCode ? state.Order.ProductCode : state.ProductCode
  },
  ArticleCode(state) {
    return state.Order.ArticleCode ? state.Order.ArticleCode : state.ArticleCode
  },
  ReferentielProduits(state) {
    return state.referentielProduits
  },
  productIsCard: () => (productCode) => {
    return PRODUCT_TYPE_BY_CODE[productCode]?.type === 'carte'
  },
  productIsPaper: () => (productCode) => {
    return PRODUCT_TYPE_BY_CODE[productCode]?.type === 'papier'
  },
  isInterimProduct(state) {
    return state.ProductConfiguration?.clientOptions.multiloadingAllowed ?? false
  },
  getCurrentProductConfiguration(state) {
    return state.ProductConfiguration
  },
  getReferentielFamilyOfUse(state) {
    return state.referentielFamilyOfUse
  },
  getProductFromNewRef: (state) => (ability) => {
    return state.referentielProduits.find((p) => p.libelleCourt.findIndex((e) => e === ability) > -1)
  },
  getImgFromTag: () => (tag) => imageByTag[tag] || imageByTag.default,
  getImg: (state, getters) => (productCode) => getters.productIsCard(productCode) ? 'card-picto' : 'paper-picto',
  getProductName: () => (productCode) => productNameByCode[productCode] || productNameByCode.default,
  getArticleName: () => (articleCode) => articleNameByCode[articleCode] || articleNameByCode.default,
  getArticleInfos: (state) => (articleCode) =>
    state.referentielProduits.find((product) => product.articleCode === articleCode),
  getProductsFromNewRef:
    (state, getters) =>
    (abilities = []) => {
      const products = []
      abilities?.forEach((ability) => {
        const product = getters.getProductFromNewRef(ability)
        if (product) {
          products.push(product)
        }
      })
      return products
    },
  getLibelle: (state) => (product) => {
    let libelle = null
    const produit = product.ProductCode ? product.ProductCode : product.productCode
    const article = product.ArticleCode ? product.ArticleCode : product.articleCode
    let code = state.referentielProduits.find((p) => p.productCode === produit)
    if (code) {
      libelle = code.libelleCourt[0]
    } else {
      code = state.referentielProduits.find((p) => p.articleCode === article)
      if (code) {
        libelle = code.libelleCourt[0]
      }
    }
    return libelle
  },
  getImgPrdt: () => (product) => {
    return prdtName(product)
  },
  getProductByCode: (state) => (productCode) => {
    return state.referentielProduits?.find((p) => p.productCode === productCode)
  },
}

const actions = {
  async getProductConfigurations({ commit }, productCode) {
    const res = await apiV4.products.getProductConfiguration(productCode)

    if (res.isOk) {
      commit('SET_PRODUCT_CONFIG', res.value)
      return res.value
    }
  },
  async setReferentielProduits({ commit }, referentielProduit) {
    commit('SET_REF_PRODUITS', referentielProduit)
  },
  async setReferentielFamilyOfUse({ commit }, referentielFamilyOfUse) {
    commit('SET_REF_FAMILY_OF_USE', referentielFamilyOfUse)
  },
  setProductCode({ commit }, productCode) {
    commit('SET_CODE_PRODUCT', productCode)
  },
  async fetchProductReferencial({ commit }) {
    let referentielProduits = null
    try {
      referentielProduits = await api.getClickProxy('Documents_Espace_Client/Produits/products.json')
    } catch (e) {
      referentielProduits = productsNewRefJson
    } finally {
      commit('SET_REF_PRODUITS', referentielProduits)
    }

    let referentielFamilyOfUse = null
    try {
      referentielFamilyOfUse = await api.getClickProxy('Documents_Espace_Client/Produits/familyOfUse.json')
    } catch (e) {
      referentielFamilyOfUse = familyOfUseRefJson
    } finally {
      commit('SET_REF_FAMILY_OF_USE', referentielFamilyOfUse)
    }
  },
}

const mutations = {
  SET_REF_PRODUITS(state, payload) {
    state.referentielProduits = payload
  },
  SET_CODE_PRODUCT(state, payload) {
    state.ProductCode = payload
  },
  SET_PRODUCT_CONFIG(state, payload) {
    state.ProductConfiguration = payload
  },
  SET_REF_FAMILY_OF_USE(state, referentielFamilyOfUse) {
    state.referentielFamilyOfUse = referentielFamilyOfUse
  },
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}

export { state, getters, mutations, actions }
