import commonsCartWorkflow from '../common'
import stepBasket from './steps/basket'
import stepConfirmation from './steps/confirmation'
import addBeneficiary from '@/store/cart/cartWorkflows/common/module/addBeneficiary'
import type { FrontCart, CartWorkflow } from '@/store/cart/model'
import { ProductCode } from '@/variables/ProductCode'

const steps = commonsCartWorkflow.steps.map((step) => {
  if (step.config.id === stepConfirmation.config.id) {
    return stepConfirmation
  } else if (step.config.id === stepBasket.config.id) {
    return stepBasket
  }
  return step
})
const modules = [...commonsCartWorkflow.modules, addBeneficiary]

const cartWorkflow: CartWorkflow = {
  ...commonsCartWorkflow,
  steps,
  modules,
  isValid(cart: FrontCart) {
    return cart.meta.productCode === ProductCode.TICKET_RESTAURANT
  },
}

export default cartWorkflow
