import { ArticleCode } from '@/variables/ArticleCode'
import habilitations from '@/variables/habilitations.json'
import { ProductCode } from '@/variables/ProductCode'

const MIXED = 'mixed'
const NOMINATIVE_STRING = 'nominative'
const ANONYME_STRING = 'anonyme'
const MAP: Record<number, Record<number, Record<string, string>>> = {
  [ProductCode.TICKET_RESTAURANT]: {
    [ArticleCode.NO_PRODUCT]: {
      [NOMINATIVE_STRING]: habilitations.PAIEMENT.TRN,
      [ANONYME_STRING]: habilitations.PAIEMENT.TRA,
    },
  },
  [ProductCode.CARTE_TICKET_RESTAURANT]: {
    [ArticleCode.CARTE_TICKET_RESTAURANT_FLEX]: {
      [ANONYME_STRING]: habilitations.PAIEMENT.CTR_FLEX,
    },
    [ArticleCode.CARTE_TICKET_RESTAURANT]: {
      [MIXED]: habilitations.PAIEMENT.TR_MIXTE,
      [NOMINATIVE_STRING]: habilitations.PAIEMENT.CTR,
    },
  },
  [ProductCode.KADEOS]: {
    [ArticleCode.KADEOS_CULTURE]: {
      [ANONYME_STRING]: habilitations.PAIEMENT.KSCULA,
      [NOMINATIVE_STRING]: habilitations.PAIEMENT.KSCULN,
    },
    [ArticleCode.KADEOS_INFINI]: {
      [ANONYME_STRING]: habilitations.PAIEMENT.KSINFA,
      [NOMINATIVE_STRING]: habilitations.PAIEMENT.KSINFN,
    },
    [ArticleCode.KADEOS_INFINI_DOM]: {
      [ANONYME_STRING]: habilitations.PAIEMENT.KSINFA_DOM,
      [NOMINATIVE_STRING]: habilitations.PAIEMENT.KSINFN_DOM,
    },
    [ArticleCode.KADEOS_SELECT]: {
      [ANONYME_STRING]: habilitations.PAIEMENT.KSSELA,
      [NOMINATIVE_STRING]: habilitations.PAIEMENT.KSSELN,
    },
  },
  [ProductCode.KADEOS_CONNECT]: {
    [ArticleCode.KADEOS_CONNECT]: {
      [ANONYME_STRING]: habilitations.PAIEMENT.KSC,
      [NOMINATIVE_STRING]: habilitations.PAIEMENT.KSC,
    },
  },
  [ProductCode.CARTE_KADEOS]: {
    [ArticleCode.CARTE_KADEOS_CULTURE]: {
      [ANONYME_STRING]: habilitations.PAIEMENT.CKSCULA,
      [NOMINATIVE_STRING]: habilitations.PAIEMENT.CKSCULN,
    },
    [ArticleCode.CARTE_KADEOS_ZENITH_CE]: {
      [ANONYME_STRING]: habilitations.PAIEMENT.CKSZENCEA,
      [NOMINATIVE_STRING]: habilitations.PAIEMENT.CKSZENCEN,
    },
    [ArticleCode.CARTE_KADEOS_ZENITH_DE]: {
      [ANONYME_STRING]: habilitations.PAIEMENT.CKSZENDEA,
      [NOMINATIVE_STRING]: habilitations.PAIEMENT.CKSZENDEN,
    },
  },
}

export function fetchPaymentPermission(
  productCode: ProductCode,
  articleCode: ArticleCode,
  isNominative = false,
  isMixed = false,
) {
  const mapByProduct = MAP[productCode]

  if (mapByProduct == null) {
    return null
  }

  const subMapByArticle = mapByProduct[articleCode]
  if (subMapByArticle == null) {
    return null
  }

  const key = isMixed ? MIXED : isNominative ? NOMINATIVE_STRING : ANONYME_STRING

  return subMapByArticle[key]
}
