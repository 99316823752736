import { defineStore } from 'pinia'
import type { ToasterState } from './model'
import type { ToastInfo } from '@/types/ToastInfo'

export const useStore = defineStore('toaster', {
  state: (): ToasterState => {
    return {
      push: () => {
        // Stub method
      },
    }
  },
  actions: {
    initToaster(push: (info: ToastInfo) => void) {
      this.push = push
    },
  },
})
