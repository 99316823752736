import { ProductCode } from './ProductCode'

export default {
  [ProductCode.TICKET_RESTAURANT]: {
    type: 'papier',
  },
  [ProductCode.TICKET_SERVICE]: {
    type: 'papier',
  },
  [ProductCode.TICKET_CESU]: {
    type: 'papier',
  },
  [ProductCode.TICKET_CAP]: {
    type: 'papier',
  },
  [ProductCode.TICKET_RESTAURANT_BENEVOLES_VOLONTAIRE]: {
    type: 'papier',
  },
  [ProductCode.KADEOS]: {
    type: 'papier',
  },
  [ProductCode.KADEOS_CONNECT]: {
    type: 'carte',
  },
  [ProductCode.KADEOS_UNIVERSEL]: {
    type: 'papier',
  },
  [ProductCode.CARTE_KADEOS]: {
    type: 'carte',
  },
  [ProductCode.TICKET_FLEETPRO]: {
    type: 'papier',
  },
  [ProductCode.CARTE_TICKET_RESTAURANT]: {
    type: 'carte',
  },
  [ProductCode.BETTERWAY]: {
    type: 'papier',
  },
  [ProductCode.KADEOS_CONNECT]: {
    type: 'papier',
  },
}
