import precommande from '@/assets/constantes/precommande.json'
import store, { accessor } from '@/store'
import HABILITATIONS from '@/variables/habilitations.json'

const preOrderDetail = () => import('@/views/Admin/PreOrder/PreOrderDetail.vue')
const preOrderImportBenefs = () => import('@/views/Admin/PreOrder/PreOrderImportBenefs.vue')
const PreOrdersBoard = () => import('@/views/Admin/PreOrder/PreOrdersBoard.vue')
const DefaultRoot = () => import('@/views/DefaultRouter/Root/index.vue')
const ChildView = () => import('@/views/DefaultRouter/Child/index.vue')
const PreOrderConfirmation = () => import('@/components/PreOrder/PreOrderConfirmation.vue')

export default {
  path: precommande.PRECOMMANDE.path,
  component: DefaultRoot,
  meta: {
    breadcrumb: precommande.PRECOMMANDE.childs.BOARD.breadcrumb,
    howTo: () => {
      return accessor.session.hasPermission(HABILITATIONS.PRECOMMANDE.BENEF.CREATE)
        ? precommande.PRECOMMANDE.PDFPATH.benef
        : precommande.PRECOMMANDE.PDFPATH.client
    },
  },
  children: [
    {
      path: precommande.PRECOMMANDE.childs.BOARD.path,
      component: PreOrdersBoard,
      name: precommande.PRECOMMANDE.childs.BOARD.name,
      meta: {
        title: precommande.PRECOMMANDE.childs.BOARD.title,
        refAbility: precommande.PRECOMMANDE.childs.BOARD.refAbility,
      },
    },
    {
      path: precommande.PRECOMMANDE.childs.DETAIL.path,
      component: ChildView,
      meta: {
        breadcrumb: () => {
          return `${store.state.preOrder.ref}`
        },
      },
      children: [
        {
          path: precommande.PRECOMMANDE.childs.DETAIL.childs.DETAIL.path,
          component: preOrderDetail,
          name: precommande.PRECOMMANDE.childs.DETAIL.childs.DETAIL.name,
          meta: {
            title: precommande.PRECOMMANDE.childs.DETAIL.childs.DETAIL.title,
            refAbility: precommande.PRECOMMANDE.childs.DETAIL.childs.DETAIL.refAbility,
          },
        },
        {
          path: precommande.PRECOMMANDE.childs.DETAIL.childs.IMPORT.path,
          name: precommande.PRECOMMANDE.childs.DETAIL.childs.IMPORT.name,
          meta: {
            breadcrumb: precommande.PRECOMMANDE.childs.DETAIL.childs.IMPORT.breadcrumb,
            title: precommande.PRECOMMANDE.childs.DETAIL.childs.IMPORT.title,
            refAbility: precommande.PRECOMMANDE.childs.DETAIL.childs.IMPORT.refAbility,
          },
          component: preOrderImportBenefs,
        },
        {
          path: precommande.PRECOMMANDE.childs.DETAIL.childs.CONFIRMATION.path,
          name: precommande.PRECOMMANDE.childs.DETAIL.childs.CONFIRMATION.name,
          meta: {
            title: precommande.PRECOMMANDE.childs.DETAIL.childs.CONFIRMATION.title,
            refAbility: precommande.PRECOMMANDE.childs.DETAIL.childs.CONFIRMATION.refAbility,
          },
          component: PreOrderConfirmation,
        },
      ],
    },
  ],
}
