import { defineStore } from 'pinia'
import { computed } from 'vue'
import type { Route } from 'vue-router'
import { accessor } from '..'
import { FeatureFlag } from '../featureFlag/model'
import type { TagEvent } from './events'
import { gtm } from './probes/gtm'
import { sentry } from './probes/sentry'
import { log } from './probes/log'
import type { TrackingProbe } from './probes/types'
import router from '@/router'
import { ConnexionOrigin } from '@/variables/ConnexionOrigin'
import { useStore as useFeatureFlagStore } from '@/store/featureFlag'
import { useSha256 } from '@/composables/useSha256'
import { vueEnv } from '@/parameters'

function fetchConnexionOrigin(connectionOrigin?: ConnexionOrigin | null): string | null {
  switch (connectionOrigin) {
    case ConnexionOrigin.LOGIN_EC:
      return 'Login_EC'

    case ConnexionOrigin.SSO:
      return 'Login_SSO'

    case ConnexionOrigin.CONTRACTUALISATION_REDFORCE:
      return 'Espace Client CEL'
  }

  return null
}

export const useStore = defineStore('tracking', () => {
  const featureFlagStore = useFeatureFlagStore()
  const probes = computed(() => {
    const probes: TrackingProbe[] = []
    if (featureFlagStore.isEnabled(FeatureFlag.TrackingGtm)) {
      probes.push(gtm())
    }
    if (featureFlagStore.isEnabled(FeatureFlag.TrackingSentry)) {
      probes.push(sentry())
    }
    if (featureFlagStore.isEnabled(FeatureFlag.TrackingLog)) {
      probes.push(log())
    }

    return probes
  })

  const metadata = computed(() => {
    return {
      connection_origin: fetchConnexionOrigin(accessor.session.user?.exc_cnx),
      user_data: accessor.session.user
        ? {
            user_id: accessor.session.user.exc_sid,
            email: useSha256()(accessor.session.user.email),
            firstname: accessor.session.user.firstname,
            lastname: accessor.session.user.lastname,
            role: accessor.session.right?.role,
            division: accessor.session.division,
          }
        : null,
      env: vueEnv,
    }
  })

  function setMetaData() {
    return probes.value.forEach((probe) => probe.setMetaData?.(metadata.value))
  }

  function trackPageView(to: Route, from?: Route) {
    return probes.value.forEach((probe) => probe.trackPageView?.(to, from, metadata.value))
  }

  function trackEvent(event: TagEvent) {
    return probes.value.forEach((probe) => probe.trackEvent?.(event, router.currentRoute, metadata.value))
  }

  function captureException(error: Error, data: Record<string, unknown>) {
    return probes.value.forEach((probe) => probe.captureException?.(error, data, router.currentRoute))
  }

  return { metadata, trackPageView, trackEvent, captureException, setMetaData }
})
