import Vue from 'vue'
import api from '@/services/api'

export default {
  state: {
    beneficiaries: [],
    totalCount: 0,
    page: 0,
    pageSize: 20,
    totalPages: 0,
  },
  actions: {
    async getBeneficiariesList({ commit }, params) {
      try {
        const response = await api.getBeneficiariesList(params)
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
        const list = response.data.map((benef) => {
          benef.BirthDate = new Date(benef.BirthDate).toLocaleDateString('fr-FR', options)
          return benef
        })
        const beneficiariesData = {
          beneficiaries: list,
          totalCount: response.headers['x-total-count'],
          page: response.config.params.page,
          pageSize: response.config.params.pageSize,
        }
        commit('SET_BENEFICIARIES', beneficiariesData)
      } catch (e) {
        console.error(e)
      }
    },
    setPage({ commit }, pageNumber) {
      commit('SET_PAGE', pageNumber)
    },
    clearBeneficiariesList({ commit }) {
      commit('SET_BENEFICIARIES', [])
    },
    setBeneficiaries({ commit }, payload) {
      commit('SET_BENEFICIARIES', payload)
    },
  },
  mutations: {
    SET_BENEFICIARIES(state, item) {
      Vue.set(state, 'beneficiaries', item.beneficiaries)
      Vue.set(state, 'totalCount', item.totalCount)
      Vue.set(state, 'page', item.page)
      Vue.set(state, 'pageSize', item.pageSize)
      Vue.set(state, 'totalPages', Math.ceil(item.totalCount / item.pageSize))
    },
    SET_PAGE(state, pageNumber) {
      Vue.set(state, 'page', pageNumber)
    },
  },
  getters: {
    beneficiaries(state) {
      return state.beneficiaries
    },
    totalCount(state) {
      return state.totalCount
    },
    pageSize(state) {
      return state.pageSize
    },
    page(state) {
      return state.page
    },
    totalPages(state) {
      return state.totalPages
    },
  },
}
