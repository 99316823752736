import { Result } from '@badrap/result'
import { ref } from 'vue'
import { useValueOfTitleWorkflow } from '../../composables/useValueOfTitleWorkflow'
import {
  type CTRVCartSettingsWorkflow,
  moduleConfig,
  moduleCTRVConfig,
  type TrRecord,
} from '@/components/Cart/Module/Settings/workflow'
import type { FrontCartDataStore, ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import { useStore } from '@/store/cart/store'
import apiV4 from '@/services/apiV4'
import { DataStoreKey } from '@/services/dataStore/types'
import { useStore as useDrawerStore } from '@/store/drawer'
import type { SettingFor } from '@/components/Cart/Module/common/workflow'

const cartSetting: ModuleBuilder<CTRVCartSettingsWorkflow> = {
  config: moduleCTRVConfig,
  isAllowed: () => true,
  useModule(cartRef, _workflow, useAction) {
    if (cartRef.value === null) {
      throw new Error('cart.errors.cartNotFound')
    }

    const { value: cart } = cartRef

    return {
      useWorkflow(record: TrRecord & { for: SettingFor }) {
        const dataStoreValueOfTitle = cart.meta.dataStore[DataStoreKey.ValueOfTitle]

        const isMultiValueOfTitle = ref(dataStoreValueOfTitle?.isMulti ?? false)

        if (record.for === 'input' && isMultiValueOfTitle.value) {
          isMultiValueOfTitle.value = false
        }

        const showValueOfTitle = record.showValueOfTitle
        const productImg = cart.meta.visual.src
        const saveDefaultParams = ref(true)

        if (showValueOfTitle && !dataStoreValueOfTitle?.save) {
          saveDefaultParams.value = false
        }
        return {
          saveDefaultParams,
          showValueOfTitle,
          isMultiValueOfTitle,
          productImg: productImg,
          useValueOfTitleWorkflow: useValueOfTitleWorkflow(
            [cart.remote.description.productCode],
            record.for,
            dataStoreValueOfTitle
              ? {
                  value: dataStoreValueOfTitle.value,
                  employersContribution: dataStoreValueOfTitle.employersContribution,
                }
              : undefined,
          ),
          showDelivery: record.showDelivery,
          submitAction: (submitValueOfTitle) =>
            useAction({
              id: 'add',
              name: 'cart.modules.settings.save',
              type: ActionType.Default,
              refresh: true,
              async execute() {
                const cartStore = useStore()
                const dataStore: FrontCartDataStore = {
                  ...cart.meta.dataStore,
                }

                if (showValueOfTitle && submitValueOfTitle) {
                  const resultAmount = await submitValueOfTitle()

                  if (resultAmount.isErr) {
                    return Result.err(resultAmount.error)
                  }

                  dataStore[DataStoreKey.ValueOfTitle] = {
                    isMulti: isMultiValueOfTitle.value,
                    value: resultAmount.value.value ?? 0,
                    employersContribution: resultAmount.value.employersContribution ?? 0,
                    save: saveDefaultParams.value,
                  }

                  if (saveDefaultParams.value) {
                    await apiV4.dataStore.putDataStoreByProduct(
                      DataStoreKey.ValueOfTitle,
                      cart.meta.productCode,
                      cart.meta.articleCode,
                      dataStore[DataStoreKey.ValueOfTitle],
                    )
                  } else {
                    await apiV4.dataStore.deleteDataStoreByProduct(
                      DataStoreKey.ValueOfTitle,
                      cart.meta.productCode,
                      cart.meta.articleCode,
                    )
                  }
                }

                await cartStore.edit({
                  localId: cart.localId,
                  meta: {
                    dataStore,
                  },
                })

                const drawerStore = useDrawerStore()
                drawerStore.pop(moduleConfig.id)
                return Result.ok(true)
              },
            }),
        }
      },
    }
  },
}

export default cartSetting
