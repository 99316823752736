import type { Entry } from 'contentful'
import type { TypeHeadMetaSkeleton } from '../generated'

export interface CFHeadMeta {
  id: string
  content: string
}

export function mapHeadMetasToLocal(items: Entry<TypeHeadMetaSkeleton, undefined, string>[]): CFHeadMeta[] {
  return items.map((item) => item.fields)
}
