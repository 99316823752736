import { Result } from '@badrap/result'
import jwtDecode, { InvalidTokenError } from 'jwt-decode'
import type { ClientProducts } from '@/services/products/types'
import type { Universe } from '@/services/scopes/types'
import type { Account, Contract } from '@/services/session/types'
import { ResultError } from '@/types/ResultError'
import type { UniversJson } from '@/assets/constantes/Univers'
import type { ConnexionOrigin } from '@/variables/ConnexionOrigin'
import type { ProductCode } from '@/variables/ProductCode'
import type { ArticleCode } from '@/variables/ArticleCode'
import type { CFUniverse } from '@/services/contentful/universe/types'

export type SessionState = {
  right: Right | null
  division: string | null
  isSubstitute: boolean
  isAdmin: boolean
  user: User | null
  accounts: Account[]
  contracts: Contract[]
  clientProducts: ClientProducts
  scopes: Universe[]
  loading: boolean
  univers: UniversJson[]
  cfUniverse: CFUniverse[]
  productReferencials: ProductReferencial[] | null
}

export type AccessTokenJWT = {
  primarysid: string
  email: string
  gender: string
  rig: string[] | string
  family_name: string
  given_name: string
  exc_sid: string
  exc_cnx: string
  iss: string
  aud: string
  exp: number
  nbf: number
  auth_time?: number | null
  logoutUrl?: string | null
}

export type User = {
  exc_sid: string
  exc_cnx: ConnexionOrigin | null
  primarysid?: string
  email: string
  lastname: string
  firstname: string
  gender: string
  exp: number
  auth_time?: number | null
  rights: Right[]
  logoutUrl?: string | null
}

export type Right = {
  roleId: string
  role: string
  companyName: string
  division: string
}

export type PermissionDetails = {
  tag: string
  productCode?: number | null
  articleCode?: number | null
}

export type SrcSet = {
  src: string
  'src@2x': string
  'src@3x': string
}

export type Src = {
  src: string
}

export type ProductReferencial = {
  libelleCourt: string[]
  abilities: string[]
  color: string
  productCode: ProductCode
  familyCode: number
  libelleProduitEXC: string
  articleCode: ArticleCode
  libelleArticleEXC: string
  images: SrcSet
  logo: Src
  pages: string[]
}

export function anonymizeEmail(email: string): string {
  return email.replace(/(.{2})(.*)(?=@)/, (_gp1, gp2, gp3) => gp2.padEnd(gp2.length + gp3.length, '*'))
}

export function anonymizeUUID(uuid: string): string {
  return uuid.replace(/(.{9})(.*)/, (_gp1, gp2) => `${gp2}****-****-****-************`)
}

function mapRightToLocal(right: string): Right {
  const split = right.split(';')
  return {
    division: split[0],
    companyName: split[1],
    role: split[2],
    roleId: split[3],
  }
}

export function mapUserToLocal(token: string): Result<User, ResultError> {
  try {
    const customer = jwtDecode<AccessTokenJWT>(token)
    let rights: Right[] = []
    if (customer.rig instanceof Array) {
      rights = customer.rig.map((rig) => mapRightToLocal(rig))
    } else {
      rights.push(mapRightToLocal(customer.rig))
    }

    return Result.ok({
      exc_sid: customer.exc_sid,
      exc_cnx: customer.exc_cnx ? parseInt(customer.exc_cnx, 10) : null,
      primarysid: customer.primarysid,
      email: customer.email,
      lastname: customer.family_name,
      firstname: customer.given_name,
      gender: customer.gender,
      exp: customer.exp,
      auth_time: customer.auth_time,
      rights,
      logoutUrl: customer.logoutUrl,
    })
  } catch (error) {
    return Result.err(new ResultError(error as InvalidTokenError))
  }
}
