import axios from 'axios'
import beneficiariesApi from './beneficiaries/beneficiariesApi'
import cartsApi from './carts/cartsApi'
import dashboardApi from './dashboard/dashboardApi'
import deliveryPointsApi from './deliveryPoints/deliveryPointsApi'
import ordersTrackingApi from './ordersTracking/ordersTrackingApi'
import preOrderApi from './preOrders/apis/preOrderApi'
import productsApi from './products/productsApi'
import migrationApi from './migration'
import millesimeApi from './millesime/millesimeApi'
import scopesApi from './scopes'
import sipsApi from './sips/sipsApi'
import contractsV4 from './uTR/apis/contracts/contractsV4'
import orderV4 from './uTR/apis/orderBasket/orderV4'
import fileUploadApi from './fileUpload/fileUploadApi'
import valueOfTitleApi from './valueOfTitle'
import dataStoreApi from './dataStore'
import managersApi from './managers'
import paymentMeansV4 from './paymentMeans'
import invoicesApi from './invoices'
import creditNotesApi from './creditNotes'
import { clientApiV4 } from '@/parameters'
import setAxiosInterceptors from '@/services/utils/interceptors/AxiosInterceptors'

const instance = setAxiosInterceptors(
  axios.create({
    baseURL: clientApiV4,
  }),
)

export default {
  beneficiaries: beneficiariesApi(instance),
  carts: cartsApi(instance),
  contract: contractsV4(instance),
  creditNotes: creditNotesApi(instance),
  dashboards: dashboardApi(instance),
  dataStore: dataStoreApi(instance),
  deliveryPoints: deliveryPointsApi(instance),
  fileUpload: fileUploadApi(instance),
  invoices: invoicesApi(instance),
  managers: managersApi(instance),
  migration: migrationApi(instance),
  millesime: millesimeApi(instance),
  orders: orderV4(instance),
  ordersTracking: ordersTrackingApi(instance),
  paymentMeans: paymentMeansV4(instance),
  preOrders: preOrderApi(instance),
  products: productsApi(instance),
  scopes: scopesApi(instance),
  sips: sipsApi(instance),
  valueOfTitle: valueOfTitleApi(instance),
}
