export default {
  table: {
    headers: {
      format: 'Format',
      decomposition: 'Décomposition',
      total: 'Total',
      deliveryPoint: 'Point de livraison',
      delivery: 'Livraison',
      distribution: 'Distribution',
      deliveryTr: 'Livraison TR',
      distributionTr: 'Distribution TR',
      deliveryCtr: 'Livraison CTR',
      distributionCtr: 'Distribution CTR',
      product: 'Produit',
      quantity: 'Quantité',
      quantityTr: 'Quantité TR',
      quantityCtr: 'Quantité CTR',
      amount: 'Montant',
      beneficiary: 'Bénéficiaire',
      lastname: 'Nom',
      firstname: 'Prénom',
      email: 'Email',
      matricule: 'Matricule',
      managers: 'Gestionnaires',
      associateManagers: 'Gestionnaires associés',
      roles: 'Rôles',
      status: 'Statut',
      entitled: 'Intitulé',
      deliveryPoints: 'Point(s) de livraison(s)',
      distributionPoints: 'Point(s) de distribution(s)',
      valueOfTitle: 'Valeur du titre',
      valueOfTitleTr: 'Valeur du titre TR',
      valueOfTitleCtr: 'Valeur du titre CTR',
      label: 'Libellé',
      employersContributionPercent: 'Part employeur en %',
      employersContributionEuro: 'Part employeur en euros',
      event: 'Événement',
      state: 'État',
      activated: 'Activé',
      new: 'Nouveau',
      family: 'Sous-produit',
      tracking: 'Suivi',
      sentThe: 'Expédié le',
      name: 'Nom',
      interlocutor: 'Interlocuteur',
      phone: 'Téléphone',
      address: 'Adresse',
      zipCode: 'Code postal',
      city: 'Ville',
      country: 'Pays',
      reference: 'Référence',
      contact: 'Contact',
      ratachement: 'Rattachement',
      birthday: 'Date de naissance',
      date: 'Date',
      loading: 'Chargement',
      missionName: 'Nom de la mission',
    },
    noItem: 'Aucun élément ne correspond à votre recherche !',
    empty: 'Aucun élément a été trouvé',
  },
}
