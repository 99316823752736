import { Result } from '@badrap/result'
import { computed, reactive, watch } from 'vue'
import { helpers, required, between, requiredIf, maxLength, maxValue } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useI18n } from '@/composables/useI18n'
import { useStore as useCartStore } from '@/store/cart/store'
import { useStore as useTrackingStore } from '@/store/tracking'
import {
  type AddItemWorkflow,
  type AmountData,
  moduleConfig,
  type AddItemForm,
  type MessageConfigs,
} from '@/components/Cart/Module/AddItem/workflow'
import { CartItemType, type CartItem, type CartItemRequest, type TitleComposition } from '@/services/carts/types'
import type { ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import apiV4 from '@/services/apiV4'
import { toPrice } from '@/utils/price'
import { getErrorMessage } from '@/utils/vuelidate'
import { ArticleCode } from '@/variables/ArticleCode'
import { accessor } from '@/store'
import { DEFAULT_SELECT_VALUE, decompose } from '@/components/Cart/Module/AddItem/subs/decompose'
import { useKSProductItemTracking } from '@/composables/useCartTracking'

const addKSProduct: ModuleBuilder<AddItemWorkflow> = {
  config: moduleConfig,
  isAllowed: () => true,
  useModule(cartRef, _workflow, useAction) {
    if (cartRef.value === null) {
      throw new Error('cart.errors.cartNotFound')
    }

    const { value: cart } = cartRef

    return {
      useWorkflow: (record?: { item?: CartItem }) => {
        const cartStore = useCartStore()
        const article = cartStore.article(cart.meta.productCode, cart.meta.articleCode)

        const itemConfigs = cartStore
          .cartItemConfigs(cart.meta.productCode, cart.meta.articleCode)
          ?.filter((itemConfig) => !itemConfig.nominative)
        if (itemConfigs == null) {
          throw new Error('cart.errors.cartItemConfig.notFound')
        }

        const messageConfigs: MessageConfigs = {
          line1: {
            show: true,
            maxChar: 50,
          },
          line2: {
            show: true,
            maxChar: 50,
          },
          line3: {
            show: true,
            maxChar: 50,
          },
        }

        const i18n = useI18n()
        const noSpecialChars = helpers.withMessage(
          i18n.t('cart.modules.addItem.personalizedMessage.errors.noSpecialChars').toString(),
          helpers.regex(/^[^,;:«»()_‘.&{}°/\\*$]+$/),
        )
        const showEvent = cart.meta.articleCode !== ArticleCode.KADEOS_CULTURE
        const showDeliveryPoint = !cart.meta.isCrossSell
        const events = cartStore.eventsByProductCode(cart.meta.productCode)
        const cartItemConfig = itemConfigs.find((config) => config.type === CartItemType.Booklet)
        const itemType = CartItemType.Booklet
        const amount = cartItemConfig?.recommendedAmount ?? null
        const form: AddItemForm = reactive({
          itemType,
          event: showEvent ? (events.length > 0 ? events[0] : null) : null,
          quantity: 10,
          amount: amount
            ? {
                value: amount,
                decompositions: decompose(amount, DEFAULT_SELECT_VALUE)?.tickets ?? [],
                hasDecomposition: false,
              }
            : null,
          deliveryPoint: null,
          message: { line1: null, line2: null, line3: null },
          pocketsQuantity: 0,
        })

        if (record?.item != null) {
          form.itemType = itemConfigs.find((config) => config.type === record.item?.itemType)?.type ?? form.itemType

          form.event = events.find((event) => event.value === record?.item?.eventCode) ?? form.event
          form.quantity =
            (form.itemType === CartItemType.Booklet
              ? record.item.packagingQuantity
              : record.item.totalTitleQuantities) ?? form.quantity
          form.deliveryPoint = record.item.deliveryPointReference
            ? {
                reference: record.item.deliveryPointReference,
                hasDistribution: !!record.item.distributionPointReference,
                distributionRef: record.item.distributionPointReference ?? undefined,
              }
            : null

          form.message = record.item.message ?? form.message
          form.pocketsQuantity = record.item.pocketsQuantity ?? form.pocketsQuantity
          form.amount = {
            value: record.item.compositions.reduce<number>((r, composition) => {
              if (form.itemType === CartItemType.Booklet) {
                r += composition.titleValue * composition.quantity
              } else {
                r += composition.titleValue
              }
              return r
            }, 0),
            decompositions: record.item.compositions.map((composition) => ({
              value: composition.titleValue,
              count: composition.quantity,
            })),
            hasDecomposition: true,
          }
        }
        const itemConfig = computed(() => itemConfigs.find((config) => config.type === form.itemType))

        const rules = computed(() => ({
          itemType: { required },
          event: {
            required: helpers.withMessage(
              i18n.t('cart.modules.addItem.events.errors.required').toString(),
              requiredIf(showEvent),
            ),
          },
          quantity: {
            required: helpers.withMessage(i18n.t('cart.modules.addItem.quantity.errors.required').toString(), required),
            between: helpers.withMessage(({ $params }) => {
              return i18n
                .t(`cart.modules.addItem.quantity.errors.${form.itemType}Between`, {
                  min: $params.min,
                  max: $params.max,
                })
                .toString()
            }, between(itemConfig.value?.minQuantity ?? 0, itemConfig.value?.maxQuantity ?? 0)),
          },
          amount: {
            required: helpers.withMessage(i18n.t('cart.modules.addItem.amount.errors.required').toString(), required),
            integer: helpers.withMessage(
              i18n.t('cart.modules.addItem.amount.errors.integer').toString(),
              (value: AmountData) => Number.isInteger(value.value / 100),
            ),
            between: helpers.withMessage(
              () =>
                i18n
                  .t('cart.modules.addItem.amount.errors.between', {
                    min: toPrice(itemConfig.value?.minAmount ?? 0),
                    max: toPrice(itemConfig.value?.maxAmount ?? 0),
                  })
                  .toString(),
              (amount: AmountData) =>
                amount.value >= (itemConfig.value?.minAmount ?? 0) &&
                amount.value <= (itemConfig.value?.maxAmount ?? 0),
            ),
          },
          deliveryPoint: {
            reference: {
              required: helpers.withMessage(
                i18n.t('cart.modules.addItem.deliveryPoint.errors.deliveryPoint.required').toString(),
                requiredIf(showDeliveryPoint),
              ),
            },
            distributionRef: {
              required: helpers.withMessage(
                i18n.t('cart.modules.addItem.deliveryPoint.errors.distributionPoint.required').toString(),
                requiredIf(
                  showDeliveryPoint &&
                    (accessor.session.clientProducts[cart.meta.productCode]?.orderSettings.distributionPointMandatory ??
                      false),
                ),
              ),
            },
          },
          message: {
            line1: {
              maxLength: helpers.withMessage(
                i18n
                  .t('cart.modules.addItem.personalizedMessage.errors.maxLength', { max: messageConfigs.line1.maxChar })
                  .toString(),
                maxLength(messageConfigs.line1.maxChar),
              ),
              noSpecialChars,
            },
            line2: {
              maxLength: helpers.withMessage(
                i18n
                  .t('cart.modules.addItem.personalizedMessage.errors.maxLength', { max: messageConfigs.line2.maxChar })
                  .toString(),
                maxLength(messageConfigs.line2.maxChar),
              ),
              noSpecialChars,
            },
            line3: {
              maxLength: helpers.withMessage(
                i18n
                  .t('cart.modules.addItem.personalizedMessage.errors.maxLength', { max: messageConfigs.line3.maxChar })
                  .toString(),
                maxLength(messageConfigs.line3.maxChar),
              ),
              noSpecialChars,
            },
          },
          pocketsQuantity: {
            required: requiredIf(form.itemType === CartItemType.Bulk),
            maxValue: helpers.withMessage(
              () => i18n.t('cart.modules.addItem.pocketsQuantity.errors.maxValue', { max: form.quantity }).toString(),
              maxValue(form.quantity),
            ),
          },
        }))

        const v$ = useVuelidate(rules, form)

        watch(
          () => v$.value.itemType.$model,
          () => {
            const cartItemConfig = itemConfigs.find((config) => config.type === v$.value.itemType.$model)
            if (cartItemConfig) {
              v$.value.amount.$model = {
                value: cartItemConfig.recommendedAmount,
                decompositions: [],
                hasDecomposition: false,
              }
            }
          },
        )

        const fetchCartItemRequest: () => Result<CartItemRequest> = () => {
          v$.value.$touch()
          if (v$.value.$invalid) {
            return Result.err(new Error(getErrorMessage(v$.value.$errors)))
          }
          if (v$.value.amount.$model == null) {
            return Result.err(new Error('cart.modules.addItem.error.field.required'))
          }

          const generic = {
            itemType: v$.value.itemType.$model,
            deliveryPointReference: v$.value.deliveryPoint.$model?.reference ?? null,
            distributionPointReference: v$.value.deliveryPoint.$model?.distributionRef ?? null,
            eventCode: v$.value.event.$model?.value ?? null,
            message: v$.value.message.$model,
          }

          if (cart.meta.articleCode === ArticleCode.KADEOS_CULTURE) {
            generic.eventCode = 75
          }

          if (v$.value.itemType.$model === CartItemType.Booklet) {
            const compositions: TitleComposition[] = v$.value.amount.$model.decompositions.map((decomposition) => ({
              quantity: decomposition.count,
              titleValue: decomposition.value,
              employersContribution: 1,
            }))

            return Result.ok({
              ...generic,
              packagingQuantity: v$.value.quantity.$model,
              compositions,
            })
          } else {
            return Result.ok({
              ...generic,
              packagingQuantity: 1,
              pocketsQuantity: v$.value.pocketsQuantity.$model,
              compositions: [
                {
                  quantity: v$.value.quantity.$model,
                  titleValue: v$.value.amount.$model.value,
                  employersContribution: 1,
                },
              ],
            })
          }
        }

        return {
          events,
          itemConfig,
          itemConfigs,
          deliveryPointTitle: 'cart.modules.settings.deliveryPoint.titlePaper',
          v$,
          messageConfigs,
          itemTypeImage: computed(() => {
            if (article == null) {
              return null
            }

            if (form.itemType === CartItemType.Bulk) {
              return article.image
            }

            return article.bookletImage
          }),
          showPocket: computed(() => form.itemType === CartItemType.Bulk),
          showEvent,
          showDeliveryPoint,
          pocketImage: article?.pocketImage ?? null,
          addAction: useAction({
            id: 'add',
            name: record?.item ? 'cart.modules.addItem.editAction' : 'cart.modules.addItem.action',
            type: ActionType.Default,
            refresh: true,
            async execute() {
              const requestItemResult = fetchCartItemRequest()

              if (requestItemResult.isErr) {
                return Result.err(requestItemResult.error)
              }

              let result: Result<CartItem>
              if (record?.item == null) {
                result = await apiV4.carts.postItem(cart.remote.id, requestItemResult.value)
              } else {
                result = await apiV4.carts.putItem(cart.remote.id, record.item.id, requestItemResult.value)
              }

              if (result.isErr) {
                return Result.err(result.error)
              }

              const trackingStore = useTrackingStore()
              const item = useKSProductItemTracking(
                cart.meta.productCode,
                cart.meta.articleCode,
                cart.meta.isNominative,
                result.value,
              )

              if (item) {
                trackingStore.trackEvent({
                  id: 'add_to_cart',
                  data: {
                    cart_id: cart.remote.id.toString(),
                    currency: 'EUR',
                    value: result.value.totalAmount / 100,
                    items: [item],
                  },
                })
              }

              return Result.ok(true)
            },
          }),
        }
      },
    }
  },
}

export default addKSProduct
