export default {
  mfa: {
    enterCodePage: {
      inputPlaceHolder: 'Votre code à 6 chiffres',
      saveDeviceLabel: 'Ne plus me le demander pendant 90 jours',
      email: {
        title: 'Renseigner le code reçu par e-mail',
        hint: 'Votre e-mail&nbsp;: <b>{email}</b>',
        requestNewCode: "Me renvoyer l'email",
        codeSentLabel: 'E-mail envoyé, il vous sera possible de renvoyer un code dans une minute',
        cannotReceiveButton: 'Je ne reçois pas l’e-mail',
        cannotAccessButton: 'Je n’ai pas accès à cette adresse e-mail',
      },
      sms: {
        title: 'Renseigner le code reçu par SMS',
        hint: 'Saisissez le code que vous venez de recevoir sur votre téléphone : <b>{phoneNumber}</b>',
        requestNewCode: 'Me renvoyer le SMS',
        codeSentLabel: 'SMS envoyé, il vous sera possible de renvoyer un code dans une minute',
        cannotReceiveButton: 'Je n’ai pas reçu le SMS',
        cannotAccessButton: 'J’ai changé de numéro de téléphone',
      },
    },
    contactPage: {
      title: 'Contacter notre service client',
      alert: {
        title: `A partir du {date}, vous devrez obligatoirement renseigner le code reçu par e-mail pour vous connecter`,
        description:
          "Afin de renforcer la sécurité, votre Espace Client ne sera plus accessible sans validation du code. Si vous n'avez pas accès à la boite email correspondant à l'adresse avec laquelle vous vous connectez, contactez notre service client dès que possible. Jusqu'à cette date, vous pouvez encore vous connecter.",
      },
      text: "Pour modifier votre adresse mail de connexion à l'Espace Client, contactez-nous au :",
      phoneNumber: '08 21 23 24 25',
      bypassButton: 'Je me connecte exceptionnellement',
    },
    codeSentAlert: {
      email: {
        title: 'Email envoyé',
        description: "Vérifiez vos spams, l'email s'y trouve peut-être !",
      },
      sms: {
        title: 'SMS envoyé',
        description: 'Un SMS contenant le code de sécurité à saisir vient de vous être envoyé.',
      },
    },
    codeNotSentAlert: {
      title: 'Une erreur est survenue',
      description: "Une erreur est survenue lors de l'envoie du code. Veuillez réessayer.",
    },
    sessionExpiredAlert: {
      title: 'Session expirée',
      description: 'Veuillez vous reconnecter',
    },
    invalidCodeAlert: {
      title: 'Code incorrect',
      description: 'Le code saisi ne correspond pas à celui reçu.',
    },
    accountLocked: {
      title: 'Compte verrouillé',
      description: 'Suite à un trop grand nombre d’essais infructueux, votre compte est verrouillé pendant 1h.',
    },
  },
}
