import type { ComputedRef, Ref } from 'vue'
import type { Result } from '@badrap/result'
import type { Validation, ValidationArgs } from '@vuelidate/core'
import type { UseDeliveryPointWorkflow, UseValueOfTitleWorkflow } from '../common/workflow'
import type { Beneficiary } from '@/services/beneficiaries/types'
import { type ModuleConfig, ModuleType, type RemoteCart, type LocalDeliveryPoint } from '@/store/cart/model'
import type { Action } from '@/types/Action'
import type { CartItem } from '@/services/carts/types'
import type { UseBeneficiaryFormWorkflow } from '@/composables/useBeneficiaryFormWorkflow'
import type { ValueOfTitleData } from '@/types/ValueOfTitle'

export const moduleConfig: ModuleConfig = {
  id: 'edit-beneficiary',
  type: ModuleType.Drawer,
  component: () => import('@/components/Cart/Module/EditItemBeneficiary/index.vue'),
}

type QuantityState = {
  quantity: number
}

export interface EditUseItemFormWorkflow {
  v$: Ref<Validation<ValidationArgs<QuantityState>, QuantityState>>
  useValueOfTitleWorkflow: UseValueOfTitleWorkflow
  useDeliveryPointWorkflow: UseDeliveryPointWorkflow
  submit: (
    submitValueOfTitle: () => Promise<Result<ValueOfTitleData>>,
    submitDeliveryPoint: () => Promise<Result<LocalDeliveryPoint>>,
  ) => (beneficiary: Beneficiary) => Promise<Result<void>>
}

export type UseEditItemFormWorkflow = (cart: RemoteCart, item: CartItem) => EditUseItemFormWorkflow

export interface EditItemBeneficiaryWorkflow {
  title: string
  cancelAction: Action | null
  useBeneficiaryFormWorkflow: ComputedRef<UseBeneficiaryFormWorkflow | null>
  useEditItemFormWorkflow: UseEditItemFormWorkflow
  submitAction: (
    submitBenef?: () => Promise<Result<Beneficiary>>,
    submitItem?: (beneficiary: Beneficiary) => Promise<Result<void>>,
  ) => Action
}
