import type { Asset, Entry } from 'contentful'
import type { TypeReinsuranceSkeleton } from '../generated'
import { mapMediaToLocal, type CFMedia } from './CFMedia'

export interface CFReinsurance {
  icon: CFMedia
  title: string
  description: string
}

export function mapReinsuranceToLocal(cta: Entry<TypeReinsuranceSkeleton, undefined, string>): CFReinsurance {
  return {
    ...cta.fields,
    icon: mapMediaToLocal(cta.fields.icon as Asset<undefined, string>),
  }
}

export function mapReinsurancesToLocal(ctas: Entry<TypeReinsuranceSkeleton, undefined, string>[]): CFReinsurance[] {
  return ctas.map((cta) => mapReinsuranceToLocal(cta))
}
