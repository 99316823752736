import _map from 'lodash/map'

export function getPdist(deliveryPoint, distributionPointMandatory) {
  const pdistDefault = [
    {
      id: 'no-pd',
      label: 'Aucun point de distribution',
      value: null,
    },
  ]

  if (deliveryPoint && deliveryPoint.distributionPoints) {
    const DistributionPoints = deliveryPoint.distributionPoints || pdistDefault
    const pdist = _map(DistributionPoints, (pdist) => ({
      value: pdist.Reference,
      label: `${pdist.Reference} - ${pdist.Designation}`,
      designation: pdist.Designation,
    }))
    if (DistributionPoints && DistributionPoints.length && !distributionPointMandatory) {
      pdist.unshift({ value: '', label: 'Aucun point de distribution', designation: 'Aucun point de distribution' })
    }
    return pdist
  } else {
    return []
  }
}
