import { defineStore } from 'pinia'
import { FeatureFlag, type FeatureFlagState } from './model'
import { ENABLED_FEATURES, DISABLED_FEATURES } from '@/parameters'

export const useStore = defineStore('featureFlag', {
  state: (): FeatureFlagState => {
    const features: { [key in FeatureFlag]: boolean } = {
      [FeatureFlag.Chargement]: false,
      [FeatureFlag.PCCMultiValidation]: false,
      [FeatureFlag.Fintecture]: true,
      [FeatureFlag.Promo]: true,
      [FeatureFlag.IsProductHolderProcess]: false,
      [FeatureFlag.Recaptcha]: true,
      [FeatureFlag.NewPaymentMeansPage]: false,
      [FeatureFlag.NewInvoicesPage]: false,
      [FeatureFlag.SmsOtp]: false,
      [FeatureFlag.TrackingLog]: false,
      [FeatureFlag.TrackingGtm]: false,
      [FeatureFlag.TrackingSentry]: false,
      [FeatureFlag.NewOrderDetail]: false,
      [FeatureFlag.NewDeliveryPointsPage]: false,
      [FeatureFlag.OneCartKSNominative]: false,
      [FeatureFlag.SmarterEmailCollection]: false,
      [FeatureFlag.FAQEnableSearch]: false,
    }

    if (ENABLED_FEATURES) {
      const enabledFeaturesList = ENABLED_FEATURES.split(',') as FeatureFlag[]

      enabledFeaturesList.forEach((feat) => {
        if (features[feat] != null) {
          features[feat] = true
        }
      })
    }

    if (DISABLED_FEATURES) {
      const disabledFeaturesList = DISABLED_FEATURES.split(',') as FeatureFlag[]

      disabledFeaturesList.forEach((feat) => {
        if (features[feat] != null) {
          features[feat] = false
        }
      })
    }

    return {
      features,
    }
  },
  getters: {
    isEnabled: (state) => (feature: FeatureFlag) => {
      return state.features[feature] ?? false
    },
  },
})
