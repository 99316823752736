export function toPrice(price: number, currency = true, digit = true): string {
  const formatter = new Intl.NumberFormat('fr-FR', {
    ...(currency ? { style: 'currency', currency: 'EUR' } : {}),
    maximumFractionDigits: 2,
  })
  const formatedPrice = formatter.format(price / 100)

  return digit ? formatedPrice : formatedPrice.replace(',00', '')
}

export function toPercent(percent: number): string {
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'percent',
    maximumFractionDigits: 2,
  })

  return formatter.format(percent / 10000)
}
