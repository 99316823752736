import basket from '../../common/steps/basket'
import type { BasketWorkflow } from '@/components/Cart/Step/Basket/workflow'
import apiV4 from '@/services/apiV4'
import type { CartItem } from '@/services/carts/types'
import type { Pagination } from '@/services/common/types'
import type { StepBuilder } from '@/store/cart/model'
import useSwrv from '@/utils/useSwrv'

const step: StepBuilder<BasketWorkflow> = {
  ...basket,
  useStep(cart, workflow, record, useAction) {
    const useStep = basket.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const commonWorkflow = useStep.useWorkflow()

        commonWorkflow.table.value.columns = [
          {
            id: 'beneficiary',
            label: 'table.headers.beneficiary',
          },
          { id: 'source', label: 'table.headers.missionName' },
          {
            id: 'quantity',
            label: 'table.headers.quantity',
            tooltip: 'cart.mixed.table.headers.tooltip.quantity',
          },
          { id: 'amount', label: 'table.headers.amount' },
          { id: 'delivery', label: 'table.headers.delivery' },
        ]
        commonWorkflow.table.value.selectable = false
        commonWorkflow.table.value.loading = false
        commonWorkflow.table.value.readonly = true
        commonWorkflow.table.value.rowComponent = () => import('@/components/Cart/Step/Basket/Row/MultiloadingRow.vue')

        const response = useSwrv<Pagination<CartItem>>(
          () =>
            `cart/${cart.remote.id}/items?${commonWorkflow.paginationData.queries.value}&isNew=${commonWorkflow.beneficiaryOption.value}`,
          async () => {
            commonWorkflow.table.value.loading = true

            const result = await apiV4.carts.fetchItems(cart.remote.id, {
              page: commonWorkflow.paginationData.currentPage.value,
              sort: commonWorkflow.paginationData.sort.value,
              sortDirection: commonWorkflow.paginationData.sortDirection.value,
              filter: commonWorkflow.paginationData.filter.value,
              pageSize: commonWorkflow.paginationData.pageSize.value,
              isNewBeneficiary: commonWorkflow.beneficiaryOption.value?.value as boolean,
            })
            if (result.isOk) {
              commonWorkflow.table.value.loading = false

              return {
                items: result.value.items.reduce<CartItem[]>((r, item) => {
                  for (let index = 0; index < item.compositions.length; index++) {
                    const composition = item.compositions[index]

                    if (index === 0) {
                      r.push({
                        ...item,
                        compositions: item.compositions,
                        rowspan: index,
                      })
                    } else {
                      r.push({
                        ...item,
                        compositions: [composition],
                        rowspan: index,
                      })
                    }
                  }

                  return r
                }, []),
                pageIndex: result.value.pageIndex,
                pageSize: result.value.pageSize,
                totalElements: result.value.totalElements,
              }
            } else {
              commonWorkflow.table.value.loading = false
              throw result.error
            }
          },
        )
        return {
          ...commonWorkflow,
          response,
          hasAdd: false,
          hasImport: true,
          showActions: false,
          showSettings: false,
        }
      },
    }
  },
}

export default step
