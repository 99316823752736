import { Result } from '@badrap/result'
import payment, {
  useBillingContent,
  useDelivery,
  commonPaymentWorkflow,
} from '@/store/cart/cartWorkflows/common/steps/payment'
import { useMixedPricings } from '@/store/cart/cartWorkflows/common/usePricing'
import type { PaymentWorkflow, DeliveryProp, BillingProp } from '@/components/Cart/Step/Payment/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { ProductCode } from '@/variables/ProductCode'
import apiV4 from '@/services/apiV4'
import type { DeliveryInformations } from '@/services/carts/types'
import type { Cart } from '@/services/carts/typesV2'

const step: StepBuilder<PaymentWorkflow> = {
  ...payment,
  async prepareStep(cart) {
    const deliveryInformations: Record<number, DeliveryInformations> = {}
    for (let index = 0; index < cart.remote.linkedCarts.length; index++) {
      const linkedCart = cart.remote.linkedCarts[index]
      const result = await apiV4.carts.fetchDeliveryInformations(linkedCart.id)

      if (result.isErr) {
        return Result.err(result.error)
      }
      deliveryInformations[linkedCart.id] = result.value
    }

    const totalItemsResult = await apiV4.carts.headItems(cart.remote.id)
    if (totalItemsResult.isErr) {
      return Result.err(totalItemsResult.error)
    }

    return Result.ok({ deliveryInformations, totalItems: totalItemsResult.value })
  },
  useStep(
    cart,
    workflow,
    record: { linkedCarts: Cart[]; deliveryInformations: Record<number, DeliveryInformations>; totalItems: number },
    useAction,
  ) {
    const useStep = payment.useStep(cart, workflow, record, useAction)
    return {
      ...useStep,
      useWorkflow() {
        const dataProps =
          record.linkedCarts.reduce<{ deliveries: DeliveryProp[]; billing: BillingProp }[]>((r, linkedCart) => {
            const deliveryInformation = record.deliveryInformations[linkedCart.id]
            if (linkedCart.description.productCode === ProductCode.CARTE_TICKET_RESTAURANT) {
              r.push({
                deliveries: [
                  useDelivery(linkedCart, deliveryInformation, {
                    title: 'cart.delivery.loadCardTitle',
                    canChangeDate: true,
                    type: 'dematerialized',
                    productCode: linkedCart.description.productCode,
                    articleCode: linkedCart.description.articleCode,
                    deliveryDate: linkedCart.paymentOptions.deliveryDate,
                  }),
                  useDelivery(linkedCart, deliveryInformation, {
                    title: 'cart.delivery.cardTitle',
                    canChangeDate: false,
                    type: 'default',
                    description:
                      linkedCart.options.isHomeDelivery || deliveryInformation.deliveryPoint?.isHomeDelivery
                        ? 'cart.delivery.description'
                        : 'cart.delivery.atCompanyDescription',
                    productCode: linkedCart.description.productCode,
                    articleCode: linkedCart.description.articleCode,
                    deliveryDate: null,
                  }),
                ],
                billing: {
                  title: 'cart.billing.cardTitle',
                  description: 'cart.billing.description',
                  right: useBillingContent(cart, deliveryInformation.billingAddress),
                  productCode: linkedCart.description.productCode,
                  articleCode: linkedCart.description.articleCode,
                },
              })
            } else if (linkedCart.description.productCode === ProductCode.TICKET_RESTAURANT) {
              r.push({
                deliveries: [
                  useDelivery(linkedCart, deliveryInformation, {
                    title: 'cart.delivery.paperTitle',
                    canChangeDate: true,
                    type: 'default',
                    description: 'cart.delivery.description',
                    productCode: linkedCart.description.productCode,
                    articleCode: linkedCart.description.articleCode,
                    deliveryDate: linkedCart.paymentOptions.deliveryDate,
                    disabledDates: { weekdays: [1, 7] },
                  }),
                ],
                billing: {
                  title: 'cart.billing.paperTitle',
                  description: 'cart.billing.description',
                  right: useBillingContent(cart, deliveryInformation.billingAddress),
                  articleCode: linkedCart.description.articleCode,
                  productCode: linkedCart.description.productCode,
                },
              })
            }
            return r
          }, []) ?? []

        return {
          ...commonPaymentWorkflow(cart, workflow, record, useMixedPricings(record.linkedCarts), useAction),
          dataProps,
          showProduct: true,
          showMissingEmails: false,
        }
      },
    }
  },
}

export default step
