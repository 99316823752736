export default {
  simulator: {
    title: 'Simulateur',
    button: 'Créer la nouvelle valeur',
    currentSituation: {
      title: 'Situation actuelle',
      actualValue: 'Valeur actuelle',
    },
    valueOfTitle: {
      title: 'Nouvelle valeur du titre',
      tooltip: "Il s'agit du montant total que vous attribuez à vos bénéficiaires pour un jour travaillé",
    },
    employersContribution: {
      title: 'Nouvelle part employeur',
      tooltip:
        'Montant que vous prenez en charge pour chaque titre. Il doit être compris entre {min} et {max} de la valeur du titre',
      description: '{employer} employeur + {employee} bénéficiaire',
      exo: "La limite d'exonération {year} est fixée à {exo}. La partie excédentaire doit être déclarée.",
      tooltipExo: 'Exonération max {exo}',
    },
    results: {
      for: 'Simulation pour {nbBenef} bénéficiaires',
      companyEconomies: '<b>Economies</b> versus une <b>augmentation</b> de salaire',
      extraPurchasingPower: '<b>Pouvoir d’achat supplémentaire</b> pour un bénéficiaire',
      noEconomy: 'Vous <b>ne faites pas d’économies supplémentaires</b> par rapport à votre <b>situation actuelle</b>',
      seeDetail: 'Voir le détail',
      detail: {
        title: 'Détail',
      },
      oldValue: 'Ancienne&nbsp;:',
      newValue: 'Nouvelle&nbsp;:',
      detailValue: '{amount} ({percent}%) = {employer} employeur + {employee} bénéficiaire',
      saving: {
        title: 'Économies annuelles supplémentaires',
        purchasingPower: 'Pouvoir d’achat supplémentaire',
        salaryIncrease: 'Équivalent en augmentation de salaire',
        savingsVSIncrease: 'Économies réalisées versus une augmentation',
        head: {
          by: 'Par bénéficiaire',
          for: 'Pour {count} bénéficiaires',
        },
        calulated: {
          byYear: '<b>{price}</b>/ an',
          byDay: '{nbDay} jours x ({newPP} - {oldPP})',
          charge: '{price} / {chargePercent}% de charges',
          subtract: '{priceA} - {priceB}',
          byBenef: '{price} x {nbBenef} bénéficiaires',
          nbNoExempt: '{nbDay} jours x ({newPP} - {exo}) x {nbBenef} bénéficiaires',
        },
        noExempt: {
          title: 'Estimation de la somme non exonérée à déclarer&nbsp;:',
          tooltip: "Part patronale soumise à cotisation car supérieure au plafond d'exonération de {exo}",
        },
      },
      mailto: {
        subject: 'Edenred - Simulateur',
      },
    },
    errors: {
      valueOfTitle: {
        required: 'Le montant est requis',
        minValue: 'Le montant minimum est de {min}€',
        maxValue: 'Le montant maximum est de {max}€',
      },
      employersContribution: {
        required: 'La part patronale en “ % “ est requise',
        minValue: 'La part patronale est de {min}% minimum',
        maxValue: 'La part patronale est de {max}% maximum',
      },
      employersContributionEuro: {
        required: 'La part patronale en  “ € “ est requise',
      },
      family: {
        required: 'Le sous-produit est requis',
      },
    },
    banner: {
      title: 'Nouveau plafond d’exonération à {exo} !',
      description: 'Faites des économies et donnez du pouvoir d’achat à vos bénéficiaires.',
      cta: 'Simuler mes économies',
    },
    family: {
      label: 'Sous-produit',
    },
    v2: {
      valueOfTitle: {
        exo: 'Valeurs les plus choisies au plafond <b>d’exonération de {exo}</b>',
        detail:
          '<b>{total}</b> = <b>{employers}</b> employeur ({employersContribution}) + <b>{employee}</b> bénéficiaire ({employeeContribution})',
        otherAmount: 'Autre valeur',
        mostUsed: 'La plus choisie',
        custom: {
          description: 'Choisissez un montant sur mesure pour vos bénéficiaires',
          amount: 'Montant du titre',
          employersContribution: 'Part employeur',
          minMax: 'Min&nbsp;: {min} - Max&nbsp;: {max}',
          exo: "La limite d'exonération {year} est fixée à {price}. La partie excédentaire doit être déclarée.",
        },
      },
    },
  },
}
