import type { ContentfulClientApi, Entry } from 'contentful'
import { mapBannerToLocal } from '../banners'
import { mapModalToLocal } from '../modals'
import type { TypeBannerSkeleton, TypeCampaignSkeleton, TypeModalSkeleton } from '../generated'
import type { CFCampaign } from '../types/CFCampaign'

export default (contentfulInstance: ContentfulClientApi<undefined>) => {
  const fetchCampaign = async (id: string) => {
    const date = new Date()

    const result = await contentfulInstance.getEntries<TypeCampaignSkeleton>({
      content_type: 'campaign',
      'fields.id': id,
      'fields.target': 'EC',
      'fields.startDate[lte]': date.toISOString() as `${number}-${number}-${number}T${number}:${number}:${number}Z`,
      'fields.endDate[gte]': date.toISOString() as `${number}-${number}-${number}T${number}:${number}:${number}Z`,
      include: 2,
    })

    const items = mapCampaignsToLocal(result.items)
    return items.length > 0 ? items[0] : null
  }

  return {
    fetchCampaign,
  }
}

function mapCampaignsToLocal(items: Entry<TypeCampaignSkeleton, undefined, string>[]): CFCampaign[] {
  return items.map((item) => mapCampaignToLocal(item))
}

function mapCampaignToLocal(item: Entry<TypeCampaignSkeleton, undefined, string>): CFCampaign {
  return {
    ...item.fields,
    discountCode: item.fields.discountCode ?? null,
    modal: item.fields.modal ? mapModalToLocal(item.fields.modal as Entry<TypeModalSkeleton, undefined, string>) : null,
    banner: item.fields.banner
      ? mapBannerToLocal(item.fields.banner as Entry<TypeBannerSkeleton, undefined, string>)
      : null,
  }
}
