import { useDownloadFile } from '@/composables/useDownloadFile'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = 'v2/orders/:basketId/summary/pdf'

const getBaseUrl = urlReplacer(baseUrl, {
  basketId: '',
})

const downloadFile = useDownloadFile()

export default (axiosInstance) => {
  const downloadSummary = async function (basketId) {
    const fileName = 'EdenredRecapitulatif.pdf'
    const result = await axiosInstance.get(getBaseUrl({ basketId }), {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    })

    const blob = new Blob([result.data], {
      type: 'application/pdf',
    })

    downloadFile(blob, fileName)
  }
  return {
    downloadSummary,
  }
}
