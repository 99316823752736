import type { AxiosError, AxiosInstance } from 'axios'
import { Result } from '@badrap/result'
import type { Pagination, PaginationRequest } from '../common/types'
import type { ApiAxiosError } from '../common/errors'
import type { FileUploadContext, FileUploadStatus } from '../fileUpload/types'
import type {
  Beneficiary,
  BeneficiaryCard,
  BeneficiaryParcel,
  ImportConflict,
  ImportDuplicate,
  PaginationParams,
  PatchBeneficiaryRequest,
  PostBeneficiaryRequest,
  PostImportRequestBody,
} from './types'
import { BeneficiariesError } from './errors'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = 'api/:version/Beneficiaries'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
})

export default (axiosInstance: AxiosInstance) => {
  const fetchBeneficiary = async (id: string | number) => {
    try {
      const result = await axiosInstance.get<Beneficiary>(`${getBaseUrl()}/${id}`)
      return Result.ok(result.data)
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      return Result.err(_error)
    }
  }

  const fetchBeneficiaryParcels = async (id: string, params: PaginationParams) => {
    try {
      const result = await axiosInstance.get<Pagination<BeneficiaryParcel>>(`${getBaseUrl()}/${id}/parcels`, { params })
      return Result.ok(result.data)
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      return Result.err(_error)
    }
  }

  const fetchBeneficiaryCards = async (id: string, params: PaginationParams) => {
    try {
      const result = await axiosInstance.get<Pagination<BeneficiaryCard>>(`${getBaseUrl()}/${id}/cards`, {
        params,
      })
      return Result.ok(result.data)
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      return Result.err(_error)
    }
  }

  const headBeneficiaries = async () => {
    try {
      const result = await axiosInstance.head(`${getBaseUrl()}`)
      return Result.ok(parseInt(result.headers['x-total-count'] || '', 10))
    } catch (error) {
      const _error = error as AxiosError
      return Result.err(_error)
    }
  }

  const fetchBeneficiaries = async (params: PaginationParams) => {
    try {
      const result = await axiosInstance.get<Pagination<Beneficiary>>(`${getBaseUrl()}`, { params })
      return Result.ok(result.data)
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      return Result.err(_error)
    }
  }

  const getBeneficiariesOutOfBasket = async (basketId: number, extraParams: PaginationParams) => {
    const params = Object.assign({}, { OutsideOfBasketId: basketId }, extraParams ? extraParams : {})
    try {
      const result = await axiosInstance.get(`${getBaseUrl()}`, { params })
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.message
    }
  }

  const postBeneficiary = async (beneficiary: PostBeneficiaryRequest) => {
    try {
      const result = await axiosInstance.post<Beneficiary>(`${getBaseUrl()}`, beneficiary)
      return Result.ok(result.data)
    } catch (error) {
      const _error = error as AxiosError
      return Result.err(_error)
    }
  }

  const patchBeneficiary = async (id: number, beneficiary: PatchBeneficiaryRequest) => {
    try {
      const result = await axiosInstance.patch<Beneficiary>(`${getBaseUrl()}/${id}`, beneficiary)
      return Result.ok(result.data)
    } catch (error) {
      const _error = error as AxiosError
      return Result.err(_error)
    }
  }

  const postImport = async (requestBody: PostImportRequestBody) => {
    try {
      const result = await axiosInstance.post<FileUploadContext>(`${getBaseUrl()}/import`, requestBody, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new BeneficiariesError(error as ApiAxiosError))
    }
  }

  const fetchImport = async (importId: number) => {
    try {
      const result = await axiosInstance.get<FileUploadStatus>(`${getBaseUrl()}/import/${importId}/status`)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new BeneficiariesError(error as ApiAxiosError))
    }
  }

  const validateImport = async (importId: number) => {
    try {
      const result = await axiosInstance.post<FileUploadStatus>(`${getBaseUrl()}/import/${importId}/validate`)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new BeneficiariesError(error as ApiAxiosError))
    }
  }

  const headImportConflicts = async (importId: number) => {
    try {
      const result = await axiosInstance.head(`${getBaseUrl()}/import/${importId}/conflicts`)

      return Result.ok(parseInt(result.headers['x-total-count'] || '', 10))
    } catch (error) {
      return Result.err(new BeneficiariesError(error as ApiAxiosError))
    }
  }

  const fetchImportConflicts = async (importId: number, params: PaginationRequest) => {
    try {
      const result = await axiosInstance.get<Pagination<ImportConflict>>(
        `${getBaseUrl()}/import/${importId}/conflicts`,
        { params },
      )
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new BeneficiariesError(error as ApiAxiosError))
    }
  }

  const fetchImportDuplicate = async (importId: number, conflictId: number) => {
    try {
      const result = await axiosInstance.get<ImportDuplicate[]>(
        `${getBaseUrl()}/import/${importId}/conflicts/${conflictId}`,
      )
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new BeneficiariesError(error as ApiAxiosError))
    }
  }

  const patchImportConflict = async (importId: number, conflictId: number, duplicateId?: number) => {
    try {
      const result = await axiosInstance.patch(`${getBaseUrl()}/import/${importId}/conflicts/${conflictId}`, {
        duplicateId,
      })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new BeneficiariesError(error as ApiAxiosError))
    }
  }

  return {
    fetchBeneficiary,
    fetchBeneficiaryParcels,
    fetchBeneficiaryCards,
    headBeneficiaries,
    fetchBeneficiaries,
    getBeneficiariesOutOfBasket,
    postBeneficiary,
    patchBeneficiary,
    postImport,
    fetchImport,
    validateImport,
    headImportConflicts,
    fetchImportConflicts,
    fetchImportDuplicate,
    patchImportConflict,
  }
}
