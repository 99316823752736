import { ApiError, type ApiAxiosError } from '../common/errors'
import { UNKNOWN_ERROR_MESSAGE } from '@/types/ResultError'

export const errorMessage: Record<string, string> = {}

export function getError(key: string): string {
  return errorMessage[key] ?? UNKNOWN_ERROR_MESSAGE
}

export class DataStoreError extends ApiError {
  constructor(error: ApiAxiosError) {
    super(error)

    this.name = 'DataStoreError'
    this.message = getError(this.code)
  }
}
