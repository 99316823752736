import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import { ApiValueOfTitleError } from './errors'
import type { ValueOfTitle, CreateValueOfTitleRequest, FacialValueStatistics } from './types'
import urlReplacer from '@/services/utils/urlReplacer'
import type { ProductCode } from '@/variables/ProductCode'

const baseUrl = 'api/:version/FacialValues/:id'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
  id: '',
})

export default (axiosInstance: AxiosInstance) => {
  const create = async (datas: CreateValueOfTitleRequest) => {
    try {
      const result = await axiosInstance.post<ValueOfTitle>(getBaseUrl(), datas)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new ApiValueOfTitleError(error as ApiAxiosError))
    }
  }

  const fetch = async (productCode?: ProductCode, isActive?: boolean) => {
    const params = new URLSearchParams()
    if (productCode) {
      params.set('productCode', productCode.toString())
    }
    if (isActive != null) {
      params.set('isActive', isActive.toString())
    }

    try {
      const result = await axiosInstance.get<ValueOfTitle[]>(`${getBaseUrl()}?${params.toString()}`)
      return Result.ok(
        result.data
          .reduce<ValueOfTitle[]>((r, value) => {
            const currentValue = r.find(
              (currentValue) =>
                currentValue.amount === value.amount &&
                currentValue.employersContributionPercentage === value.employersContributionPercentage &&
                currentValue.productCode === value.productCode &&
                currentValue.isActive === value.isActive &&
                currentValue.family === value.family,
            )

            if (currentValue == null) {
              r.push(value)
            }
            return r
          }, [])
          .sort((a, b) => {
            const amount = b.amount - a.amount
            if (amount === 0) {
              return b.employersContributionPercentage - a.employersContributionPercentage
            }

            return amount
          }),
      )
    } catch (error) {
      const _error = error as ApiAxiosError
      return Result.err(new ApiValueOfTitleError(_error))
    }
  }

  const fetchDefault = async (productCode: ProductCode) => {
    try {
      const result = await fetch(productCode, true)
      if (result.isErr) {
        return Result.err(result.error)
      }

      if (result.value.length === 0) {
        return Result.err(new Error('Amount not found'))
      }

      return Result.ok(result.value[0])
    } catch (e) {
      const error = new ApiValueOfTitleError(e as ApiAxiosError)

      return Result.err(error)
    }
  }

  const patch = async (valueOfTitle: ValueOfTitle) => {
    try {
      const result = await axiosInstance.patch<ValueOfTitle>(`${getBaseUrl({ id: valueOfTitle.id })}`, valueOfTitle)
      return Result.ok(result.data)
    } catch (error) {
      const _error = error as ApiAxiosError
      return Result.err(new ApiValueOfTitleError(_error))
    }
  }

  const fetchStatistics: (productCode: ProductCode) => Promise<Result<FacialValueStatistics | null>> = async (
    productCode,
  ) => {
    try {
      const result = await axiosInstance.get<FacialValueStatistics | null>(`${getBaseUrl()}/statistics`, {
        params: { productCode },
        validateStatus: (status) => status === 200, // To filter out 204 No Content
      })

      return Result.ok(result.data)
    } catch (e) {
      return Result.err(e as ApiAxiosError)
    }
  }

  return {
    create,
    fetch,
    fetchDefault,
    patch,
    fetchStatistics,
  }
}
