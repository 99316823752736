import type { RouteConfig } from 'vue-router'
import { AlertType } from '@/types/Alert'
import { useStore as useToasterStore } from '@/store/toast'
import { useCanAccessMigrationTunnel } from '@/composables/useMigrationAccess'
import { useMigrationStore } from '@/store/migration'
import { MigrationStatus } from '@/services/migration/types'

const DefaultRoot = () => import('@/views/DefaultRouter/Root/index.vue')

export const router: RouteConfig = {
  path: '/migration',
  component: DefaultRoot,
  children: [
    {
      meta: {
        layout: 'Migration',
      },
      path: ':id',
      component: () => import('@/views/Migration/index.vue'),
      beforeEnter: async (_, __, next) => {
        const migrationStore = useMigrationStore()
        const toastStore = useToasterStore()

        if (
          migrationStore.informations?.contractMigrationState !== MigrationStatus.Targeted &&
          migrationStore.informations?.contractMigrationState !== MigrationStatus.InProgress
        ) {
          toastStore.push({
            title: 'Migration Edenred +',
            description: "Votre statut de migration ne permet pas d'accéder à cette page.",
            type: AlertType.DANGER,
            autoHide: false,
          })
          return false
        }

        const hasAccess = useCanAccessMigrationTunnel()
        if (!hasAccess.value) {
          toastStore.push({
            title: 'Migration Edenred +',
            description:
              "Vous n'avez pas les droits nécessaire pour accéder à cette page. Veuillez contacter un superviseur ou un gestionnaire de commande.",
            type: AlertType.DANGER,
            autoHide: false,
          })
          next('/')
          return
        }

        next()
      },
    },
  ],
}
