import { FrontCartType } from '@/store/cart/model'

export enum CartRouteType {
  Local = 'l',
  Order = 'o',
  PreOrder = 'p',
}

export const cartRouteTypeToStoreType: Record<CartRouteType, FrontCartType> = {
  [CartRouteType.Local]: FrontCartType.Local,
  [CartRouteType.Order]: FrontCartType.Cart,
  [CartRouteType.PreOrder]: FrontCartType.PreOrder,
}
