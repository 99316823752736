import type { PaymentMean } from '../carts/types'
import type { MinMax } from '../common/types'
import type { PreorderType } from '../preOrders/apis/types'
import type { Address } from '@/types/Address'
import type { ProductCode } from '@/variables/ProductCode'

export type ClientProductOrderSettingsMillesime = {
  year?: number
  endDate?: string
}

export type ClientProductOrderSettings = {
  maximumDeliveryDayNumber: number
  facialValueAmount: MinMax
  titleQuantity: MinMax
  distributionPointMandatory: boolean
  orderReferenceIsMandatory: boolean
  defaultOrderReference: string
  millesime: ClientProductOrderSettingsMillesime | null
  monthValidity: number
}

export type PreorderClientOption = {
  type: PreorderType
  withCalendar: boolean
  postponementMonths: number
}

export type AutomaticMillesimeChange = {
  enabled: boolean
  forbiddenFrom: string
  forbiddenTo: string
  lastChange: string | null
}

export enum TunnelVersion {
  v1 = 'V1',
  v3 = 'V3',
  v4 = 'V4',
}

export type ClientProductOptions = {
  multiloadingAllowed: boolean
  plasticlessAllowed: boolean
  mixedOrderAllowed: boolean
  preorder: PreorderClientOption
  automaticMillesimeChange?: AutomaticMillesimeChange
  orderTunnelVersion: TunnelVersion
  rightsComputeFeatureAllowed: boolean
}

export type PaymentDeadline = {
  deadlineType: number
  maxDeadlineDays: number
}

export type AccountingSettings = {
  defaultPaymentMean: PaymentMean
  orderPaymentDeadline: PaymentDeadline
  isPFA: boolean
  billingAddress: Address
}

export enum PartnerReference {
  CMB = 1,
  CMMC = 2,
  CMSO = 3,
  CMC = 9,
  CIC = 10,
}

export type ClientProduct = {
  code: ProductCode
  shortLabel: string
  orderSettings: ClientProductOrderSettings
  clientOptions: ClientProductOptions
  accountingSettings: AccountingSettings | null
  partnerReference: PartnerReference | null
}

export type ClientProducts = Partial<Record<ProductCode, ClientProduct>>

export function mapClientProductsToLocal(products: ClientProduct[]): ClientProducts {
  return products.reduce<ClientProducts>((r, product) => {
    r[product.code] = product
    return r
  }, {})
}
