import { useMixedPricingSummaries } from '@/store/cart/cartWorkflows/common/usePricing'
import importStep, { useCommonStep } from '@/store/cart/cartWorkflows/common/steps/import'
import type { FieldResponse, ImportWorkflow } from '@/components/Cart/Step/Import/workflow'
import type { LocalDeliveryPoint, StepBuilder } from '@/store/cart/model'
import { mapRulesToLocal } from '@/types/Import'
import { DeliveryPointType } from '@/variables/DeliveryPointType'
import apiV4 from '@/services/apiV4'
import { ProductCode } from '@/variables/ProductCode'
import useSwrv from '@/utils/useSwrv'
import { toPrice } from '@/utils/price'
import { useI18n } from '@/composables/useI18n'
import { DataStoreKey } from '@/services/dataStore/types'
import options from '@/store/cart/cartWorkflows/common/module/settings/Mixed'
import { ActionType } from '@/types/Action'
import { accessor } from '@/store'
import habilitations from '@/variables/habilitations.json'

const step: StepBuilder<ImportWorkflow> = {
  ...importStep,
  isAllowed(cart) {
    if (!importStep.isAllowed(cart)) {
      return false
    }

    return accessor.session.hasPermission([habilitations.TUNNEL.CTR_IMPORT, habilitations.TUNNEL.TR_IMPORT])
  },
  useStep(cart, workflow, record, useAction) {
    const useStep = importStep.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const i18n = useI18n()
        const commonWorkflow = useCommonStep(cart, workflow, record, useAction)
        commonWorkflow.reimportAllowed.value = false
        const dataStoreValueOfTitle = cart.meta.dataStore[DataStoreKey.ValueOfTitle]
        const dataStoreDeliveries = cart.meta.dataStore[DataStoreKey.DeliveryPoint]
        const productCodes = [ProductCode.CARTE_TICKET_RESTAURANT, ProductCode.TICKET_RESTAURANT]

        const fieldResponse = useSwrv<FieldResponse>(`carts/import/rules/${cart.meta.productCode}`, async () => {
          const deliveryPoints = productCodes.reduce<LocalDeliveryPoint[]>((r, productCode) => {
            const deliveryPoint = dataStoreDeliveries && dataStoreDeliveries[productCode]

            if (deliveryPoint) {
              r.push(deliveryPoint)
            }
            return r
          }, [])

          const hasDeliveryPoint = !deliveryPoints.find((deliveryPoint) => deliveryPoint?.isMulti)
          const deliverToBeneficiary = !!deliveryPoints.find(
            (deliveryPoint) => deliveryPoint.type === DeliveryPointType.PL_BENEF,
          )

          const fields = await apiV4.fileUpload.fetchFields(cart.meta.productCode, {
            hasDeliveryPoint,
            hasFacialValue: dataStoreValueOfTitle ? !dataStoreValueOfTitle.isMulti : false,
            deliverToBeneficiary,
            isMixed: true,
          })

          if (fields.isErr) {
            throw fields.error
          }

          return {
            productCode: cart.meta.productCode,
            type: deliverToBeneficiary ? DeliveryPointType.PL_BENEF : DeliveryPointType.PL_SITE,
            fields: mapRulesToLocal(cart.meta.productCode, fields.value, record.importFields),
          }
        })

        return {
          ...commonWorkflow,
          pricings: useMixedPricingSummaries(record.linkedCarts),
          fieldResponse,
          options: [
            i18n
              .t('cart.import.settingsAction.amount', {
                amount: dataStoreValueOfTitle?.isMulti
                  ? i18n.t('cart.modules.settings.valueOfTitle.isMultiValueOfTitle').toString()
                  : `${toPrice(dataStoreValueOfTitle?.value ?? 0)} - ${
                      (dataStoreValueOfTitle?.employersContribution ?? 0) / 100
                    }%`,
              })
              .toString(),
            i18n.t('cart.import.settingsAction.deliveryPoint2').toString(),
          ],
          settingActions: [
            useAction({
              id: options.config.id,
              type: ActionType.Drawer,
              refresh: false,
              name: i18n
                .t('cart.import.settingsAction.amount', {
                  amount:
                    dataStoreValueOfTitle === null
                      ? i18n.t('toDefine').toString()
                      : dataStoreValueOfTitle.isMulti
                      ? i18n.t('cart.modules.settings.valueOfTitle.isMultiValueOfTitle').toString()
                      : `${toPrice(dataStoreValueOfTitle.value ?? 0)} - ${
                          (dataStoreValueOfTitle.employersContribution ?? 0) / 100
                        }%`,
                })
                .toString(),
              payload: {
                showDelivery: false,
                showValueOfTitle: true,
                for: 'import',
              },
            }),
            useAction({
              id: options.config.id,
              type: ActionType.Drawer,
              refresh: false,
              name: productCodes.find(
                (productCode) =>
                  dataStoreDeliveries === null || (dataStoreDeliveries && dataStoreDeliveries[productCode] == null),
              )
                ? i18n
                    .t('cart.import.settingsAction.deliveryPoint', {
                      deliveryPointType: i18n.t('toDefine').toString(),
                    })
                    .toString()
                : i18n.t('cart.import.settingsAction.deliveryPoint2').toString(),
              payload: {
                showDelivery: true,
                showValueOfTitle: false,
                for: 'import',
              },
            }),
          ],
        }
      },
    }
  },
}

export default step
