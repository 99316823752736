import { Univers } from '@/assets/constantes/Univers'

export default {
  universe: {
    discover: 'Découvrir la gamme',
    [Univers.TR]: {
      title: 'Commande Ticket Restaurant®',
    },
    [Univers.KS]: {
      title: 'Commande Kadeos',
    },
    [Univers.TS]: {
      title: 'Commande Ticket Service',
    },

    errors: {
      impossibleOrder: {
        title: 'Commande impossible',
        description:
          'Vous pouvez contacter le service client via le formulaire de contact.<br/>Vous trouverez ci-dessous le ou les codes erreurs à communiquer&nbsp;:<br/>{errors}',
      },
    },
  },
}
