import _findIndex from 'lodash/findIndex'
import Vue from 'vue'
import { mutations as deliveryPointsMutations } from './deliveryPoints'
import { mutations as productMutations } from './product'
import { mutations as vfMutations } from './vf'

function createNewBenefDatas(state, obj, tab) {
  const arrayBenef = obj.ids
  const upd = obj.upd
  // crea d'un tableau des benef
  const newBenef = arrayBenef.map((idBenef) => tab.find((benef) => benef.Id === idBenef))
  // modification des datas
  const updKeys = Object.keys(upd)
  newBenef.forEach((benef) => {
    updKeys.forEach((modif) => {
      benef[modif] = upd[modif]
    })
  })
  return newBenef
}

function sub(a, b) {
  return a - b >= 0 ? a - b : 0
}

function haveAdresse(benef) {
  return (
    benef.Address &&
    benef.Address.Address1 &&
    benef.Address.Address1 !== '' &&
    benef.Address.City &&
    benef.Address.City !== '' &&
    benef.Address.ZipCode &&
    benef.Address.ZipCode !== ''
  )
}

export default {
  ...deliveryPointsMutations,

  ...vfMutations,

  ...productMutations,

  SET_VALIDATION_IMPORT(state) {
    Vue.set(state, 'validationImport', true)
  },
  SET_ALL_EVENTS(state, events) {
    Vue.set(state, 'allEvents', events)
  },
  SET_IS_POPIN_CHOIX_TUNNEL_VISIBLE(state, bool) {
    state.popinChoixTunnelVisible = bool
  },
  SET_USER_ALERT(state, payload) {
    state.Users.alert = payload
  },
  SET_USER_RESTRICTIONS(state, payload) {
    state.Users.restrictions = payload
  },
  SET_USER_ROLES(state, payload) {
    state.Users.listRoles = payload
  },
  SET_USERS_TOTAL(state, payload) {
    state.Users.usersTotal = payload
  },
  SET_USER_ROLE_FILTERED(state, payload) {
    state.Users.userRoleFiltered = payload
  },
  RESET_BENEFICIARY(state) {
    const BeneficiariesTotal = 0
    Vue.set(state, 'BeneficiariesTotal', BeneficiariesTotal)

    const Beneficiaries = {
      BeneficiariesOutOfOrder: [],
      BeneficiariesOutOfOrderLength: 0,
      BeneficiariesInOrder: [],
      BeneficiariesInOrderLength: 0,
      BeneficiariesWithoutAddresses: [],
      BeneficiariesWithoutAddressesLength: 0,
      BeneficiariesNewLength: 0,
      /* BeneficiariesUpdatedLength: 0, */
      BeneficiariesError: [],
      BeneficiariesErrorLength: [],
      BeneficiariesDuplicated: {},
      BeneficiariesDuplicatedLength: 0,
      BeneficiariesTotal: 0,
      BeneficiariesDuplicatedRemaining: null,
      SearchInput: null,
      ItemByPage: null,
    }
    Vue.set(state, 'Beneficiaries', Beneficiaries)
  },
  SET_CODE_ARTICLE(state, articleCode) {
    Vue.set(state, 'ArticleCode', articleCode)
  },
  SET_UNIVERS(state, univers) {
    Vue.set(state, 'Univers', univers)
  },
  SET_ORDER(state, order) {
    // const myOrder = Object.assign(order, state.Order)
    Vue.set(state, 'Order', order)
  },
  SET_ORDER_REFERENCE(state, orderReference) {
    Vue.set(state.Order, 'Reference', orderReference)
  },
  SET_EARLIEST_DELIVERY_DATES(state, earliestDeliveryDates) {
    Vue.set(state.Customer, 'EarliestDeliveryDates', earliestDeliveryDates)
  },
  SET_DELIVERY_DATE(state, deliveryDate) {
    Vue.set(state.Order, 'DeliveryDate', deliveryDate)
  },
  SET_DISCOUNT_CODE(state, discountCode) {
    Vue.set(state.Order, 'DiscountCode', discountCode)
  },
  SET_ORDER_ID(state, OrderId) {
    Vue.set(state.Order, 'Id', OrderId)
  },
  UPDATE_PRICING(state, { pricing }) {
    Vue.set(state.Order, 'Pricing', pricing)
  },
  ADD_ITEM(state, { item }) {
    const items = [...(state.Order.Items || []), item]
    Vue.set(state.Order, 'Items', items)
  },
  UPDATE_ITEM(state, { item }) {
    const idx = _findIndex(state.Order.Items, (existingItem) => existingItem.Id === item.Id)
    Vue.set(state.Order.Items, idx, item)
  },
  DELETE_ITEM(state, { itemId }) {
    const idx = _findIndex(state.Order.Items, (item) => item.Id === itemId)
    const items = [...state.Order.Items.slice(0, idx), ...state.Order.Items.slice(idx + 1)]
    Vue.set(state.Order, 'Items', items)
  },
  SET_ACCOUNTING_DOCUMENTS(state, facialValues) {
    Vue.set(state.Customer, 'AccountingDocuments', facialValues)
  },
  SET_ORDERS(state, orders) {
    // aussi utilisé par home
    Vue.set(state.Customer, 'OrdersHistory', orders)
  },
  SET_PAYMENT_MEANS(state, { paymentMeans }) {
    Vue.set(state.Customer, 'PaymentMeans', paymentMeans)
  },
  SET_PAYMENT_MEANS_ADMIN(state, paymentMeans) {
    Vue.set(state.Customer, 'PaymentMeans', paymentMeans)
  },
  SET_TERMS_OF_SALE(state, termsOfSale) {
    Vue.set(state.Customer, 'TermsOfSale', termsOfSale)
  },
  SET_PAYMENT_DATA(state, formData) {
    Vue.set(state.Customer, 'FormData', formData)
  },
  SET_FACTURATION_POINTS(state, facturationPoints) {
    Vue.set(state.Customer, 'FacturationPoints', facturationPoints)
  },
  SET_IMPORTED_FIELDS_DESCRIPTION(state, fieldsDescription) {
    Vue.set(state.Import, 'ImportedFieldsDescription', fieldsDescription)
  },
  SET_IMPORTED_ID(state, importId) {
    Vue.set(state.Import, 'Id', importId)
  },
  SET_IMPORT_REQUEST_ID(state, requestId) {
    Vue.set(state.Import, 'RequestId', requestId)
  },
  SET_IMPORT_SUCCESS(state, payload) {
    Vue.set(state.Import, 'Success', payload)
  },
  SET_SELECTED_EVENT(state, event) {
    Vue.set(state.Customer, 'SelectedEvent', event)
  },
  SET_SELECTED_AMOUNT(state, amount) {
    Vue.set(state.Customer, 'SelectedAmount', amount)
  },
  SET_BENEF_OUT_OF_ORDER(state, benefList) {
    Vue.set(state.Beneficiaries, 'BeneficiariesOutOfOrder', benefList)
  },
  SET_BENEF_IN_ORDER(state, benefList) {
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrder', benefList)
  },
  SET_BENEF_WITHOUT_ADDRESSES(state, benefList) {
    Vue.set(state.Beneficiaries, 'BeneficiariesWithoutAddresses', benefList)
  },
  SET_BENEFICIARIES_NEW_LENGTH(state, BeneficiariesNewLength) {
    Vue.set(state.Beneficiaries, 'BeneficiariesNewLength', parseInt(BeneficiariesNewLength, 10))
  },
  SET_BENEFICIARIES_UPDATED_LENGTH(state, BeneficiariesUpdatedLength) {
    Vue.set(state.Beneficiaries, 'BeneficiariesUpdatedLength', parseInt(BeneficiariesUpdatedLength, 10))
  },
  SET_BENEFICIARIES_ERROR(state, BeneficiariesError) {
    Vue.set(state.Beneficiaries, 'BeneficiariesError', BeneficiariesError)
  },
  SET_BENEFICIARIES_ERROR_LENGTH(state, BeneficiariesErrorLength) {
    Vue.set(state.Beneficiaries, 'BeneficiariesErrorLength', BeneficiariesErrorLength)
  },
  SET_BENEFICIARIES_DUPLICATED(state, BeneficiariesDuplicated) {
    if (BeneficiariesDuplicated.Id) {
      BeneficiariesDuplicated.FacialValue =
        (BeneficiariesDuplicated.FacialValue / 100).toFixed(2).toString().replace('.', ',') + ' €'
    }
    Vue.set(state.Beneficiaries, 'BeneficiariesDuplicated', BeneficiariesDuplicated)
  },
  SET_BENEFICIARIES_DUPLICATED_LENGTH(state, BeneficiariesDuplicatedLength) {
    Vue.set(state.Beneficiaries, 'BeneficiariesDuplicatedLength', BeneficiariesDuplicatedLength)
    Vue.set(state.Beneficiaries, 'BeneficiariesDuplicatedRemaining', BeneficiariesDuplicatedLength)
  },
  UPDATE_BENEF_IN_ORDER(state, benef) {
    const idx = _findIndex(state.Beneficiaries.BeneficiariesInOrder, (benefInOrder) => benefInOrder.Id === benef.Id)
    Vue.set(state.Beneficiaries.BeneficiariesInOrder, idx, benef)
  },
  TOTAL_BENEF_CONSOLIDATED(state, totalBenef) {
    Vue.set(state, 'BeneficiariesTotal', totalBenef)
  },
  UPDATE_MASS_BENEF_IN_ORDER(state, obj) {
    const tab = state.Beneficiaries.BeneficiariesInOrder
    const newBenef = createNewBenefDatas(state, obj, tab)
    // comiter en store
    newBenef.forEach((ben) => {
      const idx = _findIndex(state.Beneficiaries.BeneficiariesInOrder, (benefInOrder) => benefInOrder.Id === ben.Id)
      Vue.set(state.Beneficiaries.BeneficiariesInOrder, idx, ben)
    })
  },
  UPDATE_MASS_BENEF_OUT_OF_ORDER(state, obj) {
    const tab = state.Beneficiaries.BeneficiariesOutOfOrder
    const newBenef = createNewBenefDatas(state, obj, tab)
    // comiter en store
    newBenef.forEach((ben) => {
      const idx = _findIndex(
        state.Beneficiaries.BeneficiariesOutOfOrder,
        (benefOutOfOrder) => benefOutOfOrder.Id === ben.Id,
      )
      Vue.set(state.Beneficiaries.BeneficiariesOutOfOrder, idx, ben)
    })
  },
  UPDATE_BENEF_OUT_OF_ORDER(state, benef) {
    const idx = _findIndex(
      state.Beneficiaries.BeneficiariesOutOfOrder,
      (benefOutOfOrder) => benefOutOfOrder.Id === benef.Id,
    )
    Vue.set(state.Beneficiaries.BeneficiariesOutOfOrder, idx, benef)
  },
  UPDATE_MASS_BENEF_WITHOUT_ADDRESSES(state, obj) {
    const tab = state.Beneficiaries.BeneficiariesOutOfOrder
    const newBenef = createNewBenefDatas(state, obj, tab)
    // comiter en store
    newBenef.forEach((ben) => {
      const idx = _findIndex(
        state.Beneficiaries.BeneficiariesWithoutAddresses,
        (benefWithoutAddresses) => benefWithoutAddresses.Id === ben.Id,
      )
      Vue.set(state.Beneficiaries.BeneficiariesWithoutAddresses, idx, ben)
    })
  },
  UPDATE_BENEF_WITHOUT_ADDRESSES(state, benef) {
    const idx = _findIndex(
      state.Beneficiaries.BeneficiariesWithoutAddresses,
      (benefWithoutAddresses) => benefWithoutAddresses.Id === benef.Id,
    )
    Vue.set(state.Beneficiaries.BeneficiariesWithoutAddresses, idx, benef)
  },
  UPDATE_BENEF_DATAS_IN_ORDER(state, benef) {
    const idx = _findIndex(
      state.Beneficiaries.BeneficiariesOutOfOrder,
      (benefOutOfOrder) => benefOutOfOrder.Id === benef.Id,
    )
    Vue.set(state.Beneficiaries.BeneficiariesOutOfOrder, idx, benef)
  },
  ADD_BENEF_OUT_OF_ORDER(state, benef) {
    const idx = _findIndex(state.Beneficiaries.BeneficiariesInOrder, (benefInOrder) => benefInOrder.Id === benef.Id)
    const benefInOrder = [
      ...state.Beneficiaries.BeneficiariesInOrder.slice(0, idx),
      ...state.Beneficiaries.BeneficiariesInOrder.slice(idx + 1),
    ]
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrder', benefInOrder)
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrderLength', sub(state.Beneficiaries.BeneficiariesInOrderLength, 1))

    if (!haveAdresse(benef)) {
      // n'a pas d'adresse
      const benefWithoutAdresses = [...(state.Beneficiaries.BeneficiariesWithoutAddresses || []), benef]
      Vue.set(state.Beneficiaries, 'BeneficiariesWithoutAddresses', benefWithoutAdresses)
      Vue.set(
        state.Beneficiaries,
        'BeneficiariesWithoutAddressesLength',
        state.Beneficiaries.BeneficiariesWithoutAddressesLength + 1,
      )
      Vue.set(state, 'effectTabs', 'NoAddress')
    } else if (benef.Status !== 2) {
      // ne sont pas nouveau
      const benefOutOfOrder = [...(state.Beneficiaries.BeneficiariesOutOfOrder || []), benef]
      Vue.set(state.Beneficiaries, 'BeneficiariesOutOfOrder', benefOutOfOrder)
      Vue.set(
        state.Beneficiaries,
        'BeneficiariesOutOfOrderLength',
        state.Beneficiaries.BeneficiariesOutOfOrderLength + 1,
      )
      Vue.set(state, 'effectTabs', 'OutOrder')
    }
  },
  ADD_MASS_BENEF_OUT_OF_ORDER(state, arrayId) {
    let outOfOrderLength = 0
    let withoutAdressesLength = 0
    for (const oneId of arrayId) {
      const benef = state.Beneficiaries.BeneficiariesInOrder.find((benInOrder) => benInOrder.Id === oneId)
      const idx = _findIndex(state.Beneficiaries.BeneficiariesInOrder, (benefInOrder) => benefInOrder.Id === oneId)
      const benefInOrder = [
        ...state.Beneficiaries.BeneficiariesInOrder.slice(0, idx),
        ...state.Beneficiaries.BeneficiariesInOrder.slice(idx + 1),
      ]
      Vue.set(state.Beneficiaries, 'BeneficiariesInOrder', benefInOrder)
      if (haveAdresse(benef)) {
        outOfOrderLength++
        const benefOutOfOrder = [...(state.Beneficiaries.BeneficiariesOutOfOrder || []), benef]
        Vue.set(state.Beneficiaries, 'BeneficiariesOutOfOrder', benefOutOfOrder)
      } else {
        withoutAdressesLength++
        const benefWithoutAdresses = [...(state.Beneficiaries.BeneficiariesWithoutAddresses || []), benef]
        Vue.set(state.Beneficiaries, 'BeneficiariesWithoutAddresses', benefWithoutAdresses)
      }
    }
    if (outOfOrderLength > 0) {
      Vue.set(
        state.Beneficiaries,
        'BeneficiariesOutOfOrderLength',
        state.Beneficiaries.BeneficiariesOutOfOrderLength + outOfOrderLength,
      )
      Vue.set(state, 'effectTabs', 'OutOrder')
    }
    if (withoutAdressesLength > 0) {
      Vue.set(
        state.Beneficiaries,
        'BeneficiariesWithoutAddressesLength',
        state.Beneficiaries.BeneficiariesWithoutAddressesLength + withoutAdressesLength,
      )
      Vue.set(state, 'effectTabs', 'NoAddress')
    }
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesInOrderLength',
      sub(state.Beneficiaries.BeneficiariesInOrderLength, arrayId.length),
    )
  },
  ADD_ALL_BENEF_OUT_OF_ORDER(state, arrayId) {
    for (const oneId of arrayId) {
      const benef = state.Beneficiaries.BeneficiariesInOrder.find((benInOrder) => benInOrder.Id === oneId)
      const idx = _findIndex(state.Beneficiaries.BeneficiariesInOrder, (benefInOrder) => benefInOrder.Id === oneId)
      const benefInOrder = [
        ...state.Beneficiaries.BeneficiariesInOrder.slice(0, idx),
        ...state.Beneficiaries.BeneficiariesInOrder.slice(idx + 1),
      ]
      Vue.set(state.Beneficiaries, 'BeneficiariesInOrder', benefInOrder)
      const benefOutOfOrder = [...(state.Beneficiaries.BeneficiariesOutOfOrder || []), benef]
      Vue.set(state.Beneficiaries, 'BeneficiariesOutOfOrder', benefOutOfOrder)
    }
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesOutOfOrderLength',
      state.Beneficiaries.BeneficiariesOutOfOrderLength + state.Beneficiaries.BeneficiariesInOrderLength,
    )
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrderLength', 0)
  },
  ADD_BENEF_IN_ORDER(state, benef) {
    const idx = _findIndex(
      state.Beneficiaries.BeneficiariesOutOfOrder,
      (benefOutOfOrder) => benefOutOfOrder.Id === benef.Id,
    )
    const benefOutOfOrder = [
      ...state.Beneficiaries.BeneficiariesOutOfOrder.slice(0, idx),
      ...state.Beneficiaries.BeneficiariesOutOfOrder.slice(idx + 1),
    ]
    Vue.set(state.Beneficiaries, 'BeneficiariesOutOfOrder', benefOutOfOrder)
    const benefInOrder = [...(state.Beneficiaries.BeneficiariesInOrder || []), benef]
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrder', benefInOrder)
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesOutOfOrderLength',
      sub(state.Beneficiaries.BeneficiariesOutOfOrderLength, 1),
    )
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrderLength', state.Beneficiaries.BeneficiariesInOrderLength + 1)
  },
  ADD_BENEF_IN_ORDER_FROM_NO_ADDRESSES(state, benef) {
    const idx = _findIndex(
      state.Beneficiaries.BeneficiariesWithoutAddresses,
      (benefOutOfOrder) => benefOutOfOrder.Id === benef.Id,
    )
    const benefOutOfOrder = [
      ...state.Beneficiaries.BeneficiariesWithoutAddresses.slice(0, idx),
      ...state.Beneficiaries.BeneficiariesWithoutAddresses.slice(idx + 1),
    ]
    Vue.set(state.Beneficiaries, 'BeneficiariesWithoutAddresses', benefOutOfOrder)
    const benefInOrder = [...(state.Beneficiaries.BeneficiariesInOrder || []), benef]
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrder', benefInOrder)
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesWithoutAddressesLength',
      sub(state.Beneficiaries.BeneficiariesWithoutAddressesLength, 1),
    )
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrderLength', state.Beneficiaries.BeneficiariesInOrderLength + 1)
  },
  ADD_NEW_BENEF_IN_ORDER(state, benef) {
    const benefInOrder = [...(state.Beneficiaries.BeneficiariesInOrder || []), benef]
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrder', benefInOrder)
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrderLength', state.Beneficiaries.BeneficiariesInOrderLength + 1)
  },
  ADD_MASS_BENEF_IN_ORDER_FROM_NO_ADDRESSES(state, newBenef) {
    // commit dans le store
    for (const benef of newBenef) {
      const benefs = state.Beneficiaries.BeneficiariesWithoutAddresses.find(
        (benOutOfOrder) => benOutOfOrder.Id === benef.Id,
      )
      const idx = _findIndex(
        state.Beneficiaries.BeneficiariesWithoutAddresses,
        (benefOutOfOrder) => benefOutOfOrder.Id === benef.Id,
      )
      const benefWithoutAdresses = [
        ...state.Beneficiaries.BeneficiariesWithoutAddresses.slice(0, idx),
        ...state.Beneficiaries.BeneficiariesWithoutAddresses.slice(idx + 1),
      ]
      Vue.set(state.Beneficiaries, 'BeneficiariesWithoutAddresses', benefWithoutAdresses)
      const benefInOrder = [...(state.Beneficiaries.BeneficiariesInOrder || []), benefs]
      Vue.set(state.Beneficiaries, 'BeneficiariesInOrder', benefInOrder)
    }
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesWithoutAddressesLength',
      sub(state.Beneficiaries.BeneficiariesWithoutAddressesLength, newBenef.length),
    )
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesInOrderLength',
      state.Beneficiaries.BeneficiariesInOrderLength + newBenef.length,
    )
  },
  ADD_MASS_BENEF_IN_ORDER(state, newBenef) {
    // commit dans le store
    for (const benef of newBenef) {
      const benefs = state.Beneficiaries.BeneficiariesOutOfOrder.find((benOutOfOrder) => benOutOfOrder.Id === benef.Id)
      const idx = _findIndex(
        state.Beneficiaries.BeneficiariesOutOfOrder,
        (benefOutOfOrder) => benefOutOfOrder.Id === benef.Id,
      )
      const benefOutOfOrder = [
        ...state.Beneficiaries.BeneficiariesOutOfOrder.slice(0, idx),
        ...state.Beneficiaries.BeneficiariesOutOfOrder.slice(idx + 1),
      ]
      Vue.set(state.Beneficiaries, 'BeneficiariesOutOfOrder', benefOutOfOrder)
      const benefInOrder = [...(state.Beneficiaries.BeneficiariesInOrder || []), benefs]
      Vue.set(state.Beneficiaries, 'BeneficiariesInOrder', benefInOrder)
    }
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesOutOfOrderLength',
      sub(state.Beneficiaries.BeneficiariesOutOfOrderLength, newBenef.length),
    )
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesInOrderLength',
      state.Beneficiaries.BeneficiariesInOrderLength + newBenef.length,
    )
  },
  ADD_ALL_BENEF_IN_ORDER(state, obj) {
    const tab = state.Beneficiaries.BeneficiariesOutOfOrder
    const newBenef = createNewBenefDatas(state, obj, tab)
    // commit dans le store
    for (const benef of newBenef) {
      const benefs = state.Beneficiaries.BeneficiariesOutOfOrder.find((benOutOfOrder) => benOutOfOrder.Id === benef.Id)
      const idx = _findIndex(
        state.Beneficiaries.BeneficiariesOutOfOrder,
        (benefOutOfOrder) => benefOutOfOrder.Id === benef.Id,
      )
      const benefOutOfOrder = [
        ...state.Beneficiaries.BeneficiariesOutOfOrder.slice(0, idx),
        ...state.Beneficiaries.BeneficiariesOutOfOrder.slice(idx + 1),
      ]
      Vue.set(state.Beneficiaries, 'BeneficiariesOutOfOrder', benefOutOfOrder)
      const benefInOrder = [...(state.Beneficiaries.BeneficiariesInOrder || []), benefs]
      Vue.set(state.Beneficiaries, 'BeneficiariesInOrder', benefInOrder)
    }
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesInOrderLength',
      state.Beneficiaries.BeneficiariesInOrderLength + state.Beneficiaries.BeneficiariesOutOfOrderLength,
    )
    Vue.set(state.Beneficiaries, 'BeneficiariesOutOfOrderLength', 0)
  },
  ADD_BENEFICARIES_NEW(state) {
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesDuplicatedRemaining',
      sub(state.Beneficiaries.BeneficiariesDuplicatedRemaining, 1),
    )
    Vue.set(state.Beneficiaries, 'BeneficiariesNewLength', state.Beneficiaries.BeneficiariesNewLength + 1)
  },
  ADD_BENEFICARIES_UPDATED(state) {
    /* state.Beneficiaries.BeneficiariesUpdated = [...state.Beneficiaries.BeneficiariesUpdated, benef] */
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesDuplicatedRemaining',
      sub(state.Beneficiaries.BeneficiariesDuplicatedRemaining, 1),
    )
    /* Vue.set(state.Beneficiaries, 'BeneficiariesUpdatedLength', state.Beneficiaries.BeneficiariesUpdatedLength + 1) */
  },
  DELETE_BENEFOUTOFORDER_OUT_OF_STORE(state, beneficiaryId) {
    const del = _findIndex(state.Beneficiaries.BeneficiariesOutOfOrder, (item) => item.Id === beneficiaryId)
    const items = [
      ...state.Beneficiaries.BeneficiariesOutOfOrder.slice(0, del),
      ...state.Beneficiaries.BeneficiariesOutOfOrder.slice(del + 1),
    ]
    Vue.set(state.Beneficiaries, 'BeneficiariesOutOfOrder', items)
    Vue.set(state.Beneficiaries, 'BeneficiariesOutOfOrderLength', items.length)
  },
  DELETE_BENEFINORDER_OUT_OF_STORE(state, beneficiaryId) {
    const del = _findIndex(state.Beneficiaries.BeneficiariesInOrder, (item) => item.Id === beneficiaryId)
    const items = [
      ...state.Beneficiaries.BeneficiariesInOrder.slice(0, del),
      ...state.Beneficiaries.BeneficiariesInOrder.slice(del + 1),
    ]
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrder', items)
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrderLength', items.length)
  },
  DELETE_BENEFWITHOUTADDRESS_OUT_OF_STORE(state, idsBenef) {
    const del = _findIndex(state.Beneficiaries.BeneficiariesWithoutAddresses, (item) => item.Id === idsBenef)
    const items = [
      ...state.Beneficiaries.BeneficiariesWithoutAddresses.slice(0, del),
      ...state.Beneficiaries.BeneficiariesWithoutAddresses.slice(del + 1),
    ]
    Vue.set(state.Beneficiaries, 'BeneficiariesWithoutAddresses', items)
    Vue.set(state.Beneficiaries, 'BeneficiariesWithoutAddressesLength', items.length)
  },
  DELETE_MASS_BENEF_WITHOUTADDRESS_OUT_OF_STORE(state, idsBenef) {
    for (const idBenef of idsBenef) {
      const idx = _findIndex(
        state.Beneficiaries.BeneficiariesWithoutAddresses,
        (benefWithoutAdresse) => benefWithoutAdresse.Id === idBenef,
      )
      const benefWithoutAdresses = [
        ...state.Beneficiaries.BeneficiariesWithoutAddresses.slice(0, idx),
        ...state.Beneficiaries.BeneficiariesWithoutAddresses.slice(idx + 1),
      ]
      Vue.set(state.Beneficiaries, 'BeneficiariesWithoutAddresses', benefWithoutAdresses)
    }
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesWithoutAddressesLength',
      sub(state.Beneficiaries.BeneficiariesWithoutAddressesLength, idsBenef.length),
    )
  },
  DELETE_MASS_BENEF_OUTOFORDER_OUT_OF_STORE(state, idsBenef) {
    for (const idBenef of idsBenef) {
      const idx = _findIndex(
        state.Beneficiaries.BeneficiariesOutOfOrder,
        (benefOutOfOrder) => benefOutOfOrder.Id === idBenef,
      )
      const benefOutOfOrder = [
        ...state.Beneficiaries.BeneficiariesOutOfOrder.slice(0, idx),
        ...state.Beneficiaries.BeneficiariesOutOfOrder.slice(idx + 1),
      ]
      Vue.set(state.Beneficiaries, 'BeneficiariesOutOfOrder', benefOutOfOrder)
    }
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesOutOfOrderLength',
      sub(state.Beneficiaries.BeneficiariesOutOfOrderLength, idsBenef.length),
    )
  },
  DELETE_MASS_BENEF_INORDER_OUT_OF_STORE(state, idsBenef) {
    for (const idBenef of idsBenef) {
      const idx = _findIndex(state.Beneficiaries.BeneficiariesInOrder, (benefInOrder) => benefInOrder.Id === idBenef)
      const benefInOrder = [
        ...state.Beneficiaries.BeneficiariesInOrder.slice(0, idx),
        ...state.Beneficiaries.BeneficiariesInOrder.slice(idx + 1),
      ]
      Vue.set(state.Beneficiaries, 'BeneficiariesInOrder', benefInOrder)
    }
    Vue.set(
      state.Beneficiaries,
      'BeneficiariesInOrderLength',
      sub(state.Beneficiaries.BeneficiariesInOrder, idsBenef.length),
    )
  },
  DELETE_IMPORT_SUCCESS(state, value) {
    Vue.set(state.Import, 'Success', value)
  },
  DELETE_IMPORT_REQUEST_ID(state, value) {
    Vue.set(state.Import, 'RequestId', value)
  },
  SET_IS_IMPORT(state, isImport) {
    Vue.set(state.Customer, 'IsImport', isImport)
  },
  SET_IS_SAISIE_WEB(state, isSaisieWeb) {
    Vue.set(state.Customer, 'IsSaisieWeb', isSaisieWeb)
  },
  UPDATE_SEARCH_BENEF_INPUT(state, value) {
    Vue.set(state.Beneficiaries, 'SearchInput', value)
  },
  UPDATE_ITEM_BY_PAGE(state, value) {
    Vue.set(state.Beneficiaries, 'ItemByPage', value)
  },
  TOTAL_BENEF(state, benefTotal) {
    Vue.set(state.Beneficiaries, 'BeneficiariesTotal', benefTotal)
  },
  SET_BENEF_OUT_OF_ORDER_LENGTH(state, nbrBenef) {
    Vue.set(state.Beneficiaries, 'BeneficiariesOutOfOrderLength', nbrBenef)
  },
  SET_BENEF_IN_ORDER_LENGTH(state, nbrBenef) {
    Vue.set(state.Beneficiaries, 'BeneficiariesInOrderLength', nbrBenef)
  },
  SET_BENEF_WITHOUT_ADDRESSES_LENGTH(state, nbrBenef) {
    Vue.set(state.Beneficiaries, 'BeneficiariesWithoutAddressesLength', nbrBenef)
  },
  UPDATE_TABS(state, value) {
    Vue.set(state, 'effectTabs', value)
  },
  SET_FORMAT(state, format) {
    Vue.set(state, 'Format', format)
  },
  CLEAR_ORDER(state) {
    state.Order = {}
  },
  SET_TO_NOMINATIVE(state) {
    Vue.set(state.Customer, 'IsNominative', true)
    Vue.set(state.Order, 'IsNominative', true)
  },
  SET_TO_ANONYMOUS(state) {
    Vue.set(state.Customer, 'IsNominative', false)
    Vue.set(state.Order, 'IsNominative', false)
  },
  SET_TOTAL_CHECKS(state, carnetTotal) {
    Vue.set(state.Order, 'TotalChecks', carnetTotal)
  },
  SET_TOTAL_BOOKLETS(state, ticketTotal) {
    Vue.set(state.Order, 'TotalBooklets', ticketTotal)
  },
  SET_TOTAL_CARDS(state, cardTotal) {
    Vue.set(state.Order, 'TotalCards', cardTotal)
  },
  SET_SELECTED_INORDER(state, order) {
    Vue.set(state.Beneficiaries, 'setSelectedInOrder', order)
  },
  SET_SELECTED_OUTORDER(state, order) {
    Vue.set(state.Beneficiaries, 'setSelectedOutOrder', order)
  },
  SHOW_ALERT(state, { message, cssClass = 'success' }) {
    Vue.set(state.Alert, 'message', message)
    Vue.set(state.Alert, 'css', cssClass)
    Vue.set(state.Alert, 'show', true)
  },
  HIDE_ALERT(state) {
    Vue.set(state.Alert, 'show', false)
  },
  SET_INVOICES(state, invoices) {
    Vue.set(state, 'Invoices', invoices)
  },
  SET_CGV_VERSION(state, version) {
    Vue.set(state.Order, 'versionCgv', version)
  },
  SET_PROFIL_EDITION_INFOS(state, profileGeneralInfos) {
    Vue.set(state.ProfilEditionModel, 'ProfileGeneralInfos', profileGeneralInfos)
  },
  SET_PROFIL_EDITION_PASSWORD(state, profilePassword) {
    Vue.set(state.ProfilEditionModel, 'ProfilePassword', profilePassword)
  },
  UPDATE_CURRENT_CONNECTED_PROFILE(state, account) {
    Vue.set(state, 'Rig', account)
  },
  ATTRIBUTION_ADD_ITEM_TO_BASKET(state, obj) {
    const items = state.Attribution.Basket
    items.push(obj)
    Vue.set(state.Attribution, 'Basket', items)
  },
  ATTRIBUTION_DELETE_ITEM_TO_BASKET(state, carte) {
    const del = _findIndex(state.Attribution.Basket, (item) => item.ReferenceCard === carte)
    state.Attribution.Basket.splice(del, 1)
  },
  ATTRIBUTION_RESET_BASKET(state) {
    Vue.set(state.Attribution, 'Basket', [])
  },
  ATTRIBUTION_SET_RESPONSE_IDS(state, ids) {
    Vue.set(state.Attribution, 'BeneficiariesIds', ids)
  },
  SET_BANK_ERRORS(state, payload) {
    Vue.set(state, 'bankErrors', payload)
  },
  SET_CUSTOM_ALERTS_MESSAGES(state, payload) {
    Vue.set(state, 'customAlertsMessages', payload)
  },
  SET_FILTERED_CUSTOM_ALERTS_MESSAGES(state, payload) {
    Vue.set(state, 'filteredCustomAlertsMessages', payload)
  },
  SET_PARTNER_ID(state, payload) {
    Vue.set(state, 'partnerId', payload)
  },
  SET_FAQ_CATEGORIES(state, payload) {
    Vue.set(state.Faq, 'Categories', payload)
  },
  SET_DOUBLONS_ERROR(state, payload) {
    Vue.set(state, 'errors', { import: payload })
  },
  UNSET_DOUBLONS_ERROR(state) {
    Vue.set(state, 'errors', { import: null })
  },
  SET_ORDER_REFERENCE_SUCCESS(state, payload) {
    Vue.set(state.Reference, 'orderReferenceSuccess', payload)
  },
  SET_ORDER_REFERENCE_FAIL_MSG(state, payload) {
    Vue.set(state.Reference, 'orderReferenceFailMessage', payload)
  },
  SET_AWAIT_SPINNER(state, payload) {
    Vue.set(state.Reference, 'awaitSpinner', payload)
  },
  SET_AWAIT_PATCH_ORDER(state, payload) {
    Vue.set(state.Reference, 'isWaitingPatchOrder', payload)
  },
  SET_HAS_PENDING_CALL(state, bool) {
    Vue.set(state, 'HasPendingCall', bool)
  },
}
