export function setInLocalStorage<T>(key: string, value: T | null) {
  if (value !== null) {
    localStorage.setItem(key, JSON.stringify(value))
  } else {
    localStorage.removeItem(key)
  }
}

export function getInLocalStorage<T>(key: string): T | null {
  const value = localStorage.getItem(key)

  if (value != null) {
    return JSON.parse(value) as T
  }
  return null
}
