import type { CartFileUploadStatus } from '../fileUpload/types'
import type { PaymentMean } from './types'
import type { ArticleCode } from '@/variables/ArticleCode'
import type { ProductCode } from '@/variables/ProductCode'
import type { Comma } from '@/utils/csv'

export enum CartType {
  Unknown = 'Unknown',
  Regular = 'Regular',
  Renewal = 'Renewal',
  BeneficiaryPreorder = 'BeneficiaryPreorder',
  CustomerPreorder = 'CustomerPreorder',
}

export enum CartStatus {
  Opened = 'Opened',
  Closed = 'Closed',
  Cancelled = 'Cancelled',
}

export enum CartOrigin {
  None = 'None',
  EmailAuto = 'EmailAuto',
  EmailManuel = 'EmailManuel',
  Ftp = 'Ftp',
  EC = 'EC',
  VEL = 'VEL',
  IntravelFax = 'IntravelFax',
  IntravelCourrier = 'IntravelCourrier',
  IntravelEmail = 'IntravelEmail',
  EspaceClientBenef = 'EspaceClientBenef',
}

export interface CartUser {
  id: number
  email: string | null
}

export interface CartDescription {
  productCode: ProductCode
  articleCode: ArticleCode | null
  orderReference: string | null
  isXSell: boolean
}

export interface CartPaymentOptions {
  paymentMean: PaymentMean | null
  discountCode: string | null
  deliveryDate: string | null
}

export interface CartAudit {
  creationDate: string
  creatorEmail: string | null
}

export interface CartOptions {
  isNominative: boolean
  isMixed: boolean
  isMultiLoading: boolean
  isPlasticless: boolean
  isHomeDelivery: boolean | null
}

export enum IntegrationStatus {
  Unknown = 'Unknown',
  New = 'New',
  Importing = 'Importing',
  Suspended = 'Suspended',
  Cancelled = 'Cancelled',
  Finished = 'Finished',
}

export enum CartImportStep {
  UploadingFile = 'UploadingFile',
  WaitingIntegrationStart = 'WaitingIntegrationStart',
  FileIntegration = 'FileIntegration',
  FileVerifications = 'FileVerifications',
  BeneficiaryArbitral = 'BeneficiaryArbitral',
  WaitingUserArbitralValidation = 'WaitingUserArbitralValidation',
  BeneficiariesIntegration = 'BeneficiariesIntegration',
  MixedOrderSplit = 'MixedOrderSplit',
  PricingGeneration = 'PricingGeneration',
  WaitingUserConfirmation = 'WaitingUserConfirmation',
  End = 'End',
}

export interface Cart {
  id: number
  type: CartType
  status: CartStatus
  origin: CartOrigin
  divisionCode: number
  redcartId: number | null
  description: CartDescription
  paymentOptions: CartPaymentOptions
  audit: CartAudit
  options: CartOptions
  imports: CartImport[]
  linkedCarts: Cart[]
}

export interface CartImport extends CartFileUploadStatus {
  id: number
  cartId: number
  fileId: string | null
  processId: number | null
  status: IntegrationStatus
  dateCreated: string
  dateUpdated: string | null
  preorderRedCartId: number | null
  processGroupId: number | null
  childCartImports: CartImport[] | null
}

export interface PostImportRequestBody {
  fileUpload: File
  facialValue?: number
  employersContribution?: number
  deliveryPointReference?: string
  eventCode?: number
  deliveryPointReferenceTr?: string
  deliveryPointReferenceCtr?: string
  isHomeDeliveryTr?: boolean
  isHomeDeliveryCtr?: boolean
  separator: Comma
}
