import { defineStore } from 'pinia'
import { Result } from '@badrap/result'
import { ref } from 'vue'
import type { BenefitsCalculatorItem } from './types'
import stepDaysWorked from './steps/daysWorked'
import stepLeaveDays from './steps/leaveDays'
import stepCompanyMeals from './steps/companyMeals'
import stepExpenseReports from './steps/expenseReports'
import stepResults from './steps/results'
import contentful from '@/services/contentful'
import type { CFBenefitsCalculator } from '@/services/contentful/types/CFBenefitsCalculator'

export const useBenefitsCalculatorStore = defineStore('benefits-calculator', () => {
  const benefitsCalculator = ref<CFBenefitsCalculator | null>(null)
  const steps = [stepDaysWorked, stepLeaveDays, stepCompanyMeals, stepExpenseReports, stepResults]
  const items = ref<BenefitsCalculatorItem[]>([])

  const fetchBenefitsCalculator = async () => {
    if (benefitsCalculator.value) {
      return Result.ok(benefitsCalculator.value)
    }

    const result = await contentful.benefitsCalculator.fetchBenefitsCalculator()

    if (result.isOk) {
      benefitsCalculator.value = result.value

      return Result.ok(benefitsCalculator.value)
    } else {
      return Result.err(result.error)
    }
  }

  const reset = () => {
    items.value = []
  }

  return {
    benefitsCalculator,
    steps,
    items,
    fetchBenefitsCalculator,
    reset,
  }
})
