export default {
  profile: {
    updateTooltip: 'Vous pouvez modifier ces informations via le formulaire de la page nous contacter.',
    security: {
      title: 'Mot de passe',
      question: {
        title: 'Choisir ma réponse secrète',
        select: 'Question secrète',
        answer: 'Réponse',
      },
      password: {
        title: 'Changer mon mot de passe',
        old: 'Mot de passe actuel',
        new: 'Nouveau mot de passe',
        confirm: 'Confirmation mot de passe',
      },
      passwordChanged: {
        title: 'Mot de passe modifié',
        description: 'Votre mot de passe a été modifié avec succès',
      },
      invalidPasswordError: {
        title: 'Erreur',
        description: 'Votre mot de passe actuel est erroné',
      },
    },
    mfa: {
      title: 'Sécuriser mon compte',
      description: `Un code à usage unique vous est envoyé lors de votre connexion à l’Espace Client depuis un nouveau navigateur ou un nouvel appareil, ou si vous ne vous êtes pas connecté depuis 90 jours.`,
      sms: 'Code de vérification par SMS',
      smsPhone: 'Numéro paramétré&nbsp;: <b>{phoneNumber}</b>',
      smsTooltip:
        'Pour modifier votre numéro, merci de contacter le Service client au 0 821 23 24 25 du lundi au vendredi, de 8:30 à 18:30 (Service 0,12€/min + prix de l’appel).',
      email: 'Code de vérification par e-mail',
      smsOtpDrawer: {
        title: 'Votre numéro de téléphone',
        phoneNumberForm: {
          title: 'Saisissez votre numéro mobile',
          conditions: `<b>J'accepte que mon numéro soit enregistré pour recevoir les codes d'authentification par SMS.</b> Le numéro ne sera pas enregistré par Edenred pour vous contacter, ni transmis pour du démarchage. Une fois le SMS OTP activé, vous ne pourrez plus choisir l'email OTP. Ce numéro n’est pas modifiable, si vous souhaitez le changer, contactez le service client Edenred.`,
          invalid: 'Votre numéro de téléphone est invalide.',
          countryNotSupported: 'Pays non supporté',
        },
        codeForm: {
          title: 'Renseignez le code reçu par SMS ',
          description: 'Saisissez le code que vous venez de recevoir sur votre téléphone : <b>{phoneNumber}</b>',
        },
        success: {
          title: 'Authentification SMS activée',
          description: 'Votre compte est paramétré en double authentification SMS',
        },
        errors: {
          noRemainingAttempts: {
            title: 'Authentification SMS',
            description: 'Suite à un trop grand nombre d’essais infructueux, vous devez recommencer.',
          },
        },
      },
    },
  },
}
