import _uniqBy from 'lodash/uniqBy'
import { getPagination, nextPage } from '@/assets/js/pagination'

const baseUrl = '/newDeliveryPoints'

export default (axiosInstance) => {
  const getDeliveryPoints = async (paginationData, commit, query) => {
    try {
      const getAll = getPagination({
        ...paginationData,
        pageUrl: baseUrl,
        query,
        axiosInstance,
        callback: (p) => {
          const total = Math.ceil(p.count / paginationData.pageSize)
          let page = paginationData.page
          if (page < total) {
            page++
          }
          commit('SET_DELIVERY_POINTS', {
            ...paginationData,
            TotalPages: total,
            page,
            PageStep: page,
            data: _uniqBy(paginationData.data.concat(p.data), 'Reference'),
            DisableLoadingPaging: false,
          })
        },
      })
      const next = nextPage({
        ...paginationData,
        getPagination: getAll,
      })
      await next()
      return paginationData.data
    } catch (e) {
      console.error(e)
    }
  }

  const getDeliveryPointsBenef = async (paginationData, commit, query) => {
    try {
      const getAll = getPagination({
        ...paginationData,
        pageUrl: `${baseUrl}/homeDelivery`,
        query,
        axiosInstance,
        callback: (p) => {
          const total = Math.ceil(p.count / paginationData.pageSize)
          let page = paginationData.page
          if (page < total) {
            page++
          }
          commit('SET_DELIVERY_POINTS', {
            ...paginationData,
            TotalPages: total,
            page,
            PageStep: page,
            data: _uniqBy(paginationData.data.concat(p.data), 'Reference'),
            DisableLoadingPaging: false,
          })
        },
      })
      const next = nextPage({
        ...paginationData,
        getPagination: getAll,
      })
      await next()
      return paginationData.data
    } catch (e) {
      console.error(e)
    }
  }

  const getDeliveryPointDetails = async (productCode, deliveryPointId) => {
    try {
      const url = `${baseUrl}/${deliveryPointId}?productCode=${productCode}`
      const result = await axiosInstance.get(url)
      return result ? result.data : null
    } catch (e) {
      console.error(e)
    }
  }

  const getDeliveryPointsfromParams = async (params) => {
    try {
      const result = await axiosInstance.get(baseUrl, { params })
      return result
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  const getDeliveryPointsBenefFromParams = async (params) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/homeDelivery`, { params })
      return result
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  return {
    getDeliveryPoints,
    getDeliveryPointsBenef,
    getDeliveryPointDetails,
    getDeliveryPointsfromParams,
    getDeliveryPointsBenefFromParams,
  }
}
