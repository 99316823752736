import type { AxiosInstance } from 'axios'
import type { PaymentMethod, RedirectUrlRequest } from './types'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = 'api/:version/orders/:orderId'

const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
  orderId: '',
})

export default (axiosInstance: AxiosInstance) => {
  const postPaymentV4 = async (orderId: number, paymentMethod: PaymentMethod, redirectUrl: RedirectUrlRequest) => {
    try {
      const result = await axiosInstance.post(`${getBaseUrl({ orderId })}/checkout/${paymentMethod}`, redirectUrl)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    postPaymentV4,
  }
}
