import { useDownloadFile } from '@/composables/useDownloadFile'

const downloadFile = useDownloadFile()

export default (axiosInstance) => {
  const getDeliveryPointsCsv = async function (url) {
    try {
      const result = await axiosInstance.get(url, {
        headers: {
          Accept: 'text/csv;charset=UTF-8',
        },
        responseType: 'blob',
      })
      const blobFile = new Blob(['\uFEFF', result.data])
      downloadFile(blobFile, 'download.csv')
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getDetailBeneficiariesCsv = async function (url) {
    try {
      const result = await axiosInstance.get(url, {
        headers: {
          Accept: 'text/csv;charset=UTF-8',
        },
        responseType: 'blob',
      })
      const blobFile = new Blob(['\uFEFF', result.data])
      downloadFile(blobFile, 'Beneficiaires.csv')
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    getDeliveryPointsCsv,
    getDetailBeneficiariesCsv,
  }
}
