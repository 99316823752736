import { computed, type Ref, type ComputedRef, ref } from 'vue'
import type { Pagination } from '@/services/common/types'

export interface PaginationConfig {
  pageSize?: number
  currentPage?: number
}

export interface PaginationData<T> {
  currentPage: Ref<number>
  pageSize: Ref<number>
  sort: Ref<string | undefined>
  sortDirection: Ref<'ASC' | 'DESC'>
  filter: Ref<string | undefined>
  numberOfPages: ComputedRef<number>
  paginatedData: Ref<Pagination<T> | undefined | null>
  queries: ComputedRef<string>
}

export function usePagination<T>(config?: PaginationConfig): PaginationData<T> {
  const currentPage = ref(config?.currentPage ?? 1)

  const pageSize = ref(config?.pageSize ?? 10)
  const paginatedData = ref<Pagination<T>>()
  const sort = ref<string>()
  const sortDirection = ref<'ASC' | 'DESC'>('ASC')
  const filter = ref<string>()

  const numberOfPages = computed(() => Math.ceil((paginatedData.value?.totalElements ?? 0) / pageSize.value))
  const queries = computed(() => {
    const params = new URLSearchParams({
      currentPage: currentPage.value.toString(),
      sortDirection: sortDirection.value,
    })

    if (pageSize.value) {
      params.append('pageSize', pageSize.value.toString())
    }
    if (sort.value) {
      params.append('sort', sort.value)
    }

    if (filter.value) {
      currentPage.value = 1
      params.append('filter', filter.value)
    }
    return params.toString()
  })

  return {
    currentPage,
    pageSize,
    sort,
    sortDirection,
    filter,
    numberOfPages,
    paginatedData,
    queries,
  }
}

export function useReactivePagination<T>(config?: PaginationConfig): PaginationData<T> {
  const currentPage = ref(config?.currentPage ?? 1)

  const pageSize = ref(config?.pageSize ?? 10)
  const paginatedData = ref<Pagination<T>>()
  const sort = ref<string>()
  const sortDirection = ref<'ASC' | 'DESC'>('ASC')
  const filter = ref<string>()

  const numberOfPages = computed(() => Math.ceil((paginatedData.value?.totalElements ?? 0) / pageSize.value))
  const queries = computed(() => {
    const params = new URLSearchParams({
      currentPage: currentPage.value.toString(),
      sortDirection: sortDirection.value,
    })

    if (pageSize.value) {
      params.append('pageSize', pageSize.value.toString())
    }
    if (sort.value) {
      params.append('sort', sort.value)
    }

    if (filter.value) {
      params.append('filter', filter.value)
    }
    return params.toString()
  })

  return {
    currentPage,
    pageSize,
    sort,
    sortDirection,
    filter,
    numberOfPages,
    paginatedData,
    queries,
  }
}
