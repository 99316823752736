import type { FilteredRequest, PaginatedRequest, SortRequest } from '../types'
import { BadgeColor, type Badge } from '@/types/Badge'
import { ProductCode } from '@/variables/ProductCode'

export const RESTRICTION_PRODUCT_CODES = [ProductCode.CARTE_TICKET_RESTAURANT, ProductCode.TICKET_RESTAURANT]
export const FIXED_ROLES = [1, 2, 3, 4]

export interface User {
  AuthenticationFactorType: number
  CanBypassFactorValidationUntil: boolean | null
  Email: string
  Firstname: string
  Id?: number
  IdRestriction?: number
  IdRole?: number
  Lastname: string
  LastConnexion?: string
  LockedEndDate: string | null
  MFAEnableDate: string | null
  MFAEnabled: boolean
  Role?: string
  SagahSid?: string
  Phone?: string
  isActive?: boolean
}

export interface PatchData {
  IdRestriction?: number
  IdRole?: number
  isActive?: boolean
}

export interface PostData {
  Email: string
  RedirectPath?: string
  IdRestriction?: number
  IdRole?: number
}

export interface FetchUsersParams extends SortRequest, PaginatedRequest, FilteredRequest {
  role?: number | null
  isActive?: boolean | null
  restriction?: number | null
}

export interface RoleLight {
  Id: number
  role: string
  UsersCount: number
  Description: string
}

export interface Func {
  Id: number
  Name: string
  Tag: string
  HasAccess: boolean
}

export interface Category {
  Id: number
  Name: string
  Tag: string
  HasAccess: boolean
  Functions: Func[]
}

export interface Right {
  Id: number
  Name: string
  Tag: string
  Categories: Category[]
}

export interface Role {
  Id: number
  role: string
  UsersCount: number
  Description: string
  Rights: Right[]
}

export interface RoleData {
  Id?: number
  role: string
  UsersCount: number
  Description: string | null
  Rights: Right[]
}

export interface FetchRolesParams extends SortRequest, PaginatedRequest, FilteredRequest {}

export interface RestrictionLight {
  DeliveryPointsCount: number
  Description: string
  DistributionPointsCount: number
  Id: number
  Name: string
  UsersCount: number
}

export interface Restriction {
  Id: number
  Name: string
  Description: string
  DeliveryPointsCount: number
  DistributionPointsCount: number
  UsersCount: number
  DeliveryPointsRestrictions: Record<number, RestrictionDeliveryPoint[]>
}

export interface FetchRestrictionsParams extends SortRequest, PaginatedRequest, FilteredRequest {
  deliveryPoint?: number | null
  distributionPoint?: number | null
}

export interface RestrictionDistributionPoint {
  Name: string
  HasRights: boolean
  IdAloha: number
}

export interface RestrictionDeliveryPoint {
  Name: string
  HasRights: boolean
  IdAloha: number
  DistributionPointsRestrictions: RestrictionDistributionPoint[]
}

export interface RestrictionData {
  Id?: number
  Name: string
  Description: string
  DeliveryPointsRestrictions: Record<number, RestrictionDeliveryPoint[]>
}

export interface Address {
  Address: string
  ZipCode: string
  City: string
  Country: string
}

export interface DistributionPoint {
  Reference: string
  Designation: string
  Id: number
}

export interface DeliveryPoint {
  Reference: string
  Designation: string
  Carrier: string
  IsPrincipal: boolean
  IsHomeDelivery: boolean
  Address: Address
  DistributionPoints: DistributionPoint[]
  ContactFullName: string
  ContactEmail: null
  ContactPhone: null
  Id: number
  ProductCode: ProductCode
  IsActif: boolean
}

export interface SecretAnswer {
  SecretQuestionAnswer: string
}

export function fetchRoleImage(roleId?: number | null) {
  let image = 'AvatarBlue'
  switch (roleId) {
    case 1:
      image = 'AvatarOrange'
      break
    case 4:
      image = 'AvatarGold'
      break
    case 2:
      image = 'AvatarGreen'
      break
    case 3:
      image = 'AvatarPink'
      break
  }

  return {
    src: new URL(`/src/assets/img/avatar/${image}.png`, import.meta.url).href,
    srcset: `${new URL(`/src/assets/img/avatar/${image}@2x.png`, import.meta.url).href} 2x, ${
      new URL(`/src/assets/img/avatar/${image}@3x.png`, import.meta.url).href
    } 3x`,
  }
}

export function fetchBadge(user: User): Badge {
  if (user.isActive === true) {
    return {
      color: BadgeColor.Green,
      label: 'enabled',
    }
  } else if (user.isActive === false) {
    return {
      color: BadgeColor.Red,
      label: 'disabled',
    }
  }
  return {
    color: BadgeColor.Blue,
    label: 'toActivate',
  }
}
