import type { Route, RouteConfig } from 'vue-router'
import ABILITIES from '@/variables/habilitations.json'
import { useStore as useFeatureFlagStore } from '@/store/featureFlag'
import { FeatureFlag } from '@/store/featureFlag/model'

const DefaultChild = () => import('@/views/DefaultRouter/Child/index.vue')

export const router: RouteConfig = {
  path: 'points-livraison',
  meta: {
    breadcrumb: 'Points de livraison',
    howTo: 'gestion-points-livraison-comment-faire',
  },
  component: DefaultChild,
  children: [
    {
      path: '',
      name: 'DeliveryPoints',
      component: () => {
        const store = useFeatureFlagStore()

        if (store.isEnabled(FeatureFlag.NewDeliveryPointsPage)) {
          return import('@/views/DeliveryPoints/index.vue')
        }
        return import('@/views/Admin/DeliveryPoints/index.vue')
      },
      meta: {
        title: 'Points de livraison',
        refAbility: [ABILITIES.SETTINGS.PLS],
      },
    },
    {
      path: ':deliveryPointId',
      meta: {
        title: 'Point de livraison',
        refAbility: [ABILITIES.SETTINGS.PLS],
        breadcrumb: ({ route }: { route: Route }) => route.params.deliveryPointId,
      },
      name: 'DeliveryPointsDetail',
      component: () => {
        const store = useFeatureFlagStore()
        if (store.isEnabled(FeatureFlag.NewDeliveryPointsPage)) {
          return import('@/views/DeliveryPoints/Detail/index.vue')
        }
        return import('@/views/Admin/DeliveryPoints/Detail/index.vue')
      },
      children: [
        { path: '', redirect: 'interlocuteurs' },
        {
          path: 'interlocuteurs',
          name: 'DeliveryPointsDetail',
          component: () => import('@/views/DeliveryPoints/Detail/Contact/index.vue'),
        },
        {
          path: 'point-distribution',
          name: 'DeliveryPointsDetail',
          component: () => import('@/views/DeliveryPoints/Detail/DistributionPoint/index.vue'),
        },
      ],
    },
  ],
}
