export const UNKNOWN_ERROR = 'UNKNOWN_ERROR'
export const UNKNOWN_ERROR_MESSAGE =
  "Une erreur inconnue s'est produite, nous en avons été informés et corrigerons rapidement le problème"

export class ResultError extends Error {
  code: string

  constructor(error: Error) {
    super(error.message)

    this.name = 'ResultError'
    this.stack = new Error().stack
    this.code = UNKNOWN_ERROR
  }
}
