import type { BaseTagEvent } from './common'
import type { PaymentMean } from '@/services/carts/types'

type Currency = 'EUR'

export type ProductLightItemTracking = {
  /// Item ID
  item_id: string
  /// Full commercial name of the item
  item_name: string
  /// Universe brand name ex: 'Kadeos' | 'Ticket Restaurant'
  item_brand: string
  /// Category of the item ex: 'Card' | 'Ticket' | 'Virtual Card'
  item_category: string
  /// Classification of the item ex:  'Culture' | 'Meal' | 'Gift'
  item_typology?: 'nominatif' | 'anonyme'
}

export type ProductItemTracking = ProductLightItemTracking & {
  item_category2?: string | number | null
  item_category3?: string | number | null
  item_category4?: string | number | null
  item_category5?: string | number | null
  /// Related event (eg. "Christmas" for Kadeos)
  item_variant?: string | null | boolean
  /// Price of the item
  price?: number | null
  /// Quantity of the item
  quantity?: number | null
}

export type SelectPromotionEvent = BaseTagEvent<
  'select_promotion',
  { button_id: string; button_name: string; items: ProductLightItemTracking[] }
>

export type SelectItemEvent = BaseTagEvent<'select_item', { items: ProductLightItemTracking[] }>

export type ViewItemListEvent = BaseTagEvent<'product.display', ProductLightItemTracking>

export type AddPaymentInfoEvent = BaseTagEvent<
  'cart.payment',
  {
    cart_id?: string
    currency: Currency
    value: number
    coupon?: string | null
    payment_type: PaymentMean
    items: ProductLightItemTracking[]
  }
>

export type AddToCartEvent = BaseTagEvent<
  'add_to_cart',
  {
    cart_id: string
    currency: Currency
    value: number
    items: ProductItemTracking[]
  }
>

export type BeginCheckoutEvent = BaseTagEvent<
  'begin_checkout',
  {
    cart_id: string | null
    currency: Currency
    value: number
    coupon?: string
    items: ProductLightItemTracking[]
  }
>

export type DownloadEvent = BaseTagEvent<
  'download',
  {
    items: ProductLightItemTracking[]
  }
>

export type ConfirmationEvent = BaseTagEvent<
  'transaction.confirmation',
  {
    cart_id: string
    currency: Currency
    transaction_id: string
    value: number
    coupon?: string
    shipping: number
    tax: number
    items: ProductLightItemTracking[]
  }
>

export type PurchaseEvent = BaseTagEvent<'product.purchased', null>

export type ViewCartEvent = BaseTagEvent<
  'view_cart',
  {
    cart_id: string | null
    currency: Currency
    value: number
    items: ProductItemTracking[]
  }
>

export function isEcommerceEvent(event: string) {
  return [
    'download',
    'select_promotion',
    'select_item',
    'product.item',
    'cart.payment',
    'add_shipping_info',
    'add_to_cart',
    'begin_checkout',
    'transaction.confirmation',
    'view_cart',
    'product.purchased',
  ].includes(event)
}

export type CommerceEvent =
  | SelectPromotionEvent
  | SelectItemEvent
  | AddPaymentInfoEvent
  | AddToCartEvent
  | BeginCheckoutEvent
  | ConfirmationEvent
  | ViewCartEvent
  | ViewItemListEvent
  | DownloadEvent
  | PurchaseEvent
