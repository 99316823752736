import type { CartWorkflow } from '../model'
import Mixed from './univers/TR/Mixed'
import Multiloading from './univers/TR/Multiloading'
import CTRV from './univers/TR/CTRV'
import CTR from './univers/TR/CTR'
import KSN from './univers/KS/nominative'
import KSC from './univers/KS/KSC'
import CKS from './univers/KS/CKS'
import KS from './univers/KS/common'
import TR from './univers/TR/TR'
import TRA from './univers/TR/TR/anonymous'

const cartWorkflows: CartWorkflow[] = [Mixed, Multiloading, CTRV, CTR, TRA, TR, KSC, CKS, KSN, KS]

export default cartWorkflows
