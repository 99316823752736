import type { RouteConfig } from 'vue-router'
import { router as BeneficiariesRouter } from '../Beneficiaries'
import { router as PaymentMeansRouter } from '../PaymentMeans'
import { router as DeliveryPointRouter } from '../DeliveryPoint'
import { accessor } from '@/store'
import { ProductCode } from '@/variables/ProductCode'
import ABILITIES from '@/variables/habilitations.json'

const DefaultRoot = () => import('@/views/DefaultRouter/Root/index.vue')
const Child = () => import('@/views/DefaultRouter/Child/index.vue')

export const router: RouteConfig = {
  path: '/parametres',
  component: DefaultRoot,
  children: [
    {
      path: '',
      redirect: '/404',
    },
    {
      path: 'valeurs-du-titre',
      meta: {
        title: 'Valeurs du titre',
        breadcrumb: 'Valeurs du titre',
        howTo: 'comment-faire-gestion-valeur-titre',
      },
      component: () => import('@/views/ValueOfTitle/index.vue'),
      beforeEnter: async (_, __, next) => {
        if (!accessor.session.hasPermission(ABILITIES.SETTINGS.VF)) {
          next('/404')
          return
        }

        next()
      },
    },
    {
      path: 'parametres-avances',
      meta: {
        breadcrumb: 'Paramètres avancés',
        title: 'Paramètres avancés',
        howTo: 'comment-faire-parametres-avances',
      },
      beforeEnter: async (_, __, next) => {
        if (!accessor.session.hasPermission('SETTINGS__ADVSETTINGS')) {
          next('/404')
          return
        }

        next()
      },
      component: Child,
      children: [
        {
          path: '',
          component: () => import('@/views/AdvancedSettings/index.vue'),
        },
        {
          name: 'CustomImport',
          path: 'import-personnalise/:productCode',
          beforeEnter: async (to, __, next) => {
            if (!accessor.session.hasPermission('SETTINGS__ADVSETTINGS__CUSTOM-IMPORT')) {
              next('/404')
              return
            }

            const { productCode } = to.params
            if (typeof productCode !== 'string') {
              next('/404')
              return
            }

            const isProductCode = Object.keys(ProductCode).includes(productCode)
            if (!isProductCode) {
              next('/404')
              return
            }
            next()
          },
          meta: {
            breadcrumb: 'Import personnalisé',
            title: 'Import personnalisé',
            howTo: 'import-personnalise-comment-faire',
          },
          component: () => import('@/views/CustomImport/index.vue'),
        },
      ],
    },
    BeneficiariesRouter,
    PaymentMeansRouter,
    DeliveryPointRouter,
  ],
}
