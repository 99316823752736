import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import type { CustomerPaymentMeans } from './types'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = 'api/:version/paymentmeans'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
})

export default (axiosInstance: AxiosInstance) => {
  const getAllPaymentMeans = async () => {
    try {
      const result = await axiosInstance.get<CustomerPaymentMeans>(getBaseUrl())
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(error as ApiAxiosError)
    }
  }

  return {
    getAllPaymentMeans,
  }
}
