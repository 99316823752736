import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import { ApiSessionError } from './errors'
import {
  type ApiAccount,
  type ApiClient,
  type ApiContract,
  type ApiProfileInformation,
  mapAccountsToLocal,
  mapClientToLocal,
  mapContractsToLocal,
} from './types'

export default (axiosInstance: AxiosInstance) => {
  const getSubstituteClient = async () => {
    try {
      return Result.ok(await axiosInstance.get<ApiClient>('/client')).map((value) => mapClientToLocal(value.data))
    } catch (error) {
      return Result.err(new ApiSessionError(error as ApiAxiosError))
    }
  }

  const getAccounts = async () => {
    try {
      return Result.ok(await axiosInstance.get<ApiAccount[]>('profile/v2/accounts?isAssociated=true')).map((value) =>
        mapAccountsToLocal(value.data),
      )
    } catch (error) {
      return Result.err(new ApiSessionError(error as ApiAxiosError))
    }
  }

  const getProfil = async () => {
    return (await axiosInstance.get<ApiProfileInformation>('profile/informations')).data
  }

  const getContracts = async () => {
    try {
      return Result.ok(await axiosInstance.get<ApiContract[]>('/contracts')).map((value) =>
        mapContractsToLocal(value.data),
      )
    } catch (error) {
      return Result.err(new ApiSessionError(error as ApiAxiosError))
    }
  }

  return {
    getSubstituteClient,
    getAccounts,
    getProfil,
    getContracts,
  }
}
