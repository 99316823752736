import type { RouteConfig } from 'vue-router'
import { useIsSSO } from '@/composables/useIsSSO'

export const router: RouteConfig = {
  path: '/mon-profil',
  meta: {
    breadcrumb: 'Mon profil',
    title: 'Mon profil',
    howTo: 'comment-faire-monprofil',
  },
  beforeEnter: (_, __, next) => {
    // No access to profile if user is using SSO #294580
    const isSSO = useIsSSO()
    if (isSSO) {
      next('/accueil')
      return
    }

    next()
  },
  component: () => import('@/views/DefaultRouter/Root/index.vue'),
  children: [
    {
      path: '',
      component: () => import('@/views/MyProfile/index.vue'),
    },
  ],
}
