import { ProductCode } from './ProductCode'

export default {
  [ProductCode.TICKET_RESTAURANT]: 'TR',
  [ProductCode.TICKET_SERVICE]: 'TS',
  [ProductCode.TICKET_CESU]: 'TCESU',
  [ProductCode.TICKET_CAP]: 'TSCAP',
  [ProductCode.TICKET_RESTAURANT_BENEVOLES_VOLONTAIRE]: 'TRBV',
  [ProductCode.KADEOS_CONNECT]: 'KSC',
  [ProductCode.CARTE_TICKET_RESTAURANT]: 'CTR',
  [ProductCode.KADEOS_UNIVERSEL]: 'CKU',
  [ProductCode.CARTE_KADEOS]: 'CKS',
  [ProductCode.KADEOS]: 'KS',
  [ProductCode.TICKET_FLEETPRO]: 'FLEETPRO',
  [ProductCode.BETTERWAY]: 'TM',
  default: '',
}
