import { Result } from '@badrap/result'
import { computed } from 'vue'
import { useEditItemFormWorkflow } from '../composables/useEditItemFormWorkflow'
import { moduleConfig as editItemConfig } from '@/components/Cart/Module/EditItemBeneficiary/workflow'
import type { ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import type { CartItem } from '@/services/carts/types'
import { useBeneficiaryFormWorkflow } from '@/composables/useBeneficiaryFormWorkflow'
import type { EditItemBeneficiaryWorkflow } from '@/components/Cart/Module/EditItemBeneficiary/workflow'
import useSwrv from '@/utils/useSwrv'
import apiV4 from '@/services/apiV4'
import { AlertType } from '@/types/Alert'
import { useI18n } from '@/composables/useI18n'
import { useStore as useToasterStore } from '@/store/toast'
import { useStore as useDrawerStore } from '@/store/drawer'

const editItemBeneficiary: ModuleBuilder<EditItemBeneficiaryWorkflow> = {
  config: editItemConfig,
  isAllowed: () => true,
  useModule(cartRef, _workflow, useAction) {
    return {
      useWorkflow(record: { item: CartItem }) {
        if (cartRef.value === null) {
          throw new Error('cart.errors.cartNotFound')
        }

        const i18n = useI18n()
        const toasterStore = useToasterStore()
        const drawerStore = useDrawerStore()
        const { value: cart } = cartRef

        const beneficiaryResponse = useSwrv(`beneficiaries/${record.item.beneficiary?.id}`, async () => {
          if (record.item.beneficiary?.id == null) {
            throw new Error('Beneficiary not found')
          }
          const result = await apiV4.beneficiaries.fetchBeneficiary(record.item.beneficiary.id)

          if (result.isErr) {
            throw result.error
          }

          return result.value
        })

        return {
          title: 'cart.modules.addItemBeneficiary.edit.title',
          useBeneficiaryFormWorkflow: computed(() =>
            beneficiaryResponse.data.value
              ? useBeneficiaryFormWorkflow(
                  cart.meta.productCode,
                  cart.meta.isPlasticless,
                  beneficiaryResponse.data.value,
                )
              : null,
          ),
          useEditItemFormWorkflow: useEditItemFormWorkflow,
          cancelAction: null,
          submitAction: (submitBenef, submitItem) =>
            useAction({
              id: 'add',
              name: 'add',
              type: ActionType.Default,
              refresh: true,
              async execute() {
                if (!submitBenef) {
                  return Result.err(new Error('submitBenef not found'))
                }
                const resultBenef = await submitBenef()
                if (resultBenef.isErr) {
                  return Result.err(resultBenef.error)
                }

                if (!submitItem) {
                  return Result.err(new Error('submitItem not found'))
                }
                const resultItemForm = await submitItem(resultBenef.value)
                if (resultItemForm.isErr) {
                  return Result.err(resultItemForm.error)
                }

                toasterStore.push({
                  title: i18n.t('cart.modules.beneficiary.title').toString(),
                  description: i18n.t(`cart.modules.beneficiary.editSuccess`).toString(),
                  type: AlertType.SUCCESS,
                })

                drawerStore.pop(editItemConfig.id)

                return Result.ok(true)
              },
            }),
        }
      },
    }
  },
}

export default editItemBeneficiary
