import { Result } from '@badrap/result'
import type { Asset, ContentfulClientApi, Entry } from 'contentful'
import type {
  TypeArticleSkeleton,
  TypeCartItemConfigSkeleton,
  TypeProductSkeleton,
  TypeUniverseSkeleton,
} from '../generated'
import { mapMediaToLocal } from '../types/CFMedia'
import type { CFTarget } from '../types/CFTarget'
import type { CFCartItemConfig, CFProduct } from '../types/CFProduct'
import type { CartItemType } from '@/services/carts/types'

export default (contentfulInstance: ContentfulClientApi<undefined>) => {
  const fetchProducts = async () => {
    try {
      const result = await contentfulInstance.getEntries<TypeProductSkeleton>({
        content_type: 'product',
        include: 3,
      })
      return Result.ok(mapToLocal(result.items))
    } catch (error) {
      return Result.err(error as Error)
    }
  }

  return {
    fetchProducts,
  }
}

function mapToLocal(items: Entry<TypeProductSkeleton, undefined, string>[]): CFProduct[] {
  return items.map((item) => {
    const articles = item.fields.articles
      ? (item.fields.articles as Entry<TypeArticleSkeleton, undefined, string>[])
      : []

    const product: CFProduct = {
      ...item.fields,
      articles: articles.map((article) => {
        const cartItemConfigs = article.fields.cartItemConfigs as Entry<TypeCartItemConfigSkeleton, undefined, string>[]

        return {
          ...article.fields,
          cartItemConfigs: cartItemConfigs.reduce<CFCartItemConfig[]>((r, config) => {
            if (config.fields.target === 'EC') {
              r.push({
                ...config.fields,
                target: config.fields.target as CFTarget,
                type: config.fields.type as CartItemType,
                limitExo: config.fields.limitExo,
                suggestedAmount: config.fields.suggestedAmount?.map((amount) => parseInt(amount)),
              })
            }

            return r
          }, []),
          image: mapMediaToLocal(article.fields.image as Asset<undefined, string>),
          bookletImage: article.fields.bookletImage
            ? mapMediaToLocal(article.fields.bookletImage as Asset<undefined, string>)
            : null,
          pocketImage: article.fields.pocketImage
            ? mapMediaToLocal(article.fields.pocketImage as Asset<undefined, string>)
            : null,
        }
      }),
      universe: (item.fields.universe as Entry<TypeUniverseSkeleton, undefined, string>).fields,
      cgv: mapMediaToLocal(item.fields.cgv as Asset<undefined, string>),
    }

    return product
  })
}
