import type { FilteredRequest, PaginatedRequest, SortRequest } from '../types'
import type { ProductCode } from '@/variables/ProductCode'

export enum CreditNoteType {
  Commercial = 'COMMERCIAL',
  OnInvoice = 'FACTURE',
  Return = 'RETOUR_TITRES',
  Prestation = 'PRESTATION',
}

export enum CreditNoteStatus {
  InProgress = 'EN_COURS',
  PartiallyAffected = 'AFFECTE_PARTIEL',
  TotallyAffected = 'AFFECTE_TOTAL',
  Refunded = 'REMBOURSE',
  Canceled = 'ANNULE',
}

export enum CreditNoteProcessingActionType {
  RefundedByTransfer = 'REMBOURSEMENT_VIR',
  RefundedByCheck = 'REMBOURSEMENT_CHQ',
  OnInvoice = 'LETTRAGE_FACTURE',
  WrittenOff = 'WRITE_OFF',
}

export interface CreditNoteProcessingAction {
  type?: CreditNoteProcessingActionType
  date: string
  reference?: number
}

export interface CreditNote {
  id: number
  reference: string
  productCode: ProductCode
  type: CreditNoteType
  status: CreditNoteStatus
  amount: number
  amountFree: number
  processingActions: CreditNoteProcessingAction[]
}

export interface GetCreditNotesParams extends SortRequest, PaginatedRequest, FilteredRequest {
  products?: ProductCode[]
  type?: CreditNoteType
  status?: CreditNoteStatus
}
