import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useSessionStorage } from '@vueuse/core'
import type { CampaignData } from './model'
import type { CFCampaign } from '@/services/contentful/types/CFCampaign'
import contentful from '@/services/contentful'

export const useCampaignStore = defineStore('campaign', () => {
  const campaign = ref<CFCampaign | null>(null)
  const router = useRouter()
  const route = useRoute()
  const data = useSessionStorage<CampaignData>('campaign', {})

  router.onReady(() => {
    data.value.source = data.value.source ?? (route.query.utm_source as string)
    data.value.medium = data.value.medium ?? (route.query.utm_medium as string)
    data.value.campaign = data.value.campaign ?? (route.query.utm_campaign as string)
    data.value.term = data.value.term ?? (route.query.utm_term as string)
    data.value.content = data.value.content ?? (route.query.utm_content as string)

    if (data.value.campaign) {
      contentful.campaign.fetchCampaign(data.value.campaign).then((result) => {
        campaign.value = result
      })
    }
  })

  return {
    data,
    campaign,
    clearCampaign: () => {
      data.value.source = null
      data.value.medium = null
      data.value.campaign = null
      data.value.term = null
      data.value.content = null

      campaign.value = null
    },
  }
})
