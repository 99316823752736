import { getters as deliveryPointsGetters } from './deliveryPoints'
import { getters as productGetters } from './product'
import { getters as vfGetters } from './vf'
import univers from '@/assets/constantes/univers.json'

export default {
  ...deliveryPointsGetters,

  ...vfGetters,

  ...productGetters,

  filteredCustomAlertsMessages: (state) => {
    return state.filteredCustomAlertsMessages ? state.filteredCustomAlertsMessages : null
  },
  getEventsFromLibelleProduct:
    (state) =>
    ({ articleCode, productCode }) => {
      let events = null
      if (state.allEvents && articleCode && productCode) {
        const referentiel = state.referentielProduits
        const libelleCourtProduit = referentiel.find(
          (r) => r.productCode === productCode && r.articleCode === articleCode,
        )?.libelleCourt[0]
        events = state.allEvents
          .find((e) => e.product.includes(libelleCourtProduit))
          ?.events.sort((a, b) => a.sortOrder - b.sortOrder)
      }
      return events
    },
  getEventName: (state) => (eventsId) => {
    const eventName = []

    if (state.allEvents) {
      eventsId.forEach((eventId) => {
        let eventObj
        state.allEvents.find((EventArr) => {
          eventObj = EventArr.events?.find((Event) => Event.value === eventId)
        })
        if (eventObj) {
          eventName.push(eventObj.label)
        }
      })
    }

    return eventName
  },
  getValidationImport: (state) => state.validationImport,
  getCustomerDeliveryChoice: (state) => state.Customer?.DeliveryChoice,
  getAllEvents: (state) => state.allEvents,
  getPopinChoixTunnelVisible: (state) => state.popinChoixTunnelVisible,
  getUserAlert: (state) => state.Users.alert,
  getBillingPoints: (state) => state.Customer.FacturationPoints,
  getImportFieldsDescription: (state) => state.Import.ImportedFieldsDescription,
  getOrderReferenceFixed: (state) => state.Order.OrderReferenceFixed,
  getReferenceIsMandatory: (state) => state.Order.OrderReferenceMandatory,
  AccountingDocuments(state) {
    return state.Customer.AccountingDocuments
  },
  OrdersHistory(state) {
    return state.Customer.OrdersHistory
  },
  isImport(state) {
    return state.Customer && state.Customer.IsImport
  },
  isTRNonNominatif(state) {
    return state.Customer && state.Customer.isTRNonNominatif
  },
  ArticleCode(state) {
    return state.Order.ArticleCode ? state.Order.ArticleCode : state.ArticleCode
  },
  getOrder(state) {
    return state.Order
  },
  OrderID(state) {
    return state.Order.Id
  },
  OrderItems(state) {
    return state.Order && state.Order.Items
  },
  getOrderPricing(state) {
    return state.Order && state.Order.Pricing
  },
  getBankErrors(state) {
    return state.bankErrors ? state.bankErrors : []
  },
  getCustomAlertsMessages(state) {
    return state.customAlertsMessages ? state.customAlertsMessages : null
  },
  getPartnerId(state) {
    return state.partnerId ? state.partnerId : null
  },
  getFaqCategories(state) {
    return state.Faq.Categories ? state.Faq.Categories : []
  },
  getPaymentMeans(state) {
    return state.Customer.PaymentMeans
  },
  getImportRequestId(state) {
    return state.Import && state.Import.RequestId
  },
  getImportSuccess(state) {
    return state.Import && state.Import.Success
  },
  getBeneficiariesNewLength(state) {
    return state.Beneficiaries && state.Beneficiaries.BeneficiariesNewLength
  },
  getBeneficiariesTotal(state) {
    return state.Beneficiaries && state.Beneficiaries.BeneficiariesTotal
  },
  getBeneficiariesInOrderLength(state) {
    return state.Beneficiaries && state.Beneficiaries.BeneficiariesInOrderLength
  },
  getBeneficiariesDuplicated(state) {
    return state.Beneficiaries && state.Beneficiaries.BeneficiariesDuplicated
  },
  getBeneficiariesDuplicatedId(state) {
    return (
      state.Beneficiaries &&
      state.Beneficiaries.BeneficiariesDuplicated &&
      state.Beneficiaries.BeneficiariesDuplicated.Id
    )
  },
  getBeneficiariesDuplicatedLength(state) {
    return state.Beneficiaries && state.Beneficiaries.BeneficiariesDuplicatedLength
  },
  getBeneficiariesDuplicatedRemaining(state) {
    return state.Beneficiaries && state.Beneficiaries.BeneficiariesDuplicatedRemaining
  },
  getBeneficiariesUpdatedLength(state) {
    return state.Beneficiaries && state.Beneficiaries.BeneficiariesUpdatedLength
  },
  getImportError(state) {
    return state.errors && state.errors.import
  },
  OrderReference(state) {
    return state?.Order?.Reference
  },
  IsNominative(state) {
    return state.Order.IsNominative
  },
  getTermsOfSale(state) {
    return state.Customer && state.Customer.TermsOfSale && state.Customer.TermsOfSale.termsOfSale
  },
  getUnivers(state) {
    return state.Univers
  },
  getCustomer(state) {
    return state.Customer
  },
  getCustomerFormData: (state) => state.Customer?.FormData,
  getPaymentType(state) {
    const typesMap = {
      1: 'Virement',
      2: 'Chèque',
      4: 'Prélèvement',
      7: 'CB',
    }

    const OrderPaymentType = state.Order?.Payment?.Method ? typesMap[state.Order?.Payment?.Method] : null
    const CustomerPaymentType = state.Customer?.FormData?.Method ? typesMap[state.Customer?.FormData?.Method] : null

    return OrderPaymentType || CustomerPaymentType
  },
  getOrderNameType: (state) => (step, route) => {
    if (!state?.Order?.IsNominative) {
      if (route.name === 'carteFlex') {
        return 'CtrAnonyme'
      }
      if (route.name === univers.TR.CARTE_INTERIM_CONFIRMATION.name) {
        return 'interim'
      }
      return 'anonyme'
    } else {
      const hasOrderImport = state?.Order?.IsImport
      const hasCustomerImport = state?.Customer?.IsImport
      if (hasOrderImport || (step === 2 && hasCustomerImport)) {
        return 'import'
      }
      return 'saisieWeb'
    }
  },
  getFormData: (state) => state.Customer.FormData,
  getOrderReferenceSuccess: (state) => state.Reference?.orderReferenceSuccess,
  getOrderReferenceFailMessage: (state) => state.Reference?.orderReferenceFailMessage,
  getAwaitSpinner: (state) => state.Reference?.awaitSpinner,
  getIsWaitingPatchOrder: (state) => state.Reference?.isWaitingPatchOrder,
  getIsSaisieWebCustomer: (state) => state.Customer?.IsSaisieWeb,
  getIsImportOrder: (state) => state.Order?.IsImport,
  getHasPendingCall: (state) => state.HasPendingCall,
  getOrderReference: (state) => state.Order?.Reference,
}
