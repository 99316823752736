export default {
  benefitsCalculator: {
    detailsTitle: 'Ce fichier doit inclure&nbsp;:',
    importSuccessTitle: 'Fichier importé avec succès',
    importSuccessDescription: 'Veuillez valider l’opération pour intégrer le fichier au calcul',
    importErrorTitle: 'Erreur de l’import de fichier',
    templateFilename: 'Modèle.csv',
    skipStep: 'Ignore cette étape',
    tooltip: 'Les colonnes supplémentaires seront ignorées',
    results: {
      tabs: {
        eligible: {
          title: 'Bénéficiaires éligibles',
        },
        notEligible: {
          title: 'Bénéficiaires non éligibles',
          tooltip:
            'Si vous souhaitez les imputer sur la prochaine simulation, exportez et enregistrer le fichier des résultats',
        },
      },
      headers: {
        daysWorked: 'Jours travaillés théoriques',
        leaveDays: 'Absences et formations',
        companyMeals: 'Restauration d’entreprise',
        expenseReports: 'Note de frais',
        total: 'Nombre de titres',
      },
    },
    exportList: 'Exporter la liste',
    exportResult: 'Exporter le résultat',
    order: 'Commander',
    errors: {
      lineBadFormat: 'Ligne {number}&nbsp;:&nbsp;un champ requis est vide',
      duplicatesRegistrationNumber: 'Un ou plusieurs matricules sont présents plusieurs fois dans le fichier',
      birthDateBadFormat:
        'Ligne {number}&nbsp;:&nbsp;format de la date de naissance incorrecte (format attendu jj/mm/aaaa)',
      distributionPointRequired: 'Ligne {number}&nbsp;:&nbsp;le point de distribution est requis',
      daysWorkedBadFormat: 'Ligne {number}&nbsp;:&nbsp;le nombre de jours doit être un nombre >= 0',
      valueOfTitleBadFormat: 'Ligne {number}&nbsp;:&nbsp;la valeur du titre doit être un entier >= 0',
      employersContributionBadFormat:
        'Ligne {number}&nbsp;:&nbsp;la contribution employeur doit être un nombre compris entre 50 et 60',
      leaveDaysBadFormat:
        'Ligne {number}&nbsp;:&nbsp;format du champ "nombre jours absent" invalide (format attendu : nombre >= 0)',
      companyMealBadFormat:
        'Ligne {number}&nbsp;:&nbsp;format du champ "nombre passages" invalide (format attendu : nombre >= 0)',
      expenseReportsBadFormat:
        'Ligne {number}&nbsp;:&nbsp;format du champ "Nombre de note de frais" invalide (format attendu : nombre >= 0)',
    },
    success: { download: 'Une copie de votre fichier de commande a été téléchargée sur votre ordinateur' },
  },
}
