import { Result } from '@badrap/result'
import { moduleConfig, type DiscountCodeWorkflow } from '@/components/Cart/Module/DiscountCode/workflow'
import { useI18n } from '@/composables/useI18n'
import apiV4 from '@/services/apiV4'
import type { ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import { AlertType } from '@/types/Alert'

import { useStore as useToasterStore } from '@/store/toast'

const discountCode: ModuleBuilder<DiscountCodeWorkflow> = {
  config: moduleConfig,
  isAllowed: () => true,
  useModule(cartRef, _workflow, useAction) {
    if (cartRef.value === null) {
      throw new Error('cart.errors.cartNotFound')
    }

    const { value: cart } = cartRef

    return {
      useWorkflow({ discountCode }: { discountCode: string }) {
        const i18n = useI18n()
        return {
          validateAction: useAction({
            id: 'set-discount-code-action',
            name: 'discount-code',
            type: ActionType.Default,
            refresh: true,
            execute: async () => {
              const toaster = useToasterStore()

              const result = await apiV4.carts.patchCart(cart.remote.id, {
                discountCode: discountCode,
                reference: cart.remote.description.orderReference,
                deliveryDate: cart.remote.paymentOptions.deliveryDate,
                isHomeDelivery: cart.remote.options.isHomeDelivery,
              })

              if (result.isErr) {
                toaster.push({
                  title: i18n.t('cart.payment.discountCode').toString(),
                  description: i18n.t('cart.payment.errors.invalidDiscountCode').toString(),
                  type: AlertType.DANGER,
                  autoHide: false,
                })
                return Result.ok(false)
              }

              toaster.push({
                title: i18n.t('cart.basket.success.title').toString(),
                description: i18n.t('cart.payment.errors.successDiscountCode', { code: discountCode }).toString(),
                type: AlertType.SUCCESS,
              })

              return Result.ok(true)
            },
          }),
        }
      },
    }
  },
}

export default discountCode
