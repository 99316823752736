import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import type {
  FieldDescription,
  FileUploadBody,
  FileUploadContext,
  FileUploadQuery,
  FileUploadStatus,
  CustomImportHeader,
} from './types'
import { ApiFileUploadError } from './errors'
import urlReplacer from '@/services/utils/urlReplacer'
import type { ProductCode } from '@/variables/ProductCode'

const baseUrl = 'api/:version/fileUpload/:processId'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
  processId: '',
})

export default (axiosInstance: AxiosInstance) => {
  const fetchFields = async (productCode: ProductCode, query?: FileUploadQuery) => {
    try {
      const result = await axiosInstance.get<FieldDescription[]>(`${getBaseUrl()}/${productCode}/fields`, {
        params: query,
      })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new ApiFileUploadError(error as ApiAxiosError))
    }
  }

  const postFields = async (productCode: ProductCode, body: CustomImportHeader[], isMixed = false) => {
    try {
      const result = await axiosInstance.post(`${getBaseUrl()}/${productCode}/fields`, body, {
        params: { isMixed: isMixed ? isMixed : undefined },
      })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new ApiFileUploadError(error as ApiAxiosError))
    }
  }

  const fetchStatus = async (processId: number) => {
    try {
      const result = await axiosInstance.get<FileUploadStatus>(`${getBaseUrl({ processId })}/status`)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new ApiFileUploadError(error as ApiAxiosError))
    }
  }

  const postValidate = async (processId: number) => {
    try {
      const result = await axiosInstance.post<boolean>(`${getBaseUrl({ processId })}/validate`)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new ApiFileUploadError(error as ApiAxiosError))
    }
  }

  const postUpload = async (body: FileUploadBody) => {
    try {
      const result = await axiosInstance.post<FileUploadContext>(`${getBaseUrl()}/upload`, body)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new ApiFileUploadError(error as ApiAxiosError))
    }
  }

  return {
    fetchFields,
    postFields,
    fetchStatus,
    postValidate,
    postUpload,
  }
}
