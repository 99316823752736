const baseUrl = '/utils'

export default (axiosInstance) => {
  const patchTest = async () => {
    try {
      await axiosInstance.patch(`${baseUrl}/proxy/patchTest`)
      return true
    } catch (e) {
      return false
    }
  }

  const getCitiesByZipCode = async (zip) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/cities/${zip}`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  return {
    patchTest,
    getCitiesByZipCode,
  }
}
