const namespaced = true

const persistent = true

const state = () => ({
  formData: null,
  fileObject: null,
  error: null,
  uploadInfos: {},
  uploadStatus: null,
})

const getters = {
  getRequestId: (state) => state.uploadInfos?.RequestId,
  getFormData: (state) => state.formData,
  getFileObject: (state) => state.fileObject,
  getErrors: (state) => state.error,
  getUploadStatus: (state) => state.uploadStatus,
  getUploadToken: (state) => state.uploadInfos?.Token,
}

const actions = {
  setRequestId({ commit }, payload) {
    commit('SET_IMPORT_REQUEST_ID', payload)
  },
  setFormData({ commit }, payload) {
    commit('SET_IMPORT_FORM_DATA', payload)
  },
  setFileObject({ commit }, payload) {
    commit('SET_IMPORT_FILE_OBJECT', payload)
  },
  setError({ commit }, payload) {
    commit('SET_IMPORT_ERROR', payload)
  },
  setUploadStatus({ commit }, payload) {
    commit('SET_IMPORT_UPLOAD_STATUS', payload)
  },
  setUploadToken({ commit }, payload) {
    commit('SET_IMPORT_UPLOAD_TOKEN', payload)
  },
  setUploadInfos({ commit }, payload) {
    commit('SET_IMPORT_UPLOAD_INFOS', payload)
  },
  clearUploadInfos({ commit }) {
    commit('SET_IMPORT_UPLOAD_INFOS', {})
  },
  initializeState({ commit }, payload) {
    commit('SET_STORE', payload)
  },
  clearImport({ commit }) {
    commit('CLEAR_STORE')
  },
}

const mutations = {
  SET_IMPORT_REQUEST_ID(state, payload) {
    state.uploadInfos.RequestId = payload
  },
  SET_IMPORT_FORM_DATA(state, payload) {
    state.formData = payload
  },
  SET_IMPORT_FILE_OBJECT(state, payload) {
    state.fileObject = payload
  },
  SET_IMPORT_UPLOAD_STATUS(state, payload) {
    state.uploadStatus = payload
  },
  SET_IMPORT_UPLOAD_TOKEN(state, payload) {
    state.uploadInfos.Token = payload
  },
  SET_IMPORT_UPLOAD_INFOS(state, payload) {
    state.uploadInfos = payload
  },
  CLEAR_STORE(state) {
    Object.keys(state).forEach((key) => {
      state[key] = null
    })
  },
  SET_STORE(state, payload) {
    Object.entries(payload).forEach(([key, value]) => {
      state[key] = value
    })
  },
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
  persistent,
}

export { state, getters, mutations, actions, persistent }
