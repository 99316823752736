import { getTotalPages, createOptions, applyParams, generateCallParams } from './utils'
import { baseParams } from './utils/bases'

const DEFAULT_INSTANCE_IDENTIFIER = 'default'

const namespaced = true

const state = () => ({
  paginationsDatas: {},
  paginationsParams: {},
  paginationsConfigs: {},
  paginationsLoaded: {},
  paginationsOptions: {},
})

const getters = {
  getAllDatas: (state) => state.paginationsDatas,
  getAllParams: (state) => state.paginationsParams,
  getAllLoaded: (state) => state.paginationsLoaded,
  getAllOptions: (state) => state.paginationsOptions,
  getDataOf:
    (state) =>
    (type, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER) =>
      state.paginationsDatas[type][instanceIdentifier],
  getParamsOf:
    (state) =>
    (type, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER) =>
      state.paginationsParams[type][instanceIdentifier],
  getConfigOf:
    (state) =>
    (type, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER) =>
      state.paginationsConfigs[type][instanceIdentifier],
  getLoadedOf:
    (state) =>
    (type, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER) =>
      state.paginationsLoaded[type][instanceIdentifier],
  getOptionsOf:
    (state) =>
    (type, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER) =>
      state.paginationsOptions[type][instanceIdentifier],
  getAllOf:
    (state) =>
    (type, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER) => ({
      data: state.paginationsDatas[type][instanceIdentifier],
      params: state.paginationsParams[type][instanceIdentifier],
      config: state.paginationsConfigs[type][instanceIdentifier],
      loaded: state.paginationsLoaded[type][instanceIdentifier],
      options: state.paginationsOptions[type][instanceIdentifier],
    }),
}

const mutations = {
  RESET_PAGINATION_PARAMS(state, { type, params, instanceIdentifier }) {
    const currentParamsType = state.paginationsParams[type]
    state.paginationsParams = {
      ...state.paginationsParams,
      [type]: {
        ...currentParamsType,
        [instanceIdentifier]: { ...params },
      },
    }
  },

  SET_PAGINATION_DATAS(state, { type, data, instanceIdentifier }) {
    const currentDataType = state.paginationsDatas[type]
    state.paginationsDatas = {
      ...state.paginationsDatas,
      [type]: {
        ...currentDataType,
        [instanceIdentifier]: data,
      },
    }
  },

  SET_PAGINATION_PARAMS(state, { type, params, instanceIdentifier }) {
    const currentParamsType = state.paginationsParams[type]
    state.paginationsParams = {
      ...state.paginationsParams,
      [type]: {
        ...currentParamsType,
        [instanceIdentifier]: Object.assign(
          {},
          (currentParamsType && currentParamsType[instanceIdentifier]) || {},
          params,
        ),
      },
    }
  },

  SET_PAGINATION_CONFIG(state, { type, config, instanceIdentifier }) {
    const currentConfigType = state.paginationsConfigs[type]
    state.paginationsConfigs = {
      ...state.paginationsConfigs,
      [type]: {
        ...currentConfigType,
        [instanceIdentifier]: Object.assign(
          {},
          (currentConfigType && currentConfigType[instanceIdentifier]) || {},
          config,
        ),
      },
    }
  },

  SET_PAGINATION_LOADED(state, { type, value, instanceIdentifier }) {
    const currentLoadedType = state.paginationsConfigs[type]
    state.paginationsLoaded = {
      ...state.paginationsLoaded,
      [type]: {
        ...currentLoadedType,
        [instanceIdentifier]: value,
      },
    }
  },

  SET_PAGINATION_OPTIONS(state, { type, options, instanceIdentifier }) {
    const currentOptionsType = state.paginationsConfigs[type]
    state.paginationsOptions = {
      ...state.paginationsOptions,
      [type]: {
        ...currentOptionsType,
        [instanceIdentifier]: options,
      },
    }
  },

  UNSET_PAGINATION_DATAS(state, { type, instanceIdentifier }) {
    if (instanceIdentifier === DEFAULT_INSTANCE_IDENTIFIER) {
      return
    }
    delete state.paginationsDatas[type][instanceIdentifier]
  },

  UNSET_PAGINATION_PARAMS(state, { type, instanceIdentifier }) {
    if (instanceIdentifier === DEFAULT_INSTANCE_IDENTIFIER) {
      return
    }
    delete state.paginationsParams[type][instanceIdentifier]
  },

  UNSET_PAGINATION_CONFIG(state, { type, instanceIdentifier }) {
    if (instanceIdentifier === DEFAULT_INSTANCE_IDENTIFIER) {
      return
    }
    delete state.paginationsConfigs[type][instanceIdentifier]
  },

  UNSET_PAGINATION_LOADED(state, { type, instanceIdentifier }) {
    if (instanceIdentifier === DEFAULT_INSTANCE_IDENTIFIER) {
      return
    }
    delete state.paginationsLoaded[type][instanceIdentifier]
  },

  UNSET_PAGINATION_OPTIONS(state, { type, instanceIdentifier }) {
    if (instanceIdentifier === DEFAULT_INSTANCE_IDENTIFIER) {
      return
    }
    delete state.paginationsOptions[type][instanceIdentifier]
  },
}

const actions = {
  initPagination({ commit }, { type, config, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER }) {
    if (!type) {
      throw new Error('please provide type for init pagination')
    }

    if (!config) {
      throw new Error('please provide config for init pagination')
    }

    if (config.apiMethod == null) {
      throw new Error('please provide apiMethod for init pagination')
    }

    const mergeParams = Object.assign({}, baseParams, config.params)
    const options = createOptions(0, config.options.step, config.options.max)

    commit('SET_PAGINATION_CONFIG', { type, config, instanceIdentifier })
    commit('RESET_PAGINATION_PARAMS', { type, params: mergeParams, instanceIdentifier })
    commit('SET_PAGINATION_OPTIONS', { type, options: options, instanceIdentifier })
    commit('SET_PAGINATION_LOADED', { type, value: false, instanceIdentifier })
    commit('SET_PAGINATION_DATAS', { type, data: [], instanceIdentifier })
  },

  setParamsOf({ commit }, { type, params, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER }) {
    if (!type) {
      throw new Error('please provide type for set pagination params')
    }
    commit('SET_PAGINATION_PARAMS', { type, params, instanceIdentifier })
  },

  async requestDataFor(
    { state, commit, dispatch },
    { type, params = {}, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER },
  ) {
    if (!type) {
      throw new Error('please provide type for get pagination data')
    }
    const configBaseParams = state.paginationsConfigs[type][instanceIdentifier].params
    const currentParams = state.paginationsParams[type][instanceIdentifier]
    const mergedParams = applyParams(params, configBaseParams, currentParams)

    commit('SET_PAGINATION_PARAMS', { type, params: mergedParams, instanceIdentifier })

    return dispatch('makeRequest', { type, instanceIdentifier })
  },

  async requestNextPage({ commit, state, dispatch }, { type, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER }) {
    const currentParams = state.paginationsParams[type][instanceIdentifier]
    if (currentParams.page === currentParams.totalPages) {
      return
    }

    commit('SET_PAGINATION_PARAMS', {
      type,
      params: {
        page: currentParams.page + 1,
      },
      instanceIdentifier,
    })

    return dispatch('makeRequest', { type, instanceIdentifier })
  },

  async requestPrevPage({ commit, state, dispatch }, { type, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER }) {
    const currentParams = state.paginationsParams[type][instanceIdentifier]
    if (currentParams.page === 0) {
      return
    }

    commit('SET_PAGINATION_PARAMS', {
      type,
      params: {
        page: currentParams.page - 1,
      },
      instanceIdentifier,
    })

    return dispatch('makeRequest', { type, instanceIdentifier })
  },

  async makeRequest({ commit, state }, { type, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER }) {
    const currentConfig = state.paginationsConfigs[type][instanceIdentifier]
    const currentParams = Object.assign({}, state.paginationsParams[type][instanceIdentifier])
    delete currentParams.totalPages

    const callParams = generateCallParams(currentConfig.callParams, currentParams)
    if (!currentConfig?.apiMethod) {
      throw new Error('no apiMethod found on config, please make sure the pagination config was init')
    }

    try {
      commit('SET_PAGINATION_LOADED', { type, value: false, instanceIdentifier })

      const { data, headers } = await currentConfig.apiMethod(...callParams)

      currentParams.totalPages = getTotalPages(headers['x-total-count'], currentParams.pageSize)
      currentParams.totalItems = parseInt(headers['x-total-count'], 10)

      const options = createOptions(headers['x-total-count'], currentConfig.options.step, currentConfig.options.max)

      const result = currentConfig.onSuccess ? currentConfig.onSuccess(data) : data
      commit('SET_PAGINATION_DATAS', { type, data: result, instanceIdentifier })
      commit('SET_PAGINATION_PARAMS', { type, params: currentParams, instanceIdentifier })
      commit('SET_PAGINATION_OPTIONS', { type, options: options, instanceIdentifier })
      commit('SET_PAGINATION_LOADED', { type, value: true, instanceIdentifier })

      return {
        data: data,
        params: currentParams,
      }
    } catch (error) {
      commit('SET_PAGINATION_DATAS', { type, data: [], instanceIdentifier })
      commit('SET_PAGINATION_PARAMS', { type, params: { totalItems: 0, totalPages: 0 }, instanceIdentifier })
      throw error
    } finally {
      commit('SET_PAGINATION_LOADED', { type, value: true, instanceIdentifier })
    }
  },

  clearPaginationFor({ state, dispatch }, { type, instanceIdentifier = DEFAULT_INSTANCE_IDENTIFIER }) {
    const defaultConfig = state.paginationsConfigs[type][DEFAULT_INSTANCE_IDENTIFIER]
    if (instanceIdentifier === DEFAULT_INSTANCE_IDENTIFIER) {
      dispatch('initPagination', { type, config: defaultConfig, instanceIdentifier })
    } else {
      dispatch('destroyPaginationInstanceOf', { type, instanceIdentifier })
    }
  },

  createInstanceOf({ state, dispatch }, { type, instanceIdentifier }) {
    const defaultConfig = state.paginationsConfigs[type][DEFAULT_INSTANCE_IDENTIFIER]
    dispatch('initPagination', { type, config: defaultConfig, instanceIdentifier })
  },

  destroyPaginationInstanceOf({ commit }, { type, instanceIdentifier }) {
    if (instanceIdentifier === DEFAULT_INSTANCE_IDENTIFIER) {
      return
    }
    commit('UNSET_PAGINATION_CONFIG', { type, instanceIdentifier })
    commit('UNSET_PAGINATION_PARAMS', { type, instanceIdentifier })
    commit('UNSET_PAGINATION_OPTIONS', { type, instanceIdentifier })
    commit('UNSET_PAGINATION_LOADED', { type, instanceIdentifier })
    commit('UNSET_PAGINATION_DATAS', { type, instanceIdentifier })
  },
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
