import { actionTree, createMapper, getterTree, mutationTree, useAccessor } from 'typed-vuex'
import Vue from 'vue'
import Vuex from 'vuex'
import * as auth from './auth/store'
import basket from './basket'
import beneficiariesList from './Beneficiaries/store.js'
import imports from './imports'
import paginations from './pagination'
import persisteStore from './plugins/peristStore'
import * as preOrder from './preOrder/store'
import * as session from './session/store'
import storeUTR from './uTR/utrStore.js'
import { tagCoEnv } from '@/parameters'
import api from '@/services/api'

type State = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  loadingsCount: number
  isLoading: {
    visible: boolean
    message: string | null
  }
  stopScroll: boolean
  isNavOpen: boolean
  juridicsInfo:
    | {
        popinCode: string
        version: string
        title_cta: string
        title: string
        text_popin: string
      }[]
    | null
}

const state: () => State = () => ({
  Customer: {},
  loadingsCount: 0,
  isLoading: {
    visible: false,
    message: null,
  },
  stopScroll: false,
  isNavOpen: false,
  juridicsInfo: null,
})

const getters = getterTree(state, {
  isNavOpen: (state: State) => (state.isNavOpen ? state.isNavOpen : false),
  getIsLoading: (state: State) => state.isLoading.visible,
  getJuridicsInfo: (state: State) => (state.juridicsInfo ? state.juridicsInfo : null),
})

const mutations = mutationTree(state, {
  SET_LOADING(state: State, isLoading) {
    let currentCount = state.loadingsCount
    if (isLoading.visible === true) {
      currentCount++
      Vue.set(state, 'loadingsCount', currentCount)
    } else if (isLoading.visible === false && currentCount > 0) {
      currentCount--
      Vue.set(state, 'loadingsCount', currentCount)
    }

    if (currentCount === 0) {
      Vue.set(state, 'isLoading', { visible: false, message: 'Chargement en cours' })
    } else {
      Vue.set(state, 'isLoading', { visible: true, message: isLoading.message })
    }
  },
  SET_STOP_SCROLL(state: State, stopScroll) {
    Vue.set(state, 'stopScroll', stopScroll)
  },
  TOOGLE_NAV(state: State, isNavOpen) {
    Vue.set(state, 'isNavOpen', isNavOpen)
  },
  SET_JURIDICS_INFO(state: State, juridicsInfo) {
    Vue.set(state, 'juridicsInfo', juridicsInfo)
  },
})

const actions = actionTree(
  { state, getters, mutations },
  {
    setIsLoading({ commit }, { visible, message }) {
      commit('SET_LOADING', { visible: visible, message: message })
    },
    setStopScroll({ commit }, payload) {
      commit('SET_STOP_SCROLL', payload)
    },
    toogleNav({ commit }, payload) {
      commit('TOOGLE_NAV', payload)
    },
    fetchJuridicsInfo: async ({ commit, state }) => {
      if (state.juridicsInfo != null) {
        return
      }

      try {
        const json = await api.getClickProxy('Documents_Espace_Client/Juridique/popin-juridique.json')
        commit('SET_JURIDICS_INFO', json)
      } catch (e) {
        console.error('error loading json', e)
      }
    },
  },
)

export const storePattern = {
  strict: !['preProd', 'production'].includes(tagCoEnv),
  state,
  getters,
  mutations,
  actions,
  modules: {
    uTR: storeUTR,
    beneficiariesList,
    preOrder: preOrder,
    paginations,
    basket,
    imports,
    auth,
    session,
  },
  plugins: [persisteStore],
}

Vue.use(Vuex)

const store = new Vuex.Store(storePattern)

export const accessor = useAccessor(store, storePattern)
export const mapper = createMapper(accessor)

Vue.prototype.$accessor = accessor

export default store
