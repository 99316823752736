export default {
  csv: {
    headers: {
      booklets: 'Titres',
      boards: 'Carnets',
      cards: 'Cartes',
      sendDate: "Date d'expédition",
      tracking: 'Suivi',
      trackingUrl: 'Suivi url',
      address: 'Adresse',
      delivery: 'Point de livraison',
      reasonReturn: 'Raison retour',
      dateReturn: 'Date de retour',
      ve: 'VE',
      emittedCard: 'Carte Emises',
      nbTitle: 'Nb Titre',
      vt: 'Valeur du titre',
      loadingStatus: 'Statut Chargement',
      parcelStatus: 'Statut Colis',
      reference: 'Matricule',
      lastName: 'Nom',
      firstName: 'Prénom',
      orderReference: 'Référence de commande',
    },
    errors: {
      separatorNotExist: 'Problème de séparateur',
      wrongSeparator: 'Le fichier CSV doit être séparé par une virgule ou un point-virgule',
      fileNotExist: "Le fichier n'existe pas",
      parseError: "Le fichier n'a pas pu être analysé. Veuillez vérifier qu'il est au bon format",
    },
  },
}
