const persistMap = []
const SEPARTOR = '/'
let isGlobalPersist = false
const ROOT_NAME = 'root'
export const INITIALIZE_ACTION_NAME = 'initializeState'

function getPersistantKey(mutationName) {
  const persistKey = persistMap.find((key) => new RegExp(mutationName).test(key))
  if (!persistKey) {
    return null
  }
  const splittedKEy = mutationName.split(SEPARTOR)
  return splittedKEy[0]
}

function initialiseStore(name, module, callback) {
  const persistData = { ...JSON.parse(sessionStorage.getItem(name)) }
  if (!persistData || !Object.keys(persistData).length) {
    return
  }
  if (!module?.actions[INITIALIZE_ACTION_NAME]) {
    console.error(`Persist store ${name} can't be initialize because Action ${INITIALIZE_ACTION_NAME} not found`)
    return
  }
  callback(`${name === ROOT_NAME ? '' : `${name}/`}${INITIALIZE_ACTION_NAME}`, persistData)
}

export default (store) => {
  if (store._modules.root._rawModule.persistent) {
    isGlobalPersist = true
    initialiseStore(ROOT_NAME, store._modules.root._rawModule, store.dispatch)
  } else {
    Object.entries(store._modules.root._rawModule.modules).forEach(([name, module]) => {
      let isPersistant = false
      if (module?.persistent) {
        isPersistant = true
        Object.keys(module.mutations).forEach((mutation) => persistMap.push(`${name}${SEPARTOR}${mutation}`))
      }

      if (!isPersistant) {
        return
      }

      initialiseStore(name, module, store.dispatch)
    })
  }

  store.subscribe((mutation, state) => {
    const persistModule = getPersistantKey(mutation.type)
    if (persistModule || isGlobalPersist) {
      const storageName = isGlobalPersist ? ROOT_NAME : persistModule
      const stateToSave = isGlobalPersist ? state : state[persistModule]
      sessionStorage.setItem(storageName, JSON.stringify(stateToSave))
    }
  })
}
