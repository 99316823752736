import type { AxiosInstance } from 'axios'
import { Result } from '@badrap/result'
import type { ApiAxiosError } from '../common/errors'
import type { Pagination } from '../common/types'
import type { BillingPoint } from './types'
import type { ProductCode } from '@/variables/ProductCode'

const baseUrl = '/billingPoints'

export default (axiosInstance: AxiosInstance) => {
  const getBillingPoints = async (productCode: ProductCode, page = 1): Promise<Result<Pagination<BillingPoint>>> => {
    try {
      const result = await axiosInstance.get<BillingPoint[]>(baseUrl, {
        params: { productCode, page },
      })

      return Result.ok({
        pageIndex: page,
        pageSize: 20,
        totalElements: parseInt(result.headers['x-total-count'], 10),
        items: result.data,
      })
    } catch (error) {
      return Result.err(error as ApiAxiosError)
    }
  }

  return {
    getBillingPoints,
  }
}
