import type { AxiosInstance } from 'axios'
import fpCompose from 'lodash/fp/compose'
import fpEach from 'lodash/fp/each'
import fpFilter from 'lodash/fp/filter'
import fpMap from 'lodash/fp/map'

import type { ApiConfigFile, ApiEndpoints, ApiEndpoint, ApiMethods } from './types'

const composeApis = (mapList: ApiEndpoint[], instance: AxiosInstance) => {
  const moduleObject: ApiMethods = {}

  fpCompose(
    fpEach((module: ApiMethods) => {
      Object.assign(moduleObject, module)
    }),
    fpFilter((a) => a),
    fpMap((api: ApiEndpoint) => api(instance)),
  )(mapList)

  return moduleObject
}

const getApisForInstance = (apis: ApiEndpoints): ApiEndpoint[] => {
  const apiModules: ApiEndpoint[] = []

  for (const api in apis) {
    const apiModule = apis[api]
    apiModules.push(apiModule)
  }

  return apiModules
}

export default (configFile: ApiConfigFile): ApiMethods => {
  const apiObject: ApiMethods = {}

  for (const apiName in configFile) {
    const apiConfig = configFile[apiName]

    if (Object.keys(apiConfig).includes('instance')) {
      if (Object.keys(apiConfig).includes('apis')) {
        const apiEndpoints = getApisForInstance(apiConfig.apis)
        Object.assign(apiObject, composeApis(apiEndpoints, apiConfig.instance))
      } else {
        console.error(`no apis found for instance ${apiName}`)
      }
    }
  }

  return apiObject
}
