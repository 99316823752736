import { Result } from '@badrap/result'
import type { AxiosInstance, AxiosResponse } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import {
  ApiAuthnError,
  ApiAuthnExpiredSessionError,
  ApiAuthnInvalidCodeError,
  ApiAuthnInvalidCodeFormatError,
  ApiAuthnAccountLockedError,
  ApiAuthnErrorType,
} from './errors'
import type { ApiAuthnSessionsSents, ApiAuthnSessionsCode, ApiAuthnTokens } from './types'

export default (axiosInstance: AxiosInstance) => {
  const sessionSents = async (sessionId: string) => {
    try {
      return Result.ok<AxiosResponse<ApiAuthnSessionsSents>, ApiAxiosError>(
        await axiosInstance.post('/authn/sessions/sents', { sessionId }),
      ).map((value) => value.data)
    } catch (error) {
      return Result.err(error as ApiAxiosError)
    }
  }

  const sessionCode = async (
    code: string,
    sessionId: string,
    saveDevice: boolean,
    bypassFactorValidation = false,
  ): Promise<Result<ApiAuthnTokens, ApiAuthnError>> => {
    try {
      const result = await axiosInstance.post<ApiAuthnSessionsCode>('/authn/sessions/code', {
        sessionId,
        code,
        saveDevice,
        bypassFactorValidation,
      })

      if (!result.data.IsValid) {
        if (result.data.RemainingAttempts === 0 || result.data.RemainingSessionAttempts === 0) {
          return Result.err(new ApiAuthnAccountLockedError())
        }

        return Result.err(new ApiAuthnInvalidCodeError(result.data))
      }

      if (result.data.Tokens == null) {
        return Result.err(new ApiAuthnError(ApiAuthnErrorType.UNKNOWN, new Error('No tokens')))
      }

      return Result.ok(result.data.Tokens)
    } catch (error) {
      const _error = error as ApiAxiosError
      const statusCode = _error.response?.status ?? -1

      switch (statusCode) {
        case 400:
          return Result.err(new ApiAuthnInvalidCodeFormatError(_error))

        case 404:
          return Result.err(new ApiAuthnExpiredSessionError(_error))

        default:
          return Result.err(new ApiAuthnError(ApiAuthnErrorType.UNKNOWN, _error))
      }
    }
  }

  return {
    sessionSents,
    sessionCode,
  }
}
