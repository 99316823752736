import type { Image } from '@/types/Image'
import { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'

export function fetchProductImage(productCode: ProductCode, articleCode?: ArticleCode, isMixed = false): string {
  switch (productCode) {
    case ProductCode.CARTE_TICKET_RESTAURANT: {
      if (isMixed) {
        return 'mixte'
      }
      return 'ctr'
    }
    case ProductCode.TICKET_RESTAURANT:
      return 'tr'
    case ProductCode.KADEOS: {
      switch (articleCode) {
        case ArticleCode.KADEOS_CULTURE:
          return 'kscul'
        case ArticleCode.KADEOS_INFINI:
        case ArticleCode.KADEOS_INFINI_DOM:
          return 'ksinf'
        case ArticleCode.KADEOS_SELECT:
          return 'kssel'
      }
      return 'ksmulti'
    }
    case ProductCode.KADEOS_CONNECT:
      return 'ksc'
    case ProductCode.KADEOS_UNIVERSEL:
      return 'cksuni'
    case ProductCode.CARTE_KADEOS: {
      switch (articleCode) {
        case ArticleCode.CARTE_KADEOS_CULTURE:
          return 'ckscul'
        case ArticleCode.CARTE_KADEOS_ZENITH_CE:
        case ArticleCode.CARTE_KADEOS_ZENITH_DE:
          return 'ckszen'
      }

      return 'cksmulti'
    }
    case ProductCode.TICKET_SERVICE:
      return 'ts'
    case ProductCode.TICKET_CESU:
      return 'cesu'
    case ProductCode.TICKET_CAP:
      return 'tscap'
    case ProductCode.TICKET_RESTAURANT_BENEVOLES_VOLONTAIRE:
      return 'tr'
    case ProductCode.TICKET_FLEETPRO:
      return 'tfp'
    case ProductCode.BETTERWAY:
      return 'tm'

    default:
      return 'no-visuel'
  }
}

export const noVisuelImage: Image = {
  src: new URL(`/src/assets/img/cart/product/no-visuel.png`, import.meta.url).href,
  srcset: {
    '2x': new URL(`/src/assets/img/cart/product/no-visuel@2x.png`, import.meta.url).href,
    '3x': new URL(`/src/assets/img/cart/product/no-visuel@3x.png`, import.meta.url).href,
  },
  alt: 'no-visuel',
}
