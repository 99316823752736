import type { Result } from '@badrap/result'
import type { Ref, AsyncComponent } from 'vue'
import type { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'
import type { Cart } from '@/services/carts/typesV2'
import type { DeliveryPointType } from '@/variables/DeliveryPointType'
import type { ActionConstructor, Action } from '@/types/Action'
import type { Image } from '@/types/Image'
import type { DataStoreKey } from '@/services/dataStore/types'
import type { CFProduct } from '@/services/contentful/types/CFProduct'
import type { CFEvent } from '@/services/contentful/types/CFEvent'
import type { CFImportField } from '@/services/contentful/types/CFImportField'

export type CartState = {
  carts: FrontCarts
  events: CFEvent[] | null
  importFields: CFImportField[] | null
  products: CFProduct[] | null
}

export type EditCart = {
  localId: string
  meta: EditCartMeta
}

export type LocalValueOfTitle = {
  isMulti: boolean
  value: number
  employersContribution: number
  save: boolean
}

export type LocalDistributionPoint = {
  id: number
  reference: string | null
}

export type LocalDeliveryPoint = {
  id: number | null
  reference: string | null
  distributionPoint?: LocalDistributionPoint
  type: DeliveryPointType
  isMulti?: boolean
  isMultiDistributionPoint?: boolean
  save: boolean
}

export type LocalDeliveryPoints = Record<number, LocalDeliveryPoint>

export type EditCartMeta = {
  reference?: string
  isHomeDelivery?: boolean
  dataStore?: FrontCartDataStore
}

export type FrontCarts = Record<string, FrontCart>

export enum FrontCartStatus {
  New = 'new',
  Filling = 'filling',
  ImportDedupe = 'importDedupe',
  Loading = 'loading',
  Paying = 'paying',
  Done = 'done',
}

export enum FrontCartType {
  Local = 'local',
  Cart = 'cart',
  PreOrder = 'preOrder',
}

export type RemoteCartType = FrontCartType.Cart | FrontCartType.PreOrder

export type FrontCart = LocalCart | RemoteCart

export interface FrontCartMeta {
  productCode: ProductCode
  articleCode: ArticleCode | null
  discountCode: string | null
  isPFA: boolean
  isNominative: boolean
  isMixed: boolean
  isMultiLoading: boolean
  isPlasticless: boolean
  isCrossSell: boolean
  isHomeDelivery: boolean | null
  isBenefitsCalculator: boolean | null
  dataStore: FrontCartDataStore
  visual: Image
}

export interface LocalEvent {
  event: CFEvent | null
  isMulti: boolean
}

export interface FrontCartDataStore {
  [DataStoreKey.Event]: LocalEvent | null
  [DataStoreKey.Amount]: LocalAmount | null
  [DataStoreKey.ValueOfTitle]: LocalValueOfTitle | null
  [DataStoreKey.DeliveryPoint]: LocalDeliveryPoints | null
}

export interface LocalAmount {
  isMulti: boolean
  amount: number
}

export interface CommonCart {
  localId: string
  createdAt: Date
  status: FrontCartStatus
  meta: FrontCartMeta
  terms: string
  howTo: string | null
}

export interface LocalCart extends CommonCart {
  type: FrontCartType.Local
}

export interface RemoteCart extends CommonCart {
  type: RemoteCartType
  remote: Cart
}

export enum ModuleType {
  Popin = 'popin',
  Drawer = 'drawer',
}

export interface ModuleConfig {
  id: string
  type: ModuleType
  component: AsyncComponent
}

export interface Module<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useWorkflow(record?: Record<string, any>): T
}

export interface ModuleBuilder<T> {
  config: ModuleConfig
  isAllowed(cart: RemoteCart): boolean
  useModule(cart: Ref<RemoteCart | null>, workflow: CartWorkflow, useAction: UseAction): Module<T>
}

export interface StepWorkflow {
  prevAction?: Action | null
  nextAction?: Action | null
  showHowTo: boolean
}

export enum Block {
  Hidden = 'hidden',
  Fixed = 'fixed',
  Collapsable = 'collapsable',
}

export interface StepConfig {
  id: string
  name: string
  path: string
  blocks: {
    header: Block
  }
}

export interface StepBuilder<T extends StepWorkflow> {
  config: StepConfig
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prepareStep?: (cart: RemoteCart) => Promise<Result<Record<string, any>, Error>>
  isAllowed(cart: RemoteCart): boolean
  useStep(
    cart: RemoteCart,
    workflow: CartWorkflow,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    record: Record<string, any>,
    useAction: (actionConstructor: ActionConstructor) => Action,
  ): Step<T>
}

export interface Step<T extends StepWorkflow> {
  config: StepConfig
  component: AsyncComponent
  useWorkflow: () => T
}

export type UseHeader = (
  cart: RemoteCart,
  workflow: CartWorkflow,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  record: Record<string, any>,
  useAction: (actionConstructor: ActionConstructor) => Action,
) => Header

export interface CartWorkflow {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  steps: StepBuilder<any>[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modules: ModuleBuilder<any>[]
  useHeader: UseHeader
  isValid(cart: FrontCart): boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prepareCart?(cart: RemoteCart): Promise<Result<Record<string, any>, Error>>
}

export interface CartPackage<T extends StepWorkflow> {
  cart: RemoteCart
  readonly: Ref<boolean>
  step: Step<T>
  workflow: CartWorkflow
  header: Header
}

export interface Tag {
  label: string
  icon: string
  tooltip: string | null
  values: Record<string, string | number | undefined | null>
}

export interface HeaderContentProp {
  cartId: number
  label: string
  titlePopup: string
  value: string | null
  editable: boolean
  required: boolean
  tag: Tag | null
}

export interface Header {
  title: string
  subTitle: string | null
  steppers: HeaderStep[]
  contents: HeaderContentProp[]
  editReferenceAction: (content: HeaderContentProp) => Action
}

export interface HeaderStep {
  id: string
  steps: string[]
  number: number
  label: string
  icon: string
}

export type UseAction = (actionConstructor: ActionConstructor) => Action

export function getHeaderTitleDetail(cart: FrontCart): string | null {
  if (cart.type == FrontCartType.PreOrder) {
    return 'cart.header.titleDetail.preOrder'
  }

  if (cart.meta.isPlasticless) {
    return 'cart.header.titleDetail.plasticless'
  }

  if (cart.meta.isMixed) {
    return 'cart.header.titleDetail.mixed'
  }

  if (cart.meta.productCode === ProductCode.CARTE_TICKET_RESTAURANT) {
    return 'cart.header.titleDetail.ctr'
  }

  return null
}
