import { moduleConfig, type ImportOrAddWorkflow } from '@/components/Cart/Module/ImportOrAdd/workflow'
import type { ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import { stepConfig as importStepConfig } from '@/components/Cart/Step/Import/workflow'
import addBeneficiary from '@/store/cart/cartWorkflows/common/module/addBeneficiary'

const importOrAdd: ModuleBuilder<ImportOrAddWorkflow> = {
  config: moduleConfig,
  isAllowed: () => true,
  useModule(cartRef, _workflow, useAction) {
    if (cartRef.value === null) {
      throw new Error('cart.errors.cartNotFound')
    }

    return {
      useWorkflow() {
        return {
          importChoice: {
            id: 'import-choice',
            label: 'cart.modules.importOrAdd.import.title',
            description: 'cart.modules.importOrAdd.import.description',
            image: {
              src: new URL('/src/assets/img/cart/choices/import.png', import.meta.url).href,
              srcset: {
                '2x': new URL('/src/assets/img/cart/choices/import@2x.png', import.meta.url).href,
                '3x': new URL('/src/assets/img/cart/choices/import@3x.png', import.meta.url).href,
              },
              alt: 'Import',
            },
            action: useAction({
              id: importStepConfig.id,
              type: ActionType.Step,
              name: 'cart.modules.importOrAdd.import.button',
              refresh: false,
              payload: {
                key: 'importFile',
              },
            }),
          },
          addChoice: {
            id: 'add-choice',
            label: 'cart.modules.importOrAdd.add.title',
            description: 'cart.modules.importOrAdd.add.description',
            image: {
              src: new URL('/src/assets/img/cart/choices/add.png', import.meta.url).href,
              srcset: {
                '2x': new URL('/src/assets/img/cart/choices/add@2x.png', import.meta.url).href,
                '3x': new URL('/src/assets/img/cart/choices/add@3x.png', import.meta.url).href,
              },
              alt: 'Add',
            },
            action: useAction({
              id: addBeneficiary.config.id,
              name: 'cart.modules.importOrAdd.add.button',
              type: ActionType.Drawer,
              refresh: false,
              payload: {
                key: 'addBeneficiary',
                nbItems: 0,
              },
            }),
          },
        }
      },
    }
  },
}

export default importOrAdd
