import type { RouteConfig } from 'vue-router'
import PrecommandeRouter from './_subs/Precommande'
import ticketKadeosRouter from './_subs/TicketKadeos'
import ticketRestaurantRouter from './_subs/TicketRestaurant'
import ticketServiceRouter from './_subs/TicketService'
import pages from '@/assets/constantes/pages.json'
import habilitations from '@/variables/habilitations.json'

const ChildView = () => import('@/views/DefaultRouter/Child/index.vue')
const SepaValidation = () => import('@/views/Admin/PaymentMeans_old/SepaValidation.vue')
const Waiting = () => import('@/views/Admin/PaymentMeans_old/Waiting.vue')

export const router: RouteConfig = {
  path: pages.COMMANDE.path,
  // TODO: replace by RootView when migrate univers
  component: ChildView,
  name: pages.COMMANDE.name,
  redirect: '/accueil',
  children: [
    ticketRestaurantRouter,
    PrecommandeRouter,
    ticketKadeosRouter,
    ticketServiceRouter,
    {
      path: 'sepa',
      name: 'SepaValidation',
      component: SepaValidation,
      meta: {
        refAbility: [habilitations.SETTINGS.PAYMENT_PRELEVEMENT],
        breadcrumb: 'Passage au prélèvement',
      },
      props: {},
    },
    {
      path: 'completed/',
      name: 'signSuccess',
      component: Waiting,
    },
    {
      path: 'aborted/',
      name: 'signAbort',
      component: Waiting,
    },
  ],
}
