import type { Asset } from 'contentful'

export type CFMedia = {
  title?: string | null
  description?: string | null
  src?: string | null
}

export function mapMediaToLocal(image: Asset<undefined, string>): CFMedia {
  return {
    title: image.fields.title,
    description: image.fields.description,
    src: image.fields.file?.url ? `https:${image.fields.file.url}` : null,
  }
}

export function mapMediasToLocal(images: Asset<undefined, string>[]): CFMedia[] {
  return images.map((image) => mapMediaToLocal(image))
}
