import { type ModuleConfig, ModuleType } from '@/store/cart/model'
import type { ChoiceCard } from '@/types/ChoiceCard'

export const moduleConfig: ModuleConfig = {
  id: 'import-or-add-modal',
  type: ModuleType.Popin,
  component: () => import('@/components/Cart/Module/ImportOrAdd/index.vue'),
}

export interface ImportOrAddWorkflow {
  importChoice: ChoiceCard
  addChoice: ChoiceCard
}
