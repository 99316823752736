import { mutationTree } from 'typed-vuex'
import type { PreorderType } from '@/services/preOrders/apis/types'

type PreOrderState = {
  ref: string | null
  type: PreorderType | null
  validationRequired: boolean | null
  withImputationCalendar: boolean | null
  postponementMonths: number | null
  period: unknown[]
  redCartId: number | null
  paymentMean: string | null
}

export const namespaced = true

export const state: PreOrderState = {
  ref: null,
  type: null,
  validationRequired: null,
  withImputationCalendar: null,
  postponementMonths: null,
  period: [],
  redCartId: null,
  paymentMean: null,
}

export const mutations = mutationTree(state, {
  setPreOrderDetailRef(state: PreOrderState, ref: string) {
    state.ref = ref
  },
  setPreorderType(state: PreOrderState, type: PreorderType) {
    state.type = type
  },
  setPrecoValidationRequired(state: PreOrderState, validationRequired: boolean | null) {
    state.validationRequired = validationRequired
  },
  setPeriodStep(state: PreOrderState, period: unknown[]) {
    state.period = period
  },
  setRedCartPreOrderId(state: PreOrderState, redCartId: number) {
    state.redCartId = redCartId
  },
  setPaymentMean(state: PreOrderState, paymentMean: string) {
    state.paymentMean = paymentMean
  },
  setWithImputationCalendar(state: PreOrderState, withImputationCalendar: boolean | null) {
    state.withImputationCalendar = withImputationCalendar
  },
  setPostPonementMonths(state: PreOrderState, postponementMonths: number | null) {
    state.postponementMonths = postponementMonths
  },
})
