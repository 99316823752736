import type { AxiosError } from 'axios'
import { ApiError, type ApiAxiosError } from '../common/errors'
import { ResultError, UNKNOWN_ERROR, UNKNOWN_ERROR_MESSAGE } from '@/types/ResultError'
import type { ImportErrorDetail } from '@/types/Import'

export const errorMessage: Record<string, string> = {}

export function getError(key: string): string {
  return errorMessage[key] ?? 'errors.unknown.description'
}

export class CartsError extends ApiError {
  constructor(error: ApiAxiosError) {
    super(error)

    this.name = 'CartsError'
    this.message = getError(this.code)

    if (error.response?.data.title === 'InvalidDiscountCode') {
      this.message = `cart.errors.${error.response.data.title}`
    } else if (error.response?.data.title === 'BeneficiaryAlreadyExist') {
      this.code = error.response.data.title
      this.message = `cart.errors.${error.response.data.title}`
    }
  }
}

export const bankResponseErrorType: Record<string, string> = {
  '99': 'Le service est temporairement indisponible. Merci de réssayer plus tard.',
}

export const acquirerResponseErrorType: Record<string, string> = {
  '14': 'La carte utilisée est invalide. Seules les cartes françaises et 3DSecure sont acceptées.',
  '33': 'Paiement CB refusé par votre banque, date validité de la carte dépassé (code erreur 33)',
  '41': 'Paiement CB refusé par votre banque, carte déclarée perdue (code erreur 41)',
  '43': 'Paiement CB refusé par votre banque, carte déclarée volée (code erreur 43)',
  '51': 'Paiement CB refusé par votre banque pour provision insuffisante (code erreur 51)',
  '54': 'Paiement CB refusé par votre banque, date de validité dépassée (code erreur 54)',
  '61': 'Paiement CB refusé par votre banque, montant limite de retrait dépassé (code erreur 61)',
}

export class BankResponseError extends Error {
  constructor(responseCode: string, acquirerResponseCode: string | null) {
    let message: string

    if (acquirerResponseCode) {
      // If we have an acquirerResponseCode, it means that the payment has been refused by the client bank
      message =
        acquirerResponseErrorType[acquirerResponseCode] ??
        `Paiement CB refusé par votre banque (code erreur ${acquirerResponseCode})`
    } else {
      // If not, it means that it's been refused for a technical error
      message = bankResponseErrorType[responseCode] ?? `Une erreur technique est survenue. Merci de réessayer.`
    }

    super(message)

    this.name = 'BankResponseError'
  }
}

export const cartsPaymentsErrorType: Record<string, string> = {
  InvalidCreditCard: 'La carte utilisée est invalide. Seules les cartes françaises et 3DSecure sont acceptées.',
  BankTransactionException: 'Une erreur est survenue lors du paiement. Veuillez réessayer.',
  InitPaymentFailed:
    'Ce moyen de paiement est temporairement indisponible. Nous vous invitons à en choisir un autre ou à revenir plus tard.',
}

export class CartsPaymentsError extends ApiError {
  type: string

  constructor(error: ApiAxiosError) {
    const errorTypeApi: string | undefined = error.response?.data?.type
    const errorType = errorTypeApi ?? UNKNOWN_ERROR

    const codeApi: string | undefined = error.response?.data?.title
    const code = codeApi ?? UNKNOWN_ERROR

    super(error)

    this.name = 'CartsPaymentsError'
    this.type = errorType
    this.code = code

    switch (this.type) {
      case 'BankTransactionException':
        this.message = bankResponseErrorType[code] ?? `Paiement CB refusé par votre banque (code erreur ${code})`
        break

      default:
        this.message = cartsPaymentsErrorType[code] ?? UNKNOWN_ERROR_MESSAGE
    }
  }
}

export class CartImportError extends ResultError {
  details: ImportErrorDetail[]

  constructor(error: Error, details: ImportErrorDetail[] = []) {
    super(error)

    this.name = 'CartImportError'
    this.details = details
  }
}

export type ApiCartsError = AxiosError<{ Type?: string; Title?: string; Errors?: string[] }>

export class CartsCreationError extends ResultError {
  constructor(error: ApiCartsError) {
    super(error)

    this.name = 'CartsCreationError'
    this.message = error.response?.data.Errors?.join('<br />') ?? 'errors.unknown.description'
  }
}
