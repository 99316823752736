import formPost from './formPost'
import type { PaymentsResponse } from '@/services/carts/types'

export function formPostPayment(paymentsResponse: PaymentsResponse) {
  formPost(document, paymentsResponse.redirectionUrl, {
    PaReq: paymentsResponse.meta.paReqMessage,
    TermUrl: paymentsResponse.meta.termUrl,
    MD: paymentsResponse.meta.key,
  })
}
