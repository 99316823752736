import type { ApiAxiosError } from '../common/errors'
import type { ApiAuthnSessionsCode } from './types'
import { ResultError } from '@/types/ResultError'

export enum ApiAuthnErrorType {
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  INVALID_CODE = 'INVALID_CODE',
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
  UNKNOWN = 'UNKNOWN',
}

export class ApiAuthnError extends ResultError {
  code: ApiAuthnErrorType

  constructor(code: ApiAuthnErrorType, error: Error) {
    super(error)

    this.code = code
  }
}

export class ApiAuthnExpiredSessionError extends ApiAuthnError {
  constructor(error: ApiAxiosError) {
    super(ApiAuthnErrorType.SESSION_EXPIRED, error)

    this.name = 'ApiAuthnExpiredSessionError'
    this.message = 'Session expirée'
  }
}

export class ApiAuthnInvalidCodeError extends ApiAuthnError {
  RemainingAttempts: number
  RemainingSessionAttempts: number

  constructor(result: ApiAuthnSessionsCode) {
    super(ApiAuthnErrorType.INVALID_CODE, new Error('Code invalide'))

    this.RemainingAttempts = result.RemainingAttempts
    this.RemainingSessionAttempts = result.RemainingSessionAttempts

    this.name = 'ApiAuthnInvalidCodeError'
    this.message = 'Code invalide'
  }
}

export class ApiAuthnInvalidCodeFormatError extends ApiAuthnError {
  constructor(error: ApiAxiosError) {
    super(ApiAuthnErrorType.INVALID_CODE, error)

    this.name = 'ApiAuthnInvalidCodeFormatError'
    this.message = 'Session expirée'
  }
}

export class ApiAuthnAccountLockedError extends ApiAuthnError {
  constructor() {
    super(ApiAuthnErrorType.ACCOUNT_LOCKED, new Error('Compte verrouillé'))

    this.name = 'ApiAuthnAccountLockedError'
    this.message = 'Compte verrouillé'
  }
}
