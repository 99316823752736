import _orderBy from 'lodash/orderBy'
import type { FacialValue, FacialValueOption } from '@/types/FacialValue'

export type FacialValueState = {
  facialValues: FacialValue[]
  productCode?: string | null
  vfIsLoaded: boolean
  selectedVF?: FacialValueOption | null
}

export function mapToSelectOption(facialValues: FacialValue[]): FacialValueOption[] {
  const vf: FacialValueOption[] = facialValues.map((vf, i) => ({
    id: vf.Id,
    amount: vf.Amount,
    value: i,
    employersContribution: parseFloat((vf.EmployersContribution * 100).toFixed(2)),
    label: `${vf.Amount / 100 + ' €'} - PP: ${(vf.EmployersContribution * 100).toFixed(2) + '%'}`,
    ppp: vf.EmployersContribution,
  }))

  return _orderBy(vf, [(x: FacialValueOption) => x.amount], 'desc')
}
