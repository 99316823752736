import { Result } from '@badrap/result'
import { type DeleteCartItemWorkflow, moduleConfig } from '@/components/popins/DeleteCartItem/workflow'
import type { ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import apiV4 from '@/services/apiV4'
import type { CartItem } from '@/services/carts/types'
import { CartStatus } from '@/services/carts/typesV2'

const deleteCartItem: ModuleBuilder<DeleteCartItemWorkflow> = {
  config: moduleConfig,
  isAllowed: (cart) => cart.remote.status === CartStatus.Opened,
  useModule(cartRef, _workflow, useAction) {
    if (cartRef.value === null) {
      throw new Error('cart.errors.cartNotFound')
    }

    const { value: cart } = cartRef

    return {
      useWorkflow({ item }: { item: CartItem }) {
        return {
          title: 'cart.deleteCartItem.default.title',
          description: 'cart.deleteCartItem.default.description',
          deleteAction: useAction({
            id: 'delete-item-action',
            name: 'delete',
            type: ActionType.Default,
            refresh: true,
            execute: async () => {
              try {
                await apiV4.carts.deleteItem(cart.remote.id, item.id)

                return Result.ok(true)
              } catch (error) {
                return Result.err(error as Error)
              }
            },
          }),
        }
      },
    }
  },
}

export const deleteCartItemBenefeciary: ModuleBuilder<DeleteCartItemWorkflow> = {
  config: deleteCartItem.config,
  isAllowed: deleteCartItem.isAllowed,
  useModule(cartRef, _workflow, useAction) {
    const module = deleteCartItem.useModule(cartRef, _workflow, useAction)
    return {
      ...module,
      useWorkflow(record) {
        const workflow = module.useWorkflow(record)
        return {
          ...workflow,
          title: 'cart.deleteCartItem.beneficiary.title',
          description: 'cart.deleteCartItem.beneficiary.description',
        }
      },
    }
  },
}

export default deleteCartItem
