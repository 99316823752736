import { Result } from '@badrap/result'
import { useVuelidate } from '@vuelidate/core'
import { helpers, maxLength, requiredIf } from '@vuelidate/validators'
import { ref } from 'vue'
import { type EditReferenceWorkflow, moduleConfig } from '@/components/popins/EditReference/workflow'
import type { ModuleBuilder, HeaderContentProp } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import apiV4 from '@/services/apiV4'

const editReference: ModuleBuilder<EditReferenceWorkflow> = {
  config: moduleConfig,
  isAllowed: () => true,
  useModule(cartRef, _workflow, useAction) {
    if (cartRef.value === null) {
      throw new Error('cart.errors.cartNotFound')
    }

    const { value: cart } = cartRef

    return {
      useWorkflow(record: { content: HeaderContentProp }) {
        const reference = ref(record.content.value ?? undefined)
        const rules = {
          reference: {
            required: helpers.withMessage(
              'cart.referencePopin.errors.required',
              requiredIf(() => record.content.required),
            ),
            maxLength: helpers.withMessage('cart.referencePopin.errors.maxLength', maxLength(50)),
            restrictedCharacters: helpers.withMessage(
              'cart.referencePopin.errors.restrictedCharacters',
              helpers.regex(/^[^;&#]+$/),
            ),
          },
        }

        const v$ = useVuelidate(rules, { reference })

        return {
          title: record.content.titlePopup,
          v$,
          editReference: useAction({
            id: 'edit-reference',
            name: 'cart.header.edit-reference',
            type: ActionType.Default,
            refresh: true,
            async execute() {
              v$.value.$touch()
              if (v$.value.$invalid) {
                return Result.err(new Error(v$.value.$errors[0].$message.toString()))
              }

              const result = await apiV4.carts.patchCart(record.content.cartId, {
                reference: reference.value ?? null,
                isHomeDelivery: cart.remote.options.isHomeDelivery,
                discountCode: cart.remote.paymentOptions.discountCode,
                deliveryDate: cart.remote.paymentOptions.deliveryDate,
              })

              if (result.isErr) {
                return Result.err(result.error)
              }

              return Result.ok(true)
            },
          }),
        }
      },
    }
  },
}

export default editReference
