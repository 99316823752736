import type { Route } from 'vue-router'
import type { TagEvent } from '../events'
import { isEcommerceEvent } from '../events/commerce'
import type { TrackingProbe } from './types'
import { vueEnv } from '@/parameters'

function dataLayerPush(command: Command) {
  if (window.dataLayer != null) {
    window.dataLayer.push(command)
  } else if (vueEnv !== 'recette') {
    console.error("no dataLayer, can't send event")
  }
}

export function gtm(): TrackingProbe {
  return {
    setMetaData: (metadata: Record<string, unknown>) => {
      dataLayerPush(metadata)
    },
    trackPageView: (to: Route, from: Route | undefined, metadata: Record<string, unknown>) => {
      dataLayerPush({
        event: 'page.display',
        page_title: to.name,
        page_location: to.fullPath,
        page_path: to.path,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        user_id: (metadata.user_data as any)?.id,
        referrer: from?.fullPath,
      })
    },
    trackEvent: async (event: TagEvent, route: Route, metadata: Record<string, unknown>) => {
      let eventData = structuredClone(event.data ?? {}) as Record<string, unknown>
      eventData.referrer = route.fullPath

      if (isEcommerceEvent(event.id)) {
        eventData = { event: 'ecommerce', ecommerce: eventData }
      }

      dataLayerPush({
        event: 'generic_event',
        event_name: event.id,
        user_data: metadata.user_data,
        ...eventData,
      })
    },
  }
}
