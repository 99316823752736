import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import type { Pagination } from '../common/types'
import { ApiUserError } from './errors'
import type {
  User,
  FetchUsersParams,
  PatchData,
  RoleLight,
  Role,
  RoleData,
  FetchRolesParams,
  RestrictionLight,
  Restriction,
  FetchRestrictionsParams,
  DeliveryPoint,
  RestrictionData,
  PostData,
  SecretAnswer,
} from './types'
import type { ProductCode } from '@/variables/ProductCode'

export default (axiosInstance: AxiosInstance) => {
  const fetchUsers = async (params: FetchUsersParams) => {
    try {
      const result = await axiosInstance.get<User[]>('/management/users', { params })

      const total = result.headers['x-total-count']
      const paginateUser: Pagination<User> = {
        items: result.data,
        pageIndex: params.page ?? 1,
        pageSize: params.page ?? 50,
        totalElements: total ? parseInt(total) : 0,
      }

      return Result.ok(paginateUser)
    } catch (error) {
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const fetchUser = async (userId: number) => {
    try {
      return Result.ok((await axiosInstance.get<User>(`/management/users/${userId}`)).data)
    } catch (error) {
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const patchUser = async (userId: number, data: PatchData) => {
    try {
      const result = await axiosInstance.patch<User>(`/management/users/${userId}`, data)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const postUser = async (data: PostData) => {
    try {
      const result = await axiosInstance.post(`/management/users`, data)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const fetchRoles = async (params: FetchRolesParams) => {
    try {
      const result = await axiosInstance.get<RoleLight[]>('/management/roles', { params })

      const total = result.headers['x-total-count']
      const paginateUser: Pagination<RoleLight> = {
        items: result.data,
        pageIndex: params.page ?? 1,
        pageSize: params.page ?? 50,
        totalElements: total ? parseInt(total) : 0,
      }

      return Result.ok(paginateUser)
    } catch (error) {
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const fetchRole = async (roleId: number) => {
    try {
      return Result.ok((await axiosInstance.get<Role | null>(`/management/roles/${roleId}`)).data)
    } catch (error) {
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const patchRole = async (roleId: number, data: RoleData) => {
    try {
      const result = await axiosInstance.patch<Role>(`/management/roles/${roleId}`, data)
      return Result.ok<Role, ApiUserError>(result.data)
    } catch (error) {
      return Result.err<ApiUserError>(new ApiUserError(error as ApiAxiosError))
    }
  }

  const postRole = async (data: RoleData) => {
    try {
      const result = await axiosInstance.post<Role>('/management/roles', data)
      return Result.ok<Role, ApiUserError>(result.data)
    } catch (error) {
      console.error(error)
      return Result.err<ApiUserError>(new ApiUserError(error as ApiAxiosError))
    }
  }

  const deleteRole = async (roleId: number) => {
    try {
      const result = await axiosInstance.delete(`/management/roles/${roleId}`)
      return Result.ok(result.data)
    } catch (error) {
      console.error(error)
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const fetchRestrictions = async (params: FetchRestrictionsParams) => {
    try {
      const result = await axiosInstance.get<RestrictionLight[]>('/management/restrictions', { params })

      const total = result.headers['x-total-count']
      const paginateUser: Pagination<RestrictionLight> = {
        items: result.data,
        pageIndex: params.page ?? 1,
        pageSize: params.page ?? 50,
        totalElements: total ? parseInt(total) : 0,
      }

      return Result.ok(paginateUser)
    } catch (error) {
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const fetchRestriction = async (restrictionId: number) => {
    try {
      return Result.ok((await axiosInstance.get<Restriction>(`/management/restrictions/${restrictionId}`)).data)
    } catch (error) {
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const patchRestriction = async (restrictionId: number, data: RestrictionData) => {
    try {
      const result = await axiosInstance.patch<Restriction>(`/management/restrictions/${restrictionId}`, data)
      return Result.ok(result.data)
    } catch (error) {
      console.error(error)
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const postRestriction = async (data: RestrictionData) => {
    try {
      const result = await axiosInstance.post<Restriction>('/management/restrictions', data)
      return Result.ok(result.data)
    } catch (error) {
      console.error(error)
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const deleteRestriction = async (restrictionId: number) => {
    try {
      await axiosInstance.delete(`/management/restrictions/${restrictionId}`)
      return Result.ok(true)
    } catch (error) {
      console.error(error)
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const fetchDeliveryPoints = async () => {
    try {
      return Result.ok((await axiosInstance.get<DeliveryPoint[]>('/management/deliverypoints')).data)
    } catch (error) {
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const fetchDeliveryPoint = async (productCode: ProductCode) => {
    try {
      return Result.ok((await axiosInstance.get<DeliveryPoint[]>(`/management/deliverypoints/${productCode}`)).data)
    } catch (error) {
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  const getSecretAnswer = async (userId: number) => {
    try {
      const result = await axiosInstance.get<SecretAnswer>(`/management/users/${userId}/secretanswer`)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new ApiUserError(error as ApiAxiosError))
    }
  }

  return {
    fetchUsers,
    fetchUser,
    patchUser,
    postUser,
    fetchRoles,
    fetchRole,
    patchRole,
    postRole,
    deleteRole,
    fetchRestrictions,
    fetchRestriction,
    patchRestriction,
    postRestriction,
    deleteRestriction,
    fetchDeliveryPoints,
    fetchDeliveryPoint,
    getSecretAnswer,
  }
}
