import basket from '../../../common/steps/basket'
import type { BasketWorkflow } from '@/components/Cart/Step/Basket/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import { moduleTRConfig } from '@/components/Cart/Module/AddItem/workflow'
import { useCommonBasketItemWorkflow } from '@/store/cart/cartWorkflows/common/steps/basket'

const step: StepBuilder<BasketWorkflow> = {
  ...basket,

  useStep(cart, workflow, record, useAction) {
    const useStep = basket.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const useWorkflow = useStep.useWorkflow()

        useWorkflow.table.value.rowComponent = () => import('@/components/Cart/Step/Basket/Row/TrARow.vue')
        useWorkflow.table.value.columns = [
          {
            id: 'format',
            label: 'table.headers.format',
          },
          { id: 'quantity', label: 'table.headers.quantity' },
          { id: 'decomposition', label: 'table.headers.decomposition' },
          { id: 'total', label: 'table.headers.total' },
          { id: 'delivery', label: 'table.headers.delivery' },
          { id: 'more', label: '' },
        ]

        const commonBasketItemWorkflow = useCommonBasketItemWorkflow(cart, workflow, record, useAction)

        return {
          ...useWorkflow,
          hasAdd: true,
          hasImport: false,
          showSettings: false,
          emptyDescription: 'cart.tr.anonymousEmptyDescription',
          addAction: useAction({
            id: moduleTRConfig.id,
            type: ActionType.Drawer,
            name: 'cart.button.addProduct',
            icon: 'add-circle',
            refresh: false,
          }),
          useItemWorkflow: (item) => {
            return {
              ...commonBasketItemWorkflow(item),
              updateAction: (item) => {
                return useAction({
                  id: moduleTRConfig.id,
                  name: 'update',
                  icon: 'edit',
                  type: ActionType.Drawer,
                  payload: { item },
                  refresh: false,
                })
              },
            }
          },
        }
      },
    }
  },
}

export default step
