import type { SelectOption } from './SelectOption'

export type Country = SelectOption

export const DEFAULT_COUNTRY: Country = {
  label: 'FRANCE',
  value: 'FR',
}

export const countries: Country[] = [
  {
    label: 'FRANCE',
    value: 'FR',
  },
  {
    label: 'AFGHANISTAN',
    value: 'AF',
  },
  {
    label: 'AFRIQUE DU SUD',
    value: 'ZA',
  },
  {
    label: 'ALBANIE',
    value: 'AL',
  },
  {
    label: 'ALGERIE',
    value: 'DZ',
  },
  {
    label: 'ALLEMAGNE',
    value: 'DE',
  },
  {
    label: 'ANDORRE',
    value: 'AD',
  },
  {
    label: 'ANGOLA',
    value: 'AO',
  },
  {
    label: 'ANGUILLA',
    value: 'AI',
  },
  {
    label: 'ANTARCTIQUE',
    value: 'AQ',
  },
  {
    label: 'ANTIGUA-ET-BARBUDA',
    value: 'AG',
  },
  {
    label: 'ANTILLES NEERLANDAISES',
    value: 'AN',
  },
  {
    label: 'ARABIE SAOUDITE',
    value: 'SA',
  },
  {
    label: 'ARGENTINE',
    value: 'AR',
  },
  {
    label: 'ARMENIE',
    value: 'AM',
  },
  {
    label: 'ARUBA',
    value: 'AW',
  },
  {
    label: 'AUSTRALIE',
    value: 'AU',
  },
  {
    label: 'AUTRICHE',
    value: 'AT',
  },
  {
    label: 'AZERBAIDJAN',
    value: 'AZ',
  },
  {
    label: 'BAHAMAS',
    value: 'BS',
  },
  {
    label: 'BAHREIN',
    value: 'BH',
  },
  {
    label: 'BANGLADESH',
    value: 'BD',
  },
  {
    label: 'BARBADE',
    value: 'BB',
  },
  {
    label: 'BELARUS',
    value: 'BY',
  },
  {
    label: 'BELGIQUE',
    value: 'BE',
  },
  {
    label: 'BELIZE',
    value: 'BZ',
  },
  {
    label: 'BENIN',
    value: 'BJ',
  },
  {
    label: 'BERMUDES',
    value: 'BM',
  },
  {
    label: 'BHOUTAN',
    value: 'BT',
  },
  {
    label: 'BOLIVIE',
    value: 'BO',
  },
  {
    label: 'BOSNIE-HERZEGOVINE',
    value: 'BA',
  },
  {
    label: 'BOTSWANA',
    value: 'BW',
  },
  {
    label: 'BOUVET, ILE',
    value: 'BV',
  },
  {
    label: 'BRESIL',
    value: 'BR',
  },
  {
    label: 'BRUNEI DARUSSALAM',
    value: 'BN',
  },
  {
    label: 'BULGARIE',
    value: 'BG',
  },
  {
    label: 'BURKINA FASO',
    value: 'BF',
  },
  {
    label: 'BURUNDI',
    value: 'BI',
  },
  {
    label: 'CAIMANES, ILES',
    value: 'KY',
  },
  {
    label: 'CAMBODGE',
    value: 'KH',
  },
  {
    label: 'CAMEROUN',
    value: 'CM',
  },
  {
    label: 'CANADA',
    value: 'CA',
  },
  {
    label: 'CAP-VERT',
    value: 'CV',
  },
  {
    label: 'CENTRAFRICAINE, REPUBLIQUE',
    value: 'CF',
  },
  {
    label: 'CHILI',
    value: 'CL',
  },
  {
    label: 'CHINE',
    value: 'CN',
  },
  {
    label: 'CHRISTMAS, ILE',
    value: 'CX',
  },
  {
    label: 'CHYPRE',
    value: 'CY',
  },
  {
    label: 'COCOS (KEELING), ILES',
    value: 'CC',
  },
  {
    label: 'COLOMBIE',
    value: 'CO',
  },
  {
    label: 'COMORES',
    value: 'KM',
  },
  {
    label: 'CONGO',
    value: 'CG',
  },
  {
    label: 'CONGO, LA REPUBLIQUE',
    value: 'CD',
  },
  {
    label: 'COOK, ILES',
    value: 'CK',
  },
  {
    label: 'COREE DU SUD',
    value: 'KR',
  },
  {
    label: 'COREE, REPUBLIQUE POPULAIRE',
    value: 'KP',
  },
  {
    label: 'COSTA RICA',
    value: 'CR',
  },
  {
    label: "COTE D'IVOIRE",
    value: 'CI',
  },
  {
    label: 'CROATIE',
    value: 'HR',
  },
  {
    label: 'CUBA',
    value: 'CU',
  },
  {
    label: 'DANEMARK',
    value: 'DK',
  },
  {
    label: 'DJIBOUTI',
    value: 'DJ',
  },
  {
    label: 'DOMINICAINE, REPUBLIQUE',
    value: 'DO',
  },
  {
    label: 'DOMINIQUE',
    value: 'DM',
  },
  {
    label: 'EGYPTE',
    value: 'EG',
  },
  {
    label: 'EL SALVADOR',
    value: 'SV',
  },
  {
    label: 'EMIRATS ARABES UNIS',
    value: 'AE',
  },
  {
    label: 'EQUATEUR',
    value: 'EC',
  },
  {
    label: 'ERYTHREE',
    value: 'ER',
  },
  {
    label: 'ESPAGNE',
    value: 'ES',
  },
  {
    label: 'ESTONIE',
    value: 'EE',
  },
  {
    label: 'ETATS UNIS',
    value: 'US',
  },
  {
    label: 'ETHIOPIE',
    value: 'ET',
  },
  {
    label: 'FALKLAND, ILES (MALVINAS)',
    value: 'FK',
  },
  {
    label: 'FEROE, ILES',
    value: 'FO',
  },
  {
    label: 'FIDJI',
    value: 'FJ',
  },
  {
    label: 'FINLANDE',
    value: 'FI',
  },
  {
    label: 'GABON',
    value: 'GA',
  },
  {
    label: 'GAMBIE',
    value: 'GM',
  },
  {
    label: 'GEORGIE',
    value: 'GE',
  },
  {
    label: 'GEORGIE DU SUD ET LES ILES SANDWICH DU SUD',
    value: 'GS',
  },
  {
    label: 'GHANA',
    value: 'GH',
  },
  {
    label: 'GIBRALTAR',
    value: 'GI',
  },
  {
    label: 'GRECE',
    value: 'GR',
  },
  {
    label: 'GRENADE',
    value: 'GD',
  },
  {
    label: 'GROENLAND',
    value: 'GL',
  },
  {
    label: 'GUADELOUPE',
    value: 'GP',
  },
  {
    label: 'GUAM',
    value: 'GU',
  },
  {
    label: 'GUATEMALA',
    value: 'GT',
  },
  {
    label: 'GUERNESEY',
    value: 'GG',
  },
  {
    label: 'GUINEE',
    value: 'GN',
  },
  {
    label: 'GUINEE EQUATORIALE',
    value: 'GQ',
  },
  {
    label: 'GUINÉE-BISSAU',
    value: 'GW',
  },
  {
    label: 'GUYANA',
    value: 'GY',
  },
  {
    label: 'GUYANE FRANÇAISE',
    value: 'GF',
  },
  {
    label: 'HAITI',
    value: 'HT',
  },
  {
    label: 'HEARD, ILE ET MCDONALD, ILES',
    value: 'HM',
  },
  {
    label: 'HONDURAS',
    value: 'HN',
  },
  {
    label: 'HONG KONG',
    value: 'HK',
  },
  {
    label: 'HONGRIE',
    value: 'HU',
  },
  {
    label: 'ILE DE MAN',
    value: 'IM',
  },
  {
    label: 'ILES ALAND',
    value: 'AX',
  },
  {
    label: 'ILES MINEURES ELOIGNEES DES ETATS-UNIS',
    value: 'UM',
  },
  {
    label: 'ILES VIERGES BRITANNIQUES',
    value: 'VG',
  },
  {
    label: 'ILES VIERGES DES ETATS-UNIS',
    value: 'VI',
  },
  {
    label: 'INDE',
    value: 'IN',
  },
  {
    label: 'INDONESIE',
    value: 'ID',
  },
  {
    label: "IRAN, RÉPUBLIQUE ISLAMIQUE D'",
    value: 'IR',
  },
  {
    label: 'IRAQ',
    value: 'IQ',
  },
  {
    label: 'IRLANDE',
    value: 'IE',
  },
  {
    label: 'ISLANDE',
    value: 'IS',
  },
  {
    label: 'ISRAEL',
    value: 'IL',
  },
  {
    label: 'ITALIE',
    value: 'IT',
  },
  {
    label: 'JAMAIQUE',
    value: 'JM',
  },
  {
    label: 'JAPON',
    value: 'JP',
  },
  {
    label: 'JERSEY',
    value: 'JE',
  },
  {
    label: 'JORDANIE',
    value: 'JO',
  },
  {
    label: 'KAZAKHSTAN',
    value: 'KZ',
  },
  {
    label: 'KENYA',
    value: 'KE',
  },
  {
    label: 'KIRGHIZISTAN',
    value: 'KG',
  },
  {
    label: 'KIRIBATI',
    value: 'KI',
  },
  {
    label: 'KOWEIT',
    value: 'KW',
  },
  {
    label: 'LAOS, RÉPUBLIQUE DÉMOCRATIQUE POPULAIRE',
    value: 'LA',
  },
  {
    label: 'LESOTHO',
    value: 'LS',
  },
  {
    label: 'LETTONIE',
    value: 'LV',
  },
  {
    label: 'LIBAN',
    value: 'LB',
  },
  {
    label: 'LIBERIA',
    value: 'LR',
  },
  {
    label: 'LIBYENNE, JAMAHIRIYA ARABE',
    value: 'LY',
  },
  {
    label: 'LIECHTENSTEIN',
    value: 'LI',
  },
  {
    label: 'LITUANIE',
    value: 'LT',
  },
  {
    label: 'LUXEMBOURG',
    value: 'LU',
  },
  {
    label: 'MACAO',
    value: 'MO',
  },
  {
    label: "MACÉDOINE, L'EX-RÉPUBLIQUE YOUGOSLAVE DE",
    value: 'MK',
  },
  {
    label: 'MADAGASCAR',
    value: 'MG',
  },
  {
    label: 'MALAISIE',
    value: 'MY',
  },
  {
    label: 'MALAWI',
    value: 'MW',
  },
  {
    label: 'MALDIVES',
    value: 'MV',
  },
  {
    label: 'MALI',
    value: 'ML',
  },
  {
    label: 'MALTE',
    value: 'MT',
  },
  {
    label: 'MARIANNES DU NORD, ILES',
    value: 'MP',
  },
  {
    label: 'MAROC',
    value: 'MA',
  },
  {
    label: 'MARSHALL, ILES',
    value: 'MH',
  },
  {
    label: 'MARTINIQUE',
    value: 'MQ',
  },
  {
    label: 'MAURICE',
    value: 'MU',
  },
  {
    label: 'MAURITANIE',
    value: 'MR',
  },
  {
    label: 'MAYOTTE',
    value: 'YT',
  },
  {
    label: 'MEXIQUE',
    value: 'MX',
  },
  {
    label: 'MICRONESIE, ETATS FEDERES DE',
    value: 'FM',
  },
  {
    label: 'MOLDOVA, REPUBLIQUE DE',
    value: 'MD',
  },
  {
    label: 'MONACO',
    value: 'MC',
  },
  {
    label: 'MONGOLIE',
    value: 'MN',
  },
  {
    label: 'MONTSERRAT',
    value: 'MS',
  },
  {
    label: 'MOZAMBIQUE',
    value: 'MZ',
  },
  {
    label: 'MYANMAR',
    value: 'MM',
  },
  {
    label: 'NAMIBIE',
    value: 'NA',
  },
  {
    label: 'NAURU',
    value: 'NR',
  },
  {
    label: 'NEPAL',
    value: 'NP',
  },
  {
    label: 'NICARAGUA',
    value: 'NI',
  },
  {
    label: 'NIGER',
    value: 'NE',
  },
  {
    label: 'NIGERIA',
    value: 'NG',
  },
  {
    label: 'NIUE',
    value: 'NU',
  },
  {
    label: 'NORFOLK, ILE',
    value: 'NF',
  },
  {
    label: 'NORVEGE',
    value: 'NO',
  },
  {
    label: 'NOUVELLE ZELANDE',
    value: 'NZ',
  },
  {
    label: 'NOUVELLE-CALÉDONIE',
    value: 'NC',
  },
  {
    label: 'OCEAN INDIEN, TERRITOIRE BRITANNIQUE',
    value: 'IO',
  },
  {
    label: 'OMAN',
    value: 'OM',
  },
  {
    label: 'OUGANDA',
    value: 'UG',
  },
  {
    label: 'OUZBEKISTAN',
    value: 'UZ',
  },
  {
    label: 'PAKISTAN',
    value: 'PK',
  },
  {
    label: 'PALAOS',
    value: 'PW',
  },
  {
    label: 'PALESTINIEN OCCUPE, TERRITOIRE',
    value: 'PS',
  },
  {
    label: 'PANAMA',
    value: 'PA',
  },
  {
    label: 'PAPOUASIE-NOUVELLE-GUINÉE',
    value: 'PG',
  },
  {
    label: 'PARAGUAY',
    value: 'PY',
  },
  {
    label: 'PAYS-BAS',
    value: 'NL',
  },
  {
    label: 'PEROU',
    value: 'PE',
  },
  {
    label: 'PHILIPPINES',
    value: 'PH',
  },
  {
    label: 'PITCAIRN',
    value: 'PN',
  },
  {
    label: 'POLOGNE',
    value: 'PL',
  },
  {
    label: 'POLYNESIE FRANÇAISE',
    value: 'PF',
  },
  {
    label: 'PORTO RICO',
    value: 'PR',
  },
  {
    label: 'PORTUGAL',
    value: 'PT',
  },
  {
    label: 'QATAR',
    value: 'QA',
  },
  {
    label: 'REPUBLIQUE DE SERBIE',
    value: 'RS',
  },
  {
    label: 'REPUBLIQUE TCHEQUE',
    value: 'CZ',
  },
  {
    label: 'REUNION',
    value: 'RE',
  },
  {
    label: 'ROUMANIE',
    value: 'RO',
  },
  {
    label: 'ROYAUME-UNI',
    value: 'GB',
  },
  {
    label: 'RUSSIE',
    value: 'RU',
  },
  {
    label: 'RWANDA',
    value: 'RW',
  },
  {
    label: 'SAHARA OCCIDENTAL',
    value: 'EH',
  },
  {
    label: 'SAINT BARTHÉLEMY',
    value: 'BL',
  },
  {
    label: 'SAINT MARTIN',
    value: 'MF',
  },
  {
    label: 'SAINTE-HELENE',
    value: 'SH',
  },
  {
    label: 'SAINTE-LUCIE',
    value: 'LC',
  },
  {
    label: 'SAINT-KITTS-ET-NEVIS',
    value: 'KN',
  },
  {
    label: 'SAINT-MARIN',
    value: 'SM',
  },
  {
    label: 'SAINT-PIERRE-ET-MIQUELON',
    value: 'PM',
  },
  {
    label: 'SAINT-SIEGE (VATICAN)',
    value: 'VA',
  },
  {
    label: 'SAINT-VINCENT-ET-LES GRENADINES',
    value: 'VC',
  },
  {
    label: 'SALOMON, ILES',
    value: 'SB',
  },
  {
    label: 'SAMOA',
    value: 'WS',
  },
  {
    label: 'SAMOA AMERICAINES',
    value: 'AS',
  },
  {
    label: 'SAO TOME-ET-PRINCIPE',
    value: 'ST',
  },
  {
    label: 'SENEGAL',
    value: 'SN',
  },
  {
    label: 'SEYCHELLES',
    value: 'SC',
  },
  {
    label: 'SIERRA LEONE',
    value: 'SL',
  },
  {
    label: 'SINGAPOUR',
    value: 'SG',
  },
  {
    label: 'SLOVAQUIE',
    value: 'SK',
  },
  {
    label: 'SLOVENIE',
    value: 'SI',
  },
  {
    label: 'SOMALIE',
    value: 'SO',
  },
  {
    label: 'SOUDAN',
    value: 'SD',
  },
  {
    label: 'SRI LANKA',
    value: 'LK',
  },
  {
    label: 'SUEDE',
    value: 'SE',
  },
  {
    label: 'SUISSE',
    value: 'CH',
  },
  {
    label: 'SURINAME',
    value: 'SR',
  },
  {
    label: 'SVALBARD ET ILE JAN MAYEN',
    value: 'SJ',
  },
  {
    label: 'SWAZILAND',
    value: 'SZ',
  },
  {
    label: 'SYRIENNE, RÉPUBLIQUE ARABE',
    value: 'SY',
  },
  {
    label: 'TADJIKISTAN',
    value: 'TJ',
  },
  {
    label: 'TAIWAN, PROVINCE DE CHINE',
    value: 'TW',
  },
  {
    label: 'TANZANIE',
    value: 'TZ',
  },
  {
    label: 'TCHAD',
    value: 'TD',
  },
  {
    label: 'TERRES AUSTRALES FRANÇAISES',
    value: 'TF',
  },
  {
    label: 'THAILANDE',
    value: 'TH',
  },
  {
    label: 'TIMOR-LESTE',
    value: 'TL',
  },
  {
    label: 'TOGO',
    value: 'TG',
  },
  {
    label: 'TOKELAU',
    value: 'TK',
  },
  {
    label: 'TONGA',
    value: 'TO',
  },
  {
    label: 'TRINITE-ET-TOBAGO',
    value: 'TT',
  },
  {
    label: 'TUNISIE',
    value: 'TN',
  },
  {
    label: 'TURKMENISTAN',
    value: 'TM',
  },
  {
    label: 'TURKS ET CAIQUES, ILES',
    value: 'TC',
  },
  {
    label: 'TURQUIE',
    value: 'TR',
  },
  {
    label: 'TUVALU',
    value: 'TV',
  },
  {
    label: 'UKRAINE',
    value: 'UA',
  },
  {
    label: 'URUGUAY',
    value: 'UY',
  },
  {
    label: 'VANUATU',
    value: 'VU',
  },
  {
    label: 'VENEZUELA',
    value: 'VE',
  },
  {
    label: 'VIET NAM',
    value: 'VN',
  },
  {
    label: 'WALLIS ET FUTUNA',
    value: 'WF',
  },
  {
    label: 'YEMEN',
    value: 'YE',
  },
  {
    label: 'YOUGOSLAVIE',
    value: 'YU',
  },
  {
    label: 'ZAMBIE',
    value: 'ZM',
  },
  {
    label: 'ZIMBABWE',
    value: 'ZW',
  },
]
