import commonsCartWorkflow from '../CTR'
import stepImport from './steps/import'
import ctrvSetting from '@/store/cart/cartWorkflows/common/module/settings/CTRV'
import type { FrontCart, CartWorkflow } from '@/store/cart/model'
import { ProductCode } from '@/variables/ProductCode'

const steps = commonsCartWorkflow.steps.map((step) => {
  if (step.config.id === stepImport.config.id) {
    return stepImport
  }
  return step
})

const modules = commonsCartWorkflow.modules.map((module) => {
  if (module.config.id === ctrvSetting.config.id) {
    return ctrvSetting
  }
  return module
})

const cartWorkflow: CartWorkflow = {
  ...commonsCartWorkflow,
  steps,
  modules,
  isValid(cart: FrontCart) {
    return cart.meta.productCode === ProductCode.CARTE_TICKET_RESTAURANT && cart.meta.isPlasticless
  },
}

export default cartWorkflow
