import { Result } from '@badrap/result'
import type { ContentfulClientApi } from 'contentful'
import type { TypeImportFieldSkeleton } from '../generated'
import { mapImportFieldsToLocal } from '../types/CFImportField'

export default (contentfulInstance: ContentfulClientApi<undefined>) => {
  const fetchImportFields = async () => {
    try {
      const result = await contentfulInstance.getEntries<TypeImportFieldSkeleton>({
        content_type: 'importField',
      })
      return Result.ok(mapImportFieldsToLocal(result.items))
    } catch (error) {
      return Result.err(error as Error)
    }
  }

  return {
    fetchImportFields,
  }
}
