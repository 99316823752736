import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import type { Pagination, PaginationRequest } from '../common/types'
import type { ImportConflict, ImportDuplicate } from '../beneficiaries/types'
import { CartsError, CartsPaymentsError, type ApiCartsError, CartsCreationError } from './errors'
import type {
  FetchCartItemsParams,
  CreateCartRequest,
  PatchCartRequest,
  CartItem,
  CartItemRequest,
  PaymentsResponse,
  PaymentRequestBody,
  PaymentMean,
  Pricing,
  CommonCartItemRequest,
  PricingSummary,
  DeliveryInformations,
} from './types'
import type { Cart, CartImport, PostImportRequestBody } from './typesV2'
import urlReplacer from '@/services/utils/urlReplacer'
import { useDownloadFile } from '@/composables/useDownloadFile'

const baseUrl = 'api/:version/carts/:cartId'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v2',
  cartId: '',
})
const downloadFile = useDownloadFile()

export default (axiosInstance: AxiosInstance) => {
  const create = async (body: CreateCartRequest) => {
    try {
      const result = await axiosInstance.post<Cart>(getBaseUrl(), body)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsCreationError(error as ApiCartsError))
    }
  }

  const patchCart = async (cartId: number, body: PatchCartRequest) => {
    try {
      const result = await axiosInstance.patch<Cart>(getBaseUrl({ cartId }), body)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const fetchCart = async (cartId: number) => {
    try {
      const result = await axiosInstance.get<Cart>(getBaseUrl({ cartId: cartId.toString() }))
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const fetchItems: (cartId: number, params: FetchCartItemsParams) => Promise<Result<Pagination<CartItem>>> = async (
    cartId,
    params,
  ) => {
    try {
      const result = await axiosInstance.get<Pagination<CartItem>>(`${getBaseUrl({ cartId })}/items`, { params })
      return Result.ok(result.data)
    } catch (error) {
      const e = error as ApiAxiosError
      return Result.err(new CartsError(e))
    }
  }

  const headItems: (cartId: number, params?: FetchCartItemsParams) => Promise<Result<number, CartsError>> = async (
    cartId,
    params,
  ) => {
    try {
      const result = await axiosInstance.head(`${getBaseUrl({ cartId })}/items`, { params })

      if (result.headers['x-total-count'] == null) {
        return Result.ok(0)
      }
      return Result.ok(parseInt(result.headers['x-total-count'], 10))
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const createItem = async (cartId: number, item: CartItemRequest) => {
    try {
      const result = await axiosInstance.post<CartItem>(`${getBaseUrl({ cartId })}/items`, item)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const deleteItem = async (cartId: number, itemId: number) => {
    try {
      const result = await axiosInstance.delete(`${getBaseUrl({ cartId })}/items/${itemId}`)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const postItem = async (cartId: number, item: CartItemRequest) => {
    try {
      const result = await axiosInstance.post<CartItem>(`${getBaseUrl({ cartId })}/items`, item)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const putItem = async (cartId: number, itemId: number, item: CommonCartItemRequest) => {
    try {
      const result = await axiosInstance.put<CartItem>(`${getBaseUrl({ cartId })}/items/${itemId}`, item)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const postItemsBatch = async (cartId: number, items: CommonCartItemRequest[]) => {
    try {
      const result = await axiosInstance.post<CartItem>(`${getBaseUrl({ cartId })}/items/$batch`, items)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const postImport = async (cartId: number, requestBody: PostImportRequestBody) => {
    try {
      const result = await axiosInstance.post<CartImport>(`${getBaseUrl({ cartId })}/imports`, requestBody, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const fetchImport = async (cartId: number, importId: number) => {
    try {
      const result = await axiosInstance.get<CartImport>(`${getBaseUrl({ cartId })}/imports/${importId}`)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const validateImport = async (cartId: number, importId: number) => {
    try {
      const result = await axiosInstance.post<CartImport>(`${getBaseUrl({ cartId })}/imports/${importId}/validate`)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const headCartImportConflicts = async (cartId: number, importId: number) => {
    try {
      const result = await axiosInstance.head(`${getBaseUrl({ cartId })}/imports/${importId}/conflicts`)

      return Result.ok(parseInt(result.headers['x-total-count'] || '', 10))
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const fetchCartImportConflicts = async (cartId: number, importId: number, params: PaginationRequest) => {
    try {
      const result = await axiosInstance.get<Pagination<ImportConflict>>(
        `${getBaseUrl({ cartId })}/imports/${importId}/conflicts`,
        { params },
      )
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const fetchCartImportDuplicate = async (cartId: number, importId: number, conflictId: number) => {
    try {
      const result = await axiosInstance.get<ImportDuplicate[]>(
        `${getBaseUrl({ cartId })}/imports/${importId}/conflicts/${conflictId}`,
      )
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const patchCartImportConflict = async (
    cartId: number,
    importId: number,
    conflictId: number,
    duplicateId?: number,
  ) => {
    try {
      const result = await axiosInstance.patch(
        `${getBaseUrl({ cartId })}/imports/${importId}/conflicts/${conflictId}`,
        { duplicateId },
      )
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsError(error as ApiAxiosError))
    }
  }

  const fetchPricing = async (cartId: number, readOnly = false) => {
    try {
      const result = await axiosInstance.get<Pricing>(`${getBaseUrl({ cartId })}/pricing`, { params: { readOnly } })
      return result.data
    } catch (error) {
      throw new CartsError(error as ApiAxiosError)
    }
  }

  const fetchPricingSummary = async (cartId: number) => {
    try {
      const result = await axiosInstance.get<PricingSummary>(`${getBaseUrl({ cartId })}/pricing/summary`)
      return result.data
    } catch (error) {
      throw new CartsError(error as ApiAxiosError)
    }
  }

  const postPayments = async (cartId: number, body: PaymentRequestBody) => {
    try {
      const result = await axiosInstance.post<PaymentsResponse>(`${getBaseUrl({ cartId })}/payments`, body)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new CartsPaymentsError(error as ApiAxiosError))
    }
  }

  const fetchPaymentMeans = async (cartId: number) => {
    try {
      const result = await axiosInstance.get<PaymentMean[]>(`${getBaseUrl({ cartId })}/paymentmeans`)
      return result.data
    } catch (error) {
      const _error = error as ApiAxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const fetchDeliveryInformations = async (cartId: number) => {
    try {
      const result = await axiosInstance.get<DeliveryInformations>(`${getBaseUrl({ cartId })}/deliveryInformations`)
      return Result.ok(result.data)
    } catch (error) {
      const _error = error as ApiAxiosError
      return Result.err(new CartsError(_error))
    }
  }

  const fetchSummaryPdf = async (cartId: number) => {
    try {
      const result = await axiosInstance.get(`${getBaseUrl({ cartId })}/pdf/summary`, { responseType: 'blob' })

      downloadFile(result.data, 'Recapitulatif.pdf')
    } catch (error) {
      const _error = error as ApiAxiosError
      console.error(_error)
      throw _error.response
    }
  }
  return {
    create,
    patchCart,
    fetchCart,
    createItem,
    fetchItems,
    postItemsBatch,
    headItems,
    deleteItem,
    postItem,
    putItem,
    postImport,
    fetchImport,
    validateImport,
    headCartImportConflicts,
    fetchCartImportConflicts,
    fetchCartImportDuplicate,
    patchCartImportConflict,
    fetchPricing,
    fetchPricingSummary,
    postPayments,
    fetchPaymentMeans,
    fetchDeliveryInformations,
    fetchSummaryPdf,
  }
}
