import { Result } from '@badrap/result'
import type { ContentfulClientApi, Entry } from 'contentful'
import type { TypeModalSkeleton, TypeUniverseSkeleton } from '../generated'
import { mapModalToLocal } from '../modals'
import type { CFUniverse } from './types'

export default (contentfulInstance: ContentfulClientApi<undefined>) => {
  const fetchUniverses = async () => {
    try {
      const result = await contentfulInstance.getEntries<TypeUniverseSkeleton>({
        content_type: 'universe',
      })
      return Result.ok(mapUniverseToLocal(result.items))
    } catch (error) {
      return Result.err(error as Error)
    }
  }

  return {
    fetchUniverses,
  }
}

function mapUniverseToLocal(items: Entry<TypeUniverseSkeleton, undefined, string>[]): CFUniverse[] {
  return items.map((item) => {
    const juridicModal = item.fields.juridicModal as Entry<TypeModalSkeleton, undefined, string>

    return {
      ...item.fields,
      juridicModal: mapModalToLocal(juridicModal),
    }
  })
}
