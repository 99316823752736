import type { Address } from '@/types/Address'
import type { SelectOption } from '@/types/SelectOption'
import type { ProductCode } from '@/variables/ProductCode'

export type ApiContract = {
  Product: ApiProduct
  Articles: ApiArticle[]
  OrderAllowed: boolean
  IsActive: boolean
  CreatedDate: string // DateTime
  CompanyName: string
}

export type ApiProduct = {
  Code: number
  Family: number
  Label: string
  ShortLabel: string
}

export type ApiArticle = {
  Code: number
  Label: string
  ShortLabel: string
}

export type ApiAccount = {
  Division: string
  Society: string
  ProductsFamilies: ApiProductsFamily[]
  isAssociated: boolean
  IsActive: boolean
  FromAloha: boolean
  IsDefault: boolean
}

export type ApiProductsFamily = {
  Id: number
  Description: string
}

export type ApiProfileInformation = {
  Nom: string
  Prenom: string
  Telephone: string
  Email: string
  CommercialProspection: boolean
}

export type ApiClient = {
  Name: string
  SIRET: string
  Address: ApiAddress
}

export type ApiAddress = {
  Address1: string
  Address2: string
  Address3: string
  ZipCode: string
  City: string
  Country?: string | null
}

export type Contract = {
  product: Product
  articles: Article[]
  orderAllowed: boolean
  isActive: boolean
  createdDate: Date
  companyName: string
}

export type Product = {
  code: ProductCode
  family: number
  label: string
  shortLabel: string
}

export type Article = {
  code: number
  label: string
  shortLabel: string
}

export type Account = {
  division: string
  society: string
  productsFamilies: ProductsFamily[]
  isAssociated: boolean
  isActive: boolean
  fromAloha: boolean
  isDefault: boolean
}

export type ProductsFamily = {
  id: number
  description: string
}

export type ProfileInformation = {
  lastName: string
  firstName: string
  phone: string
  email: string
  commercialProspection: boolean
}

export type Client = {
  name: string
  siret: string
  address: Address
}

export function mapContractsToLocal(contracts: ApiContract[]): Contract[] {
  return contracts.map((contract) => ({
    product: mapProductToLocal(contract.Product),
    articles: contract.Articles.length ? mapArticlesToLocal(contract.Articles) : [],
    orderAllowed: contract.OrderAllowed,
    isActive: contract.IsActive,
    createdDate: new Date(contract.CreatedDate),
    companyName: contract.CompanyName,
  }))
}

function mapProductToLocal(product: ApiProduct): Product {
  return {
    code: product.Code,
    family: product.Family,
    label: product.Label,
    shortLabel: product.ShortLabel,
  }
}

function mapArticlesToLocal(articles: ApiArticle[]): Article[] {
  return articles.reduce<Article[]>((r, article) => {
    if (article) {
      r.push({
        code: article.Code,
        label: article.Label,
        shortLabel: article.ShortLabel,
      })
    }
    return r
  }, [])
}

export function mapAccountsToLocal(accounts: ApiAccount[]): Account[] {
  return accounts.map((account) => ({
    division: account.Division,
    society: account.Society,
    productsFamilies: mapProductsFamiliesToLocal(account.ProductsFamilies),
    isAssociated: account.isAssociated,
    isActive: account.IsActive,
    fromAloha: account.FromAloha,
    isDefault: account.IsDefault,
  }))
}

function mapProductsFamiliesToLocal(productFamilies: ApiProductsFamily[]): ProductsFamily[] {
  return productFamilies.map((productFamily) => ({
    id: productFamily.Id,
    description: productFamily.Description,
  }))
}

export function mapClientToLocal(client: ApiClient): Client {
  return {
    name: client.Name,
    siret: client.SIRET,
    address: {
      address1: client.Address.Address1,
      address2: client.Address.Address2,
      address3: client.Address.Address3,
      zipCode: client.Address.ZipCode,
      city: client.Address.City,
      country: client.Address.Country ?? null,
    },
  }
}
export function mapProductsToSelectOptions(products: Product[]): SelectOption<Product>[] {
  return products.map((product) => ({
    value: product.code,
    label: product.label,
    data: product,
  }))
}
