import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import { ApiMillesimeError } from './errors'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = 'api/:version/millesime'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
})

export default (axiosInstance: AxiosInstance) => {
  const patchMillesimeEnabled = async (enabled: boolean, productCode: number) => {
    try {
      const result = await axiosInstance.patch(`${getBaseUrl()}/${productCode}/automaticChange`, { enabled })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new ApiMillesimeError(error as ApiAxiosError))
    }
  }

  return {
    patchMillesimeEnabled,
  }
}
