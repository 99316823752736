import commonsCartWorkflow from '../common'
import addCKSProduct from '../../../common/module/addCKSProduct'
import stepBasket from './steps/basket'
import stepPayment from './steps/payment'
import type { CartWorkflow, FrontCart } from '@/store/cart/model'
import { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'

const steps = commonsCartWorkflow.steps.map((step) => {
  if (step.config.id === stepBasket.config.id) {
    return stepBasket
  } else if (step.config.id === stepPayment.config.id) {
    return stepPayment
  }
  return step
})

const modules = commonsCartWorkflow.modules.map((module) => {
  if (module.config.id === addCKSProduct.config.id) {
    return addCKSProduct
  }
  return module
})

const cartWorkflow: CartWorkflow = {
  ...commonsCartWorkflow,
  steps,
  modules,
  isValid(cart: FrontCart) {
    return (
      cart.meta.isNominative === false &&
      cart.meta.productCode === ProductCode.CARTE_KADEOS &&
      (cart.meta.articleCode === ArticleCode.CARTE_KADEOS_ZENITH_CE ||
        cart.meta.articleCode === ArticleCode.CARTE_KADEOS_ZENITH_DE ||
        cart.meta.articleCode === ArticleCode.CARTE_KADEOS_CULTURE)
    )
  },
}

export default cartWorkflow
