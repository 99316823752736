import type { RouteConfig } from 'vue-router'

const title = 'Edenred - Bienvenue sur votre Espace Client'

export const router: RouteConfig = {
  path: '/login',
  meta: {
    isAuthUrl: true,
    title,
  },
  component: () => import('@/views/Auth/root/AuthView.vue'),
  children: [
    {
      path: '',
      meta: {
        layout: 'Auth',
        title,
      },
      component: () => import('@/views/Auth/Login.vue'),
    },
    {
      path: 'forgotPassword',
      meta: {
        layout: 'Auth',
        title,
      },
      component: () => import('@/views/Auth/ForgotPassword.vue'),
    },
    {
      path: 'forgotPassword/form',
      meta: {
        layout: 'Auth',
        title,
      },
      component: () => import('@/views/Auth/ForgotPasswordForm.vue'),
    },
    {
      path: 'activation',
      meta: {
        layout: 'Auth',
        title,
      },
      component: () => import('@/views/Auth/Activation.vue'),
    },
    {
      path: 'activation/form',
      meta: {
        layout: 'Auth',
        clearSession: true,
        title,
      },
      component: () => import('@/views/Auth/ActivationForm.vue'),
    },
    {
      path: 'contactUs',
      meta: {
        layout: 'Auth',
        title,
      },
      component: () => import('@/views/Auth/ContactUs.vue'),
    },
    {
      path: 'maintenance',
      meta: {
        layout: 'Auth',
        title,
      },
      component: () => import('@/views/Auth/Maintenance.vue'),
    },
    {
      path: 'mfa',
      meta: {
        layout: 'Auth',
        title,
      },
      component: () => import('@/views/Auth/Mfa/index.vue'),
    },
    {
      path: 'mfa/contact',
      meta: {
        layout: 'Auth',
        title,
      },
      component: () => import('@/views/Auth/Mfa/Contact.vue'),
    },
  ],
}
