const baseUrl = '/api/upload/file'

export default (axiosInstance) => {
  const postUploadFile = async (formData, token) =>
    axiosInstance.post(baseUrl, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })

  return {
    postUploadFile,
  }
}
