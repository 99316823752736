import { Result } from '@badrap/result'
import type { CartWorkflow, FrontCart } from '../../../../model'
import { useStore as useCartStore } from '../../../../store'
import editReference from '../../../common/module/editReference'
import addKSProduct from '../../../common/module/addKSProduct'
import deleteCartItem from '../../../common/module/deleteCartItem'
import discountCode from '../../../common/module/discountCode'
import stepPayment from '../../../common/steps/payment'
import stepConfirmation from '../../../common/steps/confirmation'
import stepLoading from '../../../common/steps/loading'
import useHeader from '../../../common/useHeader'
import stepBasket from './steps/basket'
import { ProductCode } from '@/variables/ProductCode'

const cartWorkflow: CartWorkflow = {
  steps: [stepBasket, stepLoading, stepPayment, stepConfirmation],
  modules: [editReference, addKSProduct, deleteCartItem, discountCode],
  useHeader,
  isValid(cart: FrontCart) {
    return cart.meta.productCode === ProductCode.KADEOS
  },
  async prepareCart() {
    const store = useCartStore()
    const eventResult = await store.fetchEventConfig()

    if (eventResult.isErr) {
      return Result.err(eventResult.error)
    }

    const fetchProducts = await store.fetchProducts()
    if (fetchProducts == null) {
      return Result.err(new Error('cart.error.product.loadFailed'))
    }

    return Result.ok({})
  },
}

export default cartWorkflow
