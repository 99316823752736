export function formatTimeRemaining(timeRemaingInSeconds: number) {
  const hours = Math.floor(timeRemaingInSeconds / 3600)
  const minutes = Math.floor(timeRemaingInSeconds / 60) % 60
  const seconds = timeRemaingInSeconds % 60
  return (
    [hours, minutes, seconds]
      .map((v) => (v < 10 ? '0' + v : v))
      .filter((v, i) => v !== '00' || i > 0)
      .join(' : ') + 's'
  )
}
