import { computed } from 'vue'
import { accessor } from '@/store'
import { Role } from '@/types/Role'

export function useCanSeeMigration() {
  return computed(() =>
    [Role.SUPERVISEUR, Role.GESTIONNAIRE_COMMANDE, Role.ADMIN_EDENRED].includes(accessor.session.right?.roleId as Role),
  )
}

export function useCanAccessMigrationTunnel() {
  return computed(() => [Role.SUPERVISEUR, Role.GESTIONNAIRE_COMMANDE].includes(accessor.session.right?.roleId as Role))
}
