const baseUrl = '/accountingDocuments'

export default (axiosInstance) => {
  const sendOrDownloadInvoice = async (id, dematerialisationStatus) => {
    try {
      const options = {}
      if (dematerialisationStatus === 2) {
        options.responseType = 'blob'
      }
      const result = axiosInstance.get(`${baseUrl}/${id}`, options)
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getInvoices = async (params) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}`, { params })
      return {
        data: result.data,
        count: result.headers['x-total-count'],
        headers: result.headers,
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  return {
    sendOrDownloadInvoice,
    getInvoices,
  }
}
