import { Result } from '@badrap/result'
import { useVuelidate, type Validation, type ValidationArgs } from '@vuelidate/core'
import { email, helpers, maxLength, requiredIf } from '@vuelidate/validators'
import { computed, reactive, ref, type ComputedRef, type Ref } from 'vue'
import differenceInYears from 'date-fns/differenceInYears'
import apiV4 from '@/services/apiV4'
import { Civility, type Beneficiary, type BeneficiaryRequest } from '@/services/beneficiaries/types'
import { countries, DEFAULT_COUNTRY } from '@/types/Country'
import type { SelectOption } from '@/types/SelectOption'
import { ProductCode } from '@/variables/ProductCode'
import { formatDate, parseDate } from '@/utils/date'

export interface BeneficiaryForm {
  registrationNumber: string | null
  lastName: string | null
  firstName: string | null
  birthdate: Date | null
  email: string | null
  cellPhone: string | null
  address1: string | null
  address2: string | null
  address3: string | null
  zipCode: string | null
  city: string | null
  country: SelectOption | null
}

export type UseBeneficiaryFormWorkflow = () => BeneficiaryFormWorkflow
export type UseVuelidateBeneficiaryForm = (
  productCode: ProductCode,
  isPlasticless: boolean,
  beneficiary?: Beneficiary | null,
) => {
  v$: Ref<Validation<ValidationArgs<BeneficiaryForm>, BeneficiaryForm>>
  submit: () => Promise<Result<Beneficiary>>
}

export interface BeneficiaryFormWorkflow {
  isNew: boolean
  readonly: ComputedRef<boolean>
  v$: Ref<Validation<ValidationArgs<BeneficiaryForm>, BeneficiaryForm>>
  submit: () => Promise<Result<Beneficiary>>
}

async function fetchBeneficiary(registrationNumber: string | null, page: number): Promise<Result<Beneficiary | null>> {
  if (registrationNumber === null || registrationNumber === '') {
    return Result.ok(null)
  }

  const pageSize = 50
  const result = await apiV4.beneficiaries.fetchBeneficiaries({
    page,
    pageSize,
    filter: registrationNumber,
    sortDirection: 'ASC',
  })
  if (result.isErr) {
    return Result.err(result.error)
  }

  const beneficiary = result.value.items?.find(
    (item) => item.registrationNumber.toLowerCase() === registrationNumber.toLowerCase(),
  )
  if (beneficiary) {
    return Result.ok(beneficiary)
  }

  if (page * pageSize < result.value.totalElements) {
    return fetchBeneficiary(registrationNumber, page + 1)
  }

  return Result.ok(null)
}

export const useVuelidateBeneficiaryForm: UseVuelidateBeneficiaryForm = (productCode, isPlasticless, beneficiary) => {
  const config = fetchBeneficiaryFormConfig(productCode, isPlasticless)

  const debounced = ref<ReturnType<typeof setTimeout>>()

  const { withMessage, withAsync } = helpers
  const beneficiaryForm = reactive<BeneficiaryForm>({
    registrationNumber: beneficiary?.registrationNumber ?? null,
    lastName: beneficiary?.name ?? null,
    firstName: beneficiary?.firstName ?? null,
    birthdate: beneficiary?.birthdate ? parseDate(beneficiary.birthdate) : null,
    email: beneficiary?.email ?? null,
    cellPhone: beneficiary?.cellPhone ?? null,
    address1: beneficiary?.address?.address1?.length ? beneficiary.address.address1 : null,
    address2: beneficiary?.address?.address2?.length ? beneficiary?.address?.address2 : null,
    address3: beneficiary?.address?.address3?.length ? beneficiary?.address?.address3 : null,
    zipCode: beneficiary?.address?.zipCode?.length ? beneficiary?.address?.zipCode : null,
    city: beneficiary?.address?.city?.length ? beneficiary?.address?.city : null,
    country: countries.find((country) => country.value === beneficiary?.address?.country) ?? DEFAULT_COUNTRY,
  })
  const rules = {
    registrationNumber: {
      required: withMessage(
        'beneficiary.forms.registrationNumber.errors.required',
        requiredIf(config.registrationNumber.required),
      ),
      maxLength: withMessage(
        () => `Le matricule ne doit pas dépasser ${config.registrationNumber.maxLength} caractères`,
        maxLength(config.registrationNumber.maxLength),
      ),
      asyncValidator: withMessage(
        'beneficiary.forms.registrationNumber.errors.unique',
        withAsync((registrationNumber: string) => {
          if (registrationNumber === beneficiary?.registrationNumber) {
            return Promise.resolve(true)
          }

          return new Promise((resolve, reject) => {
            if (debounced.value !== null) {
              clearTimeout(debounced.value)
            }

            debounced.value = setTimeout(async () => {
              const result = await fetchBeneficiary(registrationNumber, 1)

              if (
                result.isOk &&
                ((!beneficiary && !result.value) ||
                  (beneficiary && (!result.value || (result.value && beneficiary.id === result.value.id))))
              ) {
                resolve(true)
              }

              reject()
            }, 1000)
          })
        }),
      ),
    },
    lastName: {
      required: withMessage('beneficiary.forms.lastname.errors.required', requiredIf(config.lastName.required)),
      maxLength: withMessage(
        () => `Le nom ne doit pas dépasser ${config.lastName.maxLength} caractères`,
        maxLength(config.lastName.maxLength),
      ),
    },
    firstName: {
      required: withMessage('beneficiary.forms.firstname.errors.required', requiredIf(config.firstName.required)),
      maxLength: withMessage(
        () => `Le prénom ne doit pas dépasser ${config.firstName.maxLength} caractères`,
        maxLength(config.firstName.maxLength),
      ),
    },
    birthdate: {
      required: withMessage('beneficiary.forms.birthdate.errors.required', requiredIf(config.birthdate.required)),
      range: withMessage('beneficiary.forms.birthdate.errors.range', (value: Date | null) => {
        if (!value) {
          return true
        }

        const diff = differenceInYears(new Date(), value)
        return diff <= 130 && diff >= 14
      }),
    },
    email: {
      required: withMessage('beneficiary.forms.email.errors.required', requiredIf(config.email.required)),
      email: withMessage('beneficiary.forms.email.errors.email', email),
    },
    cellPhone: {},
    address1: {
      required: withMessage('beneficiary.forms.address1.errors.required', requiredIf(config.address1.required)),
    },
    address2: {},
    address3: {},
    zipCode: {
      required: withMessage('beneficiary.forms.zipCode.errors.required', requiredIf(config.zipCode.required)),
    },
    city: {
      required: withMessage('beneficiary.forms.city.errors.required', requiredIf(config.city.required)),
    },
    country: {
      required: withMessage('beneficiary.forms.country.errors.required', requiredIf(config.country.required)),
    },
  }

  const v$ = useVuelidate(rules, beneficiaryForm)
  return {
    v$,
    submit: async () => {
      v$.value.$touch()

      if (v$.value.$invalid) {
        return Result.err(new Error(v$.value.$errors[0].$message.toString()))
      }

      const birthdate = formatDate(v$.value.birthdate.$model, 'yyyy-MM-dd')

      if (
        !v$.value.registrationNumber.$model ||
        !v$.value.lastName.$model ||
        !v$.value.firstName.$model ||
        !birthdate
      ) {
        return Result.err(new Error('cart.errors'))
      }

      const body: BeneficiaryRequest = {
        productCode,
        civility: Civility.NotSet,
        registrationNumber: v$.value.registrationNumber.$model,
        name: v$.value.lastName.$model,
        firstName: v$.value.firstName.$model,
        birthdate,
        email: v$.value.email.$model?.length ? v$.value.email.$model : null,
        cellPhone: v$.value.cellPhone.$model?.length ? v$.value.cellPhone.$model : null,
        address1: v$.value.address1.$model?.length ? v$.value.address1.$model : null,
        address2: v$.value.address2.$model?.length ? v$.value.address2.$model : null,
        address3: v$.value.address3.$model?.length ? v$.value.address3.$model : null,
        zipCode: v$.value.zipCode.$model?.length ? v$.value.zipCode.$model : null,
        city: v$.value.city.$model?.length ? v$.value.city.$model : null,
        country: v$.value.country.$model?.label ?? null,
        displayed: true,
      }

      let result: Result<Beneficiary>
      if (beneficiary?.id) {
        result = await apiV4.beneficiaries.patchBeneficiary(beneficiary.id, body)
      } else {
        result = await apiV4.beneficiaries.postBeneficiary(body)
      }

      if (result.isErr) {
        return Result.err(result.error)
      }

      return Result.ok(result.value)
    },
  }
}

export const useBeneficiaryFormWorkflow: (
  productCode: ProductCode,
  isPlasticless: boolean,
  beneficiary?: Beneficiary | null,
) => UseBeneficiaryFormWorkflow = (productCode, isPlasticless, beneficiary) => () => {
  const { v$, submit } = useVuelidateBeneficiaryForm(productCode, isPlasticless, beneficiary)

  const readonly = computed(() => v$.value.registrationNumber.$invalid)

  return {
    isNew: !beneficiary,
    readonly,
    v$,
    submit,
  }
}

export function fetchBeneficiaryFormConfig(productCode: ProductCode, isPlasticless = false) {
  if (isPlasticless) {
    return {
      registrationNumber: {
        required: true,
        maxLength: 15,
      },
      lastName: {
        required: true,
        maxLength: 50,
      },
      firstName: {
        required: true,
        maxLength: 50,
      },
      birthdate: {
        required: true,
      },
      email: {
        required: true,
      },
      address1: {
        required: false,
      },
      zipCode: {
        required: false,
      },
      city: { required: false },
      country: { required: false },
    }
  }

  if (productCode === ProductCode.TICKET_RESTAURANT) {
    return {
      registrationNumber: {
        required: true,
        maxLength: 15,
      },
      lastName: {
        required: true,
        maxLength: 50,
      },
      firstName: {
        required: true,
        maxLength: 50,
      },
      birthdate: {
        required: true,
      },
      email: {
        required: false,
      },
      address1: {
        required: true,
      },
      zipCode: {
        required: true,
      },
      city: { required: true },
      country: { required: true },
    }
  }

  return {
    registrationNumber: {
      required: true,
      maxLength: 15,
    },
    lastName: {
      required: true,
      maxLength: 50,
    },
    firstName: {
      required: true,
      maxLength: 50,
    },
    birthdate: {
      required: true,
    },
    email: {
      required: false,
    },
    address1: {
      required: true,
    },
    zipCode: {
      required: true,
    },
    city: { required: true },
    country: { required: true },
  }
}
