import { ArticleCode } from './ArticleCode'

export default {
  [ArticleCode.KADEOS_HORIZON]: 'TK-HORIZON',
  [ArticleCode.KADEOS_INFINI]: 'TK-INFINI',
  [ArticleCode.KADEOS_INTENSE]: 'TK-INTENSE',
  [ArticleCode.KADEOS_HORIZONPLUS]: 'TK-HORIZONPLUS',
  [ArticleCode.KADEOS_CULTURE]: 'TK-CULTURE',
  [ArticleCode.KADEOSINTENSEPLUS]: 'TK-KADEOSINTENSEPLUS',
  [ArticleCode.KADEOS_INFINI_DOM]: 'TK-INFINI_DOM',
  [ArticleCode.KADEOS_SELECT]: 'TK-SELECT',
  [ArticleCode.KADEOS_UNIVERSEL]: 'TK-UNIVERSEL',
  [ArticleCode.KADEOS_CONNECT]: 'TK-CONNECT',
  [ArticleCode.CARTE_KADEOS_ZENITH_CE]: 'CKS-ZENITH_CE',
  [ArticleCode.CARTE_KADEOS_ZENITH_DE]: 'CKS-ZENITH_DE',
  [ArticleCode.CARTE_KADEOS_CULTURE]: 'CKS-CULTURE',
  [ArticleCode.CARTE_TICKET_RESTAURANT_FLEX]: 'CTR-FLEX',
  [ArticleCode.CARTE_TICKET_RESTAURANT]: 'CTR',
  [ArticleCode.CARTE_KADEOS_PREFERENCE_CE]: 'CKS-PREFERENCE_CE',
  [ArticleCode.CARTE_KADEOS_PREFERENCE_DE]: 'CKS-PREFERENCE_DE',
  [ArticleCode.NO_PRODUCT]: '',
  default: '',
}
