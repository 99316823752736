export function getTotalPages(totalCount, pageSize) {
  return Math.ceil(totalCount / pageSize)
}

export function createOptions(totalCount, step = 20, max = 100) {
  let cmpt = step
  const result = [
    {
      label: step,
      value: step,
    },
  ]
  while (cmpt < totalCount && cmpt < max) {
    cmpt += step
    result.push({
      label: cmpt,
      value: cmpt,
    })
  }
  return result
}

export function applyParams(params, baseParams, currentParams) {
  if (!params || !Object.keys(params).length) {
    return currentParams
  }
  if (params.page != null && Object.keys(params).length === 1) {
    return Object.assign({}, currentParams, params)
  }
  return Object.assign({}, currentParams, baseParams, params)
}

export function generateCallParams(callParamsConfig = [], params) {
  const tempParams = Object.assign({}, params)
  const customParams = callParamsConfig.map((param) => {
    delete tempParams[param]
    return params[param]
  })
  customParams.push(tempParams)
  return customParams
}
