import { accessor } from '..'
import { mapPricingV4ToV3 } from '../basket/types'
import { actions as deliveryPointsActions } from './deliveryPoints'
import { actions as productActions } from './product'
import { actions as vfActions } from './vf'
import api from '@/services/api'
import apiV3 from '@/services/apiV3'
import apiV4 from '@/services/apiV4'
import { DeliveryPointType } from '@/variables/DeliveryPointType'
import { ProductCode as ProductCodeType } from '@/variables/ProductCode'

const getOrderId = (state) => state.Order.Id

export default {
  ...deliveryPointsActions,

  ...vfActions,

  ...productActions,

  async putEventsInStore({ commit }) {
    try {
      const events = await api.getClickProxy('Documents_Espace_Client/Commande/evenements.json')
      commit('SET_ALL_EVENTS', events)
    } catch (e) {
      console.error('Unable to get Events')
    }
  },
  validImport: ({ commit }) => {
    commit('SET_VALIDATION_IMPORT', true)
  },
  setPopinChoixTunnelVisible: ({ commit }, bool) => {
    commit('SET_IS_POPIN_CHOIX_TUNNEL_VISIBLE', bool)
  },
  setIsImport: ({ commit }, payload) => {
    commit('SET_IS_IMPORT', payload)
  },
  setIsSaisieWeb: ({ commit }, payload) => {
    commit('SET_IS_SAISIE_WEB', payload)
  },
  setUserAlert: ({ commit }, payload) => {
    commit('SET_USER_ALERT', payload)
  },
  setUserRestrictions: ({ commit }, payload) => {
    commit('SET_USER_RESTRICTIONS', payload)
  },
  setUserRoles: ({ commit }, payload) => {
    commit('SET_USER_ROLES', payload)
  },
  setUsersTotal: ({ commit }, payload) => {
    commit('SET_USERS_TOTAL', payload)
  },
  setUserRoleFiltered: ({ commit }, payload) => {
    commit('SET_USER_ROLE_FILTERED', payload)
  },
  async getErrorAccesTunnel(_, { productCode, articleCode }) {
    await api.getErrorAccesTunnel(productCode, articleCode)
  },

  async fetchOrder({ commit }, { orderId }) {
    const order = await api.getOrder(orderId)
    commit('SET_ORDER', order)
  },

  setOrder({ commit }, order) {
    commit('SET_ORDER', order)
  },

  clearOrder({ commit }) {
    commit('CLEAR_ORDER')
  },

  async setOrderReference({ commit }, orderReference) {
    commit('SET_ORDER_REFERENCE', orderReference)
  },

  async setDeliveryDate({ state, commit }, deliveryDate) {
    await api.patchOrder(state.Order.Id, { DeliveryDate: deliveryDate })
    commit('SET_DELIVERY_DATE', deliveryDate)
  },

  async setDiscountCode({ commit }, { discountCode, orderId }) {
    await api.patchOrder(orderId, { DiscountCode: discountCode })
    commit('SET_DISCOUNT_CODE', discountCode)
  },

  async getPricing({ dispatch, commit }, orderId) {
    try {
      const res = await apiV4.carts.getPricingV1(orderId)
      const pricing = mapPricingV4ToV3(res)
      commit('UPDATE_PRICING', { pricing })
      // Les moyens de paiement sont dépendants du montant total, du coup il faut les re-checker quand celui-ci change
      await dispatch('fetchPaymentMeans')
    } catch (e) {
      commit('UPDATE_PRICING', { pricing: {} })
      if (e.status === 409) {
        commit('SET_DOUBLONS_ERROR', { errorCode: e.data.ErrorCode, errors: e.data.Data, message: e.data.Message })
      }
      throw e
    }
  },

  async addItem({ state, dispatch, commit }, item) {
    try {
      const responseItem = await api.addItem(getOrderId(state), item)
      commit('ADD_ITEM', { item: responseItem })
      await dispatch('getPricing', {})
    } catch (e) {
      console.error(e)
    }
  },

  async updateItemQuantity({ state, dispatch, commit }, { itemId, qty }) {
    if (qty <= 0) {
      await dispatch('deleteItem', { itemId })
    } else {
      const item = state.Order.Items.find((item) => item.Id === itemId)
      let updatedItem
      switch (item.Format) {
        case 1:
          updatedItem = await api.updateBookletItemQuantity(getOrderId(state), itemId, qty)
          break
        case 4:
        case 5:
          updatedItem = await api.updateCardItemQuantity(getOrderId(state), itemId, qty)
          break
        case 3:
        case 9:
          updatedItem = await api.updateUnitItemQuantity(getOrderId(state), itemId, qty)
          break
        default:
          throw new Error('Unknown item format')
      }
      commit('UPDATE_ITEM', { item: updatedItem })
      await dispatch('getPricing', {})
    }
  },

  async deleteItem({ state, dispatch, commit }, { itemId }) {
    await api.deleteItem(getOrderId(state), itemId)
    commit('DELETE_ITEM', { itemId })
    await dispatch('getPricing', {})
  },

  async getCustomerInformation({ state, dispatch }, { isHomeDelivery, all, ProductCode = state.ProductCode }) {
    if (ProductCode !== ProductCodeType.KADEOS_CONNECT) {
      if ([ProductCodeType.TICKET_RESTAURANT, ProductCodeType.CARTE_TICKET_RESTAURANT].includes(ProductCode)) {
        await dispatch('fetchProductFacialValues', ProductCode)
      }
      if (all) {
        await dispatch('getDeliveryPointsOnDemand', { deliveryPointsType: DeliveryPointType.ALL_PL })
      } else if (isHomeDelivery) {
        await dispatch('getDeliveryPointsOnDemand', { deliveryPointsType: DeliveryPointType.PL_BENEF })
      } else {
        await dispatch('getDeliveryPointsOnDemand', { deliveryPointsType: DeliveryPointType.PL_SITE })
      }
    }
    await dispatch('setFacturationPoints', ProductCode)
  },

  async setFacturationPoints({ state, commit }, productCode = state.ProductCode) {
    const product = accessor.session.clientProducts[productCode]

    if (product == null) {
      return
    }

    const FacturationPoints = product.accountingSettings?.billingAddress
    commit('SET_FACTURATION_POINTS', FacturationPoints)
  },

  async getEarliestDeliveryDates({ state, commit }, deliveryPointsRefs) {
    const earliestDeliveryDates = await api.getEarliestDeliveryDates(
      state.ProductCode,
      state.ArticleCode,
      deliveryPointsRefs,
    )
    await commit('SET_EARLIEST_DELIVERY_DATES', earliestDeliveryDates)
  },

  async fetchPaymentMeans({ commit, state }) {
    const paymentMeans = await apiV4.carts.getPaymentMeans(getOrderId(state))
    commit('SET_PAYMENT_MEANS', {
      paymentMeans,
    })
  },

  async fetchTermsOfSale({ commit }) {
    commit('SET_TERMS_OF_SALE', { termsOfSale: await api.getTermsOfSale(240, 204) })
  },

  setPaymentType({ commit }, payload) {
    commit('SET_PAYMENT_DATA', payload)
  },

  async postMethodPayment({ state, commit }, { body }) {
    const result = await apiV4.carts.postPaymentsV1(getOrderId(state), body)
    if (result.isErr) {
      throw result.error
    }
    const { paymentMean, redirectionUrl } = result.value

    commit('SET_PAYMENT_DATA', { Method: paymentMean, redirectionUrl })

    return result.value
  },

  async setImportedFieldsDescription({ commit }, { productCode, excludedColumn, isHomeDelivery, isBenef }) {
    const fields = await api.getFieldsImport(productCode, excludedColumn, isHomeDelivery, isBenef)
    commit('SET_IMPORTED_FIELDS_DESCRIPTION', fields)
  },

  setSelectedEvent({ commit }, event) {
    commit('SET_SELECTED_EVENT', event)
  },
  setSelectedAmount({ commit }, value) {
    commit('SET_SELECTED_AMOUNT', value)
  },
  async getBeneficiaries(store, { filter, page, pageSize }) {
    return api.getBeneficiaries(filter, page, pageSize)
  },

  async getBenefOutOfOrder({ state, commit }) {
    const BenefList = await api.getBenefOutOfOrder(state.Order.Id)
    commit('SET_BENEF_OUT_OF_ORDER', BenefList)
  },
  async headBenefOutOfOrder({ state, commit }) {
    const BenefListHeader = await api.headBenefOutOfOrder(state.Order.Id)
    const totalCount = BenefListHeader.headers['x-total-count']
    commit('TOTAL_BENEF_CONSOLIDATED', totalCount)
    return totalCount
  },

  async getBenefInOrder({ state, commit }) {
    const BenefList = await api.getBenefInOrder(state.Order.Id)
    commit('SET_BENEF_IN_ORDER', BenefList)
    commit('TOTAL_BENEF', BenefList.length)
  },

  async getBenefWithoutAddresses({ state, commit }) {
    const BenefList = await api.getBenefWithoutAddresses(state.Order.Id)
    commit('SET_BENEF_WITHOUT_ADDRESSES', BenefList)
    commit('TOTAL_BENEF', BenefList.length)
  },

  async setTotalBenef({ commit }, payload) {
    commit('TOTAL_BENEF', payload)
  },

  async setBenefInOrderLength({ commit }, payload) {
    commit('SET_BENEF_IN_ORDER_LENGTH', payload)
  },

  async setBenefNewLength({ commit }, payload) {
    commit('SET_BENEFICIARIES_NEW_LENGTH', payload)
  },

  async addNewBenefInOrder({ state, commit }, { benef }) {
    const benefWithFormat = Object.assign({}, benef, { FormatId: state.Format })

    const result = await api.postNewBeneficiaryInOrder(state.Order.Id, benefWithFormat)
    if (result.status === 200) {
      commit('ADD_NEW_BENEF_IN_ORDER', result.data)
    } else if (result.status === 400) {
      result.data.errorCode = result.errorCode
      throw result
    } else {
      throw result
    }
  },

  async checkCardStatus(store, { idsBenef }) {
    return api.checkCardStatus(idsBenef)
  },

  async deletedBenefOutOfOrder({ state, commit }, { beneficiaryId }) {
    await api.deleteBenefOrder(state.Order.Id, beneficiaryId)
    commit('DELETE_BENEFOUTOFORDER_OUT_OF_STORE', beneficiaryId)
  },

  async deletedBenefInOrder({ state, commit }, { beneficiaryId }) {
    await api.deleteBenefOrder(state.Order.Id, beneficiaryId)
    commit('DELETE_BENEFINORDER_OUT_OF_STORE', beneficiaryId)
  },

  async deletedBenefWithoutAddress({ state, commit }, { beneficiaryId }) {
    await api.deleteBenefOrder(state.Order.Id, beneficiaryId)
    commit('DELETE_BENEFWITHOUTADDRESS_OUT_OF_STORE', beneficiaryId)
  },

  async deletedMassBenefWithoutAddress({ state, commit }, { idsBenef }) {
    await api.deleteMassBenefOrder(state.Order.Id, idsBenef)
    commit('DELETE_MASS_BENEF_WITHOUTADDRESS_OUT_OF_STORE', idsBenef)
  },

  async deletedMassBenefOutOfOrder({ state, commit }, { idsBenef }) {
    await api.deleteMassBenefOrder(state.Order.Id, idsBenef)
    commit('DELETE_MASS_BENEF_OUTOFORDER_OUT_OF_STORE', idsBenef)
  },

  async deletedMassBenefInOrder({ state, commit }, { idsBenef }) {
    await api.deleteMassBenefOrder(state.Order.Id, idsBenef)
    commit('DELETE_MASS_BENEF_INORDER_OUT_OF_STORE', idsBenef)
  },

  async forceAddNewBenefInOrder({ state, commit }, { benef }) {
    const benefWithFormat = Object.assign({}, benef, { FormatId: state.Format })
    const newBenef = await api.forcePostNewBeneficiaryInOrder(state.Order.Id, benefWithFormat)
    commit('ADD_NEW_BENEF_IN_ORDER', newBenef)
  },

  async putBenefInOrder({ state, commit }, { benef, payload }) {
    const benefWithFormat = Object.assign({}, benef, payload, { FormatId: state.Format })
    const newBenef = await api.putBenefInOrder(state.Order.Id, benefWithFormat)
    commit('ADD_BENEF_IN_ORDER', newBenef)
  },

  async putBenefInOrderFromWithoutAddresses({ state, commit }, { benef, payload }) {
    const benefWithFormat = Object.assign({}, benef, payload, { FormatId: state.Format })
    const newBenef = await api.putBenefInOrder(state.Order.Id, benefWithFormat)
    commit('ADD_BENEF_IN_ORDER_FROM_NO_ADDRESSES', newBenef)
  },

  async putBeneficiariesWithoutAddressInMass({ state, commit }, { benefList }) {
    const formatedBenefList = benefList.map((benef) => Object.assign({}, benef, { FormatId: state.Format }))
    await api.putMassBenefInOrder(state.Order.Id, formatedBenefList)
    commit('ADD_MASS_BENEF_IN_ORDER_FROM_NO_ADDRESSES', formatedBenefList)
  },

  async putBeneficiariesInMass({ state, commit }, { benefList }) {
    const formatedBenefList = benefList.map((benef) => Object.assign({}, benef, { FormatId: state.Format }))
    await api.putMassBenefInOrder(state.Order.Id, formatedBenefList)
    commit('ADD_MASS_BENEF_IN_ORDER', formatedBenefList)
  },

  async putBeneficiariesInMassAll({ state, commit }, { arrayId, payload }) {
    const payloadWithFormat = Object.assign({}, payload, { FormatId: state.Format })
    await api.putMassBenefInOrder(state.Order.Id, '$all', payloadWithFormat)
    commit('ADD_ALL_BENEF_IN_ORDER', { ids: arrayId, upd: payloadWithFormat })
  },

  async putBenefOutOfOrder({ state, commit }, benef) {
    /** from in to out **/
    await api.deleteBenefInOrder(state.Order.Id, benef)
    commit('ADD_BENEF_OUT_OF_ORDER', benef)
  },

  async addBenefInOrder({ state, commit }, benef) {
    const newBenef = await api.postNewBeneficiaryInOrder(state.Order.Id, benef)
    commit('ADD_BENEF_IN_ORDER', newBenef)
  },

  async updateBeneficiariesInMass({ state, commit }, { arrayId, payload }) {
    await api.massUpdateBeneficiaries(state.Order.Id, arrayId, payload)
    commit('UPDATE_MASS_BENEF_IN_ORDER', { ids: arrayId, upd: payload })
  },

  async updateBeneficiariesInMassAll({ state, commit }, { arrayId, payload }) {
    await api.massUpdateBeneficiaries(state.Order.Id, '$all', payload)
    commit('UPDATE_MASS_BENEF_IN_ORDER', { ids: arrayId, upd: payload })
  },

  async deleteBeneficiariesInMassAll({ state, commit }, arrayId) {
    await api.massDeleteBeneficiariesIncluded(state.Order.Id, '$all')
    commit('ADD_ALL_BENEF_OUT_OF_ORDER', arrayId)
  },

  async deleteBeneficiariesInMass({ state, commit }, arrayId) {
    await api.massDeleteBeneficiariesIncluded(state.Order.Id, arrayId)
    commit('ADD_MASS_BENEF_OUT_OF_ORDER', arrayId)
  },

  async updateBeneficiariesOutOfOrder({ commit }, { benef, payload }) {
    const newBenef = Object.assign({}, benef, payload)
    commit('UPDATE_BENEF_OUT_OF_ORDER', newBenef)
  },

  async updateBeneficiariesWithoutAddress({ commit }, { benef, payload }) {
    const newBenef = Object.assign({}, benef, payload)
    commit('UPDATE_BENEF_WITHOUT_ADDRESSES', newBenef)
  },

  async updMassBenefOutOfOrder({ state, commit }, { arrayId, payload }) {
    await api.massUpdateBeneficiaries(state.Order.Id, '$all', payload)
    commit('UPDATE_MASS_BENEF_OUT_OF_ORDER', { ids: arrayId, upd: payload })
  },

  async updateBeneficiariesInOrder({ state, commit }, { benef, payload }) {
    const newBenef = await api.updateBeneficiaries(state.Order.Id, benef.Id, payload)
    commit('UPDATE_BENEF_IN_ORDER', Object.assign({}, benef, newBenef)) // le newBenef n'as pas les infos de commande (pl,vf,quantiter), le benef les a
  },

  async getAnalysisBeneficariesError({ commit }, { orderId, benefId }) {
    const BeneficiariesError = await api.getAnalysisBeneficariesError(orderId, benefId)
    commit('SET_BENEFICIARIES_ERROR', BeneficiariesError.data)
    commit('SET_BENEFICIARIES_ERROR_LENGTH', parseInt(BeneficiariesError.headers['x-total-count'], 10))
  },

  async getAnalysisBeneficariesDuplicated({ state, commit }, { orderId, status }) {
    const BeneficiariesDuplicated = await api.getAnalysisBeneficariesDuplicated(orderId, status)
    commit('SET_BENEFICIARIES_DUPLICATED', BeneficiariesDuplicated.data.length ? BeneficiariesDuplicated.data[0] : {})
    if (!state.Beneficiaries.BeneficiariesDuplicatedLength) {
      commit('SET_BENEFICIARIES_DUPLICATED_LENGTH', parseInt(BeneficiariesDuplicated.headers['x-total-count'], 10))
    }
  },

  async addBeneficaryInNewBeneficaries({ commit }, { orderId, benef }) {
    await api.addBeneficary(orderId, benef.Id)
    commit('ADD_BENEFICARIES_NEW', benef)
  },

  async mergeBeneficiaryInUpdatedBeneficaries({ commit }, { orderId, benef, idMerge }) {
    await api.mergeBeneficary(orderId, benef.Id, idMerge)
    commit('ADD_BENEFICARIES_UPDATED', benef)
  },

  async addBeneficariesUpdated({ commit }, benef) {
    commit('ADD_BENEFICARIES_UPDATED', benef)
  },

  async getEmployee(store, employeeId) {
    const employee = await api.getEmployeeData(employeeId)
    return employee
  },

  async updateBeneficiaryDatas({ commit }, { benef }) {
    const newBenef = await api.updateBeneficiariesDatas(benef)
    commit('UPDATE_BENEF_DATAS_IN_ORDER', Object.assign({}, benef, newBenef))
  },

  async getCitiesByZip(store, zip) {
    return api.getCitiesByZipCode(zip)
  },

  async getBeneficariesDuplicated({ state, commit }, { requestId, status }) {
    const BeneficiariesDuplicated = await api.getBeneficariesDuplicated(requestId, status)
    commit('SET_BENEFICIARIES_DUPLICATED', BeneficiariesDuplicated.data.length ? BeneficiariesDuplicated.data[0] : {})
    if (!state.Beneficiaries.BeneficiariesDuplicatedLength) {
      commit('SET_BENEFICIARIES_DUPLICATED_LENGTH', parseInt(BeneficiariesDuplicated.headers['x-total-count'], 10))
    }
  },

  async getBeneficariesError({ commit }, { requestId, employeeId }) {
    const BeneficiariesError = await api.getBeneficariesError(requestId, employeeId)
    commit('SET_BENEFICIARIES_ERROR', BeneficiariesError.data)
    commit('SET_BENEFICIARIES_ERROR_LENGTH', parseInt(BeneficiariesError.headers['x-total-count'], 10))
  },

  async addNewBeneficary({ commit }, { requestId, benef }) {
    await api.addNewBeneficary(requestId, benef.Id)
    commit('ADD_BENEFICARIES_NEW', benef)
  },

  /* ADMIN */

  async getInvoices({ commit }) {
    const ret = await api.getInvoices()
    commit('SET_INVOICES', ret)
  },

  /* CUSTOM IMPORT */
  async saveCustomImport(store, params) {
    return api.saveCustomImport(params)
  },

  async saveCgvVersion({ commit }, { ProductCode, Version }) {
    const savedVersion = await api.postCgvVersion(ProductCode, Version)
    commit('SET_CGV_VERSION', savedVersion)
  },

  async setProfilEditionInfosModel({ commit }, profilEditionInfosModel) {
    commit('SET_PROFIL_EDITION_INFOS', profilEditionInfosModel)
  },

  async setProfilEditionPasswordModel({ commit }, profilEditionPasswordModel) {
    commit('SET_PROFIL_EDITION_PASSWORD', profilEditionPasswordModel)
  },

  async setProfilEditionAccountsModel({ commit }, profilEditionAccountsModel) {
    commit('SET_PROFIL_EDITION_ACCOUNTS', profilEditionAccountsModel)
  },

  async sendOrDownloadInvoice(store, data) {
    return api.sendOrDownloadInvoice(data.id, data.dematerialisationStatus)
  },

  async isBillsSolvable(store, productCode) {
    return api.isBillsSolvable(productCode)
  },

  async patchPaymentMeans(store, data) {
    return api.patchPaymentMeans(data)
  },

  async upgradeDirectDebit(store, data) {
    return api.upgradeDirectDebit(data)
  },

  async attributionCheckRegistrationNumber(state, registrationNumber) {
    return api.attributionCheckRegistrationNumber(registrationNumber)
  },

  async doesCardExistAndIsAvailable(state, cardNumber) {
    return api.doesCardExistAndIsAvailable(cardNumber)
  },

  async sendAttributions({ commit }, arrayAttributions) {
    await api
      .sendAttributions(arrayAttributions)
      .then((resp) => {
        commit('ATTRIBUTION_SET_RESPONSE_IDS', resp.data)
        return resp.data
      })
      .catch((resp) => {
        throw resp.response.data
      })
  },

  async attributionLoadOrder(state, ArrayBenefIds) {
    return api.attributionLoadOrder(ArrayBenefIds)
  },
  async getDatasFiltered(state, data) {
    return api.getDatasFiltered(data.url, data.params)
  },
  async getOrdersInformations({ commit }) {
    const ordersHistory = await api.getAllOrders()
    commit('SET_ORDERS', ordersHistory)
  },
  async getProfil() {
    return apiV3.session.getProfil()
  },
  async getOrderDetail(state, data) {
    return apiV4.ordersTracking.getOrderTracking(data)
  },
  // home
  async fetchOrders({ commit }) {
    const ghisto = await api.getHistory()
    commit('SET_ORDERS', ghisto)
  },
  async click(store, url) {
    return api.getClick(url)
  },
  async setBankErrors({ commit }) {
    try {
      const bankMessages = await api.getClickProxy('Documents_Espace_Client/Commande/bank-error.json')
      commit('SET_BANK_ERRORS', bankMessages)
    } catch (e) {
      console.error('Unable to get bank errors messages: ', e)
    }
  },
  async setCustomAlertsMessages({ commit }) {
    try {
      const customAlertsMessages = await api.getCustomAlertsMessages()
      commit('SET_CUSTOM_ALERTS_MESSAGES', customAlertsMessages)
      return customAlertsMessages
    } catch (e) {
      console.error('Unable to set custom alert messages: ', e)
    }
  },
  async setFilteredCustomAlertsMessages({ commit }, payload) {
    try {
      commit('SET_FILTERED_CUSTOM_ALERTS_MESSAGES', payload)
    } catch (e) {
      console.error('Unable to set filtered custom alert messages: ', e)
    }
  },
  async getContractPricing(store, { productCodeSource, productCodeTarget }) {
    return api.getContractPricing(productCodeSource, productCodeTarget)
  },
  async postContractualisation(store, { idContrat, productCodeSource, productCodeTarget }) {
    return api.postContractualisation(idContrat, productCodeSource, productCodeTarget)
  },

  // for FacialValue View
  async getAccountingDocumentsFiltered(store, { params, pageSize, page, filter, sort, sortDirection }) {
    return api.getDatasFiltered(`accountingDocuments${params}`, {
      pageSize: pageSize,
      page: page,
      filter: filter,
      sort: sort,
      sortDirection: sortDirection,
    })
  },
  // for FacialValue View
  async getOrdersHistoryFiltered(store, { params, pageSize, page, filter, sort, sortDirection }) {
    return api.getDatasFiltered(`history/ordersHistory${params}`, {
      pageSize: pageSize,
      page: page,
      filter: filter,
      sort: sort,
      sortDirection: sortDirection,
    })
  },

  setAccountingDocuments({ commit }, payload) {
    commit('SET_ACCOUNTING_DOCUMENTS', payload)
  },

  setOrdersHistory({ commit }, payload) {
    commit('SET_ORDERS', payload)
  },

  setPaymentMeans({ commit }, payload) {
    commit('SET_PAYMENT_MEANS_ADMIN', payload)
  },

  setImportRequestId({ commit }, payload) {
    commit('SET_IMPORT_REQUEST_ID', payload)
  },

  setImportSuccess({ commit }, payload) {
    commit('SET_IMPORT_SUCCESS', payload)
  },

  setImportId({ commit }, payload) {
    commit('SET_IMPORTED_ID', payload)
  },

  deleteImportSuccess({ commit }) {
    commit('DELETE_IMPORT_SUCCESS', null)
  },

  deleteImportRequestId({ commit }) {
    commit('DELETE_IMPORT_REQUEST_ID', null)
  },

  async fetchPaymentMeansFiltered(store, { params, pageSize, page, filter, sort, sortDirection }) {
    return api.getDatasFiltered(`paymentMeans${params}`, {
      pageSize: pageSize,
      page: page,
      filter: filter,
      sort: sort,
      sortDirection: sortDirection,
    })
  },
  clearImportError({ commit }) {
    commit('UNSET_DOUBLONS_ERROR')
  },
  setTotalBooklets({ commit }, payload) {
    commit('SET_TOTAL_BOOKLETS', payload)
  },
  setTotalCards({ commit }, payload) {
    commit('SET_TOTAL_CARDS', payload)
  },
  setTotalChecks({ commit }, payload) {
    commit('SET_TOTAL_CHECKS', payload)
  },
  setUniversId({ commit }, payload) {
    commit('SET_UNIVERS', payload)
  },
  setOrderRefFailMsg({ commit }, payload) {
    commit('SET_ORDER_REFERENCE_FAIL_MSG', payload)
  },
  setOrderReferenceSuccess({ commit }, payload) {
    commit('SET_ORDER_REFERENCE_SUCCESS', payload)
  },
  getIsOrderReferenceValid: async ({ state, commit }) => {
    if (!state.Order?.Reference?.trim().length && state.Order?.OrderReferenceMandatory) {
      commit('SET_ORDER_REFERENCE_SUCCESS', false)
      commit('SET_ORDER_REFERENCE_FAIL_MSG', 'REFERENCE_DEFAULT')
      throw 'La référence de commande est vide'
    }

    if (
      state.Order?.Reference &&
      (state.Order?.Reference?.includes(';') ||
        state.Order?.Reference?.includes('&') ||
        state.Order?.Reference?.includes('#'))
    ) {
      commit('SET_ORDER_REFERENCE_SUCCESS', false)
      commit('SET_ORDER_REFERENCE_FAIL_MSG', 'REFERENCE_INVALID')
      throw 'La référence de commande contient des charactères invalides'
    }

    commit('SET_AWAIT_SPINNER', true)

    const tempOrderReference = state.Order?.Reference
    try {
      await api.patchOrder(state.Order.Id, { Reference: state.Order?.Reference })

      if (tempOrderReference === state.Order?.Reference) {
        commit('SET_ORDER_REFERENCE', state.Order?.Reference)
        commit('SET_ORDER_REFERENCE_SUCCESS', true)
        commit('SET_ORDER_REFERENCE_FAIL_MSG', 'REFERENCE_DEFAULT')
      }
    } catch (e) {
      console.error('patchOrder error', e.response.data.ErrorCode)
      commit('SET_ORDER_REFERENCE', '')
      commit('SET_ORDER_REFERENCE_SUCCESS', false)
      commit('SET_ORDER_REFERENCE_FAIL_MSG', e.response.data.ErrorCode)
      throw e.response.data.ErrorCode
    }
    commit('SET_AWAIT_SPINNER', false)
    commit('SET_AWAIT_PATCH_ORDER', false)
  },
  setArticleCode({ commit }, payload) {
    commit('SET_CODE_ARTICLE', payload)
  },
  setHasPendingCall({ commit }, bool) {
    commit('SET_HAS_PENDING_CALL', bool)
  },
}
