import { type ModuleConfig, ModuleType } from '@/store/cart/model'
import type { Action } from '@/types/Action'

export const moduleConfig: ModuleConfig = {
  id: 'reimport-file-popin',
  type: ModuleType.Popin,
  component: () => import('@/components/popins/ClearCart/index.vue'),
}

export interface ClearCartWorkflow {
  title: string
  description: string
  confirmAction: Action
}
