import type { Route } from 'vue-router'
import * as Sentry from '@sentry/vue'
import type { TrackingProbe } from './types'

export function sentry(): TrackingProbe {
  return {
    captureException: (error: Error, data: Record<string, unknown>, route: Route) => {
      Sentry.captureException(error, { extra: { ...data, referrer: route.fullPath } })
    },
  }
}
