export interface Address {
  address1: string | null
  address2: string | null
  address3: string | null
  zipCode: string | null
  city: string | null
  country: string | null
  streetAddress?: string | null
}

export function mapAddressToHtml(address: Address, name: string | null = null, separator = '<br />'): string {
  return [
    name,
    address.address1,
    address.address2,
    address.address3,
    `${address.zipCode} ${address.city}`,
    address.country,
  ]
    .filter((address) => address)
    .join(separator)
}
