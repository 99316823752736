import basket from '../../common/steps/basket'
import type { BasketWorkflow } from '@/components/Cart/Step/Basket/workflow'
import type { StepBuilder } from '@/store/cart/model'

const step: StepBuilder<BasketWorkflow> = {
  ...basket,

  useStep(cart, workflow, record, useAction) {
    const useStep = basket.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const useWorkflow = useStep.useWorkflow()

        useWorkflow.table.value.columns = [
          { id: 'format', label: 'table.headers.format' },
          { id: 'quantity', label: 'table.headers.quantity' },
          { id: 'total', label: 'table.headers.total' },
          { id: 'delivery', label: 'table.headers.delivery' },
          { id: 'more', label: '' },
        ]

        return {
          ...useWorkflow,
        }
      },
    }
  },
}

export default step
