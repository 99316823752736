import { Result } from '@badrap/result'
import type { AxiosError, AxiosInstance } from 'axios'
import type { Pagination } from '../common/types'
import type { FetchOrderTrackingDeliveryPointsParams, OrderTrackingDeliveryPoint } from './types'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = '/history/ordersHistory/:id'
const getBaseUrl = urlReplacer(baseUrl)

export default (axiosInstance: AxiosInstance) => {
  const fetchOrderTrackingDeliveryPoints: (
    id: string,
    params: FetchOrderTrackingDeliveryPointsParams,
  ) => Promise<Result<Pagination<OrderTrackingDeliveryPoint>>> = async (id, params) => {
    try {
      const result = await axiosInstance.get<OrderTrackingDeliveryPoint[]>(`${getBaseUrl({ id })}/deliveryPoints`, {
        params: {
          pageSize: params.pageSize ?? 50,
          page: params.page ?? 1,
          sort: 'Reference',
          sortDirection: 'Asc',
        },
      })

      return Result.ok({
        pageIndex: params.page ?? 1,
        pageSize: params.pageSize ?? 50,
        items: result.data,
        totalElements: result.headers['x-total-count'],
      })
    } catch (error) {
      return Result.err(error as AxiosError)
    }
  }

  return {
    fetchOrderTrackingDeliveryPoints,
  }
}
