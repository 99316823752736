const baseUrl = '/history'

export default (axiosInstance) => {
  const getAllOrders = async (params) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/ordersHistory`, { params })
      return result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  // home 4 dernieres commandes
  const getHistory = async () => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/lastOrders`)
      return result.data
    } catch (error) {
      console.error('error get History', error)
      return []
    }
  }

  // téléchargement du bordereau
  const getHistoryReceipt = async (orderNumber) => {
    try {
      const result = await axiosInstance.post(`${baseUrl}/orderHistory/${orderNumber}/deliveryReceipt`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getHistoryDeliveryPoints = async (orderNumber, params) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/ordersHistory/${orderNumber}/deliveryPoints`, { params })
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getHistoryBenefiairies = async (orderNumber, params) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/ordersHistory/${orderNumber}/beneficiairies`, { params })
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    getAllOrders,
    getHistory,
    getHistoryReceipt,
    getHistoryDeliveryPoints,
    getHistoryBenefiairies,
  }
}
