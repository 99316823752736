import { computed, ref } from 'vue'
import { Result } from '@badrap/result'
import type { BenefitsCalculatorImportStep, BenefitsCalculatorStepBuilder } from '../types'
import { useBenefitsCalculatorStore } from '..'
import { ActionType } from '@/types/Action'
import { useAction } from '@/composables/useAction'
import { ImportStatus } from '@/components/Cart/Step/Import/workflow'
import { fetchSeparator, parseCSV } from '@/utils/csv'
import { useDownloadFile } from '@/composables/useDownloadFile'
import { useI18n } from '@/composables/useI18n'
import { formatDate, isValidDate, parseDate } from '@/utils/date'
import { accessor } from '@/store'
import { ProductCode } from '@/variables/ProductCode'
import type { ImportField } from '@/types/Import'

export interface DaysWorkedHeaders {
  matricule: string
  nom: string
  prenom: string
  'date de naissance': string
  'adresse 1': string
  'code postal': string
  ville: string
  pays: string
  'vf en centimes': string
  'pourcentage pp': string
  'nombre jours': string
  'ref pl': string
  'ref pdist'?: string
}

const csvNames: Record<string, keyof DaysWorkedHeaders> = {
  '009_MATRICULE_BENEFICIAIRE': 'matricule',
  '013_NOM_BENEFICIAIRE': 'nom',
  '014_PRENOM_BENEFICIAIRE': 'prenom',
  '015_DATE_NAISSANCE_BENEFICIAIRE': 'date de naissance',
  '020_ADRESSE_1_BENEFICIAIRE': 'adresse 1',
  '023_CODE_POSTAL_BENEFICIAIRE': 'code postal',
  '024_VILLE_BENEFICIAIRE': 'ville',
  '025_PAYS_BENEFICIAIRE': 'pays',
  '036_VALEUR_FACIALE_CENTIME_EUROS': 'vf en centimes',
  '039_PART_PATRONALE_EN_POURCENTAGE': 'pourcentage pp',
  BENEFITS_CALCULATOR_COLUMN_WORKING_DAY_COUNT: 'nombre jours',
  '002_CODE_PL': 'ref pl',
  '003_CODE_PDIST': 'ref pdist',
}

const step: BenefitsCalculatorStepBuilder<BenefitsCalculatorImportStep<DaysWorkedHeaders>> = {
  config: {
    id: 'jours-travailles-theoriques',
    component: () => import('@/views/BenefitsCalculator/Step.vue'),
    canSkip: false,
  },
  useStep(cfStep) {
    const isPfa = computed(
      () => accessor.session.clientProducts[ProductCode.CARTE_TICKET_RESTAURANT]?.accountingSettings?.isPFA ?? false,
    )

    const fields: ImportField[] = cfStep.fields.map<ImportField>((cfField) => {
      const csvName = csvNames[cfField.fcmsName]
      const required = cfField.fcmsName === '003_CODE_PDIST' ? isPfa.value : true

      return {
        ...cfField,
        csvName: required ? csvName.toUpperCase() : csvName.toLocaleLowerCase(),
        required,
      }
    })

    const filename = ref('Beneficiaires.csv')
    const file = ref<File>()
    const fileContent = ref<DaysWorkedHeaders[] | null>(null)
    const status = ref(ImportStatus.Default)
    const errors = ref<string[]>([])

    const store = useBenefitsCalculatorStore()
    const i18n = useI18n()

    return {
      fields,
      filename,
      file,
      fileContent,
      status,
      errors,
      downloadTemplateAction: useAction({
        id: 'download-template',
        name: 'cart.import.download',
        icon: 'document-download',
        type: ActionType.Default,
        refresh: false,
        async execute() {
          const downloadFile = useDownloadFile()
          const blobFile = new Blob([fields.map((field) => field.csvName).join(';') ?? ''], {
            type: 'text/csv',
          })

          downloadFile(blobFile, i18n.t('benefitsCalculator.templateFilename').toString())
          return Result.ok(true)
        },
      }),
      importAction: useAction({
        id: 'import-file',
        name: 'cart.button.importCsv',
        type: ActionType.Default,
        refresh: false,
        async execute() {
          if (file.value == null) {
            return Result.err(new Error('cart.import.errors.fileNotExist'))
          }

          const resultSeparator = await fetchSeparator(file.value)
          if (resultSeparator.isErr) {
            return Result.err(resultSeparator.error)
          }

          status.value = ImportStatus.Importing
          filename.value = file.value.name
          fileContent.value = null
          errors.value = []

          const result = await parseCSV<DaysWorkedHeaders>(file.value)

          if (result.isErr) {
            status.value = ImportStatus.Error
            return Result.err(result.error)
          }

          fileContent.value = result.value

          result.value.forEach((line, index) => {
            if (
              !line.matricule ||
              !line.nom ||
              !line.prenom ||
              !line['date de naissance'] ||
              !line['adresse 1'] ||
              !line['code postal'] ||
              !line.ville ||
              !line.pays ||
              !line['vf en centimes'] ||
              !line['pourcentage pp'] ||
              !line['nombre jours'] ||
              !line['ref pl']
            ) {
              errors.value.push(i18n.t('benefitsCalculator.errors.lineBadFormat', { number: index + 1 }).toString())
              return
            }

            const daysWorked = Number.parseFloat(line['nombre jours'].replace(',', '.'))
            if (isNaN(daysWorked) || daysWorked < 0) {
              errors.value.push(
                i18n.t('benefitsCalculator.errors.daysWorkedBadFormat', { number: index + 1 }).toString(),
              )
              return
            }

            const valueOfTitle = Number.parseInt(line['vf en centimes'], 10)
            if (isNaN(valueOfTitle) || valueOfTitle <= 0) {
              errors.value.push(
                i18n.t('benefitsCalculator.errors.valueOfTitleBadFormat', { number: index + 1 }).toString(),
              )
              return
            }

            const employersContribution = Number.parseFloat(line['pourcentage pp'].replace(',', '.'))
            if (isNaN(employersContribution) || employersContribution < 50 || employersContribution > 60) {
              errors.value.push(
                i18n.t('benefitsCalculator.errors.employersContributionBadFormat', { number: index + 1 }).toString(),
              )
              return
            }

            if (!isValidDate(parseDate(line['date de naissance'], 'dd/MM/yyyy'))) {
              errors.value.push(
                i18n.t('benefitsCalculator.errors.birthDateBadFormat', { number: index + 1 }).toString(),
              )
            }

            if (isPfa.value && !line['ref pdist']) {
              errors.value.push(
                i18n.t('benefitsCalculator.errors.distributionPointRequired', { number: index + 1 }).toString(),
              )
            }
          })

          const matricules = result.value.reduce<string[]>((carry, line) => {
            carry.push(line.matricule)
            return carry
          }, [])

          const matriculesDeduplicated = new Set(matricules)

          if (matriculesDeduplicated.size < matricules.length) {
            errors.value.push(i18n.t('benefitsCalculator.errors.duplicatesRegistrationNumber').toString())
          }

          if (errors.value.length > 0) {
            status.value = ImportStatus.Error
            return Result.err(new Error('cart.import.errors.fileNotExist'))
          }

          status.value = ImportStatus.Success

          return Result.ok(true)
        },
      }),
      saveAction: useAction({
        id: 'save',
        name: 'confirm',
        type: ActionType.Default,
        refresh: false,
        async execute() {
          if (fileContent.value == null) {
            return Result.err(new Error('cart.import.errors.fileNotExist'))
          }

          if (status.value !== ImportStatus.Success) {
            return Result.err(new Error('cart.import.errors.fileNotExist'))
          }

          fileContent.value.forEach((line) => {
            const daysWorked = Number.parseFloat(line['nombre jours'].replace(',', '.'))

            store.items.push({
              beneficiary: {
                registrationNumber: line.matricule,
                firstName: line.prenom,
                name: line.nom,
                birthdate: formatDate(parseDate(line['date de naissance'], 'dd/MM/yyyy'), 'yyyy-MM-dd') ?? '',
                address: {
                  address1: line['adresse 1'],
                  address2: null,
                  address3: null,
                  zipCode: line['code postal'],
                  city: line.ville,
                  country: line.pays,
                },
              },
              valueOfTitle: Number.parseInt(line['vf en centimes'], 10),
              employersContribution: Number.parseFloat(line['pourcentage pp'].replace(',', '.')),
              deliveryPoint: line['ref pl'],
              distributionPoint: line['ref pdist'] ?? null,
              daysWorked,
              leaveDays: null,
              companyMeals: null,
              expenseReports: null,
              total: daysWorked,
            })
          })
          return Result.ok(true)
        },
      }),
    }
  },
}

export default step
