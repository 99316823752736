import actions from './actions'
import { state as deliveryPointsState } from './deliveryPoints'
import getters from './getters'
import mutations from './mutations'
import { state as productState } from './product'
import { state as vfState } from './vf'

export default {
  namespaced: true,
  state: {
    ...productState(),

    allEvents: null,
    popinChoixTunnelVisible: true,
    bankErrors: null,
    partnerId: null,
    customAlertsMessages: null,
    filteredCustomAlertsMessages: null,
    Attribution: {
      Basket: [],
      BeneficiariesIds: [],
    },
    Users: {
      listRoles: [],
      restrictions: [],
      userRoleFiltered: {},
      usersTotal: null,
      alert: {
        show: false,
        message: '',
        name: '',
      },
    },
    Customer: {
      ...vfState(),
      ...deliveryPointsState(),
      PaymentMeans: [],
      MillesimeInformation: null,
      OrdersHistory: [],
    },
    ProfilEditionModel: {
      ProfileGeneralInfos: null,
      ProfilePassword: null,
    },
    Invoices: {},
    Order: {},
    Reference: {
      orderReferenceSuccess: null,
      orderReferenceFailMessage: 'REFERENCE_DEFAULT',
      awaitSpinner: false,
      isWaitingPatchOrder: false,
    },
    Import: {
      Id: null,
      ImportedFieldsDescription: [],
      RequestId: null,
    },
    BeneficiariesTotal: 0,
    Beneficiaries: {
      BeneficiariesOutOfOrder: [],
      BeneficiariesOutOfOrderLength: 0,
      BeneficiariesInOrder: [],
      BeneficiariesInOrderLength: 0,
      BeneficiariesWithoutAddresses: [],
      BeneficiariesWithoutAddressesLength: 0,
      BeneficiariesNewLength: 0,
      /* BeneficiariesUpdatedLength: 0, */
      BeneficiariesError: [],
      BeneficiariesErrorLength: [],
      BeneficiariesDuplicated: {},
      BeneficiariesDuplicatedLength: 0,
      BeneficiariesDuplicatedRemaining: null,
      SearchInput: null,
      ItemByPage: null,
      setSelectedInOrder: 0,
      setSelectedOutOrder: 0,
    },
    HasPendingCall: false,
    Alert: {
      message: '',
      css: '',
      show: false,
    },
    effectTabs: null,
    referentielclient: null,
    Faq: {
      Pictos: [],
      Categories: [],
    },
    errors: {},
  },
  actions,
  mutations,
  getters,
}
