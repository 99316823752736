import type { SimpleLink } from './Link'

export enum AlertType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
}

export interface Alert {
  title: string
  description?: string | null
  type: AlertType
  cta?: SimpleLink | null
  active?: boolean
}

export interface Composing {
  name: string
  props: object
}

export interface AlertList {
  identifier: string | null
  type: AlertType
  message: string | null
  withClose?: boolean
  component?: Composing | null
  timer: number | null
  withIcon: boolean
  name: string
}
