import type { ErrorObject } from '@vuelidate/core'
import { useI18n } from '@/composables/useI18n'

type Address = {
  Address1: string
  ZipCode: string
  City: string
  Country: string
}

export function requiredAddressIf(address: Address, isPlasticless: boolean) {
  return !!(!isPlasticless || address.Address1 || address.ZipCode || address.City || address.Country)
}

export function getError(errors: ErrorObject[]) {
  if (errors.length === 0) {
    return undefined
  }

  return useI18n().t(errors[0].$message.toString()).toString()
}

export function getErrorMessage(errors: ErrorObject[]) {
  if (errors.length === 0) {
    return undefined
  }

  return errors[0].$message.toString()
}

export function getErrors(errors: ErrorObject[]) {
  return errors.map((error) => error.$message.toString())
}
