import basket from '../../common/steps/basket'
import type { BasketWorkflow } from '@/components/Cart/Step/Basket/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { moduleConfig as clearCartPopupConfig } from '@/components/popins/ClearCart/workflow'
import { stepConfig as importStepConfig } from '@/components/Cart/Step/Import/workflow'
import { ActionType } from '@/types/Action'
import { DataStoreKey } from '@/services/dataStore/types'
import settings from '@/store/cart/cartWorkflows/common/module/settings/KSN'
import { ArticleCode } from '@/variables/ArticleCode'
import { useCommonBasketItemWorkflow } from '@/store/cart/cartWorkflows/common/steps/basket'

const step: StepBuilder<BasketWorkflow> = {
  ...basket,

  useStep(cart, workflow, record, useAction) {
    const useStep = basket.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const useWorkflow = useStep.useWorkflow()

        useWorkflow.table.value.readonly = true
        useWorkflow.table.value.rowComponent = () => import('@/components/Cart/Step/Basket/Row/KSNRow.vue')
        useWorkflow.table.value.columns = [
          { id: 'beneficiary', label: 'table.headers.beneficiary' },
          { id: 'event', label: 'table.headers.event' },
          { id: 'amount', label: 'table.headers.amount' },
          { id: 'delivery', label: 'table.headers.delivery' },
        ]

        const isCulture =
          cart.meta.articleCode &&
          [ArticleCode.CARTE_KADEOS_CULTURE, ArticleCode.KADEOS_CULTURE].includes(cart.meta.articleCode)

        if (isCulture) {
          useWorkflow.table.value.columns.splice(1, 1)
        }

        const dataStoreAmount = cart.meta.dataStore[DataStoreKey.Amount]
        const dataStoreEvent = cart.meta.dataStore[DataStoreKey.Event]
        const dataStoreDeliveries = cart.meta.dataStore[DataStoreKey.DeliveryPoint]
        const dataStoreDeliveryPoint = dataStoreDeliveries ? dataStoreDeliveries[cart.meta.productCode] : null
        const showEvent = !isCulture
        const commonBasketItemWorkflow = useCommonBasketItemWorkflow(cart, workflow, record, useAction)

        return {
          ...useWorkflow,
          hasAdd: false,
          hasImport: true,
          addAction: null,
          showSettings:
            !dataStoreAmount || (showEvent && !dataStoreEvent) || (!cart.meta.isCrossSell && !dataStoreDeliveryPoint),
          settingsAction: useAction({
            name: 'settings',
            id: settings.config.id,
            type: ActionType.Drawer,
            refresh: false,
            payload: {
              showEvent: true,
              showAmount: true,
              showDelivery: !cart.meta.isCrossSell,
              close: false,
            },
          }),
          importAction: useAction({
            id: importStepConfig.id,
            type: ActionType.Step,
            name: 'cart.button.importCsv',
            icon: 'document-upload',
            refresh: false,
          }),
          reimportAction: useAction({
            id: clearCartPopupConfig.id,
            type: ActionType.Popin,
            name: 'cart.button.reimportCsv',
            icon: 'document-upload',
            refresh: false,
            payload: { key: 'reimportFile' },
          }),
          useItemWorkflow: (item) => {
            return {
              ...commonBasketItemWorkflow(item),
              updateAction: null,
            }
          },
        }
      },
    }
  },
}

export default step
