import { ConnexionOrigin } from '@/variables/ConnexionOrigin'
import { mapUserToLocal } from '@/store/session/model'

export interface Token {
  accessToken?: string
  refreshToken?: string
}

export function useIsSSO() {
  const token: string | null = localStorage.getItem('token')
  if (!token) {
    return false
  }
  const { accessToken }: Token = JSON.parse(token)
  if (!accessToken) {
    return false
  }
  const res = mapUserToLocal(accessToken)
  if (res.isOk) {
    const { exc_cnx } = res.value
    return exc_cnx == ConnexionOrigin.SSO
  }
  return false
}
