const baseUrl = '/cgv'

export default (axiosInstance) => {
  const postCgvVersion = async (producCode, version) => {
    try {
      const result = await axiosInstance.post(baseUrl, {
        ProductCode: producCode,
        Version: version,
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getCgvVersion = async (productCode) => {
    try {
      const result = await axiosInstance.get(baseUrl, {
        params: {
          productCode,
        },
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    postCgvVersion,
    getCgvVersion,
  }
}
