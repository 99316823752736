import { Block, type StepConfig, type StepWorkflow } from '@/store/cart/model'

export const stepConfig: StepConfig = {
  id: 'loading',
  path: 'chargement',
  name: 'Chargement',
  blocks: {
    header: Block.Fixed,
  },
}

export interface LoadingWorkflow extends StepWorkflow {
  isReady(): Promise<boolean>
}
