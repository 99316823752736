import type { Result } from '@badrap/result'
import { Block, type StepConfig, type StepWorkflow } from '@/store/cart/model'
import type { Pagination } from '@/services/common/types'
import type { Action } from '@/types/Action'
import type { ImportConflict, ImportDuplicate } from '@/services/beneficiaries/types'

export const stepConfig: StepConfig = {
  id: 'dedupe',
  path: 'dedoublonage',
  name: 'Dédoublonage',
  blocks: {
    header: Block.Fixed,
  },
}

export interface DedupeWorkflow extends StepWorkflow {
  importAction: Action
  addItemAction: (beneficiaryId: number) => Action
  replaceItemAction: (beneficiaryId: number, duplicateId: number) => Action
  headImportConflicts(): Promise<Result<number>>
  fetchImportConflicts(perPage: number): Promise<Result<Pagination<ImportConflict>>>
  fetchImportDuplicate(beneficiaryId: number): Promise<Result<ImportDuplicate[]>>
}
