import { Result } from '@badrap/result'
import type { ContentfulClientApi, Entry } from 'contentful'
import type { TypeEventSkeleton, TypeProductSkeleton } from '../generated'
import type { CFEvent } from '../types/CFEvent'

export default (contentfulInstance: ContentfulClientApi<undefined>) => {
  const fetchEvents = async () => {
    try {
      const result = await contentfulInstance.getEntries<TypeEventSkeleton>({
        content_type: 'event',
      })
      return Result.ok(mapEventToLocal(result.items))
    } catch (error) {
      return Result.err(error as Error)
    }
  }

  return {
    fetchEvents,
  }
}

function mapEventToLocal(items: Entry<TypeEventSkeleton, undefined, string>[]): CFEvent[] {
  return items
    .map((item) => {
      return {
        ...item.fields,
        products: (item.fields.products as Entry<TypeProductSkeleton, undefined, string>[]).map(
          (product) => product.fields.code,
        ),
      }
    })
    .sort((a, b) => a.order - b.order)
}
