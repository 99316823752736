import type { ComputedRef, Ref } from 'vue'
import type { Validation, ValidationArgs } from '@vuelidate/core'
import type { Result } from '@badrap/result'
import type { UseDeliveryPointWorkflow, UseValueOfTitleWorkflow } from '../common/workflow'
import type { CartItemType, Message } from '@/services/carts/types'
import type { DeliveryPointItem } from '@/services/deliveryPoints/types'
import { type ModuleConfig, ModuleType, type LocalDeliveryPoint } from '@/store/cart/model'
import type { Action } from '@/types/Action'
import type { Tab } from '@/types/Tab'
import type { CFCartItemConfig } from '@/services/contentful/types/CFProduct'
import type { CFEvent } from '@/services/contentful/types/CFEvent'
import type { ValueOfTitleData } from '@/types/ValueOfTitle'
import type { CFMedia } from '@/services/contentful/types/CFMedia'

export const moduleConfig: ModuleConfig = {
  id: 'add-item',
  type: ModuleType.Drawer,
  component: () => import('@/components/Cart/Module/AddItem/index.vue'),
}

export const moduleTRConfig: ModuleConfig = {
  id: 'add-item',
  type: ModuleType.Drawer,
  component: () => import('@/components/Cart/Module/AddItem/TR.vue'),
}

export interface AmountData {
  value: number
  hasDecomposition: boolean
  decompositions: {
    value: number
    count: number
  }[]
}

export interface AddItemForm {
  itemType: CartItemType
  event: CFEvent | null
  quantity: number
  amount: AmountData | null
  deliveryPoint: AddItemDeliveryPoint | null
  message: Message
  pocketsQuantity: number
}

export interface MessageConfig {
  show: boolean
  maxChar: number
}

export interface MessageConfigs {
  line1: MessageConfig
  line2: MessageConfig
  line3: MessageConfig
}

export interface AddItemWorkflow {
  events: CFEvent[]
  deliveryPointTitle: string
  messageConfigs: MessageConfigs
  itemConfig: ComputedRef<CFCartItemConfig | undefined>
  itemConfigs: CFCartItemConfig[]
  itemTypeImage: ComputedRef<CFMedia | null>
  v$: Ref<Validation<ValidationArgs<AddItemForm>, AddItemForm>>
  showEvent: boolean
  showDeliveryPoint: boolean
  showPocket: ComputedRef<boolean>
  pocketImage: CFMedia | null
  addAction: Action
}

export interface AddTRItemForm {
  itemType: CartItemType
  quantity: number
  titleQuantity: number
}

export interface AddTRItemWorkflow {
  itemConfig: ComputedRef<CFCartItemConfig | undefined>
  itemConfigs: CFCartItemConfig[]
  itemTypeImage: ComputedRef<CFMedia | null>
  v$: Ref<Validation<ValidationArgs<AddTRItemForm>, AddTRItemForm>>
  useValueOfTitleWorkflow: UseValueOfTitleWorkflow
  useDeliveryPointWorkflow: UseDeliveryPointWorkflow
  addAction: (
    submitValueOfTitle: () => Promise<Result<ValueOfTitleData>>,
    submitDeliveryPoint: () => Promise<Result<LocalDeliveryPoint>>,
  ) => Action
}

export interface AddItemDeliveryPoint {
  reference: string
  hasDistribution: boolean
  distributionRef?: string
  address?: string
}

export interface RecommendedAmount {
  title: string
  tooltip?: string
}

export interface AmountChoice {
  value: number
  recommended?: true
}

export interface ItemTypeTab extends Tab {
  id: 'booklet' | 'board' | 'card' | 'dematerialized'
  type: CartItemType
  meta: {
    minQuantity: number
    maxQuantity: number
    minAmount: number
    maxAmount: number
    amountChoices: AmountChoice[]
  }
}

export const MAX_KS_MESSAGE_CHAR = 50
export const MAX_CKS_LINE_1_MESSAGE_CHAR = 19
export const MAX_CKS_LINE_2_MESSAGE_CHAR = 20

export interface DeliveryPointData {
  filter: string | null
  pageIndex: number
  totalItems: number
  deliveryPoints: DeliveryPointItem[]
  defaultDeliveryPoints: DeliveryPointItem[]
}
