import { ApiError, type ApiAxiosError } from '../common/errors'

export const errorMessage: Record<string, string> = {}

export function getError(key: string): string {
  return errorMessage[key] ?? 'errors.unknown.description'
}

export class BeneficiariesError extends ApiError {
  constructor(error: ApiAxiosError) {
    super(error)

    this.name = 'BeneficiariesError'
    this.message = getError(this.code)

    if (error.response?.data.title === 'InvalidDiscountCode') {
      this.message = `cart.errors.${error.response.data.title}`
    }
  }
}
