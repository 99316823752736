const baseUrl = '/paymentMeans'

export default (axiosInstance) => {
  const getPaymentMeans = async (productCode) => {
    try {
      const result = await axiosInstance.get(baseUrl, {
        params: {
          productCode,
          full: true,
        },
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getAllPaymentMeans = async (params) => {
    try {
      const result = await axiosInstance.get(baseUrl, { params })
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const isBillsSolvable = async (productCode) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/billsSolvency?productCode=${productCode}`)
      return result
    } catch (error) {
      console.error(error)
      return error.response
    }
  }

  const patchPaymentMeans = async (patchData) => {
    try {
      const result = await axiosInstance.patch(
        `${baseUrl}/${patchData.ProductCode}?passToDirectDebit=${patchData.passToDirectDebit}`,
        patchData,
      )
      return result
    } catch (error) {
      console.error(error)
      return error.response
    }
  }

  const upgradeDirectDebit = async (data) => {
    try {
      const result = await axiosInstance.get(
        `${baseUrl}/upgradedirectdebit?productCode=${data.ProductCode}&bic=${data.BIC}&iban=${data.IBAN}`,
      )
      return result
    } catch (error) {
      console.error(error)
      return error.response
    }
  }

  return {
    getPaymentMeans,
    getAllPaymentMeans,
    isBillsSolvable,
    patchPaymentMeans,
    upgradeDirectDebit,
  }
}
