import pagination from '@/assets/js/pagination'

export const deliveryPointsPagination = pagination({
  initState: {
    page: 1,
    filter: '',
    sortDirection: 'Asc',
    items: [],
    sort: 'Reference',
    pageSize: 2499, // to delete when PL paginable
  },
})
