import type { RemoteCart } from '../../model'
import apiV4 from '@/services/apiV4'
import { fetchPrices, fetchPricesWithSummary, type PricingData } from '@/types/Price'
import useSwrv from '@/utils/useSwrv'
import { PriceKey } from '@/services/carts/types'
import type { Cart } from '@/services/carts/typesV2'
import { ProductCode } from '@/variables/ProductCode'

export function usePricing(cart: RemoteCart, readonly = false) {
  return useSwrv<PricingData>(`cart/${cart.remote.id}/pricing`, async () => {
    const pricing = await apiV4.carts.fetchPricing(cart.remote.id, readonly)

    return {
      readonly,
      productCode: cart.remote.description.productCode,
      prices: fetchPrices(pricing),
      pricing,
    }
  })
}

export function usePricingSummary(cart: RemoteCart) {
  return useSwrv<PricingData>(`cart/${cart.remote.id}/pricing/summary`, async () => {
    const pricing = await apiV4.carts.fetchPricingSummary(cart.remote.id)

    return {
      readonly: true,
      productCode: cart.remote.description.productCode,
      prices: fetchPricesWithSummary(pricing),
    }
  })
}

export function useMixedPricings(carts: Cart[], readonly = false) {
  return carts.map((cart) => {
    const key = cart.description.productCode === ProductCode.CARTE_TICKET_RESTAURANT ? 'card' : 'paper'
    return useSwrv<PricingData>(`cart/mixed/${cart.id}/paper/pricing`, async () => {
      const pricing = await apiV4.carts.fetchPricing(cart.id, readonly)
      return {
        readonly,
        productCode: cart.description.productCode,
        prices: fetchPrices(pricing).map((price) => {
          if (price.id === PriceKey.orderedAmount) {
            price.label = `cart.pricing.mixed.${key}.orderedAmount`
          } else if (price.id === PriceKey.totalWithVAT) {
            price.label = `cart.pricing.mixed.${key}.totalWithVAT`
          }

          return price
        }),
        pricing,
      }
    })
  })
}

export function useMixedPricingSummaries(carts: Cart[]) {
  return carts.map((cart) => {
    const key = cart.description.productCode === ProductCode.CARTE_TICKET_RESTAURANT ? 'card' : 'paper'
    return useSwrv<PricingData>(`cart/mixed/${cart.id}/paper/pricing`, async () => {
      const pricing = await apiV4.carts.fetchPricingSummary(cart.id)
      return {
        readonly: true,
        productCode: cart.description.productCode,
        prices: fetchPricesWithSummary(pricing).map((price) => {
          if (price.id === PriceKey.orderedAmount) {
            price.label = `cart.pricing.mixed.${key}.orderedAmount`
          }

          return price
        }),
      }
    })
  })
}
