import type { Ref } from 'vue'
import type { Validation, ValidationArgs } from '@vuelidate/core'
import { type ModuleConfig, ModuleType } from '@/store/cart/model'
import type { Action } from '@/types/Action'

export const moduleConfig: ModuleConfig = {
  id: 'edit-ref-popin',
  type: ModuleType.Popin,
  component: () => import('@/components/popins/EditReference/index.vue'),
}
export interface EditReferenceState {
  reference: Ref<string | undefined>
}

export interface EditReferenceWorkflow {
  title: string
  v$: Ref<Validation<ValidationArgs<EditReferenceState>, EditReferenceState>>
  editReference: Action
}
