import type { AxiosError } from 'axios'
import { ResultError, UNKNOWN_ERROR } from '@/types/ResultError'

export type ApiAxiosError = AxiosError<{ ErrorCode?: string; error?: string; title?: string; type?: string }>

export class ApiError extends ResultError {
  axiosError: AxiosError
  code: string

  constructor(error: ApiAxiosError) {
    super(error)
    const code =
      error.response?.data?.ErrorCode ?? error.response?.data?.error ?? error.response?.data?.title ?? UNKNOWN_ERROR

    this.name = 'ApiError'
    this.stack = new Error().stack
    this.code = code
    this.axiosError = error
  }
}
