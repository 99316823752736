import { capitalize } from 'lodash'
import type { FieldDescription } from '@/services/fileUpload/types'
import type { CFImportField } from '@/services/contentful/types/CFImportField'
import type { ProductCode } from '@/variables/ProductCode'

export type ImportField = CFImportField & {
  csvName: string
  required: boolean
}

export type ApiImportField = {
  Description: string
  Nom: string
  NomFcms: string
  NomPersonnalise: string | null
  Obligatoire: boolean
}

export enum FileUploadStatus {
  InProgress = 0,
  Success = 2,
  Error = 3,
}

export type FileUploadStatusResponse =
  | FileUploadInProgressResponse
  | FileUploadSuccessResponse
  | FileUploadErrorResponse

export type FileUploadInProgressResponse = {
  status: FileUploadStatus.InProgress
  actualStep: ImportLoading
}

export type FileUploadSuccessResponse = {
  status: FileUploadStatus.Success
  orderId: number
}

export type FileUploadErrorResponse = {
  status: FileUploadStatus.Error
  errorMessage: string
  details: ImportErrorDetail[]
}

export type ImportErrorDetail = {
  title: string | null
  reference: string
  message: string
  lines: number[]
}

export type ApiFileUploadStatusResponse =
  | ApiFileUploadInProgressResponse
  | ApiFileUploadSuccessResponse
  | ApiFileUploadErrorResponse

export type ApiFileUploadInProgressResponse = {
  Status: FileUploadStatus.InProgress
  ActualStep: ImportLoading
}

export type ApiFileUploadSuccessResponse = {
  Status: FileUploadStatus.Success
  OrderId: number
}

export type ApiFileUploadErrorResponse = {
  Status: FileUploadStatus.Error
  ErrorMessage: string
  Details: ApiImportErrorDetail[]
}

export type ApiImportErrorDetail = {
  Reference: string
  Message: string
  Lines: number[]
}

export enum ImportLoading {
  FileTransfert = 'FILE_TRANSFERT',
  Analyse = 'ANALYSE',
  SavingFile = 'SAVING_FILE',
  FileControle = 'FILE_CONTROLE',
  SavingCart = 'SAVING_CART',
  CheckDuplicate = 'CHECK_DUPLICATES',
  Display = 'DISPLAY',
}

export function mapRulesToLocal(
  productCode: ProductCode,
  fields: FieldDescription[],
  cfImportFields: CFImportField[],
): ImportField[] {
  const fieldsWithProductCode = cfImportFields.filter((field) => field.productCode === productCode) ?? []

  const filteredImportFields = cfImportFields
    .filter((field) => field.productCode == null)
    .reduce<CFImportField[]>((r, field) => {
      if (r.findIndex((s) => s.fcmsName === field.fcmsName) === -1) {
        r.push(field)
      }
      return r
    }, fieldsWithProductCode)

  return fields.reduce<ImportField[]>((r, field) => {
    const importField = filteredImportFields.find((importField) => importField.fcmsName === field.fcmsName)

    if (importField) {
      const name = field.customName ?? importField.label
      r.push({
        ...importField,
        label: field.mandatory ? name.toUpperCase() : capitalize(name),
        csvName: field.csvName,
        required: field.mandatory,
      })
    } else {
      const name = field.customName ?? field.name
      r.push({
        ...field,
        label: field.mandatory ? name.toUpperCase() : capitalize(name),
        required: field.mandatory,
      })
    }

    return r
  }, [])
}

export function mapFileUploadStatusToLocal(response: ApiFileUploadStatusResponse): FileUploadStatusResponse {
  switch (response.Status) {
    case FileUploadStatus.Error:
      return {
        status: response.Status,
        errorMessage: response.ErrorMessage,
        details:
          response.Details?.map((detail) => {
            const field = detail.Message.match('colonne "(.*)"')

            return {
              title: field && field.length > 1 ? capitalize(field[1]) : null,
              reference: detail.Reference,
              message: detail.Message,
              lines: detail.Lines,
            }
          }) ?? [],
      }
    case FileUploadStatus.InProgress:
      return {
        status: response.Status,
        actualStep: response.ActualStep,
      }
    case FileUploadStatus.Success:
      return {
        status: response.Status,
        orderId: response.OrderId,
      }
  }
}
