import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import type { MigrationInformations, MigrationRedirect } from './types'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = 'api/:version/migration'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
})

export default (axiosInstance: AxiosInstance) => {
  const getMigrationInformations: () => Promise<Result<MigrationInformations | null>> = async () => {
    try {
      const result = await axiosInstance.get<MigrationInformations>(`${getBaseUrl()}/informations`)

      if (result.status === 204) {
        return Result.ok(null)
      }

      return Result.ok(result.data)
    } catch (e) {
      return Result.err(e as ApiAxiosError)
    }
  }

  const getMigrationRedirect: () => Promise<Result<MigrationRedirect | null>> = async () => {
    try {
      const result = await axiosInstance.get<MigrationRedirect>(`${getBaseUrl()}/redirect`)

      if (result.status === 204) {
        return Result.ok(null)
      }

      return Result.ok(result.data)
    } catch (e) {
      return Result.err(e as ApiAxiosError)
    }
  }

  return {
    getMigrationInformations,
    getMigrationRedirect,
  }
}
