import { useDownloadFile } from '@/composables/useDownloadFile'

const downloadFile = useDownloadFile()

export default (axiosInstance) => {
  const getCustomerImportFile = async function (url, excludedColonne, isHomeDelivery, isBenef) {
    try {
      const result = await axiosInstance.get(url, {
        headers: { Accept: 'text/csv' },
        responseType: 'blob',
        params: {
          excludedColonne,
          isHomeDelivery,
          isBenef,
        },
      })
      const blobFile = new Blob([result.data], { type: 'text/csv' })
      downloadFile(blobFile, 'Import.csv')
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const postFileUploadRequest = async (productInfo) => {
    try {
      const result = await axiosInstance.post('/fileUpload/request', productInfo)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getFileUploadStatus = async (fileUploadRequestId, productCode, isBenef, isHomeDelivery) => {
    try {
      const result = await axiosInstance.get(`/fileUpload/${fileUploadRequestId}/status`, {
        params: {
          productCode,
          isBenef,
          isHomeDelivery,
        },
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getFieldsImport = async (productCode, excludedColonne, isHomeDelivery, isBenef) => {
    try {
      const result = await axiosInstance.get(`/fileUpload/${productCode}/rules`, {
        params: {
          excludedColonne,
          isHomeDelivery,
          isBenef,
        },
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const saveCustomImport = async (params) => {
    try {
      const result = await axiosInstance.post(`/mapping?productCode=${params.productCode}`, params.mapping)
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const putValidationBenef = async (requestId) => {
    try {
      const result = await axiosInstance.put(`fileUpload/${requestId}/validationBenef`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    getCustomerImportFile,
    postFileUploadRequest,
    getFileUploadStatus,
    getFieldsImport,
    saveCustomImport,
    putValidationBenef,
  }
}
