import type { AxiosInstance } from 'axios'
import { Result } from '@badrap/result'
import type { Pagination } from '../common/types'
import urlReplacer from '../utils/urlReplacer'
import type { ApiAxiosError } from '../common/errors'
import type { GetCreditNotesParams, CreditNote } from './types'

const baseUrl = 'api/:version/creditNotes'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
})

export default (axiosInstance: AxiosInstance) => {
  const getCreditNotes = async (params: GetCreditNotesParams) => {
    try {
      const result = await axiosInstance.get<Pagination<CreditNote>>(getBaseUrl(), {
        params: { ...params, products: params.products ? params.products.join(',') : undefined },
      })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(error as ApiAxiosError)
    }
  }

  return {
    getCreditNotes,
  }
}
