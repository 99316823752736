import * as Sentry from '@sentry/vue'
import { ApiError, type ApiAxiosError } from '../common/errors'
import { UNKNOWN_ERROR, UNKNOWN_ERROR_MESSAGE } from '@/types/ResultError'

export enum ApiSipsErrorType {
  INVALID_REQUEST = 'INVALID_REQUEST',
  FAIL_AUTH_SOGEN = 'FAIL_AUTH_SOGEN',
  SOGEN_NOT_AVAILABLE = 'SOGEN_NOT_AVAILABLE',
}

export const errorMessage: Record<ApiSipsErrorType | string, string> = {
  [ApiSipsErrorType.INVALID_REQUEST]: 'La requête est invalide',
  [ApiSipsErrorType.FAIL_AUTH_SOGEN]: "Problème d'authentification",
  [ApiSipsErrorType.SOGEN_NOT_AVAILABLE]: "SogenActif n'est pas disponible",
}

export function getError(key: string): string {
  return errorMessage[key] ?? UNKNOWN_ERROR_MESSAGE
}

export class ApiSipError extends ApiError {
  constructor(error: ApiAxiosError) {
    super(error)

    const codeApi: string | undefined = error.response?.data?.ErrorCode
    let code: ApiSipsErrorType | string

    switch (error.code) {
      case '400':
        code = ApiSipsErrorType.INVALID_REQUEST
        break
      case '424':
        code = ApiSipsErrorType.FAIL_AUTH_SOGEN
        break
      case '502':
        code = ApiSipsErrorType.SOGEN_NOT_AVAILABLE
        break
      default:
        code = codeApi ?? UNKNOWN_ERROR
    }

    Sentry.captureException(code ?? `error.code: ${error.code}`)

    this.name = 'ApiSipsError'
    this.code = code
    this.message = getError(code)
  }
}
