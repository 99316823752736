const baseUrl = '/contracts'

export default (axiosInstance) => {
  const getContracts = async () => {
    try {
      const result = await axiosInstance.get(baseUrl)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getContractPricing = async (productCodeSource, productCodeTarget) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/pricing`, {
        params: {
          productCodeSource,
          productCodeTarget,
        },
      })
      return result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const postContractualisation = async (contratType, productCodeSource, productCodeTarget) => {
    try {
      const result = await axiosInstance.post(`${baseUrl}/contractualisation`, {
        ContratType: contratType,
        ProductCodeSource: productCodeSource,
        ProductCodeTarget: productCodeTarget,
      })
      return result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  return {
    getContracts,
    getContractPricing,
    postContractualisation,
  }
}
