import { Result } from '@badrap/result'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { LocalValueOfTitle } from '../cart/model'
import { accessor } from '..'
import type { ProductCode } from '@/variables/ProductCode'
import type { SimulatorConfig, ValueOfTitleConfig } from '@/types/ValueOfTitle'
import api from '@/services/api'
import apiV4 from '@/services/apiV4'
import type { ArticleCode } from '@/variables/ArticleCode'
import { DataStoreKey } from '@/services/dataStore/types'
import type { FamilyOfUse, FamilyOfUseJson } from '@/services/facialValues/types'

export const useStore = defineStore('valueOfTitle', () => {
  const configs = ref<ValueOfTitleConfig[] | null>(null)
  const simulatorConfig = ref<SimulatorConfig | null>(null)
  const familyOfUse = ref<FamilyOfUse[] | null>(null)

  const getConfig = (productCode: ProductCode) =>
    configs.value?.find((config) => config.productCodes.includes(productCode)) ?? null

  const setConfigs = (newConfigs: ValueOfTitleConfig[]) => {
    configs.value = newConfigs
  }

  const setSimulatorConfig = (config: SimulatorConfig) => {
    simulatorConfig.value = config
  }

  const setFamilyOfUse = (newFamilyOfUse: FamilyOfUse[]) => {
    familyOfUse.value = newFamilyOfUse
  }

  const fetchDefaultData = async (
    productCode: ProductCode,
    articleCode: ArticleCode | null,
  ): Promise<Result<LocalValueOfTitle | null>> => {
    await accessor.session.fetchReferenceProduct()

    const products = accessor.session.getProductForRoute('vf', true)
    if (products.length === 0) {
      return Result.ok(null)
    }

    const valueOfTitle = await apiV4.dataStore.fetchDataStoreByProduct<LocalValueOfTitle>(
      DataStoreKey.ValueOfTitle,
      productCode,
      articleCode,
    )
    if (valueOfTitle) {
      return Result.ok(valueOfTitle)
    }

    const result = await apiV4.valueOfTitle.fetchDefault(productCode)
    if (result.isOk) {
      return Result.ok({
        isMulti: false,
        value: result.value.amount,
        employersContribution: result.value.employersContributionPercentage,
        save: false,
      })
    } else {
      const config = getConfig(productCode)
      if (!config) {
        return Result.err(new Error('Amount config not found'))
      }

      return Result.ok({
        isMulti: false,
        value: config.valueOfTitle.value,
        employersContribution: config.employersContribution?.min ?? 100,
        save: false,
      })
    }
  }

  const fetchConfigs = async (): Promise<Result<ValueOfTitleConfig[]>> => {
    if (configs.value) {
      return Result.ok(configs.value)
    }

    try {
      const valueOfTitles: ValueOfTitleConfig[] = await api.getClickProxy(
        'Documents_Espace_Client/EspaceClient/value-of-title-config.json',
      )
      setConfigs(valueOfTitles)

      return Result.ok(valueOfTitles)
    } catch (error) {
      return Result.err(new Error('cart.error.valueOfTitleLoadFail'))
    }
  }

  const fetchSimulatorConfig = async (): Promise<Result<SimulatorConfig>> => {
    if (simulatorConfig.value) {
      return Result.ok(simulatorConfig.value)
    }

    try {
      const simulatorConfig: SimulatorConfig = await api.getClickProxy(
        'Documents_Espace_Client/EspaceClient/simulator.json',
      )

      setSimulatorConfig(simulatorConfig)

      return Result.ok(simulatorConfig)
    } catch (error) {
      return Result.err(new Error('cart.error.valueOfTitleLoadFail'))
    }
  }

  const fetchFamilyOfUse = async (): Promise<Result<FamilyOfUse[]>> => {
    if (familyOfUse.value) {
      return Result.ok(familyOfUse.value)
    }

    try {
      const familyOfUse: FamilyOfUse[] = (
        await api.getClickProxy('Documents_Espace_Client/Produits/familyOfUse.json')
      ).map((family: FamilyOfUseJson) => ({
        id: parseInt(family.id),
        label: family.label,
        productCode: parseInt(family.productCode) as ProductCode,
      }))

      setFamilyOfUse(familyOfUse)

      return Result.ok(familyOfUse)
    } catch (error) {
      return Result.err(new Error('cart.error.valueOfTitleLoadFail'))
    }
  }

  return {
    configs,
    simulatorConfig,
    familyOfUse,
    getConfig,
    setConfigs,
    fetchDefaultData,
    fetchConfigs,
    fetchSimulatorConfig,
    fetchFamilyOfUse,
  }
})
