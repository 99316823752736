import { type ModuleConfig, ModuleType } from '@/store/cart/model'
import type { Action } from '@/types/Action'

export const moduleConfig: ModuleConfig = {
  id: 'delete-cart-item-popin',
  type: ModuleType.Popin,
  component: () => import('@/components/popins/DeleteCartItem/index.vue'),
}

export interface DeleteCartItemWorkflow {
  title: string
  description: string
  deleteAction: Action
}
