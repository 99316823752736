import type { RouteConfig } from 'vue-router'
import { accessor } from '@/store'
import { RESTRICTION_PRODUCT_CODES } from '@/services/management/types'

export const router: RouteConfig = {
  path: '/gestionnaires-et-roles',
  meta: {
    breadcrumb: 'Gestionnaires et rôles',
    title: 'Gestionnaires et rôles',
    howTo: 'comment-faire-gestionnaires-et-roles',
  },

  component: () => import('@/views/DefaultRouter/Root/index.vue'),
  children: [
    {
      path: '',
      component: () => import('@/views/Management/index.vue'),
      children: [
        {
          path: '',
          component: () => import('@/views/Management/Managers/index.vue'),
        },
        {
          path: 'roles',
          meta: {
            breadcrumb: 'Rôles',
            title: 'Rôles',
          },
          component: () => import('@/views/Management/Roles/index.vue'),
        },
        {
          beforeEnter: (_, __, next) => {
            const hasActiveContract = accessor.session.hasActiveContract(RESTRICTION_PRODUCT_CODES)

            if (!hasActiveContract) {
              next('/')
              return
            }

            next()
          },
          path: 'restrictions',
          meta: {
            breadcrumb: 'Restrictions',
            title: 'Restrictions',
          },
          component: () => import('@/views/Management/Restrictions/index.vue'),
        },
      ],
    },
  ],
}
