import { type ModuleConfig, ModuleType } from '@/store/cart/model'
import type { Action } from '@/types/Action'

export const moduleConfig: ModuleConfig = {
  id: 'discount-code-modal',
  type: ModuleType.Popin,
  component: () => import('@/components/Cart/Module/DiscountCode/index.vue'),
}

export interface DiscountCodeWorkflow {
  validateAction: Action
}
