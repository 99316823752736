import type { RouteConfig } from 'vue-router'

export const loadV1: RouteConfig = {
  path: '/v1/load',
  component: () => import('@/views/V1/Load.vue'),
}

export const redirectV1: RouteConfig = {
  path: '/v1/*',
  component: () => import('@/views/V1/Redirect.vue'),
}
