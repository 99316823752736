import type { FilteredRequest, PaginatedRequest, SortRequest } from '../types'
import type { Address } from '@/types/Address'
import type { ArticleCode } from '@/variables/ArticleCode'
import type { ProductCode } from '@/variables/ProductCode'

export enum DematerialisationStatus {
  None = 'None',
  Email = 'Email',
  Download = 'Download',
  AwaitingGeneration = 'AwaitingGeneration',
  EDI = 'EDI',
}

export enum DematerialisationType {
  None = 'None',
  PrivateXML = 'PrivateXML',
  ChorusXML = 'ChorusXML',
  SignedPDF = 'SignedPDF',
}

export enum InvoiceStatus {
  Paid = 'Soldé',
  ToBePaid = 'A payer',
  NotPaid = 'Impayé',
}

export interface Invoice {
  productCode: ProductCode
  articleCode: ArticleCode
  amount: number
  status: InvoiceStatus
  documentId: number
  documentReference: string
  orderReference: string
  billingAddress: Address
  emissionDate: string
  dematerialisationStatus: DematerialisationStatus
  dematerialisationType: DematerialisationType
}

export interface GetInvoicesParams extends SortRequest, PaginatedRequest, FilteredRequest {
  productCode?: ProductCode
  billingPointId?: number
  endDate?: string | null
  startDate?: string | null
}

export interface GetInvoice {
  type?: string
  title?: string
  status?: number
  details?: string
  instance?: string
}
