import type { AxiosError, AxiosInstance } from 'axios'
import { Result } from '@badrap/result'
import type { Pagination } from '../common/types'
import type { CartItem } from '../carts/types'
import type { ApiAxiosError } from '../common/errors'
import type {
  OrderTracking,
  GetOrderTrackingByDivisionRequest,
  StatTrackingType,
  GetStatTrackingByBenef,
  StatTrackingDetailBenefType,
  FetchOrderTrackingBeneficiariesParams,
  OrdersTrackingBeneficiary,
  ItemsStatDetailBenef,
} from './types'
import { ApiCardsActivationError } from './errors'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = 'api/:version/OrdersTracking'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
})

export default (axiosInstance: AxiosInstance) => {
  const getOrderTracking = async (orderId: number) => {
    try {
      const result = await axiosInstance.get<OrderTracking>(`${getBaseUrl()}/${orderId}`)
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.message
    }
  }

  const getOrderTrackingByRedcartId = async (redcartId: number) => {
    try {
      const result = await axiosInstance.get<OrderTracking>(`${getBaseUrl()}/byRedcartId/${redcartId}`)
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.message
    }
  }

  const getOrderTrackingByTrackingNumber = async (trackingNumber: number) => {
    try {
      const result = await axiosInstance.get<OrderTracking>(`${getBaseUrl()}/byNumber/${trackingNumber}`)
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.message
    }
  }

  const getOrdersTracking = async (params: GetOrderTrackingByDivisionRequest) => {
    try {
      const result = await axiosInstance.get<Pagination<OrderTracking>>(getBaseUrl(), { params })
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const getOrdersTrackingStatCampaign = async (trackingId: number) => {
    try {
      const result = await axiosInstance.get<StatTrackingType>(`${getBaseUrl()}/${trackingId}/campaign`)
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const getOrdersTrackingStatCampaignDetails: (
    trackingId: number,
    params: GetStatTrackingByBenef,
  ) => Promise<Result<Pagination<ItemsStatDetailBenef>>> = async (trackingId, params) => {
    try {
      const result = await axiosInstance.get<StatTrackingDetailBenefType>(
        `${getBaseUrl()}/${trackingId}/campaign/beneficiaries`,
        { params },
      )

      return Result.ok({
        pageIndex: params.page ?? 1,
        pageSize: params.pageSize ?? 50,
        items: result.data.items,
        totalElements: result.data.totalElements,
      })
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      return Result.err(_error)
    }
  }

  const getOrdersTrackingItems = async (trackingId: number, page = 1, pageSize = 20) => {
    try {
      const result = await axiosInstance.get<Pagination<CartItem>>(`${getBaseUrl()}/${trackingId}/items`, {
        params: {
          page,
          pageSize,
        },
      })
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const getOrdersTrackingBeneficiaries = async (trackingId: number, params: FetchOrderTrackingBeneficiariesParams) => {
    try {
      const result = await axiosInstance.get<Pagination<OrdersTrackingBeneficiary>>(
        `${getBaseUrl()}/${trackingId}/beneficiaries/parcels`,
        {
          params: {
            page: params.page ?? 1,
            pageSize: params.pageSize ?? 20,
            filter: params.filter,
          },
        },
      )
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(error as AxiosError)
    }
  }

  const postCardsActivation = async (trackingId: number): Promise<Result<boolean, ApiCardsActivationError>> => {
    try {
      await axiosInstance.post(`${getBaseUrl()}/${trackingId}/cards-activation`)
      return Result.ok(true)
    } catch (error) {
      return Result.err(new ApiCardsActivationError(error as ApiAxiosError))
    }
  }

  const postCardsActivationExport = async (trackingId: number) => {
    try {
      await axiosInstance.post(`${getBaseUrl()}/${trackingId}/cards-activation/export`)
      return Result.ok(true)
    } catch (error) {
      return Result.err(error as AxiosError)
    }
  }

  return {
    getOrderTracking,
    getOrdersTracking,
    getOrderTrackingByRedcartId,
    getOrderTrackingByTrackingNumber,
    getOrdersTrackingStatCampaign,
    getOrdersTrackingStatCampaignDetails,
    getOrdersTrackingItems,
    getOrdersTrackingBeneficiaries,
    postCardsActivation,
    postCardsActivationExport,
  }
}
