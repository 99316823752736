import confirmation from '@/store/cart/cartWorkflows/common/steps/confirmation'
import type { ConfirmationWorkflow } from '@/components/Cart/Step/Confirmation/workflow'
import type { StepBuilder } from '@/store/cart/model'
import type { Cart } from '@/services/carts/typesV2'

const step: StepBuilder<ConfirmationWorkflow> = {
  ...confirmation,
  useStep(cart, workflow, record: { linkedCarts?: Cart[] }, useAction) {
    const useStep = confirmation.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const confirmationWorkflow = useStep.useWorkflow()

        confirmationWorkflow.references.value =
          record.linkedCarts?.reduce<string[]>((r, linkedCart) => {
            if (linkedCart.description.orderReference) {
              r.push(linkedCart.description.orderReference)
            }
            return r
          }, []) ?? []
        return {
          ...confirmationWorkflow,
        }
      },
    }
  },
}

export default step
