import type { AxiosInstance } from 'axios'
import { Result } from '@badrap/result'
import type { ApiAxiosError } from '../common/errors'
import type { Pagination, PaginationRequest } from '../common/types'
import { ApiFacialValueError } from './error'
import type { CreateFacialValueParams } from './types'
import type { ProductCode } from '@/variables/ProductCode'
import type { FacialValue } from '@/types/FacialValue'

export default (axiosInstance: AxiosInstance) => {
  const createFacialValue = async (params: CreateFacialValueParams) => {
    try {
      await axiosInstance.post('/facialValues', params)
      return Result.ok(true)
    } catch (e) {
      const error = new ApiFacialValueError(e as ApiAxiosError)

      if (error.code === 'FACIAL_VALUE_DUPLICATE') {
        return Result.ok(true)
      }
      return Result.err(error)
    }
  }

  const fetchFamily = async (productCode: ProductCode) => {
    try {
      const result = await axiosInstance.get<number[]>(`/facialValues/${productCode}/family`)

      return Result.ok(result.data)
    } catch (e) {
      const error = new ApiFacialValueError(e as ApiAxiosError)

      return Result.err(error)
    }
  }

  const fetchLastAmount = async (productCode: ProductCode) => {
    try {
      const result = await axiosInstance.get<FacialValue[]>('/facialValues', {
        params: { pageSize: 1, page: 1, sort: 'emissionDate', sortDirection: 'Desc', productCode, active: true },
      })

      if (result.data.length === 0) {
        return Result.err(new Error('Amount not found'))
      }

      return Result.ok(result.data[0])
    } catch (e) {
      const error = new ApiFacialValueError(e as ApiAxiosError)

      return Result.err(error)
    }
  }

  const fetchValues: (
    productCode: ProductCode,
    params: PaginationRequest,
  ) => Promise<Result<Pagination<FacialValue>>> = async (productCode, params) => {
    try {
      const result = await axiosInstance.get<FacialValue[]>('/facialValues', {
        params: {
          pageSize: params.pageSize,
          page: params.page,
          sort: 'emissionDate',
          sortDirection: 'Desc',
          productCode,
          active: true,
        },
      })

      if (result.data.length === 0) {
        return Result.err(new Error('Amount not found'))
      }

      return Result.ok({
        pageIndex: params.page ?? 10,
        items: result.data,
        pageSize: 50,
        totalElements: result.headers['x-total-count'] ? Number.parseInt(result.headers['x-total-count']) : 0,
      })
    } catch (e) {
      const error = new ApiFacialValueError(e as ApiAxiosError)

      return Result.err(error)
    }
  }

  return {
    createFacialValue,
    fetchLastAmount,
    fetchFamily,
    fetchValues,
  }
}
