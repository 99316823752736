import type { Entry } from 'contentful'
import type { TypeAlertSkeleton, TypeCtaSkeleton } from '../generated'
import { documentToHtmlString } from '../utils/RichTextHtmlRenderer'
import { type CFCta, mapCtaToLocal } from './CFCta'
import type { AlertType } from '@/types/Alert'

export interface CFAlert {
  title: string
  description: string | null
  type: AlertType
  cta: CFCta | null
  active: boolean
}

export function mapAlertToLocal(alert: Entry<TypeAlertSkeleton, undefined, string>): CFAlert {
  return {
    ...alert.fields,
    description: alert.fields.description ? documentToHtmlString(alert.fields.description) : null,
    type: alert.fields.type as AlertType,
    cta: alert.fields.cta ? mapCtaToLocal(alert.fields.cta as Entry<TypeCtaSkeleton, undefined, string>) : null,
  }
}

export function mapAlertsToLocal(alerts: Entry<TypeAlertSkeleton, undefined, string>[]): CFAlert[] {
  return alerts.map((alert) => mapAlertToLocal(alert))
}
