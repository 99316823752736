import type { Pricing as PricingV4 } from '@/services/carts/types'

export enum PriceV3Key {
  orderedAmount = 'OrderedAmount',
  administrativeFees = 'AdministrativeFees',
  cardsCreationFees = 'CardsCreationFees',
  shippingFees = 'ShippingFees',
  otherFees = 'OtherFees',
  total = 'Total',
  vat = 'VAT',
  totalWithVAT = 'TotalWithVAT',
  discountVE = 'DiscountVE',
  discount = 'Discount',
}

export type PricingV3 = Record<PriceV3Key, number>

export function mapPricingV4ToV3(pricingV4: PricingV4): PricingV3 {
  return {
    OrderedAmount: pricingV4.orderedAmount,
    AdministrativeFees: pricingV4.administrativeFees,
    CardsCreationFees: pricingV4.cardsCreationFees,
    ShippingFees: pricingV4.shippingFees,
    OtherFees: pricingV4.otherFees,
    Total: pricingV4.total,
    VAT: pricingV4.vat,
    TotalWithVAT: pricingV4.totalWithVAT,
    DiscountVE: pricingV4.discountVE,
    Discount: pricingV4.discount,
  }
}
