import { Result } from '@badrap/result'
import { type ClearCartWorkflow, moduleConfig } from '@/components/popins/ClearCart/workflow'
import type { ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import router from '@/router'
import { stepConfig as importStepConfig } from '@/components/Cart/Step/Import/workflow'

const editReference: ModuleBuilder<ClearCartWorkflow> = {
  config: moduleConfig,
  isAllowed: () => true,
  useModule(cartRef, _workflow, useAction) {
    if (cartRef.value === null) {
      throw new Error('cart.errors.cartNotFound')
    }

    const { value: cart } = cartRef

    return {
      useWorkflow(record) {
        return {
          title: `cart.clearCart.${record?.key}.title`,
          description: `cart.clearCart.${record?.key}.description`,
          confirmAction: useAction({
            id: importStepConfig.id,
            name: `cart.clearCart.${record?.key}.button`,
            type: ActionType.Default,
            refresh: false,
            async execute() {
              try {
                await router.push({
                  path: `/panier/nouveau`,
                  query: {
                    productCode: cart.meta.productCode.toString(),
                    articleCode: cart.meta.articleCode?.toString(),
                    isMixed: cart.meta.isMixed.toString(),
                    isNominative: cart.meta.isNominative.toString(),
                  },
                })
              } catch (error) {
                return Result.ok(false)
              }

              return Result.ok(true)
            },
          }),
        }
      },
    }
  },
}

export default editReference
