import type { RouteConfig } from 'vue-router'
import { ProductCode } from '@/variables/ProductCode'
import { useStore as useToasterStore } from '@/store/toast'
import { AlertType } from '@/types/Alert'
import { accessor } from '@/store'

export const router: RouteConfig = {
  path: '/simulateur',
  meta: {
    breadcrumb: 'Simulateur',
    hideBreadcrumb: () => !accessor.auth.isLoggedIn,
  },
  component: () => import('@/views/DefaultRouter/Root/index.vue'),
  beforeEnter: (to, __, next) => {
    const toastStore = useToasterStore()
    if (typeof to.query.productCode !== 'string') {
      toastStore.push({
        title: 'Simulateur',
        description: 'Product not found',
        type: AlertType.DANGER,
        autoHide: false,
      })
      next('/')
      return
    }

    const { productCode } = to.query
    const isProductCode = Object.keys(ProductCode).includes(productCode)
    if (!isProductCode) {
      toastStore.push({
        title: 'Simulateur',
        description: 'Product not found',
        type: AlertType.DANGER,
        autoHide: false,
      })
      next('/')
      return
    }
    next()
  },

  children: [
    { path: '', meta: { layout: 'Public', isPublicUrl: true }, component: () => import('@/views/Simulator/index.vue') },
  ],
}
