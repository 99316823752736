import { Result } from '@badrap/result'
import commonsCartWorkflow from '../common'
import stepImport from './steps/import'
import stepBasket from './steps/basket'
import stepPayment from './steps/payment'
import stepConfirmation from './steps/confirmation'
import {
  type FrontCart,
  type CartWorkflow,
  getHeaderTitleDetail,
  type HeaderContentProp,
  type LocalValueOfTitle,
  type LocalDeliveryPoint,
  type Tag,
} from '@/store/cart/model'
import editReference from '@/store/cart/cartWorkflows/common/module/editReference'
import apiV4 from '@/services/apiV4'
import type { Cart } from '@/services/carts/typesV2'
import { accessor } from '@/store'
import { formatDate } from '@/utils/date'
import { ActionType } from '@/types/Action'
import options from '@/store/cart/cartWorkflows/common/module/settings/Mixed'
import { ProductCode } from '@/variables/ProductCode'
import { useStore as useCartStore } from '@/store/cart/store'
import { DataStoreKey } from '@/services/dataStore/types'

const steps = commonsCartWorkflow.steps.map((step) => {
  switch (step.config.id) {
    case stepImport.config.id:
      return stepImport
    case stepConfirmation.config.id:
      return stepConfirmation
    case stepBasket.config.id:
      return stepBasket
    case stepPayment.config.id:
      return stepPayment
  }
  return step
})

const modules = commonsCartWorkflow.modules.map((module) => {
  if (module.config.id === options.config.id) {
    return options
  }

  return module
})

const cartWorkflow: CartWorkflow = {
  ...commonsCartWorkflow,
  steps,
  modules,
  useHeader(cart, _workflow, record, useAction) {
    const contents: HeaderContentProp[] = []

    const cartStore = useCartStore()
    const linkedCarts: Cart[] = record.linkedCarts
    linkedCarts.forEach((linkedCart) => {
      const key = linkedCart.description.productCode === ProductCode.CARTE_TICKET_RESTAURANT ? 'card' : 'paper'
      const product = accessor.session.clientProducts[linkedCart.description.productCode]

      const itemConfigs = cartStore.cartItemConfigs(
        linkedCart.description.productCode,
        linkedCart.description.articleCode,
      )
      if (itemConfigs == null) {
        throw new Error('cart.errors.cartItemConfig.notFound')
      }

      if (product) {
        let tag: Tag | null

        if (product.orderSettings.millesime) {
          tag = {
            label: 'cart.header.vintage',
            icon: itemConfigs[0]?.icon ?? 'card',
            tooltip: 'cart.header.tooltip.default',
            values: {
              year: product.orderSettings.millesime.year,
              date: formatDate(product.orderSettings.millesime.endDate),
            },
          }
        } else {
          tag = {
            label: 'cart.header.vintage',
            icon: itemConfigs[0]?.icon ?? 'card',
            tooltip: null,
            values: { year: 'Inconnu' },
          }
        }

        contents.push({
          cartId: linkedCart.id,
          label: `cart.header.referenceMixed.${key}`,
          titlePopup: `cart.referencePopin.title.${key}`,
          value: linkedCart.description.orderReference,
          editable: !product.orderSettings.defaultOrderReference,
          required: product.orderSettings.orderReferenceIsMandatory,
          tag,
        })
      }
    })

    return {
      title: 'cart.mixed.title',
      subTitle: getHeaderTitleDetail(cart),
      contents,
      steppers: [
        {
          id: 'basket',
          steps: ['basket', 'import', 'loading', 'dedupe'],
          number: 1,
          label: 'Panier',
          icon: 'basket',
        },
        {
          id: 'payment',
          steps: ['payment', 'confirmation'],
          number: 2,
          label: 'Paiement',
          icon: 'card-picto',
        },
      ],
      editReferenceAction: (content) =>
        useAction({
          id: editReference.config.id,
          type: ActionType.Popin,
          name: 'cart.referencePopin.title.default',
          refresh: false,
          payload: {
            content,
            for: 'import',
          },
        }),
    }
  },
  isValid(cart: FrontCart) {
    return cart.meta.productCode === ProductCode.CARTE_TICKET_RESTAURANT && cart.meta.isMixed
  },
  async prepareCart(cart) {
    const cartStore = useCartStore()
    const linkedCarts: Cart[] = []

    for (const linkedCart of cart.remote.linkedCarts) {
      const productConfig = accessor.session.clientProducts[linkedCart.description.productCode]
      if (productConfig == null) {
        return Result.err(new Error('cart.errors.productConfigNotFound'))
      }

      linkedCarts.push(linkedCart)
    }

    const dataStore = { ...cart.meta.dataStore }
    if (cart.meta.dataStore[DataStoreKey.DeliveryPoint] === null) {
      dataStore[DataStoreKey.DeliveryPoint] = await apiV4.dataStore.fetchDataStoreByProduct<LocalDeliveryPoint>(
        DataStoreKey.DeliveryPoint,
        cart.meta.productCode,
        cart.meta.articleCode,
        true,
      )
    }

    if (cart.meta.dataStore[DataStoreKey.ValueOfTitle] === null) {
      let valueOfTitle = await apiV4.dataStore.fetchDataStoreByProduct<LocalValueOfTitle>(
        DataStoreKey.ValueOfTitle,
        cart.meta.productCode,
        cart.meta.articleCode,
        true,
      )

      // #341314 Make sure the saved value of title is still active to use it
      if (valueOfTitle && !valueOfTitle.isMulti) {
        const activeValuesOfTitle = await apiV4.valueOfTitle.fetch(cart.meta.productCode, true)
        if (
          activeValuesOfTitle.isOk &&
          !activeValuesOfTitle.value.some(
            (value) =>
              value.amount === valueOfTitle?.value &&
              value.employersContributionPercentage === valueOfTitle.employersContribution,
          )
        ) {
          await apiV4.dataStore.deleteDataStoreByProduct(
            DataStoreKey.ValueOfTitle,
            cart.meta.productCode,
            cart.meta.articleCode,
          )

          valueOfTitle = null
        }
      }

      dataStore[DataStoreKey.ValueOfTitle] = valueOfTitle
    }

    cartStore.edit({
      localId: cart.localId,
      meta: { dataStore },
    })

    const products = await cartStore.fetchProducts()
    if (products == null) {
      return Result.err(new Error('cart.error.product.loadFailed'))
    }

    return Result.ok({
      linkedCarts,
    })
  },
}

export default cartWorkflow
