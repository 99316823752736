// RG
// 3 <= tickets.length <= 28
// values: 10, 20, 50
// one exotic value
// 3 values max
// 30€ <= amount <= 1400€

export const DEFAULT_SELECT_VALUE = 2000
export const STANDARD_VALUES = Array.from({ length: 50 }, (_, i) => (i + 1) * 100).filter(
  (value) => value % 500 === 0 && value >= 1000,
)
export const EXOTIC_VALUES = Array.from({ length: 50 }, (_, i) => (i + 1) * 100).filter(
  (value) => value % 500 !== 0 && value >= 1000,
)
export const MINIMUM_TICKETS = 3
export const MAXIMUM_TICKETS = 28
export const MAX_VALUES = 3

export enum ValueType {
  STANDARD,
  EXOTIC,
}

export type Decomposition = {
  metadata: {
    count: number
    standardCount: number
    exoticCount: number
    valuesCount: number
  }
  tickets: { value: number; count: number }[]
}

function getPreferenceValuesCount(preferenceValues: number[], decomposition: Decomposition) {
  return preferenceValues.reduce(
    (r, value) => {
      const total = decomposition.tickets.find((ticket) => ticket.value === value)?.count
      if (total) {
        r.count += 1
        r.total += total
      }

      return r
    },
    { total: 0, count: 0 },
  )
}

function mergeDecompositions(baseDecomposition: Decomposition, decomposition: Decomposition) {
  const clonedDecomposition = structuredClone(baseDecomposition)

  clonedDecomposition.metadata.count += decomposition.metadata.count
  clonedDecomposition.metadata.standardCount += decomposition.metadata.standardCount
  clonedDecomposition.metadata.exoticCount += decomposition.metadata.exoticCount
  clonedDecomposition.metadata.valuesCount += decomposition.metadata.valuesCount
  clonedDecomposition.tickets = clonedDecomposition.tickets.concat(decomposition.tickets)

  return clonedDecomposition
}

function simulateDecompose(
  amount: number,
  type: ValueType,
  minTickets: number,
  maxTickets: number,
  remainingValues: number,
  excludedValues: number[] = [],
): Decomposition[] {
  return (type === ValueType.STANDARD ? STANDARD_VALUES : EXOTIC_VALUES)
    .filter((value) => !excludedValues.includes(value))
    .flatMap((value) => {
      if (amount < value) {
        return []
      }

      let rest = amount % value
      let count = (amount - rest) / value

      if (count > maxTickets) {
        count = Math.floor(maxTickets / 2)
        rest = amount - count * value
      }

      if (0 < rest && rest < 1000) {
        count -= 1
        rest = amount - count * value
      }

      if (count <= 0) {
        return []
      }

      const decomposition: Decomposition = {
        metadata: {
          count,
          standardCount: type === ValueType.STANDARD ? count : 0,
          exoticCount: type === ValueType.EXOTIC ? count : 0,
          valuesCount: 1,
        },
        tickets: [
          {
            value,
            count,
          },
        ],
      }

      const newMinTickets = Math.max(minTickets - count, 0)

      if (rest > 0) {
        if (remainingValues <= 1 || type === ValueType.EXOTIC) {
          return []
        }

        const restDecompositions = simulateDecompose(
          rest,
          ValueType.STANDARD,
          newMinTickets,
          maxTickets - count,
          remainingValues - 1,
          excludedValues.concat(decomposition.tickets.map((ticket) => ticket.value)),
        )

        if (restDecompositions.length > 0) {
          return restDecompositions
            .map((restDecomposition) => mergeDecompositions(decomposition, restDecomposition))
            .filter((decomposition) => decomposition.metadata.count >= minTickets)
        } else {
          const restExoticDecomposition = simulateDecompose(
            rest,
            ValueType.EXOTIC,
            newMinTickets,
            maxTickets - count,
            remainingValues - 1,
            excludedValues.concat(decomposition.tickets.map((ticket) => ticket.value)),
          )

          if (restExoticDecomposition.length > 0) {
            return restExoticDecomposition
              .map((restDecomposition) => mergeDecompositions(decomposition, restDecomposition))
              .filter((decomposition) => decomposition.metadata.count >= minTickets)
          }
        }
      } else if (count >= minTickets) {
        return [decomposition]
      }

      return []
    })
}

export function decompose(amount: number, preferenceValue: number): Decomposition | null {
  const result = simulateDecompose(amount, ValueType.STANDARD, MINIMUM_TICKETS, MAXIMUM_TICKETS, MAX_VALUES).sort(
    (decompositionA, decompositionB) => {
      const preferenceValuesCountA = getPreferenceValuesCount([preferenceValue], decompositionA)
      const preferenceValuesCountB = getPreferenceValuesCount([preferenceValue], decompositionB)
      if (preferenceValuesCountA.count > preferenceValuesCountB.count) {
        return -1
      } else if (preferenceValuesCountA.count < preferenceValuesCountB.count) {
        return 1
      } else if (preferenceValuesCountA.total > preferenceValuesCountB.total) {
        return -1
      } else if (preferenceValuesCountA.total < preferenceValuesCountB.total) {
        return 1
      }

      if (decompositionB.metadata.standardCount - decompositionA.metadata.standardCount > 0) {
        return 1
      }

      return decompositionB.metadata.count - decompositionA.metadata.count
    },
  )

  if (result.length === 0) {
    return null
  }

  return result[0]
}
