export default {
  valueOfTitle: {
    title: 'Valeurs du titre',
    decomposition: '{employer} employeur + {employee} bénéficiaire',
    createTitle: 'Créer une nouvelle valeur',
    errors: {
      alreadyExists: 'Votre valeur du titre est déjà existante',
      cannotDeactivate: 'Au moins une valeur du titre doit être active sur le produit',
    },
    statistics: {
      averageDepartment: {
        label: '<b>Valeur moyenne</b> des entreprises de votre département :',
        tooltip:
          "Valeur moyenne du titre attribuée sur le dernier mois par les entreprises clientes de Ticket Restaurant® Edenred, quel que soit leur secteur d'activité",
      },
      averageTransaction: {
        label: '<b>Transaction moyenne</b> de vos bénéficiaires :',
        tooltip: 'Montant moyen quotidien dépensé au restaurant par vos bénéficiaires sur le mois dernier',
      },
    },
  },
}
