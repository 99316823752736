import { getHeaderTitleDetail, type UseHeader, type HeaderContentProp, type Tag } from '../../model'
import editReference from './module/editReference'
import { useStore as useCartStore } from '@/store/cart/store'
import { ActionType } from '@/types/Action'
import { accessor } from '@/store'
import { formatDate } from '@/utils/date'
import { Univers, universByProductCode } from '@/assets/constantes/Univers'
import { ArticleCode } from '@/variables/ArticleCode'

const useHeader: UseHeader = (cart, _workflow, _record, useAction) => {
  const contents: HeaderContentProp[] = []
  const product = accessor.session.clientProducts[cart.remote.description.productCode]

  const cartStore = useCartStore()
  const itemConfigs = cartStore.cartItemConfigs(cart.meta.productCode, cart.meta.articleCode)
  if (itemConfigs == null) {
    throw new Error('cart.errors.cartItemConfig.notFound')
  }

  if (product) {
    let tag: Tag | null
    const univers = universByProductCode[cart.meta.productCode]

    if (univers === Univers.KS) {
      // #311359 - Temporarily hide validity information
      tag = null

      // tag = {
      //   label: 'cart.header.validity',
      //   icon: 'calendar-remove',
      //   tooltip: null,
      //   values: {
      //     month: product.orderSettings.monthValidity,
      //   },
      // }
    } else {
      if (product.orderSettings.millesime) {
        tag = {
          label: 'cart.header.vintage',
          icon: itemConfigs[0]?.icon ?? 'card',
          tooltip: 'cart.header.tooltip.default',
          values: {
            year: product.orderSettings.millesime.year,
            date: formatDate(product.orderSettings.millesime.endDate),
          },
        }
      } else {
        tag = {
          label: 'cart.header.vintage',
          icon: itemConfigs[0]?.icon ?? 'card',
          tooltip: null,
          values: { year: 'Inconnu' },
        }
      }
    }

    contents.push({
      cartId: cart.remote.id,
      label: 'cart.header.reference',
      titlePopup: 'cart.referencePopin.title.default',
      value: cart.remote.description.orderReference,
      editable: !product.orderSettings.defaultOrderReference,
      required: product.orderSettings.orderReferenceIsMandatory,
      tag,
    })
  }

  let title: string
  const article = cartStore.article(cart.meta.productCode, cart.meta.articleCode)
  if (article && article.code !== ArticleCode.NO_PRODUCT) {
    title = article.name
  } else {
    const product = cartStore.product(cart.meta.productCode)
    if (product == null) {
      throw new Error('cart.errors.product.notFound')
    }
    title = product.name
  }

  return {
    title,
    subTitle: getHeaderTitleDetail(cart),
    contents,
    steppers: [
      {
        id: 'basket',
        steps: ['basket', 'import', 'loading', 'dedupe'],
        number: 0,
        label: 'Panier',
        icon: 'basket',
      },
      {
        id: 'payment',
        steps: ['payment', 'confirmation'],
        number: 1,
        label: 'Paiement',
        icon: 'card-picto',
      },
    ],
    editReferenceAction: (content) =>
      useAction({
        id: editReference.config.id,
        type: ActionType.Popin,
        name: 'cart.referencePopin.title.default',
        refresh: false,
        payload: {
          content,
        },
      }),
  }
}

export default useHeader
