import { Result } from '@badrap/result'
import confirmation from '../../common/steps/confirmation'
import type { ConfirmationWorkflow } from '@/components/Cart/Step/Confirmation/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { useDigitalizationStore } from '@/store/digitalization'

const step: StepBuilder<ConfirmationWorkflow> = {
  ...confirmation,
  async prepareStep(cart) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let data: Record<string, any> = {}

    if (confirmation.prepareStep) {
      const result = await confirmation.prepareStep(cart)

      if (result.isErr) {
        return Result.err(result.error)
      }

      data = result.value
    }

    const digitalizationStore = useDigitalizationStore()
    const digitalizationCard = await digitalizationStore.fetchOrderConfirmationCard()

    if (digitalizationCard) {
      return Result.ok({ ...data, cfCard: digitalizationCard })
    }

    return Result.ok({ ...data })
  },
}

export default step
