import type { Entry } from 'contentful'
import type {
  TypeBenefitsCalculatorSkeleton,
  TypeBenefitsCalculatorStepSkeleton,
  TypeImportFieldSkeleton,
} from '../generated'
import { mapImportFieldsToLocal, type CFImportField } from './CFImportField'
import { documentToHtmlString } from '@/utils/richTextHtmlRenderer'

export interface CFBenefitsCalculator {
  title: string
  description: string
  hint: string | null
  steps: CFBenefitsCalculatorStep[]
}

export interface CFBenefitsCalculatorStep {
  id: string
  icon: string
  title: string
  description: string | null
  details: string | null
  fields: CFImportField[]
}

export function mapBenefitsCalculatorToLocal(
  item: Entry<TypeBenefitsCalculatorSkeleton, undefined, string>,
): CFBenefitsCalculator {
  return {
    title: item.fields.title,
    description: documentToHtmlString(item.fields.description),
    hint: item.fields.hint ?? null,
    steps: item.fields.steps.map((step) =>
      mapBenefitsCalculatorStepToLocal(step as Entry<TypeBenefitsCalculatorStepSkeleton, undefined, string>),
    ),
  }
}

function mapBenefitsCalculatorStepToLocal(
  item: Entry<TypeBenefitsCalculatorStepSkeleton, undefined, string>,
): CFBenefitsCalculatorStep {
  return {
    id: item.fields.id,
    icon: item.fields.icon,
    title: item.fields.title,
    description: item.fields.description ? documentToHtmlString(item.fields.description) : null,
    details: item.fields.details ? documentToHtmlString(item.fields.details) : null,
    fields: item.fields.fields
      ? mapImportFieldsToLocal(item.fields.fields as Entry<TypeImportFieldSkeleton, undefined, string>[])
      : [],
  }
}
