import { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'

export function prdtName(product) {
  const prdt = product.ProductCode ? product.ProductCode : product.productCode
  const article = product.ArticleCode ? product.ArticleCode : product.articleCode
  const mixte = product.isMixed ? product.isMixed : false
  if (mixte) {
    return 'mixte'
  }
  switch (prdt) {
    case ProductCode.TICKET_RESTAURANT:
    case ProductCode.TICKET_RESTAURANT_BENEVOLES_VOLONTAIRE:
      return 'tr'
    case ProductCode.TICKET_SERVICE:
    case ProductCode.TICKET_CAP:
      return 'ts'
    case ProductCode.TICKET_CESU:
      return 'cesu'
    case ProductCode.CARTE_TICKET_RESTAURANT:
      return 'ctr'
    case ProductCode.BETTERWAY:
      return 'tm'
    case ProductCode.KADEOS_CONNECT:
      return 'ksc'
    case ProductCode.KADEOS_UNIVERSEL:
      return 'cksuni'
    case ProductCode.CARTE_KADEOS:
      if (article) {
        return artName(article)
      } else {
        return 'cksmulti'
      }
    case ProductCode.KADEOS:
      if (article) {
        return artName(article)
      } else {
        return 'ksinf'
      }
    default:
      return 'no-visuel'
  }
}

export function artName(articleCode) {
  switch (articleCode) {
    case ArticleCode.KADEOS_INFINI:
    case ArticleCode.KADEOS_INFINI_DOM:
      return 'ksinf'
    case ArticleCode.KADEOS_CULTURE:
      return 'kscul'
    case ArticleCode.KADEOS_SELECT:
      return 'kssel'
    case ArticleCode.KADEOS_UNIVERSEL:
      return 'cksuni'
    case ArticleCode.KADEOS_CONNECT:
      return 'ksc'
    case ArticleCode.CARTE_KADEOS_ZENITH_CE:
    case ArticleCode.CARTE_KADEOS_ZENITH_DE:
      return 'ckszen'
    case ArticleCode.CARTE_KADEOS_CULTURE:
      return 'ckscul'
    default:
      return 'no-visuel'
  }
}
