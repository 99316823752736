export default {
  orders: {
    renew: 'Renouveler',
    cannotBeRenewedV4: 'Le renouvellement est temporairement suspendu',
    activate: 'Activer les cartes',
    detail: {
      title: 'Commande n° {reference}',
      invoice: {
        title:
          'Facture | Facture <span class="body-large">({count})</span> | Factures <span class="body-large">({count})</span>',
        empty: 'La facture associée n’est pas encore disponible',
        multi: 'Plusieurs factures',
        multiDescription:
          'Il y a plusieurs factures attribuées à votre commande. Vous pouvez les télécharger individuellement.',
        description: 'Vous pouvez télécharger la facture associée',
        link: 'Voir ma facture | Voir ma facture | Voir mes factures',
      },
      delivery: {
        title: 'Livraison',
        plBenef: 'À l’adresse de vos bénéficiaires',
        plSite: 'À l’adresse de l’entreprise',
        multi: 'Plusieurs points de livraison',
        multiDescription: 'Il y a plusieurs livraisons pour votre commande. Vous pouvez les suivre individuellement.',
        cardDescription: 'Si le bénéficiaire ne possède pas de carte, elle lui est envoyée à son adresse.',
        button: {
          byDeliveryPoint: 'Livraison par point de livraison',
          byDeliveryBenef: 'Livraison par bénéficiaire',
          byBenef: 'Détail par bénéficiaire',
          slip: "M'envoyer le bordereau de distribution",
        },
        slip: {
          erros: 'Un problème est survenu et ne nous permet pas de traiter cette demande.',
          success: 'Le bordereau de distribution vous a été envoyé par e-mail.',
        },
        card: '0 Carte | {count} Carte | {count} Cartes',
        board: '0 Carnet | {count} Carnet | {count} Carnets',
        booklet: '0 Titre | {count} Titre | {count} Titres',
        deliveryProof: 'Preuve de livraison',
      },
      loading: {
        title: 'Chargement',
        subTitle: 'Commande',
        description:
          'Une fois chargés et leur carte activée, vos bénéficiaires peuvent l’utiliser sur le réseau d’affiliés&nbsp;!',
        demateDescription:
          'Une fois chargés, vos bénéficiaires peuvent utiliser leur solde sur la boutique cadeau en ligne&nbsp;!',
        button: 'Chargement par bénéficiaire',
        sendCodes: {
          button: "M'envoyer les codes bénéficiaires",
          confirmation: {
            title: 'Codes envoyés',
            description: "Les codes d'activation de vos bénéficiaires vous ont été envoyés par email.",
          },
        },
      },
      pricingDetails: {
        loadingCard: 'Chargements commandés',
        newCardOrder: 'Nouvelles cartes',
        bookletOrder: 'Carnet commandés',
        titleOrder: 'Titres commandés',
        beneficiary: 'Bénéficiaires',
      },
      payment: {
        title: 'Paiement',
        CHE: { title: 'Règlement par chèque' },
        VIR: {
          title: 'Règlement par virement',
          description:
            '<span class="body-medium">Coordonnées bancaires Edenred</span><br/>Merci d’indiquer la référence de votre commande dans le libellé du virement.',
        },
        PRE: { title: 'Règlement par prélèvement', description: 'Coordonnées bancaires du compte débité&nbsp;:' },
        CB: { title: 'Règlement par carte bancaire' },
        imediatTransfert: { title: 'Règlement par virement immédiat' },
      },
      mixed: {
        title: 'Commande mixte',
        subTitle: 'Commande Carte et Papier',
        description: 'Vous avez une commande {type} liée.',
        button: {
          seeOrder: 'Voir ma commande {type}',
          download: 'Télécharger la répartition par bénéficiaire',
        },
      },
      statistics: {
        title: 'Statistiques',
        received: 'Reçus&nbsp;: {stat}',
        notReceived: 'Non reçus&nbsp;: {stat}',
        notSend: 'Non envoyés&nbsp;: {stat}',
        button: 'Détail par bénéficiaire',
        error: "Les statistiques d'envoi sont indisponibles pour le moment",
        within72h: "Vous pourrez consulter les statistiques d'envoi sous 72 heures après votre commande.",
        description: 'Parmi {totalStat} emails',
      },
    },
    activateCard: {
      title: 'Activer les cartes de la commande',
      description:
        'Après validation de votre demande d’activation, les Cartes Kadéos seront actives et donc utilisables pour le paiement d’achats sous 24 heures. ',
      condition:
        '<b>Je certifie avoir reçu l’ensemble des cartes</b> de la commande concernée, et <b>j’accepte être pleinement responsable</b> de toute éventuelle transaction qui interviendrait entre l’activation et la remise des cartes Kadéos aux bénéficiaires.',
      mainButton: 'Activer',
      status: {
        waiting: 'En attente',
        toActivate: 'Cartes à activer',
        beingActivated: 'Cartes en cours d’activation',
        active: 'Cartes activées',
      },
      tooltip: {
        benef:
          'Les cartes sont à activer directement par vos bénéficiaires dans leur Espace MyEdenred. Un email contenant le code d’activation leur a été envoyé.',
        send: 'Vous pourrez activer les cartes lorsque les colis seront expédiés',
      },
      success: {
        title: 'Activation en cours',
        description:
          'Un email vous sera envoyé lorsque les cartes seront activées. Vous pourrez ensuite les distribuer.',
      },
      error: {
        title: "Erreur d'activation",
      },
    },
  },
}
