import type { PluginObject } from 'vue'
import apiV4 from '@/services/apiV4'

const APIV4Plugin: PluginObject<Record<string, never>> = {
  install(_Vue) {
    _Vue.prototype.$apiV4 = apiV4
  },
}

export default APIV4Plugin

declare module 'vue/types/vue' {
  interface Vue {
    $apiV4: typeof apiV4
  }
}
