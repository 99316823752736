import { computed } from 'vue'
import apiV4 from '@/services/apiV4'
import { useBenefitsCalculatorStore } from '@/store/benefitsCalculator'
import { ProductCode } from '@/variables/ProductCode'
import { formatDate, parseDate } from '@/utils/date'
import useSwrv from '@/utils/useSwrv'

export function useBenefitsCalculatorResults() {
  const store = useBenefitsCalculatorStore()

  const response = useSwrv(`fileupload/field/${ProductCode.CARTE_TICKET_RESTAURANT}`, async () => {
    const resultFields = await apiV4.fileUpload.fetchFields(ProductCode.CARTE_TICKET_RESTAURANT, {
      hasFacialValue: false,
      hasDeliveryPoint: false,
    })

    if (resultFields.isErr) {
      throw resultFields.error
    }

    return resultFields.value
  })

  const blob = computed(() => (isEligible: boolean) => {
    if (response.data.value === null) {
      return null
    }
    const content = store.items
      .filter((item) => (isEligible ? item.total > 0 : item.total <= 0))
      .map(
        (item) =>
          response.data.value
            ?.map((field) => {
              switch (field.fcmsName) {
                case '009_MATRICULE_BENEFICIAIRE':
                  return item.beneficiary.registrationNumber
                case '012_CIVILITE_BENEFICIAIRE':
                  return ''
                case '013_NOM_BENEFICIAIRE':
                  return item.beneficiary.name
                case '014_PRENOM_BENEFICIAIRE':
                  return item.beneficiary.firstName
                case '015_DATE_NAISSANCE_BENEFICIAIRE':
                  return formatDate(parseDate(item.beneficiary.birthdate))
                case '020_ADRESSE_1_BENEFICIAIRE':
                  return item.beneficiary.address?.address1
                case '023_CODE_POSTAL_BENEFICIAIRE':
                  return item.beneficiary.address?.zipCode
                case '024_VILLE_BENEFICIAIRE':
                  return item.beneficiary.address?.city
                case '025_PAYS_BENEFICIAIRE':
                  return item.beneficiary.address?.country
                case '035_NOMBRE_TITRES':
                  return item.total
                case '002_CODE_PL':
                  return item.deliveryPoint
                case '003_CODE_PDIST':
                  return item.distributionPoint
                case '036_VALEUR_FACIALE_CENTIME_EUROS':
                  return item.valueOfTitle
                case '039_PART_PATRONALE_EN_POURCENTAGE':
                  return item.employersContribution
                case '111_PART_PATRONALE_CENTIME_EUROS':
                  return ''
              }

              return ''
            })
            .join(';') ?? '',
      )

    return new Blob(
      [
        '\uFEFF' +
          [response.data.value?.map((field) => field.customName ?? field.csvName).join(';') ?? '', ...content].join(
            '\n',
          ),
      ],
      {
        type: 'text/csv',
      },
    )
  })

  const file = computed(() => (isEligible: boolean) => {
    const blobValue = blob.value(isEligible)
    return blobValue ? new File([blobValue], 'import.csv', { lastModified: Date.now(), type: 'text/csv' }) : null
  })

  return {
    response,
    blob,
    file,
  }
}
