export default {
  deliveryPoints: {
    title: 'Points de livraison',
    createTooltip:
      'Contactez-nous via « Nous contacter » rubrique « Mon compte » puis « Ajouter ou modifier une adresse »',
    detail: {
      reference: 'Référence&nbsp;: <b>{reference}</b>',
      deliveryDays: 'Jours de livraison&nbsp;:',
      tabs: {
        contact: {
          title: 'Interlocuteur(s)',
          noItem: 'Vous n’avez aucun interlocuteur',
        },
        distributionPoint: {
          title: 'Points de distribution',
          noItem: 'Vous n’avez aucun point de distribution',
          reference: 'Réf&nbsp;: {reference}',
        },
      },
    },
  },
}
