type ReplacerObject = {
  [propName: string]: string | number
}

type MatcherObject = {
  [propName: string]: string
}

function matchAll(pattern: string | RegExp, string: string): RegExpMatchArray[] {
  const regex = new RegExp(pattern, 'g')
  const matches: RegExpMatchArray[] = []

  const matchResult = string.match(regex)

  if (!matchResult) {
    return []
  }

  for (const index in matchResult) {
    const item = matchResult[index]
    const match = item.match(new RegExp(pattern))

    if (!match) {
      continue
    }

    matches[index] = match
  }

  return matches
}

function normaliseUrl(url: string): string {
  const urlLastIndex = url.length - 1
  return url.lastIndexOf('/') === urlLastIndex ? url.substring(0, urlLastIndex) : url
}

function replacePattern(url: string, pattern: string, replacer: string): string {
  return url.replace(pattern, replacer)
}

function getParameters(url: string): MatcherObject {
  const matchers: MatcherObject = {}
  const matchs = matchAll(/:(\w*)/, url)
  matchs.forEach((match) => {
    Object.assign(matchers, { [match[1]]: match[0] })
  })
  return matchers
}

export default function (
  url: string,
  defaultReplacers: ReplacerObject = {},
): (replacerObject?: ReplacerObject) => string {
  const innerUrl: string = url
  const matchers: MatcherObject = getParameters(url)

  const getUrl = (replacerObject: ReplacerObject = {}) => {
    let formatedUrl = innerUrl
    const mergedReplacers = Object.assign({}, defaultReplacers, replacerObject)
    Object.entries(mergedReplacers).forEach(([key, val]) => {
      formatedUrl = replacePattern(formatedUrl, matchers[key], val.toString())
    })
    return normaliseUrl(formatedUrl)
  }
  return getUrl
}
