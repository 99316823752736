import type { RouteConfig } from 'vue-router'
import { accessor } from '@/store'
import ABILITIES from '@/variables/habilitations.json'

const DefaultChild = () => import('@/views/DefaultRouter/Child/index.vue')

export const router: RouteConfig = {
  path: 'beneficiaires',
  meta: {
    breadcrumb: 'Mes bénéficiaires',
    howTo: 'bénéficiaire-comment-faire',
  },
  component: DefaultChild,
  children: [
    {
      path: '',
      meta: {
        title: 'Mes bénéficiaires',
      },
      name: 'MyBeneficiaries',
      component: () => import('@/views/Beneficiaries/index.vue'),
      beforeEnter: async (_, __, next) => {
        if (!accessor.session.hasPermission(ABILITIES.SETTINGS.BENEFS)) {
          next('/404')
          return
        }

        next()
      },
      children: [
        {
          path: '',
          redirect: 'actifs',
        },
        {
          path: 'actifs',
          meta: {
            breadcrumb: 'Actifs',
            title: 'Bénéficiaires actifs',
          },
          component: () => import('@/views/Beneficiaries/Table/index.vue'),
          props: {
            archived: false,
          },
        },
        {
          path: 'archives',
          meta: {
            breadcrumb: 'Archivés',
            title: 'Bénéficiaires archivés',
          },
          component: () => import('@/views/Beneficiaries/Table/index.vue'),
          props: {
            archived: true,
          },
        },
      ],
    },
    {
      path: 'import',
      meta: {
        title: 'Bénéficiaires - Importer mon fichier',
      },
      component: () => import('@/views/Beneficiaries/Import/Root.vue'),
      beforeEnter: async (_, __, next) => {
        if (!accessor.session.hasPermission(ABILITIES.SETTINGS.BENEFS_IMPORT)) {
          next('/404')
          return
        }

        next()
      },
      children: [
        {
          path: '',
          component: () => import('@/views/Beneficiaries/Import/index.vue'),
        },
        {
          path: ':id',
          component: () => import('@/views/Beneficiaries/Import/index.vue'),
        },
        {
          path: ':id/dedoublonage',
          component: () => import('@/views/Beneficiaries/Import/index.vue'),
        },
      ],
    },
    {
      path: ':id',
      meta: {
        title: 'Mon bénéficiaire',
        breadcrumb: 'Détail bénéficiaire',
      },
      name: 'MyBeneficiaryDetail',
      component: () => import('@/views/Beneficiaries/Detail/index.vue'),
      children: [
        {
          path: '',
          redirect: 'commandes',
        },
        {
          path: 'commandes',
          component: () => import('@/views/Beneficiaries/Detail/Orders/index.vue'),
        },
        {
          path: 'cartes',
          component: () => import('@/views/Beneficiaries/Detail/Cards/index.vue'),
        },
        {
          path: 'rattachements',
          component: () => import('@/views/Beneficiaries/Detail/Connections/index.vue'),
        },
      ],
    },
  ],
}
