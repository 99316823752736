export default class LastPath {
  get() {
    const lastPath = sessionStorage.getItem('lastPath')
    return JSON.parse(lastPath)
  }

  clear() {
    sessionStorage.setItem('lastPath', null)
  }

  set(value) {
    const prev = this.get()?.current
    sessionStorage.setItem(
      'lastPath',
      JSON.stringify({
        prev,
        current: value,
      }),
    )
  }

  addListener() {
    window.addEventListener(
      'beforeunload',
      function () {
        const path = window.location.pathname
        this.set(path)
      },
      null,
    )
  }

  removeListener() {
    window.removeEventListener('beforeunload', null, null)
  }
}
