import type { Asset, Entry } from 'contentful'
import type { TypeCtaSkeleton, TypeFooterSkeleton, TypeReinsuranceSkeleton } from '../generated'
import type { CFTarget } from './CFTarget'
import { mapCtasToLocal, type CFCta } from './CFCta'
import { mapMediaToLocal, type CFMedia } from './CFMedia'
import { mapReinsurancesToLocal, type CFReinsurance } from './CFReinsurance'

export interface CFFooter {
  logo: CFMedia
  target: CFTarget
  legals: CFCta[]
  links: CFCta[]
  legalMentions?: string | null
  socials: CFCta[]
  reinsurances: CFReinsurance[]
}

export function mapFooterToLocal(item: Entry<TypeFooterSkeleton, undefined, string>): CFFooter {
  return {
    target: item.fields.target,
    logo: mapMediaToLocal(item.fields.logo as Asset<undefined, string>),
    links: mapCtasToLocal(item.fields.links as Entry<TypeCtaSkeleton, undefined, string>[]),
    socials: mapCtasToLocal(item.fields.socials as Entry<TypeCtaSkeleton, undefined, string>[]),
    legals: mapCtasToLocal(item.fields.legals as Entry<TypeCtaSkeleton, undefined, string>[]),
    reinsurances: mapReinsurancesToLocal(
      item.fields.reinsurances as Entry<TypeReinsuranceSkeleton, undefined, string>[],
    ),
  }
}
