import { Result } from '@badrap/result'
import type { AxiosInstance, AxiosResponse } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import { ApiAuthError, ApiLoginError, type ApiLoginAxiosError } from './errorTypes'
import type { AccountIdentify, ApiToken, IdentifyType, Question } from './types'

const ACTIVATION_ROUTE = '/login/activation/form'
const RESET_PASSWORD_ROUTE = '/login/forgotPassword/form'

export default (axiosInstance: AxiosInstance) => {
  const refreshToken = async (refresh: string): Promise<Result<ApiToken, ApiAuthError>> => {
    const params = new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: refresh,
    })

    try {
      return Result.ok<AxiosResponse<ApiToken>, ApiAuthError>(
        await axiosInstance.post('/token', params.toString()),
      ).map((value) => value.data)
    } catch (error) {
      return Result.err(new ApiAuthError(error as ApiAxiosError))
    }
  }

  const login = async (
    username: string,
    password: string,
    captchaToken?: string | null,
  ): Promise<Result<ApiToken, ApiLoginError>> => {
    const params = new URLSearchParams({
      username: username,
      password: password,
      grant_type: 'password',
    }).toString()
    const headers: {
      'g-recaptcha-response'?: string
    } = {}

    if (captchaToken) {
      headers['g-recaptcha-response'] = captchaToken
    }

    try {
      const response = await axiosInstance.post<ApiToken>('/token', params, {
        headers,
      })

      return Result.ok(response.data)
    } catch (error) {
      return Result.err(new ApiLoginError(error as ApiLoginAxiosError))
    }
  }

  const requestPasswordReset = async (email: string, captchaToken?: string | null) => {
    const headers = captchaToken
      ? {
          'g-recaptcha-response': captchaToken,
        }
      : undefined

    try {
      return Result.ok<AxiosResponse, ApiAuthError>(
        await axiosInstance.post(
          `/accounts/${email}/resetPassword`,
          {
            resetPasswordRedirectPath: RESET_PASSWORD_ROUTE,
            activationRedirectPath: ACTIVATION_ROUTE,
          },
          {
            headers,
          },
        ),
      ).map((value) => value.data)
    } catch (error) {
      return Result.err(new ApiAuthError(error as ApiAxiosError))
    }
  }

  const createAndSendActivation = async (email: string, clientCode: string) => {
    // if it's a first request, it will not work without a clientCode
    try {
      return Result.ok<AxiosResponse, ApiAuthError>(
        await axiosInstance.post(`/accounts/${email}/activation`, {
          redirectPath: ACTIVATION_ROUTE,
          clientCode,
        }),
      ).map((value) => value.data)
    } catch (error) {
      return Result.err(new ApiAuthError(error as ApiAxiosError))
    }
  }

  const sendActivationMail = async (email: string, captchaToken?: string | null) => {
    // if it's a first request, it will not work without a clientCode
    const headers = captchaToken
      ? {
          'g-recaptcha-response': captchaToken,
        }
      : undefined

    try {
      return Result.ok<AxiosResponse, ApiAuthError>(
        await axiosInstance.post(
          `/accounts/${email}/activationMail`,
          {
            redirectPath: ACTIVATION_ROUTE,
          },
          {
            headers,
          },
        ),
      ).map((value) => value.data)
    } catch (error) {
      return Result.err(new ApiAuthError(error as ApiAxiosError))
    }
  }

  const activate = async (email: string, token: string, questionId: string, secretAnswer: string, password: string) => {
    try {
      return Result.ok<AxiosResponse, ApiAuthError>(
        await axiosInstance.patch(`/accounts/${email}/credentials`, {
          token,
          questionId,
          secretAnswer,
          password,
        }),
      ).map((value) => value.data)
    } catch (error) {
      return Result.err(new ApiAuthError(error as ApiAxiosError))
    }
  }

  const changePassword = async (
    email: string,
    token: string,
    questionId: number,
    secretAnswer: string,
    password: string,
  ) => {
    try {
      return Result.ok<AxiosResponse, ApiAuthError>(
        await axiosInstance.patch(`/accounts/${email}/password`, {
          token,
          questionId,
          secretAnswer,
          password,
        }),
      ).map((value) => value.data)
    } catch (error) {
      return Result.err(new ApiAuthError(error as ApiAxiosError))
    }
  }

  const secretQuestions = async (): Promise<Result<Question[], ApiAuthError>> => {
    try {
      return Result.ok<AxiosResponse<Question[]>, ApiAuthError>(
        await axiosInstance.get('/accounts/secretQuestions'),
      ).map((value) => value.data)
    } catch (error) {
      return Result.err(new ApiAuthError(error as ApiAxiosError))
    }
  }

  const checkSecurityQuestion = async (email: string, token: string, questionId: string, secretAnswer: string) => {
    try {
      return Result.ok<AxiosResponse, ApiAuthError>(
        await axiosInstance.post(`/accounts/${email}/checkSecretQuestion`, {
          questionId,
          secretAnswer,
          token,
        }),
      ).map((value) => value.data)
    } catch (error) {
      return Result.err(new ApiAuthError(error as ApiAxiosError))
    }
  }

  const getClickProxy = async (path: string) => {
    try {
      return Result.ok<AxiosResponse, ApiAuthError>(
        await axiosInstance.get('utils/clickProxy', { params: { path } }),
      ).map((value) => value.data)
    } catch (error) {
      return Result.err(new ApiAuthError(error as ApiAxiosError))
    }
  }

  const getEmail = async (token: string, type: IdentifyType): Promise<Result<AccountIdentify, ApiAuthError>> => {
    try {
      return Result.ok<AxiosResponse<AccountIdentify, ApiAuthError>, ApiAuthError>(
        await axiosInstance.post('/accounts/identify', { token, typeId: type }),
      ).map((value) => value.data)
    } catch (error) {
      return Result.err(new ApiAuthError(error as ApiAxiosError))
    }
  }

  const isIpLabel = async (): Promise<Result<boolean, ApiAuthError>> => {
    try {
      return Result.ok<AxiosResponse<boolean>, ApiAuthError>(await axiosInstance.get('utils/recaptcha/isIpLabel')).map(
        (value) => value.data,
      )
    } catch (error) {
      return Result.err(new ApiAuthError(error as ApiAxiosError))
    }
  }

  return {
    login,
    requestPasswordReset,
    sendActivationMail,
    createAndSendActivation,
    activate,
    changePassword,
    secretQuestions,
    checkSecurityQuestion,
    getEmail,
    getClickProxy,
    isIpLabel,
    refreshToken,
  }
}
