export default (axiosInstance) => {
  const getClickProxy = async (path) => {
    try {
      const result = await axiosInstance.get(path)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getClick = async (url) => {
    try {
      const result = await axiosInstance.get(url)
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const getCustomAlertsMessages = async () => {
    try {
      const result = await getClickProxy('/Documents_Espace_Client/accueil/notifications.json')
      return result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  return {
    getClickProxy,
    getClick,
    getCustomAlertsMessages,
  }
}
