/* eslint-disable import/no-duplicates */
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import fr from 'date-fns/locale/fr'

export function parseDate(dateString: string, formatString = 'yyyy-MM-dd') {
  return parse(dateString, formatString, new Date())
}

export function formatDate(date?: Date | string | null, fmt = 'dd/MM/yyyy'): string | null {
  if (!date) {
    return null
  }

  return format(new Date(date), fmt, { locale: fr })
}

export function isValidDate(value: string | number | Date) {
  const date = new Date(value)
  return date instanceof Date && !isNaN(date.valueOf())
}
