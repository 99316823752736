const baseUrl = '/V2/employees'

export default (axiosInstance) => {
  const checkCardStatus = async function (idsBenef) {
    try {
      const result = await axiosInstance.get(`${baseUrl}/cardStatus?employeesId=${idsBenef}`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response.data
    }
  }

  const getBeneficiariesList = async (params) => {
    try {
      const result = await axiosInstance.get(baseUrl, { params })
      return result
    } catch (error) {
      console.error(error)
      throw error.response.data
    }
  }

  const updateBeneficiariesDatas = async (benef) => {
    try {
      const result = await axiosInstance.patch(`${baseUrl}/${benef.Id}`, benef)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getEmployeeData = async (id) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/${id}`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const createEmployeeData = async (benef, productCode) => {
    try {
      const result = await axiosInstance.post(`${baseUrl}/${productCode}`, benef)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const updateEmployeeData = async (benef, productCode) => {
    try {
      const result = await axiosInstance.patch(`${baseUrl}/${benef.Id}?productCode=${productCode}`, benef)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const attributionCheckRegistrationNumber = async (registrationNumber) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/unicity?registrationNumber=${registrationNumber}`)
      return result.status === 204
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const doesCardExistAndIsAvailable = async (cardNumber) => {
    try {
      await axiosInstance.get(`${baseUrl}/cardUnicity?referenceCard=${cardNumber}`)
      return 'OK'
    } catch (error) {
      console.error(error)
      throw error.response.data.ErrorCode
    }
  }

  const sendAttributions = async (ArrayAttrib) => {
    try {
      const result = await axiosInstance.post(`${baseUrl}/240/allocateCard`, ArrayAttrib)
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getBeneficariesImportList = async (requestId, params) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/importResult/${requestId}`, { params })
      return result
    } catch (error) {
      console.error(error)
      throw error.response.data
    }
  }

  const getBeneficariesDuplicated = async (requestId, status) => {
    try {
      const result = await axiosInstance.get(
        `${baseUrl}/importResult/${requestId}?status=${status}&pageSize=20&page=1&sort=LastName&sortDirection=Asc/duplicated`,
      )
      return result
    } catch (error) {
      console.error(error)
      throw error.response.data
    }
  }

  const getBeneficariesError = async (requestId, employeeId) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/importResult/${requestId}/duplicated?employeeId=${employeeId}`)
      return result
    } catch (error) {
      console.error(error)
      throw error.response.data
    }
  }

  const addNewBeneficary = async (requestId, employeeId) => {
    try {
      const result = await axiosInstance.patch(`/v2/employees/importResult/${requestId}/duplicated/${employeeId}`, {
        Status: 1,
      })
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const mergeBeneficaries = async (requestId, employeeId, idMerge) => {
    try {
      const result = await axiosInstance.patch(`${baseUrl}/importResult/${requestId}/duplicated/${employeeId}`, {
        Status: 4,
        DuplicateId: idMerge,
      })
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    checkCardStatus,
    getBeneficiariesList,
    updateBeneficiariesDatas,
    getEmployeeData,
    createEmployeeData,
    updateEmployeeData,
    attributionCheckRegistrationNumber,
    doesCardExistAndIsAvailable,
    sendAttributions,
    getBeneficariesImportList,
    getBeneficariesDuplicated,
    getBeneficariesError,
    addNewBeneficary,
    mergeBeneficaries,
  }
}
