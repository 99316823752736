async function createNewFile(_blob) {
  const buffer = await _blob.arrayBuffer()
  const fileName = _blob.name
  const file = new File([buffer], fileName, {
    type: 'text/csv',
  })
  return file
}

async function createNewFormDataCSV(_formData) {
  const blobFile = _formData.get('file')
  const file = await createNewFile(blobFile)
  const formData = new FormData()
  formData.append('file', file)
  return formData
}

const baseUrl = '/fileUpload/manpower/upload'
export default (apiInstance) => {
  const postInterimCSV = async (formDataEntry) => {
    const formData = await createNewFormDataCSV(formDataEntry)
    try {
      const result = await apiInstance.post(baseUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  return {
    postInterimCSV,
  }
}
