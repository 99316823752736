import { Result } from '@badrap/result'
import { usePricingSummary } from '@/store/cart/cartWorkflows/common/usePricing'
import importStep, { useCommonStep } from '@/store/cart/cartWorkflows/common/steps/import'
import { ImportStatus, type FieldResponse, type ImportWorkflow } from '@/components/Cart/Step/Import/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { mapRulesToLocal } from '@/types/Import'
import { DeliveryPointType } from '@/variables/DeliveryPointType'
import apiV4 from '@/services/apiV4'
import useSwrv from '@/utils/useSwrv'
import { useI18n } from '@/composables/useI18n'
import { toPrice } from '@/utils/price'
import { fetchSeparator } from '@/utils/csv'
import { ActionType } from '@/types/Action'
import { DataStoreKey } from '@/services/dataStore/types'
import settings from '@/store/cart/cartWorkflows/common/module/settings/KSC'

const step: StepBuilder<ImportWorkflow> = {
  ...importStep,
  useStep(cart, workflow, record, useAction) {
    const useStep = importStep.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const i18n = useI18n()
        const commonWorkflow = useCommonStep(cart, workflow, record, useAction)
        commonWorkflow.reimportAllowed.value = false
        const dataStoreAmount = cart.meta.dataStore[DataStoreKey.Amount]
        const dataStoreEvent = cart.meta.dataStore[DataStoreKey.Event]
        const fieldResponse = useSwrv<FieldResponse>(`carts/import/rules/${cart.meta.productCode}`, async () => {
          const fields = await apiV4.fileUpload.fetchFields(cart.meta.productCode, {
            hasEvent: true,
            hasAmount: !dataStoreAmount?.isMulti,
          })

          if (fields.isErr) {
            throw fields.error
          }

          return {
            productCode: cart.meta.productCode,
            type: DeliveryPointType.PL_BENEF,
            fields: mapRulesToLocal(cart.meta.productCode, fields.value, record.importFields),
          }
        })

        const actionConstructor = {
          name: 'settings',
          id: settings.config.id,
          type: ActionType.Drawer,
          refresh: false,
          payload: {
            showEvent: true,
            showAmount: true,
            for: 'import',
          },
        }

        return {
          ...commonWorkflow,
          settingActions: [
            useAction({
              ...actionConstructor,
              name: i18n
                .t('cart.import.settingsAction.amount', {
                  amount:
                    dataStoreAmount === null
                      ? i18n.t('toDefine').toString()
                      : dataStoreAmount.isMulti
                      ? i18n.t('cart.modules.settings.amount.isMulti').toString()
                      : toPrice(dataStoreAmount.amount),
                })
                .toString(),
              payload: {
                showEvent: false,
                showAmount: true,
                for: 'import',
              },
            }),
            useAction({
              ...actionConstructor,
              name: i18n
                .t('cart.import.settingsAction.event', {
                  event: dataStoreEvent?.event == null ? i18n.t('toDefine').toString() : dataStoreEvent.event.label,
                })
                .toString(),
              payload: {
                showEvent: true,
                showAmount: false,
                for: 'import',
              },
            }),
          ],
          importAction: useAction({
            name: 'importFile.importAction',
            id: 'import-file',
            icon: 'document-upload',
            type: ActionType.Default,
            refresh: false,
            async execute() {
              if (dataStoreAmount === null || dataStoreEvent === null) {
                return Result.err(new Error('cart.import.errors.settingKscNotConfigured'))
              }

              if (commonWorkflow.file.value == null) {
                return Result.err(new Error('cart.import.errors.fileNotExist'))
              }

              const resultSeparator = await fetchSeparator(commonWorkflow.file.value)
              if (resultSeparator.isErr) {
                return Result.err(resultSeparator.error)
              }

              commonWorkflow.status.value = ImportStatus.Waiting
              commonWorkflow.filename.value = commonWorkflow.file.value.name

              const result = await apiV4.carts.postImport(cart.remote.id, {
                fileUpload: commonWorkflow.file.value,
                eventCode: dataStoreEvent?.event?.value,
                facialValue: dataStoreAmount?.isMulti ? undefined : dataStoreAmount?.amount,
                separator: resultSeparator.value,
              })

              if (result.isErr) {
                commonWorkflow.status.value = ImportStatus.Error
                return Result.err(result.error)
              }

              commonWorkflow.importId.value = result.value.id
              commonWorkflow.filename.value = commonWorkflow.file.value.name

              commonWorkflow.setImportInterval()
              return Result.ok(true)
            },
          }),
          pricings: [usePricingSummary(cart)],
          fieldResponse,
          downloadTemplateWithDataAction: null,
        }
      },
    }
  },
}

export default step
