import { watch } from 'vue'
import { useMixedPricingSummaries } from '@/store/cart/cartWorkflows/common/usePricing'
import basket, { useCommonWorkflow, useCommonBasketItemWorkflow } from '@/store/cart/cartWorkflows/common/steps/basket'
import type { BasketWorkflow } from '@/components/Cart/Step/Basket/workflow'
import type { StepBuilder } from '@/store/cart/model'
import useSwrv from '@/utils/useSwrv'
import type { Pagination } from '@/services/common/types'
import type { CartItem } from '@/services/carts/types'
import apiV4 from '@/services/apiV4'
import { ProductCode } from '@/variables/ProductCode'
import mixedCartItemsToCsv from '@/helpers/mixedCartItemsToCsv'
import settings from '@/store/cart/cartWorkflows/common/module/settings/Mixed'
import { ActionType } from '@/types/Action'
import { DataStoreKey } from '@/services/dataStore/types'

const step: StepBuilder<BasketWorkflow> = {
  ...basket,
  useStep(cart, workflow, record, useAction) {
    const useStep = basket.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const commonWorkflow = useCommonWorkflow(cart, workflow, record, useAction)

        commonWorkflow.table.value.rowSpan = 2
        commonWorkflow.table.value.columns = [
          {
            id: 'beneficiary',
            label: 'table.headers.beneficiary',
          },
          { id: 'product', label: 'table.headers.product' },
          {
            id: 'quantity',
            label: 'table.headers.quantity',
            tooltip: 'cart.mixed.table.headers.tooltip.quantity',
          },
          { id: 'amount', label: 'table.headers.amount' },
          { id: 'delivery', label: 'table.headers.delivery' },
        ]
        commonWorkflow.table.value.selectable = false
        commonWorkflow.table.value.loading = false
        commonWorkflow.table.value.readonly = true
        commonWorkflow.table.value.rowComponent = () => import('@/components/Cart/Step/Basket/Row/MixedRow.vue')

        const response = useSwrv<Pagination<CartItem>>(
          () =>
            `cart/${cart.remote.id}/items?${commonWorkflow.paginationData.queries.value}&isNew=${commonWorkflow.beneficiaryOption.value}`,
          async () => {
            commonWorkflow.table.value.loading = true

            const result = await apiV4.carts.fetchItems(cart.remote.id, {
              page: commonWorkflow.paginationData.currentPage.value,
              sort: commonWorkflow.paginationData.sort.value,
              sortDirection: commonWorkflow.paginationData.sortDirection.value,
              filter: commonWorkflow.paginationData.filter.value,
              pageSize: commonWorkflow.paginationData.pageSize.value,
              isNewBeneficiary: commonWorkflow.beneficiaryOption.value?.value as boolean,
            })
            if (result.isOk) {
              result.value.items =
                result.value.items?.reduce<CartItem[]>((r, item) => {
                  r.push({
                    ...item,
                    compositions: item.compositions.filter(
                      (composition) =>
                        composition.mixedComposition?.productCode === ProductCode.CARTE_TICKET_RESTAURANT,
                    ),
                  })
                  r.push({
                    ...item,
                    compositions: item.compositions.filter(
                      (composition) => composition.mixedComposition?.productCode === ProductCode.TICKET_RESTAURANT,
                    ),
                  })

                  return r
                }, []) ?? null
              commonWorkflow.table.value.loading = false

              return result.value
            } else {
              commonWorkflow.table.value.loading = false
              throw result.error
            }
          },
        )

        watch(
          () => response.data.value,
          (value) => {
            if (commonWorkflow.nextAction) {
              commonWorkflow.nextAction.disabled.value = !(value && value.totalElements > 0)
            }

            commonWorkflow.paginationData.paginatedData.value = value
          },
        )

        const dataStoreValueOfTitle = cart.meta.dataStore[DataStoreKey.ValueOfTitle]
        const dataStoreDeliveries = cart.meta.dataStore[DataStoreKey.DeliveryPoint]
        const productCodes = [ProductCode.CARTE_TICKET_RESTAURANT, ProductCode.TICKET_RESTAURANT]

        return {
          ...commonWorkflow,
          response,
          showActions: false,
          showSettings:
            dataStoreValueOfTitle === null ||
            !!productCodes.find(
              (productCode) =>
                dataStoreDeliveries === null || (dataStoreDeliveries && dataStoreDeliveries[productCode] == null),
            ),
          settingsAction: useAction({
            name: 'settings',
            id: settings.config.id,
            type: ActionType.Drawer,
            refresh: false,
            payload: {
              showDelivery: true,
              showValueOfTitle: true,
              for: 'import',
              close: false,
            },
          }),
          export: {
            id: 'btn-export-cart',
            filename: 'Restitution.csv',
            fetchData: async () => {
              if (response.data.value?.totalElements == null || response.data.value?.totalElements === 0) {
                return []
              }

              const result = await apiV4.carts.fetchItems(cart.remote.id, {
                page: 1,
                pageSize: response.data.value.totalElements,
                isNewBeneficiary: commonWorkflow.beneficiaryOption.value?.value as boolean,
              })

              if (result.isErr || result.value.items == null) {
                return []
              }

              return mixedCartItemsToCsv(result.value.items)
            },
          },
          useItemWorkflow: useCommonBasketItemWorkflow(cart, workflow, record, useAction),
          hasAdd: false,
          hasBeneficiaryFilter: true,
          emptyDescription: 'cart.tr.emptyDescription',
          pricings: useMixedPricingSummaries(record.linkedCarts),
        }
      },
    }
  },
}

export default step
