import { watch } from 'vue'
import importStep from '@/store/cart/cartWorkflows/common/steps/import'
import type { ImportWorkflow } from '@/components/Cart/Step/Import/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { accessor } from '@/store'
import habilitations from '@/variables/habilitations.json'
import { ProductCode } from '@/variables/ProductCode'
import { stepConfig as basketStepConfig } from '@/components/Cart/Step/Basket/workflow'
import { ActionType } from '@/types/Action'
import { useBenefitsCalculatorResults } from '@/composables/useBenefitsCalculatorResults'

const step: StepBuilder<ImportWorkflow> = {
  ...importStep,
  isAllowed(cart) {
    if (!importStep.isAllowed(cart)) {
      return false
    }

    return accessor.session.hasPermission(
      cart.meta.productCode === ProductCode.CARTE_TICKET_RESTAURANT
        ? habilitations.TUNNEL.CTR_IMPORT
        : habilitations.TUNNEL.TR_IMPORT,
    )
  },
  useStep(cart, workflow, record, useAction) {
    const useStep = importStep.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const workflow = useStep.useWorkflow()

        if (
          workflow.importId.value === null &&
          cart.meta.productCode === ProductCode.CARTE_TICKET_RESTAURANT &&
          cart.meta.isBenefitsCalculator
        ) {
          const benefitsCalculatorResult = useBenefitsCalculatorResults()

          watch(benefitsCalculatorResult.response.data, (data) => {
            if (data == null) {
              return
            }
            workflow.file.value = benefitsCalculatorResult.file.value(true) ?? undefined

            workflow.importAction.execute()
          })
        }
        const hasField = accessor.session.hasPermission(
          cart.meta.productCode === ProductCode.CARTE_TICKET_RESTAURANT
            ? habilitations.TUNNEL.CTR_SAISIE_WEB
            : habilitations.TUNNEL.TR_SAISIE_WEB,
        )

        return {
          ...workflow,
          prevAction: hasField
            ? useAction({
                name: 'back',
                id: basketStepConfig.id,
                type: ActionType.Step,
                refresh: false,
              })
            : workflow.prevAction,
        }
      },
    }
  },
}

export default step
