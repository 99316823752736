import { Result } from '@badrap/result'
import { stepConfig as importStepConfig } from '@/components/Cart/Step/Import/workflow'
import { type DedupeWorkflow, stepConfig } from '@/components/Cart/Step/Dedupe/workflow'
import type { StepBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import apiV4 from '@/services/apiV4'
import { CartStatus } from '@/services/carts/typesV2'

const step: StepBuilder<DedupeWorkflow> = {
  config: stepConfig,
  isAllowed: (cart) => {
    return cart.remote.status === CartStatus.Opened
  },
  useStep(cart, _workflow, record, useAction) {
    return {
      config: this.config,
      component: () => import('@/components/Cart/Step/Dedupe/index.vue'),
      useWorkflow() {
        if (cart.remote.imports.length === 0) {
          throw new Error('Import not found')
        }

        const importId = cart.remote.imports[cart.remote.imports.length - 1].id

        const headImportConflicts = () => {
          return apiV4.carts.headCartImportConflicts(cart.remote.id, importId)
        }
        const fetchImportConflicts = async (pageSize: number) => {
          return apiV4.carts.fetchCartImportConflicts(cart.remote.id, importId, {
            page: 1,
            pageSize,
            sortDirection: 'ASC',
          })
        }
        const fetchImportDuplicate = async (conflictId: number) => {
          return apiV4.carts.fetchCartImportDuplicate(cart.remote.id, importId, conflictId)
        }

        return {
          showHowTo: true,
          headImportConflicts,
          fetchImportConflicts,
          fetchImportDuplicate,
          addItemAction: (conflictId) =>
            useAction({
              name: 'cart.dedupe.button.addAndKeep',
              id: 'dedupe.add.action',
              type: ActionType.Default,
              refresh: false,
              async execute() {
                const result = await apiV4.carts.patchCartImportConflict(cart.remote.id, importId, conflictId)

                if (result.isErr) {
                  return Result.err(result.error)
                }

                return Result.ok(true)
              },
            }),
          replaceItemAction: (conflictId, duplicateId) =>
            useAction({
              name: 'cart.dedupe.button.replace',
              id: 'dedupe.replace.action',
              type: ActionType.Default,
              refresh: false,
              async execute() {
                const result = await apiV4.carts.patchCartImportConflict(
                  cart.remote.id,
                  importId,
                  conflictId,
                  duplicateId,
                )

                if (result.isErr) {
                  return Result.err(result.error)
                }

                return Result.ok(true)
              },
            }),
          importAction: useAction({
            name: 'cart.dedupe.button.import',
            id: importStepConfig.id,
            type: ActionType.Step,
            icon: 'document-upload',
            refresh: false,
            payload: {
              reimport: true,
            },
          }),
          prevAction: useAction({
            name: 'back',
            id: importStepConfig.id,
            type: ActionType.Step,
            refresh: false,
          }),
          nextAction: useAction({
            name: 'cart.button.next',
            id: importStepConfig.id,
            type: ActionType.Step,
            refresh: false,
            payload: { importId: record.importId },
            async execute() {
              const totalResult = await headImportConflicts()
              if (totalResult.isErr) {
                return Result.err(totalResult.error)
              }

              if (totalResult.value > 0) {
                const result = await fetchImportConflicts(totalResult.value)
                if (result.isErr) {
                  return Result.err(result.error)
                }

                const conflict = result.value.items?.find((item) => !item.resolved)
                if (conflict) {
                  return Result.err(new Error('cart.dedupe.errors.hasConfictNotResolved'))
                }
              }

              const result = await apiV4.carts.validateImport(cart.remote.id, importId)
              if (result.isErr) {
                return Result.err(result.error)
              }
              return Result.ok(true)
            },
          }),
        }
      },
    }
  },
}

export default step
