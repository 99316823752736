import { type LoadingWorkflow, stepConfig } from '@/components/Cart/Step/Loading/workflow'
import { FrontCartStatus, type StepBuilder } from '@/store/cart/model'

const step: StepBuilder<LoadingWorkflow> = {
  config: stepConfig,
  isAllowed: (cart) => {
    return cart.status === FrontCartStatus.Loading
  },
  useStep() {
    return {
      config: this.config,
      component: () => import('@/components/Cart/Step/Loading/index.vue'),
      useWorkflow() {
        return {
          showHowTo: true,
          nextAction: null,
          async isReady() {
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(true)
              }, 5000)
            })
          },
        }
      },
    }
  },
}

export default step
