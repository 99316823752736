import { documentToHtmlString as cfDocumentToHtmlString } from '@contentful/rich-text-html-renderer'
import type { Document } from '@contentful/rich-text-types'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import type { Asset } from 'contentful'
import { mapMediaToLocal } from '../types/CFMedia'

export function documentToHtmlString(document: Document) {
  return cfDocumentToHtmlString(document, {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const media = mapMediaToLocal(node.data.target as Asset<undefined, string>)
        return `<img src="${media.src}" />`
      },
      [INLINES.HYPERLINK]: (node, next) => {
        return `<a target="_blank" href=${node.data.uri}>${next(node.content)}</a>`
      },
      [INLINES.ASSET_HYPERLINK]: (node, next) => {
        const media = mapMediaToLocal(node.data.target as Asset<undefined, string>)
        return `<a target="_blank" href=${media.src}>${next(node.content)}</a>`
      },
    },
  })
}
