export enum ArticleCode {
  KADEOS_HORIZON = 82,
  KADEOS_INFINI = 81,
  KADEOS_INTENSE = 83,
  KADEOS_HORIZONPLUS = 84,
  KADEOS_CULTURE = 85,
  KADEOSINTENSEPLUS = 86,
  KADEOS_INFINI_DOM = 87,
  KADEOS_SELECT = 90,
  KADEOS_UNIVERSEL = 202,
  CARTE_TICKET_RESTAURANT_FLEX = 203,
  CARTE_TICKET_RESTAURANT = 204,
  KADEOS_CONNECT = 142,
  CARTE_KADEOS_PREFERENCE_CE = 205,
  CARTE_KADEOS_PREFERENCE_DE = 206,
  CARTE_KADEOS_ZENITH_CE = 208,
  CARTE_KADEOS_ZENITH_DE = 209,
  CARTE_KADEOS_CULTURE = 207,
  NO_PRODUCT = 0,
}
