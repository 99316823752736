import type { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import _trimStart from 'lodash/trimStart'
import type { ApiAuthError } from '@/services/auth/errorTypes'
import type { ApiToken } from '@/services/auth/types'
import { accessor } from '@/store'
import { useStore as useAuthnStore } from '@/store/authn'
import { useStore as useTrackingStore } from '@/store/tracking'

let promiseState: Promise<Result<ApiToken, ApiAuthError | Error>> | null = null

export default (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    config.url = _trimStart(config.url, '/')

    const headers = config.headers || {}

    const authnStore = useAuthnStore()
    headers['x-deviceid'] = authnStore.deviceId

    if (!accessor.auth.isLoggedIn) {
      config.headers = headers
      return config
    }

    const token = accessor.auth.token?.accessToken
    if (!token) {
      throw new Error('Faire une requête sans token est interdit')
    }
    headers.Authorization = `Bearer ${token}`

    const division = accessor.session.division ?? accessor.session.user?.rights[0].division
    if (!division) {
      throw new Error('Faire une requête sans numéro de division est interdit')
    }
    headers['X-EXC-Division'] = division

    config.headers = headers
    return config
  })

  axiosInstance.interceptors.response.use(
    (res) => {
      return res
    },
    async (error) => {
      if (error.toJSON && error.toJSON().status === 401) {
        if (!promiseState) {
          promiseState = accessor.auth.refreshToken()
        }

        const result = await promiseState

        const trackingStore = useTrackingStore()
        if (result?.isOk) {
          promiseState = null

          trackingStore.setMetaData()
          return axiosInstance(error.config)
        } else {
          await accessor.auth.logout()
          trackingStore.setMetaData()
        }
      }
      return Promise.reject(error)
    },
  )

  return axiosInstance
}
