/* eslint-disable import/no-duplicates */
import { Result } from '@badrap/result'
import { ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays'
import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'
import options from '../module/settings/Tr'
import { usePricingSummary } from '../usePricing'
import { stepConfig as dedupeStepConfig } from '@/components/Cart/Step/Dedupe/workflow'
import { stepConfig as basketStepConfig } from '@/components/Cart/Step/Basket/workflow'
import {
  type ImportWorkflow,
  type CommonImportWorkflow,
  stepConfig,
  ImportStatus,
  type ImportResponse,
  type FieldResponse,
} from '@/components/Cart/Step/Import/workflow'
import type { CartWorkflow, RemoteCart, StepBuilder } from '@/store/cart/model'
import { ActionType, type ActionConstructor, type Action } from '@/types/Action'
import apiV4 from '@/services/apiV4'
import { CartImportStep, CartStatus, type CartImport, type PostImportRequestBody } from '@/services/carts/typesV2'
import { mapRulesToLocal, type ImportField } from '@/types/Import'
import useSWRV from '@/utils/useSwrv'
import { DeliveryPointType } from '@/variables/DeliveryPointType'
import { useStore as useValueOfTitleStore } from '@/store/valueOfTitle'
import { useStore as useTrackingStore } from '@/store/tracking'
import { useStore as useCartStore } from '@/store/cart/store'
import { fetchSeparator } from '@/utils/csv'
import { ProductCode } from '@/variables/ProductCode'
import { useI18n } from '@/composables/useI18n'
import { toPrice } from '@/utils/price'
import { DataStoreKey } from '@/services/dataStore/types'
import { useDownloadCSVFile } from '@/composables/useDownloadFile'
import { useProductLightItemTracking } from '@/composables/useCartTracking'
import { Univers, universByProductCode } from '@/assets/constantes/Univers'
import { Civility, type Beneficiary } from '@/services/beneficiaries/types'
import { formatDate } from '@/utils/date'

export function fetchImportResponse(cartImport: CartImport): ImportResponse {
  if (!cartImport.suspended) {
    return {
      remote: cartImport,
      status: ImportStatus.Importing,
    }
  }

  if (cartImport.validationRequired && cartImport.step === CartImportStep.WaitingUserArbitralValidation) {
    return {
      remote: cartImport,
      status: ImportStatus.Dedupe,
    }
  } else if (cartImport.step === CartImportStep.MixedOrderSplit && cartImport.childCartImports) {
    for (let index = 0; index < cartImport.childCartImports.length; index++) {
      const childCartImport = cartImport.childCartImports[index]

      const result = fetchImportResponse(childCartImport)

      if (result.status !== ImportStatus.Success) {
        return { ...result, remote: cartImport }
      }
    }

    return {
      remote: cartImport,
      status: ImportStatus.Success,
    }
  } else if (cartImport.step === CartImportStep.WaitingUserConfirmation) {
    return {
      remote: cartImport,
      status: ImportStatus.Success,
    }
  } else {
    return {
      remote: cartImport,
      status: ImportStatus.Error,
      error: {
        errors: cartImport.errors,
        rejectMessage: cartImport.rejectMessage,
        businessRejectCode: cartImport.businessRejectCode,
      },
    }
  }
}

export const useCommonStep: (
  cart: RemoteCart,
  workflow: CartWorkflow,
  record: Record<string, string>,
  useAction: (actionConstructor: ActionConstructor) => Action,
) => CommonImportWorkflow = (cart, workflow, record, useAction) => {
  const module = workflow.modules.find((module) => module.config.id === options.config.id)
  if (!module) {
    throw new Error('cart.errors.moduleNotFound')
  }

  const i18n = useI18n()

  const importId = ref<number | null>(null)
  const filename = ref(record.filename ?? 'Import.csv')
  const file = ref<File>()
  const status = ref(record.importId ? ImportStatus.Importing : ImportStatus.Default)
  const intervalId = ref<ReturnType<typeof setInterval> | null>(null)

  const nextAction = useAction({
    name: 'cart.button.next',
    id: basketStepConfig.id,
    type: ActionType.Step,
    refresh: false,
  })
  nextAction.disabled.value = true

  const cartImportLength = cart.remote.imports.length
  if (!record.importId && cartImportLength > 0) {
    const cartImport = cart.remote.imports[cartImportLength - 1]

    const result = fetchImportResponse(cartImport)

    if (result.status === ImportStatus.Success) {
      nextAction.disabled.value = false
    }

    if (record.reimport !== 'true') {
      status.value = result.status
      importId.value = cartImport.id
    }
  } else if (record.importId) {
    importId.value = Number.parseInt(record.importId)
  }

  const dedupeAction = useAction({
    name: 'cart.button.next',
    id: dedupeStepConfig.id,
    type: ActionType.Step,
    refresh: false,
    async execute() {
      if (importId.value === null) {
        return Result.err(new Error('cart.import.errors.importIdNotFound'))
      }

      return Result.ok(true)
    },
  })

  const clear = () => {
    if (intervalId.value) {
      clearInterval(intervalId.value)
      intervalId.value = null
    }
  }

  const importResponse = useSWRV<ImportResponse | null>(
    () => `cart/${cart.localId}/import/${importId.value}`,
    async () => {
      if (importId.value == null) {
        return null
      }

      const importResult = await apiV4.carts.fetchImport(cart.remote.id, importId.value)
      if (importResult.isErr) {
        status.value = ImportStatus.Error
        clear()
        throw importResult.error
      }

      const result = fetchImportResponse(importResult.value)
      if (result.status === ImportStatus.Dedupe) {
        const totalResult = await apiV4.carts.headCartImportConflicts(cart.remote.id, importId.value)
        if (totalResult.isErr) {
          clear()
          status.value = ImportStatus.Error
          throw totalResult.error
        }

        if (totalResult.value === 0) {
          const result = await apiV4.carts.validateImport(cart.remote.id, importId.value)
          if (result.isErr) {
            clear()

            status.value = ImportStatus.Error
            throw result.error
          }
        } else {
          clear()
          await dedupeAction.execute()
        }
      } else if (result.status !== ImportStatus.Importing) {
        clear()
        if (result.status === ImportStatus.Success) {
          status.value = result.status
          nextAction.disabled.value = false
          await nextAction.execute()
        }
      }

      status.value = result.status
      return result
    },
  )

  const setImportInterval = () => {
    intervalId.value = setInterval(() => {
      if (intervalId.value) {
        importResponse.mutate()
      }
    }, 1000)
  }
  if (importId.value) {
    setImportInterval()
  }

  const dataStoreValueOfTitle = cart.meta.dataStore[DataStoreKey.ValueOfTitle]
  const dataStoreDeliveries = cart.meta.dataStore[DataStoreKey.DeliveryPoint]
  const dataStoreDeliveryPoint = dataStoreDeliveries ? dataStoreDeliveries[cart.meta.productCode] : null

  const actionConstructor = {
    name: 'settings',
    id: module.config.id,
    type: ActionType.Drawer,
    refresh: false,
    payload: {
      showDelivery: true,
      showValueOfTitle: true,
      for: 'import',
    },
  }
  const router = useRouter()

  return {
    productCode: cart.meta.productCode,
    showHowTo: true,
    importId,
    filename,
    file,
    status,
    intervalId,
    importResponse,
    reimportAllowed: ref(true),
    settingActions: [
      useAction({
        ...actionConstructor,
        name: i18n
          .t('cart.import.settingsAction.amount', {
            amount:
              dataStoreValueOfTitle === null
                ? i18n.t('toDefine').toString()
                : dataStoreValueOfTitle.isMulti
                ? i18n.t('cart.modules.settings.valueOfTitle.isMultiValueOfTitle').toString()
                : `${toPrice(dataStoreValueOfTitle.value ?? 0)} - ${
                    (dataStoreValueOfTitle.employersContribution ?? 0) / 100
                  }%`,
          })
          .toString(),
        payload: {
          showDelivery: false,
          showValueOfTitle: true,
          for: 'import',
        },
      }),
      useAction({
        ...actionConstructor,
        name: i18n
          .t('cart.import.settingsAction.deliveryPoint', {
            deliveryPointType:
              dataStoreDeliveryPoint === null
                ? i18n.t('toDefine').toString()
                : dataStoreDeliveryPoint?.type === DeliveryPointType.PL_BENEF
                ? i18n.t('cart.modules.settings.deliveryPoint.beneficiary.title')
                : i18n.t('cart.modules.settings.deliveryPoint.company.title'),
          })
          .toString(),
        payload: {
          showDelivery: true,
          showValueOfTitle: false,
          for: 'import',
        },
      }),
    ],
    setImportInterval,
    importAction: useAction({
      name: 'importFile.importAction',
      id: 'import-file',
      icon: 'document-upload',
      type: ActionType.Default,
      refresh: false,
      async execute() {
        if (dataStoreValueOfTitle === null || dataStoreDeliveryPoint === null) {
          return Result.err(new Error('cart.import.errors.settingTrNotConfigured'))
        }
        if (file.value == null) {
          return Result.err(new Error('cart.import.errors.fileNotExist'))
        }

        const resultSeparator = await fetchSeparator(file.value)
        if (resultSeparator.isErr) {
          return Result.err(resultSeparator.error)
        }

        status.value = ImportStatus.Waiting
        filename.value = file.value.name

        const dataStoreDeliveryPointCtr =
          dataStoreDeliveries && dataStoreDeliveries[ProductCode.CARTE_TICKET_RESTAURANT]
        const dataStoreDeliveryPointTr = dataStoreDeliveries && dataStoreDeliveries[ProductCode.TICKET_RESTAURANT]

        const requestBody: PostImportRequestBody = {
          fileUpload: file.value,
          facialValue: dataStoreValueOfTitle?.isMulti ? undefined : dataStoreValueOfTitle?.value,
          employersContribution: dataStoreValueOfTitle?.isMulti
            ? undefined
            : (dataStoreValueOfTitle?.employersContribution ?? 0) / 100,
          separator: resultSeparator.value,
        }

        if (cart.meta.isMixed) {
          requestBody.deliveryPointReferenceCtr =
            dataStoreDeliveryPointCtr && !dataStoreDeliveryPointCtr.isMulti
              ? dataStoreDeliveryPointCtr.reference ?? undefined
              : undefined
          requestBody.deliveryPointReferenceTr =
            dataStoreDeliveryPointTr && !dataStoreDeliveryPointTr.isMulti
              ? dataStoreDeliveryPointTr.reference ?? undefined
              : undefined
          requestBody.isHomeDeliveryCtr = dataStoreDeliveryPointCtr
            ? dataStoreDeliveryPointCtr.type === DeliveryPointType.PL_BENEF
            : undefined
          requestBody.isHomeDeliveryTr = dataStoreDeliveryPointTr
            ? dataStoreDeliveryPointTr.type === DeliveryPointType.PL_BENEF
            : undefined
        } else {
          requestBody.deliveryPointReference = !dataStoreDeliveryPoint.isMulti
            ? dataStoreDeliveryPoint?.reference ?? undefined
            : undefined
        }

        const result = await apiV4.carts.postImport(cart.remote.id, requestBody)

        if (result.isErr) {
          status.value = ImportStatus.Error
          return Result.err(result.error)
        }

        importId.value = result.value.id
        filename.value = file.value.name

        setImportInterval()
        return Result.ok(true)
      },
    }),
    downloadTemplateAction: (fields: ImportField[]) =>
      useAction({
        id: 'download-template',
        name: 'cart.import.download.empty',
        icon: 'document-download',
        type: ActionType.Default,
        refresh: false,
        async execute() {
          const downloadCSVFile = useDownloadCSVFile()
          downloadCSVFile([fields.map((field) => field.csvName).join(';')], 'Import.csv')

          const trackingStore = useTrackingStore()
          const item = useProductLightItemTracking(cart.meta.productCode, cart.meta.articleCode, cart.meta.isNominative)

          if (item) {
            trackingStore.trackEvent({
              id: 'download',
              data: {
                items: [item],
              },
            })
          }

          return Result.ok(true)
        },
      }),
    downloadTemplateWithDataAction: (fields: ImportField[]) =>
      useAction({
        id: 'download-template',
        name: 'cart.import.download.withData',
        icon: 'document-download',
        type: ActionType.Default,
        refresh: false,
        async execute() {
          const pageSize = 100
          const result = await apiV4.beneficiaries.headBeneficiaries()

          if (result.isErr) {
            return Result.err(new Error('Count not found'))
          }

          const results = await Promise.all(
            Array.from({ length: Math.ceil(result.value / pageSize) }, (_, page) =>
              apiV4.beneficiaries.fetchBeneficiaries({ page: page + 1, pageSize }),
            ),
          )
          const content = results
            .reduce<Beneficiary[]>((r, benefResult) => {
              if (benefResult.isErr) {
                return r
              }

              r.push(...benefResult.value.items)
              return r
            }, [])
            .reduce<string[]>((r, benef) => {
              const data = fields.map((field) => {
                switch (field.fcmsName) {
                  case '009_MATRICULE_BENEFICIAIRE':
                    return benef.registrationNumber
                  case '012_CIVILITE_BENEFICIAIRE': {
                    switch (benef.civility) {
                      case Civility.Sir:
                        return 'M'
                      case Civility.Madam:
                      case Civility.Miss:
                        return 'Mme'
                      default:
                        return ''
                    }
                  }
                  case '013_NOM_BENEFICIAIRE':
                    return benef.name
                  case '014_PRENOM_BENEFICIAIRE':
                    return benef.firstName
                  case '015_DATE_NAISSANCE_BENEFICIAIRE':
                    return formatDate(benef.birthdate)
                  case '016_EMAIL_BENEFICIAIRE':
                    return benef.email
                  case '020_ADRESSE_1_BENEFICIAIRE':
                    return benef.address?.address1
                  case '021_ADRESSE_2_BENEFICIAIRE':
                    return benef.address?.address2
                  case '022_ADRESSE_3_BENEFICIAIRE':
                    return benef.address?.address3
                  case '023_CODE_POSTAL_BENEFICIAIRE':
                    return benef.address?.zipCode
                  case '024_VILLE_BENEFICIAIRE':
                    return benef.address?.city
                  case '025_PAYS_BENEFICIAIRE':
                    return benef.address?.country
                  case '035_NOMBRE_TITRES': {
                    const date = new Date()
                    return differenceInBusinessDays(endOfMonth(date), startOfMonth(date))
                  }
                  case '002_CODE_PL':
                  case '003_CODE_PDIST':
                  case '036_VALEUR_FACIALE_CENTIME_EUROS':
                  case '039_PART_PATRONALE_EN_POURCENTAGE':
                  case '111_PART_PATRONALE_CENTIME_EUROS':
                    return ''
                }

                return ''
              })

              r.push(data.join(';'))
              return r
            }, [])

          const downloadCSVFile = useDownloadCSVFile()
          downloadCSVFile([fields.map((field) => field.csvName).join(';') ?? '', ...content], 'Import.csv')

          const trackingStore = useTrackingStore()
          const item = useProductLightItemTracking(cart.meta.productCode, cart.meta.articleCode, cart.meta.isNominative)

          if (item) {
            trackingStore.trackEvent({
              id: 'download',
              data: {
                items: [item],
              },
            })
          }

          return Result.ok(true)
        },
      }),
    nextAction,
    prevAction: useAction({
      id: 'back',
      name: 'back',
      type: ActionType.Default,
      refresh: false,
      async execute() {
        const univers = universByProductCode[cart.meta.productCode]
        router.push(`/commande/${univers === Univers.TR ? 'ticket-restaurant' : 'kadeos'}`)
        return Result.ok(true)
      },
    }),
    dedupeAction,
  }
}

const step: StepBuilder<ImportWorkflow> = {
  config: stepConfig,
  async prepareStep() {
    const valueOfTitleStore = useValueOfTitleStore()
    const result = await valueOfTitleStore.fetchConfigs()
    if (result.isErr) {
      return Result.err(result.error)
    }

    const cartStore = useCartStore()
    const resultImportFields = await cartStore.fetchImportFields()
    if (resultImportFields.isErr) {
      return Result.err(resultImportFields.error)
    }

    return Result.ok({ importFields: resultImportFields.value })
  },
  isAllowed(cart) {
    return cart.remote.status === CartStatus.Opened
  },
  useStep(cart, workflow, record, useAction) {
    return {
      config: stepConfig,
      component: () => import('@/components/Cart/Step/Import/index.vue'),
      useWorkflow: () => {
        const dataStoreValueOfTitle = cart.meta.dataStore[DataStoreKey.ValueOfTitle]
        const dataStoreDeliveries = cart.meta.dataStore[DataStoreKey.DeliveryPoint]
        const dataStoreDeliveryPoint = dataStoreDeliveries ? dataStoreDeliveries[cart.meta.productCode] : null

        const fieldResponse = useSWRV<FieldResponse>(`carts/import/rules/${cart.meta.productCode}`, async () => {
          const hasDeliveryPoint = !dataStoreDeliveryPoint?.isMulti
          const deliverToBeneficiary = dataStoreDeliveryPoint?.type === DeliveryPointType.PL_BENEF
          const fields = await apiV4.fileUpload.fetchFields(cart.meta.productCode, {
            hasDeliveryPoint,
            hasFacialValue: dataStoreValueOfTitle ? !dataStoreValueOfTitle.isMulti : false,
            deliverToBeneficiary,
          })

          if (fields.isErr) {
            throw fields.error
          }
          return {
            productCode: cart.meta.productCode,
            type: deliverToBeneficiary ? DeliveryPointType.PL_BENEF : DeliveryPointType.PL_SITE,
            fields: mapRulesToLocal(cart.meta.productCode, fields.value, record.importFields),
          }
        })

        return {
          ...useCommonStep(cart, workflow, record, useAction),
          pricings: [usePricingSummary(cart)],
          fieldResponse,
        }
      },
    }
  },
}

export default step
