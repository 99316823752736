import type { IResponse } from 'swrv/dist/types'
import type { Ref } from 'vue'
import { Block, type StepConfig, type StepWorkflow } from '@/store/cart/model'
import type { Action } from '@/types/Action'
import type { ImportField } from '@/types/Import'
import type { PricingData } from '@/types/Price'
import type { DeliveryPointType } from '@/variables/DeliveryPointType'
import type { ProductCode } from '@/variables/ProductCode'
import type { FileUploadStatus, ImportError } from '@/services/fileUpload/types'

export enum ImportStatus {
  Default = 'default',
  Waiting = 'waiting',
  Importing = 'importing',
  Dedupe = 'dedupe',
  Error = 'error',
  Success = 'success',
}

export const stepConfig: StepConfig = {
  id: 'import',
  path: 'import',
  name: 'Import',
  blocks: {
    header: Block.Fixed,
  },
}

export interface ImportResponse {
  remote: FileUploadStatus
  status: ImportStatus
  error?: {
    rejectMessage: string | null
    businessRejectCode: string | null
    errors: ImportError[]
  }
}

export interface FieldResponse {
  type: DeliveryPointType
  productCode: ProductCode
  fields: ImportField[]
}

export interface CommonImportWorkflow extends StepWorkflow {
  importId: Ref<number | null>
  filename: Ref<string>
  file: Ref<File | undefined>
  status: Ref<ImportStatus>
  intervalId: Ref<ReturnType<typeof setInterval> | null>
  importResponse: IResponse<ImportResponse | null>
  reimportAllowed: Ref<boolean>
  settingActions: Action[]
  setImportInterval: () => void
  downloadTemplateAction: (fields: ImportField[]) => Action
  downloadTemplateWithDataAction: ((fields: ImportField[]) => Action) | null
  importAction: Action
  dedupeAction: Action
  productCode: ProductCode
}

export interface ImportWorkflow extends CommonImportWorkflow {
  fieldResponse: IResponse<FieldResponse>
  pricings: IResponse<PricingData>[]
}
