import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import { DataStoreError } from './errors'
import type { DataStore, DataStoreKey } from './types'
import urlReplacer from '@/services/utils/urlReplacer'
import type { ProductCode } from '@/variables/ProductCode'
import type { ArticleCode } from '@/variables/ArticleCode'

const baseUrl = 'api/:version/dataStore'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
})

export default (axiosInstance: AxiosInstance) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const putDataStore = async (key: string, value: any) => {
    try {
      const result = await axiosInstance.put<DataStore>(getBaseUrl(), { key, value: JSON.stringify(value) })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new DataStoreError(error as ApiAxiosError))
    }
  }

  const putDataStoreByProduct = async (
    key: DataStoreKey,
    productCode: ProductCode,
    articleCode: ArticleCode | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    isMixed = false,
  ) => {
    const mixed = isMixed ? '-mixed' : ''
    return putDataStore(`${key}-${productCode}-${articleCode}${mixed}`, value)
  }

  const fetchDataStore = async (key: string) => {
    try {
      const result = await axiosInstance.get<DataStore>(getBaseUrl(), { params: { key } })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new DataStoreError(error as ApiAxiosError))
    }
  }

  async function fetchDataStoreByProduct<T>(
    key: DataStoreKey,
    productCode: ProductCode,
    articleCode: ArticleCode | null,
    isMixed = false,
  ) {
    const mixed = isMixed ? '-mixed' : ''
    const result = await fetchDataStore(`${key}-${productCode}-${articleCode}${mixed}`)

    if (result.isOk && result.value.value) {
      return JSON.parse(result.value.value) as T
    }

    return null
  }

  const deleteDataStore = async (key: string) => {
    try {
      const result = await axiosInstance.delete(getBaseUrl(), { params: { key } })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new DataStoreError(error as ApiAxiosError))
    }
  }

  async function deleteDataStoreByProduct(
    key: DataStoreKey,
    productCode: ProductCode,
    articleCode: ArticleCode | null,
    isMixed = false,
  ) {
    const mixed = isMixed ? '-mixed' : ''
    return deleteDataStore(`${key}-${productCode}-${articleCode}${mixed}`)
  }

  return {
    putDataStore,
    putDataStoreByProduct,
    fetchDataStore,
    fetchDataStoreByProduct,
    deleteDataStore,
    deleteDataStoreByProduct,
  }
}
