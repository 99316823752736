const baseUrl = '/facialValues'

export default (axiosInstance) => {
  const getFacialValueLogo = async (facialValue) => {
    if (!facialValue.LogoId) {
      return { ...facialValue }
    }
    try {
      const logo = await axiosInstance.get(`/logo/${facialValue.LogoId}`, {
        headers: { 'Content-Type': 'image/png' },
        responseType: 'arraybuffer',
      })
      const newLogo = window.URL.createObjectURL(new Blob([logo.data]))
      return { ...facialValue, newLogo }
    } catch (e) {
      return { ...facialValue }
    }
  }

  const getFacialValues = async (productCode) => {
    const facialValues = (
      await axiosInstance.get(baseUrl, {
        params: { productCode },
      })
    ).data
    return facialValues
    // return await Promise.all(facialValues.map(async (facialValue) => getFacialValueLogo(facialValue)))
  }

  const getAllFacialValues = async (productCode) => {
    const facialValues = (
      await axiosInstance.get('/allFacialValues', {
        params: { productCode },
      })
    ).data
    return facialValues
    // return await Promise.all(facialValues.map(async (facialValue) => getFacialValueLogo(facialValue)))
  }

  const getFacialValuesList = async (params) => {
    try {
      const result = await axiosInstance.get(baseUrl, { params })
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const setVf = async ({ Id, isActive }) => {
    let res
    try {
      res = (await axiosInstance.post(`${baseUrl}/active`, { Id, isActive })).data
    } catch (e) {
      throw e.response
    }
    return res
  }
  return {
    getFacialValues,
    getAllFacialValues,
    getFacialValuesList,
    getFacialValueLogo,
    setVf,
  }
}
