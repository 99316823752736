import type VueRouter from 'vue-router'
import type { Route } from 'vue-router'
import router from '@/router'

export type Options = {
  onReady: (() => void) | null
  onError: (() => void) | null
  onBeforeTrack: ((to: Route, from: Route) => void) | null
  onAfterTrack: ((to: Route, from: Route) => void) | null
  excludedRoutes: string[]
  enabled: boolean
  router?: VueRouter | null
}

export const getDefaultParams: () => Options = () => ({
  onReady: null,
  onError: null,
  onBeforeTrack: null,
  onAfterTrack: null,
  excludedRoutes: [],
  enabled: true,
})

let params = getDefaultParams()

export const setOptions = (options: Partial<Options> = {}) => {
  const defaultParams = getDefaultParams()
  params = {
    ...defaultParams,
    ...options,
    router,
  }
}

export const getOptions = () => {
  return params
}
