import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import type { Universe } from './types'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = 'api/:version/scopes'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
})

export default (axiosInstance: AxiosInstance) => {
  const getScopes = async () => {
    try {
      const result = await axiosInstance.get<Universe[]>(getBaseUrl())
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(error as ApiAxiosError)
    }
  }

  return {
    getScopes,
  }
}
