import { defineStore } from 'pinia'
import { type AsyncComponent, ref } from 'vue'
import type { DrawerPage } from './model'

export const useStore = defineStore('drawer', () => {
  const pages = ref<DrawerPage[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const push = ref<(id: string, component: AsyncComponent, props?: Record<string, any>) => void>(() => {
    // Stub method
  })

  const pop = ref<(id: string) => void>(() => {
    // Stub method
  })

  const popAll = ref<() => void>(() => {
    // Stub method
  })

  const initDrawer = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _push: (id: string, component: AsyncComponent, props?: Record<string, any>) => void,
    _pop: (id: string) => void,
    _popAll: () => void,
  ) => {
    push.value = _push
    pop.value = _pop
    popAll.value = _popAll
  }

  return { pages, push, pop, popAll, initDrawer }
})
