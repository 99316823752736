export enum MigrationStatus {
  Selected = 'SELECTIONNE',
  Qualified = 'QUALIFIE',
  Eligible = 'ELIGIBLE',
  Initialized = 'INITIALISE',
  Targeted = 'CIBLE',
  InProgress = 'CONVERSION_EN_COURS',
  Engaged = 'ENGAGE',
  Migrated = 'MIGRE',
  Completed = 'COMPLETE',
}

export interface MigrationInformations {
  contractMigrationCohortId: string
  contractMigrationState: MigrationStatus
  contractMigrationStateLastUpdateDate: string
  contractMigrationStartDate: string | null
  contractMigrationEndDate: string | null
}

export interface MigrationRedirect extends MigrationInformations {
  redirectLink: string
}
