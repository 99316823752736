import type { AxiosError, AxiosInstance } from 'axios'
import type { ContractualisationRequest } from './types'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrlV4 = 'api/:version/Contracts'
const getBaseUrlV4 = urlReplacer(baseUrlV4, {
  version: 'v1',
})

export default (axiosInstance: AxiosInstance) => {
  const postContractualisationV4 = async (payload: ContractualisationRequest) => {
    try {
      const result = await axiosInstance.post(`${getBaseUrlV4()}/oneClickContracting`, payload)
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const postOneClickTracking = async (productCodeTarget: number) => {
    try {
      const result = await axiosInstance.post(`${getBaseUrlV4()}/oneClickTracking`, {
        productCode: productCodeTarget,
      })
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  return {
    postContractualisationV4,
    postOneClickTracking,
  }
}
