const baseUrl = '/earliestDeliveryDate'

export default (axiosInstance) => {
  const getEarliestDeliveryDates = async (productCode, articleCode, deliveryPointsRefs) => {
    try {
      const result = await axiosInstance.get(baseUrl, {
        params: {
          productCode,
          articleCode,
          deliveryPointsRefs,
        },
      })
      return result.data
    } catch (error) {
      console.error('getEarliestDeliveryDates error : ', error)
      throw error
    }
  }

  return {
    getEarliestDeliveryDates,
  }
}
