import commonsCartWorkflow from '..'
import stepBasket from './steps/basket'
import addTRProduct from '@/store/cart/cartWorkflows/common/module/addTRProduct'
import type { CartWorkflow, FrontCart } from '@/store/cart/model'
import { ProductCode } from '@/variables/ProductCode'

const steps = commonsCartWorkflow.steps.map((step) => {
  if (step.config.id === stepBasket.config.id) {
    return stepBasket
  }

  return step
})

const cartWorkflow: CartWorkflow = {
  ...commonsCartWorkflow,
  steps,
  modules: [...commonsCartWorkflow.modules, addTRProduct],
  isValid(cart: FrontCart) {
    return cart.meta.isNominative === false && cart.meta.productCode === ProductCode.TICKET_RESTAURANT
  },
}

export default cartWorkflow
