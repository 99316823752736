import { Result } from '@badrap/result'
import { reactive, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { helpers, requiredIf, minValue, maxValue } from '@vuelidate/validators'
import {
  type KscCartSettingsWorkflow,
  moduleKSCConfig,
  type KscImportOptionsState,
} from '@/components/Cart/Module/Settings/workflow'
import type { FrontCartDataStore, ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import { getErrorMessage } from '@/utils/vuelidate'
import { useI18n } from '@/composables/useI18n'
import { CartItemType } from '@/services/carts/types'
import { toPrice } from '@/utils/price'
import { useStore as useCartStore } from '@/store/cart/store'
import { DataStoreKey } from '@/services/dataStore/types'
import { useStore as useDrawerStore } from '@/store/drawer'

const cartSetting: ModuleBuilder<KscCartSettingsWorkflow> = {
  config: moduleKSCConfig,
  isAllowed: () => true,
  useModule(cartRef, _workflow, useAction) {
    if (cartRef.value === null) {
      throw new Error('cart.errors.cartNotFound')
    }

    const { value: cart } = cartRef

    return {
      useWorkflow({ showAmount, showEvent }: { showAmount: boolean; showEvent: boolean }) {
        const i18n = useI18n()

        const cartStore = useCartStore()
        const cartItemConfig = cartStore
          .cartItemConfigs(cart.meta.productCode, cart.meta.articleCode)
          ?.find((config) => config.type === CartItemType.Dematerialized)

        if (cartItemConfig == null) {
          throw new Error('cart.errors.cartItemConfig.notFound')
        }

        const events = cartStore.eventsByProductCode(cart.meta.productCode)
        const amount = cart.meta.dataStore[DataStoreKey.Amount]?.amount ?? cartItemConfig.recommendedAmount ?? null
        const state: KscImportOptionsState = reactive({
          isMultiAmount: cart.meta.dataStore[DataStoreKey.Amount]?.isMulti ?? false,
          amount: amount ? { value: amount, hasDecomposition: false, decompositions: [] } : null,
          event: cart.meta.dataStore[DataStoreKey.Event]?.event ?? (events.length > 0 ? events[0] : null),
        })

        const rules = {
          isMultiAmount: {},
          amount: {
            required: helpers.withMessage(
              i18n.t('cart.modules.settings.amount.errors.required').toString(),
              requiredIf(() => !state.isMultiAmount && showAmount),
            ),
            value: {
              minValue: helpers.withMessage(
                i18n
                  .t('cart.modules.settings.amount.errors.minValue', { min: toPrice(cartItemConfig.minAmount) })
                  .toString(),
                minValue(cartItemConfig.minAmount),
              ),
              maxValue: helpers.withMessage(
                i18n
                  .t('cart.modules.settings.amount.errors.maxValue', { max: toPrice(cartItemConfig.maxAmount) })
                  .toString(),
                maxValue(cartItemConfig.maxAmount),
              ),
            },
          },
          event: {
            required: helpers.withMessage(
              i18n.t('cart.modules.addItem.events.errors.required').toString(),
              requiredIf(() => showEvent),
            ),
          },
        }

        const v$ = useVuelidate(rules, state)
        return {
          events,
          showAmount,
          showEvent,
          v$,
          saveDefaultParams: ref(false),
          cartItemConfig,
          submitAction: useAction({
            id: 'add',
            name: 'cart.modules.settings.save',
            type: ActionType.Default,
            refresh: true,
            async execute() {
              v$.value.$touch()

              if (v$.value.$invalid) {
                return Result.err(new Error(getErrorMessage(v$.value.$errors)))
              }

              if (showEvent && v$.value.event.$model == null) {
                return Result.err(new Error())
              }
              const dataStore: FrontCartDataStore = {
                ...cart.meta.dataStore,
              }

              if (showAmount) {
                dataStore[DataStoreKey.Amount] = {
                  isMulti: v$.value.isMultiAmount.$model,
                  amount: v$.value.amount.$model?.value ?? 0,
                }
              }

              if (showEvent) {
                dataStore[DataStoreKey.Event] = {
                  event: v$.value.event.$model,
                  isMulti: false,
                }
              }
              cartStore.edit({
                localId: cart.localId,
                meta: {
                  dataStore,
                },
              })

              const drawerStore = useDrawerStore()
              drawerStore.pop(moduleKSCConfig.id)
              return Result.ok(true)
            },
          }),
        }
      },
    }
  },
}
export default cartSetting
