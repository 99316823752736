import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import { ApiSipError } from './errors'
import type { Sips } from './types'
import urlReplacer from '@/services/utils/urlReplacer'
import type { ProductCode } from '@/variables/ProductCode'

const baseUrl = 'api/:version/sips'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
  basketId: '',
})

export default (axiosInstance: AxiosInstance) => {
  const getSips = async (productCode: ProductCode) => {
    try {
      const result = await axiosInstance.get<Sips>(`${getBaseUrl()}?productCode=${productCode}`)

      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new ApiSipError(error as ApiAxiosError))
    }
  }

  return {
    getSips,
  }
}
