import type { Asset, Entry } from 'contentful'
import type { TypeContentSkeleton } from '../generated'
import { mapMediaToLocal, type CFMedia } from './CFMedia'
import { documentToHtmlString } from '@/utils/richTextHtmlRenderer'

export interface CFContent {
  title: string
  description: string
  icon?: CFMedia | null
}
export function mapContentToLocal(content: Entry<TypeContentSkeleton, undefined, string>): CFContent {
  return {
    title: content.fields.title,
    description: documentToHtmlString(content.fields.description),
    icon: content.fields.icon ? mapMediaToLocal(content.fields.icon as Asset<undefined, string>) : null,
  }
}

export function mapContentsToLocal(contents: Entry<TypeContentSkeleton, undefined, string>[]): CFContent[] {
  return contents.map((content) => mapContentToLocal(content))
}
