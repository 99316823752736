import habilitations from '@/variables/habilitations.json'

const DefaultRoot = () => import('@/views/DefaultRouter/Root/index.vue')
const Dashboard = () => import('@/views/Admin/Dashboard/index.vue')

export default {
  path: '/dashboard',
  meta: {
    breadcrumb: 'Tableau de bord',
    howTo: 'comment-faire-tableau-de-bord',
  },
  component: DefaultRoot,
  children: [
    {
      path: '',
      meta: {
        title: 'Tableau de bord',
        refAbility: [habilitations.DASHBOARD.ACCES],
      },
      name: 'Dashboard',
      component: Dashboard,
    },
  ],
}
