import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import { ApiClientProductError } from './errors'
import { type ClientProduct, mapClientProductsToLocal } from './types'
import urlReplacer from '@/services/utils/urlReplacer'
import type { ProductCode } from '@/variables/ProductCode'

const baseUrl = 'api/:version/products'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
})

export default (axiosInstance: AxiosInstance) => {
  const getProducts = async () => {
    try {
      const result = await axiosInstance.get<ClientProduct[]>(getBaseUrl())
      return Result.ok(result.data).map((products) => mapClientProductsToLocal(products))
    } catch (error) {
      return Result.err(new ApiClientProductError(error as ApiAxiosError))
    }
  }

  const getProductConfiguration = async (productCode: ProductCode) => {
    try {
      const result = await axiosInstance.get<ClientProduct>(`${getBaseUrl()}/${productCode}`)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(new ApiClientProductError(error as ApiAxiosError))
    }
  }

  return {
    getProducts,
    getProductConfiguration,
  }
}
