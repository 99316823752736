import { Result } from '@badrap/result'
import type { AxiosInstance, AxiosResponse } from 'axios'
import type { Profile, ProfileAccount, ProfileAccountsRequest, ProfileEnrollmentSMS, ProfileSecurity } from './types'
import type { ApiAxiosError } from '@/services/common/errors'
import type { ApiAuthnSessionsCode, ApiAuthnSessionsSents } from '@/services/authn/types'
import {
  ApiAuthnAccountLockedError,
  ApiAuthnErrorType,
  ApiAuthnExpiredSessionError,
  ApiAuthnInvalidCodeError,
  ApiAuthnInvalidCodeFormatError,
  ApiAuthnError,
} from '@/services/authn/errors'

const baseUrl = '/profile'

export default (axiosInstance: AxiosInstance) => {
  const getProfile = async () => {
    try {
      const result = await axiosInstance.get<Profile>(baseUrl)
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(error as ApiAxiosError)
    }
  }

  const mfaEnrollmentSMS = async (PhoneNumber: string) => {
    try {
      const result = await axiosInstance.post<ProfileEnrollmentSMS>(`${baseUrl}/enrollment/sms`, { PhoneNumber })
      return Result.ok(result.data)
    } catch (error) {
      return Result.err(error as ApiAxiosError)
    }
  }

  const mfaEnrollmentSents = async (sessionId: string) => {
    try {
      return Result.ok<AxiosResponse<ApiAuthnSessionsSents>, ApiAxiosError>(
        await axiosInstance.post(`${baseUrl}/enrollment/sents`, { sessionId }),
      ).map((value) => value.data)
    } catch (error) {
      return Result.err(error as ApiAxiosError)
    }
  }

  const mfaEnrollmentCode = async (
    code: string,
    sessionId: string,
  ): Promise<Result<ApiAuthnSessionsCode, ApiAuthnError>> => {
    try {
      const result = await axiosInstance.post<ApiAuthnSessionsCode>(`${baseUrl}/enrollment/code`, {
        sessionId,
        code,
      })

      if (!result.data.IsValid) {
        if (result.data.RemainingAttempts === 0 || result.data.RemainingSessionAttempts === 0) {
          return Result.err(new ApiAuthnAccountLockedError())
        }

        return Result.err(new ApiAuthnInvalidCodeError(result.data))
      }

      return Result.ok(result.data)
    } catch (error) {
      const _error = error as ApiAxiosError
      const statusCode = _error.response?.status ?? -1

      switch (statusCode) {
        case 400:
          return Result.err(new ApiAuthnInvalidCodeFormatError(_error))

        case 404:
          return Result.err(new ApiAuthnExpiredSessionError(_error))

        default:
          return Result.err(new ApiAuthnError(ApiAuthnErrorType.UNKNOWN, _error))
      }
    }
  }

  const changePassword = async (obj: ProfileSecurity) => {
    try {
      await axiosInstance.patch(`${baseUrl}/security`, obj)
      return Result.ok(null)
    } catch (error) {
      console.error(error)
      return Result.err(error as ApiAxiosError)
    }
  }

  const getProfileAccounts = async (params: ProfileAccountsRequest) => {
    try {
      const result = await axiosInstance.get<ProfileAccount[]>(`${baseUrl}/v2/accounts`, { params })
      return Result.ok({ data: result.data, totalCount: result.headers['x-total-count'] })
    } catch (error) {
      console.error(error)
      return Result.err(error as ApiAxiosError)
    }
  }

  const updateProfileAssociations = async (accounts: ProfileAccount[]) => {
    try {
      await axiosInstance.patch(`${baseUrl}/accounts/association`, accounts)
      return Result.ok(null)
    } catch (error) {
      console.error(error)
      throw Result.err(error as ApiAxiosError)
    }
  }

  const associateAlohaDivision = async (division: number): Promise<Result<null, ApiAxiosError>> => {
    try {
      await axiosInstance.post(`${baseUrl}/accounts/association/aloha/${division}`)
      return Result.ok(null)
    } catch (error) {
      console.error(error)
      return Result.err(error as ApiAxiosError)
    }
  }

  return {
    getProfile,
    mfaEnrollmentSMS,
    mfaEnrollmentSents,
    mfaEnrollmentCode,
    changePassword,
    getProfileAccounts,
    updateProfileAssociations,
    associateAlohaDivision,
  }
}
