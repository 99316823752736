import type { Asset, Entry } from 'contentful'
import type { TypeHeadMetaSkeleton, TypeHeaderSkeleton } from '../generated'
import type { CFTarget } from './CFTarget'
import { mapHeadMetasToLocal, type CFHeadMeta } from './CFHeadMeta'
import { mapMediaToLocal, type CFMedia } from './CFMedia'
import type { SimpleLink } from '@/types/Link'

export interface CFHeaderContent {
  mainCta: SimpleLink
}

export interface CFHeader {
  logo: CFMedia
  content: CFHeaderContent
  target: CFTarget
  meta: CFHeadMeta[]
}

export function mapHeaderToLocal(item: Entry<TypeHeaderSkeleton, undefined, string>): CFHeader {
  return {
    target: item.fields.target,
    logo: mapMediaToLocal(item.fields.logo as Asset<undefined, string>),
    content: item.fields.content as unknown as CFHeaderContent,
    meta: item.fields.meta
      ? mapHeadMetasToLocal(item.fields.meta as Entry<TypeHeadMetaSkeleton, undefined, string>[])
      : [],
  }
}
