export default function post(iframe, path, params, method = 'post') {
  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.

  const form = iframe.createElement('form')
  form.setAttribute('method', method)
  form.setAttribute('action', path)
  if (params && Object.keys(params).length) {
    for (const key in params) {
      const hiddenField = iframe.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('name', key)
      hiddenField.setAttribute('value', params[key])

      form.appendChild(hiddenField)
    }
  }
  if (iframe.body) {
    iframe.body.appendChild(form)
  } else {
    const body = iframe.createElement('body')
    iframe.appendChild(body)
    iframe.body.appendChild(form)
  }
  form.submit()
}
