import { nextTick } from 'vue'
import { isFunction } from '@vue/shared'
import type { Route } from 'vue-router'
import { getOptions } from './options'
import { useStore as useTrackingStore } from '@/store/tracking'

const isRouteExcluded = (route: Route) => {
  const { excludedRoutes: routes } = getOptions()
  return routes.includes(route.path) || (route.name != null && routes.includes(route.name))
}

export default () => {
  const { onBeforeTrack, onAfterTrack, router } = getOptions()

  if (router == null) {
    // Nothing to register
    console.warn('[Tracking] No router provided.')
    return
  }

  router.onReady(() => {
    nextTick().then(() => {
      const tracking = useTrackingStore()
      const { currentRoute } = router

      if (isRouteExcluded(currentRoute)) {
        return
      }

      tracking.trackPageView(currentRoute)
    })

    router.afterEach((to, from) => {
      nextTick().then(() => {
        const tracking = useTrackingStore()

        if (isRouteExcluded(to)) {
          return
        }

        if (isFunction(onBeforeTrack)) {
          onBeforeTrack(to, from)
        }

        tracking.trackPageView(to, from)

        if (isFunction(onAfterTrack)) {
          onAfterTrack(to, from)
        }
      })
    })
  })
}
