export enum ProductCode {
  TICKET_RESTAURANT = 1,
  TICKET_SERVICE = 2,
  TICKET_CESU = 3,
  TICKET_CAP = 9,
  TICKET_RESTAURANT_BENEVOLES_VOLONTAIRE = 24,
  KADEOS = 80,
  KADEOS_CONNECT = 104,
  KADEOS_UNIVERSEL = 220,
  CARTE_KADEOS = 221,
  CARTE_TICKET_RESTAURANT = 240,
  TICKET_FLEETPRO = 214,
  BETTERWAY = 250,
}

export const productKey: Record<number, string> = {
  [ProductCode.KADEOS]: 'KS',
  [ProductCode.KADEOS_CONNECT]: 'KSC',
  [ProductCode.CARTE_KADEOS]: 'CKS',
  [ProductCode.KADEOS_UNIVERSEL]: 'CKS',
}

export const productsLabel: Record<ProductCode, string> = {
  [ProductCode.TICKET_RESTAURANT]: 'Ticket Restaurant®',
  [ProductCode.CARTE_TICKET_RESTAURANT]: 'Carte Ticket Restaurant®',
  [ProductCode.KADEOS]: 'Kadéos',
  [ProductCode.KADEOS_CONNECT]: 'Kadéos Connect',
  [ProductCode.TICKET_SERVICE]: 'Ticket Service',
  [ProductCode.TICKET_CAP]: 'Ticket Service CAP',
  [ProductCode.TICKET_CESU]: 'Ticket CESU',
  [ProductCode.TICKET_RESTAURANT_BENEVOLES_VOLONTAIRE]: 'Ticket Restaurant®',
  [ProductCode.KADEOS_UNIVERSEL]: 'Carte Kadéos Universel',
  [ProductCode.CARTE_KADEOS]: 'Carte Kadéos',
  [ProductCode.TICKET_FLEETPRO]: 'Ticket FleetPro',
  [ProductCode.BETTERWAY]: 'Ticket Mobilité',
}
