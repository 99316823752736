const baseUrl = '/management'

export default (axiosInstance) => {
  const getAllDeliveryPoints = async () => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/deliverypoints`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const listOfRoles = async (params) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/roles`, { params })
      return result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const getListUsers = async (params) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/users`, { params })
      return result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const getListOfRestrictions = async (params) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/restrictions`, { params })
      return result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const hasReadCgvPopin = async (popinCode, popinVersion) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/cgv`, {
        params: {
          popinCode,
          popinVersion,
        },
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const saveUserPopinState = async (popinCode, version) => {
    try {
      const result = await axiosInstance.post(`${baseUrl}/cgv`, {
        popinCode: popinCode,
        popinVersion: version,
      })
      return result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const userDetails = async (userId) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/users/${userId}`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const patchUserDetails = async (payload) => {
    try {
      const result = await axiosInstance.patch(`${baseUrl}/users/${payload.userId}`, payload.patchData)
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const userRoleDetails = async ({ id }) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/roles/${id}`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const patchRole = async ({ roleId, patchData }) => {
    try {
      const result = await axiosInstance.patch(`${baseUrl}/roles/${roleId}`, patchData)
      return result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const postRole = async ({ patchData }) => {
    try {
      const result = await axiosInstance.post(`${baseUrl}/roles/`, patchData)
      return result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const postRestriction = async ({ postData }) => {
    try {
      const result = await axiosInstance.post(`${baseUrl}/restrictions`, postData)
      return result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const userRestrictionDetails = async (restrictionId) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/restrictions/${restrictionId}`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const getManagementDeliveryPoints = async (productCode) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/deliveryPoints/${productCode}`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const patchRestriction = async (id, patchData) => {
    try {
      const result = await axiosInstance.patch(`${baseUrl}/restrictions/${id}`, patchData)
      return result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const addUser = async ({ Email, RedirectPath, IdRestriction, IdRole }) => {
    try {
      const result = await axiosInstance.post(`${baseUrl}/users/`, {
        Email,
        RedirectPath,
        IdRestriction,
        IdRole,
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const getSecretAnswer = async ({ userId }) => {
    try {
      const result = await axiosInstance.get(`${baseUrl}/users/${userId}/secretanswer`)
      return (result.data && result.data.SecretQuestionAnswer) || result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const deleteRole = async ({ roleId }) => {
    try {
      const result = await axiosInstance.delete(`${baseUrl}/roles/${roleId}`)
      return result.data || result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const deleteRestriction = async ({ restrictionId }) => {
    try {
      const result = await axiosInstance.delete(`${baseUrl}/restrictions/${restrictionId}`)
      return result.data || result
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  return {
    getAllDeliveryPoints,
    listOfRoles,
    getListUsers,
    getListOfRestrictions,
    hasReadCgvPopin,
    saveUserPopinState,
    userDetails,
    patchUserDetails,
    userRoleDetails,
    patchRole,
    postRole,
    postRestriction,
    userRestrictionDetails,
    getManagementDeliveryPoints,
    patchRestriction,
    addUser,
    getSecretAnswer,
    deleteRole,
    deleteRestriction,
  }
}
