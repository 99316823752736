import type { AxiosError, AxiosInstance } from 'axios'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = 'api/:version/Dashboards'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
})

export default (axiosInstance: AxiosInstance) => {
  const postToken = async () => {
    try {
      const result = await axiosInstance.post(`${getBaseUrl()}/token`)
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      throw _error.response
    }
  }

  return {
    postToken,
  }
}
