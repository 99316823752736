import { Result } from '@badrap/result'
import { getterTree, actionTree, mutationTree } from 'typed-vuex'
import { accessor } from '..'
import { useStore as useCartStore } from '../cart/store'
import { useStore as useDrawerStore } from '../drawer'
import type { ActivationParams, AuthState, LoginParams, Token } from './model'
import formPost from '@/helpers/formPost'
import { clientUrlV1, NODE_ENV } from '@/parameters'
import router from '@/router'
import apiV3 from '@/services/apiV3'
import type { ApiAuthError } from '@/services/auth/errorTypes'
import type { ApiToken } from '@/services/auth/types'
import type { AsyncData } from '@/types/AsyncData'
import { getInLocalStorage, setInLocalStorage } from '@/utils/localStorage'
import contentful from '@/services/contentful'
import type { CFLayout } from '@/services/contentful/types/CFLayout'

export const namespaced = true
export const TOKEN_KEY = 'token'
export const LEGACY_ACCESS_TOKEN_KEY = 'access_token'
export const LEGACY_REFRESH_TOKEN_KEY = 'refresh_token'
export const AUTH_DATA_KEY = 'auth_data'
export const IS_LOGGED_IN_V1 = 'isLoggedInV1'

export const state: () => AuthState = () => {
  let token = getInLocalStorage<Token>(TOKEN_KEY)

  // Legacy to be removed soon
  if (!token) {
    const accessToken = localStorage.getItem(LEGACY_ACCESS_TOKEN_KEY)
    const refreshToken = localStorage.getItem(LEGACY_REFRESH_TOKEN_KEY)

    if (accessToken) {
      token = { accessToken, refreshToken }
    }
  }

  return {
    token,
    data: {
      loading: false,
      refreshed: false,
      value: getInLocalStorage<CFLayout>(AUTH_DATA_KEY),
    },
    isIpLabel: null,
    isLoggedInV1: getInLocalStorage<boolean>(IS_LOGGED_IN_V1) ?? false,
  }
}

export const getters = getterTree(state, {
  isLoggedIn: (state) => state.token !== null,
  isLoggedInV1: (state) => state.isLoggedInV1,
  isIpLabel: (state) => state.isIpLabel,
  token: (state) => state.token,
  data: (state) => state.data,
})

export const mutations = mutationTree(state, {
  setToken(state, token: Token | null) {
    state.token = token

    setInLocalStorage(TOKEN_KEY, token)

    if (token) {
      localStorage.setItem(LEGACY_ACCESS_TOKEN_KEY, token.accessToken)

      if (token.refreshToken) {
        localStorage.setItem(LEGACY_REFRESH_TOKEN_KEY, token.refreshToken)
      } else {
        localStorage.removeItem(LEGACY_REFRESH_TOKEN_KEY)
      }
    } else {
      localStorage.removeItem(LEGACY_ACCESS_TOKEN_KEY)
      localStorage.removeItem(LEGACY_REFRESH_TOKEN_KEY)
    }
  },
  setData(state, data: AsyncData<CFLayout>) {
    state.data = data
    setInLocalStorage(AUTH_DATA_KEY, data.value)
  },
  setIsIpLabel(state, isIpLabel: boolean) {
    state.isIpLabel = isIpLabel
  },
  setIsLoggedInV1(state, isLoggedInV1: boolean | null) {
    state.isLoggedInV1 = isLoggedInV1 ?? false
    setInLocalStorage<boolean>(IS_LOGGED_IN_V1, isLoggedInV1)
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    async fetchAuthData({ commit, state }, force?: boolean) {
      if (state.data.loading || (!force && state.data.refreshed && state.data.value)) {
        return
      }

      commit('setData', {
        loading: true,
        refreshed: state.data.refreshed,
        value: state.data.value,
      })

      const result = await contentful.layout.fetchLayout()
      if (result.isOk) {
        commit('setData', {
          loading: false,
          refreshed: true,
          value: result.value,
        })
      } else {
        commit('setData', {
          loading: false,
          refreshed: state.data.refreshed,
          value: state.data.value,
          error: result.error,
        })
      }
    },

    async login({ commit }, { email, password, captchaToken }: LoginParams): Promise<Result<ApiToken, ApiAuthError>> {
      const result = await apiV3.auth.login(email, password, captchaToken)
      if (result.isOk) {
        commit('setToken', {
          accessToken: result.value.access_token,
          refreshToken: result.value.refresh_token,
        })
      }

      return result
    },

    async goToV1({ state, commit }, { path, query }: { path: string; query: string }) {
      if (!state.token) {
        return
      }

      commit('setIsLoggedInV1', true)
      const body = {
        token: state.token.accessToken,
        redirectUrl: `${clientUrlV1}${path}?${query}`,
      }

      if (accessor.session.isSubstitute || accessor.session.divisionsCount > 0) {
        const params = new URLSearchParams({ path })
        body.redirectUrl = `${window.location.origin}/v1/load?${params.toString()}&${query}`
      }

      formPost(document, `${clientUrlV1}account/SignInToken`, body)
    },

    requestPasswordReset(
      _,
      { email, captchaToken }: { email: string; captchaToken?: string },
    ): Promise<Result<boolean, ApiAuthError>> {
      return apiV3.auth.requestPasswordReset(email, captchaToken)
    },

    passwordReset(
      _,
      {
        email,
        token,
        questionId,
        answer,
        password,
      }: { email: string; token: string; questionId: number; answer: string; password: string },
    ): Promise<Result<boolean, ApiAuthError>> {
      return apiV3.auth.changePassword(email, token, questionId, answer, password)
    },

    createAndSendActivation(
      _,
      { email, clientCode }: { email: string; clientCode: string },
    ): Promise<Result<boolean, ApiAuthError>> {
      return apiV3.auth.createAndSendActivation(email, clientCode)
    },

    requestActivation(_, { email, captchaToken }: { email: string; captchaToken?: string }) {
      return apiV3.auth.sendActivationMail(email, captchaToken)
    },

    activation(_, { email, token, questionId, secretAnswer, password }: ActivationParams) {
      return apiV3.auth.activate(email, token, questionId, secretAnswer, password)
    },

    async refreshToken({ state, commit }): Promise<Result<ApiToken, ApiAuthError | Error>> {
      if (!state.token?.refreshToken) {
        return Result.err(new Error('Missing token'))
      }
      const result = await apiV3.auth.refreshToken(state.token.refreshToken)
      if (result.isOk) {
        commit('setToken', {
          accessToken: result.value.access_token,
          refreshToken: result.value.refresh_token,
        })
      }

      return result
    },

    async logout({ state, commit }, { redirect }: { redirect: boolean } = { redirect: true }) {
      const ssoLogoutUrl = accessor.session.user?.logoutUrl

      accessor.session.clearSession()
      const cartStore = useCartStore()
      cartStore.setCarts(null)
      commit('setToken', null)

      const drawerStore = useDrawerStore()
      drawerStore.popAll()

      if (NODE_ENV !== 'development' && state.isLoggedInV1) {
        commit('setIsLoggedInV1', false)

        await new Promise<void>((resolve) => {
          const iframeSub = document.createElement('iframe')
          iframeSub.style.display = 'none'
          iframeSub.src = `${clientUrlV1}/deconnexion`
          document.body.appendChild(iframeSub)
          iframeSub.addEventListener('load', () => {
            document.body.removeChild(iframeSub)
            resolve()
          })
        })
      }

      // si sso, redirection vers le logout sso. Sinon vers la page V1
      if (ssoLogoutUrl) {
        location.href = ssoLogoutUrl
      } else if (redirect) {
        if (!router.currentRoute.path.startsWith('/login')) {
          await router.replace('/login')
        }
      }
    },

    async fetchIsIpLabel({ commit, state }) {
      if (state.isIpLabel !== null) {
        return Result.ok(state.isIpLabel)
      }

      const result = await apiV3.auth.isIpLabel()

      commit('setIsIpLabel', result.isOk ? result.value : false)

      return result
    },
  },
)
