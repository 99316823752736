import basket, { useCommonBasketItemWorkflow } from '../../../../common/steps/basket'
import type { BasketWorkflow } from '@/components/Cart/Step/Basket/workflow'
import type { StepBuilder } from '@/store/cart/model'
import addKSProduct from '@/store/cart/cartWorkflows/common/module/addKSProduct'
import apiV4 from '@/services/apiV4'
import { ActionType } from '@/types/Action'
import { useStore as useCartStore } from '@/store/cart/store'
import { moduleConfig as addItemConfig } from '@/components/Cart/Module/AddItem/workflow'
import { useI18n } from '@/composables/useI18n'

const step: StepBuilder<BasketWorkflow> = {
  ...basket,
  useStep(cart, workflow, record, useAction) {
    const useStep = basket.useStep(cart, workflow, record, useAction)

    return {
      ...useStep,
      useWorkflow: () => {
        const cartStore = useCartStore()
        const useWorkflow = useStep.useWorkflow()
        const i18n = useI18n()
        useWorkflow.table.value = {
          columns: [
            {
              id: 'format',
              label: 'table.headers.format',
            },
            { id: 'quantity', label: 'table.headers.quantity' },
            { id: 'decomposition', label: 'table.headers.decomposition' },
            { id: 'total', label: 'table.headers.total' },
            { id: 'delivery', label: 'table.headers.delivery' },
            { id: 'more', label: '' },
          ],
          selectable: false,
          loading: false,
          readonly: useWorkflow.table.value.readonly,
          rowComponent: () => import('@/components/Cart/Step/Basket/Row/KSRow.vue'),
        }
        const commonBasketItemWorkflow = useCommonBasketItemWorkflow(cart, workflow, record, useAction)

        return {
          ...useWorkflow,
          addAction: useAction({
            id: addKSProduct.config.id,
            name: 'cart.button.addProduct',
            type: ActionType.Drawer,
            icon: 'add-circle',
            refresh: false,
            payload: record,
          }),
          emptyDescription: 'cart.ks.emptyDescription',
          hasImport: false,
          hasSearch: false,
          importAction: null,
          export: {
            id: 'btn-export-cart',
            filename: 'Restitution.csv',
            fetchData: async () => {
              if (
                useWorkflow.response.data.value?.totalElements == null ||
                useWorkflow.response.data.value?.totalElements === 0
              ) {
                return []
              }

              const result = await apiV4.carts.fetchItems(cart.remote.id, {
                page: 1,
                pageSize: useWorkflow.response.data.value.totalElements,
              })

              if (result.isErr) {
                return []
              }

              return (
                result.value.items?.map((item) => ({
                  ['table.headers.format']: i18n.t(item.itemType).toString(),
                  ['table.headers.event']:
                    cartStore.events?.find((event) => event.value === item.eventCode)?.label ?? item.eventCode,
                  ['table.headers.decomposition']: item.description,
                  ['table.headers.quantity']: item.totalQuantities,
                  ['table.headers.amount']: item.totalTitleAmount,
                  ['table.headers.delivery']: item.deliveryPointReference,
                  ['table.headers.distribution']: item.distributionPointReference,
                })) ?? []
              )
            },
          },
          useItemWorkflow: (item) => {
            return {
              ...commonBasketItemWorkflow(item),
              updateAction: (item) => {
                return useAction({
                  id: addItemConfig.id,
                  name: 'update',
                  icon: 'edit',
                  type: ActionType.Drawer,
                  payload: { item },
                  refresh: false,
                })
              },
            }
          },
        }
      },
    }
  },
}

export default step
