import type { RouteConfig } from 'vue-router'
import ABILITIES from '@/variables/habilitations.json'
import { useStore as useFeatureFlagStore } from '@/store/featureFlag'
import { FeatureFlag } from '@/store/featureFlag/model'

const DefaultChild = () => import('@/views/DefaultRouter/Child/index.vue')

export const router: RouteConfig = {
  path: 'moyens-paiements',
  meta: {
    breadcrumb: 'Moyens de paiement',
    howTo: 'gestion-moyens-paiements-comment-faire',
  },
  component: DefaultChild,
  children: [
    {
      path: '',
      meta: {
        title: 'Moyens de paiement',
        refAbility: [ABILITIES.SETTINGS.PAYMENT],
        howTo: 'gestion-moyens-paiements-comment-faire',
      },
      name: 'PaymentMeansView',
      component: () => {
        const store = useFeatureFlagStore()

        if (store.isEnabled(FeatureFlag.NewPaymentMeansPage)) {
          return import('@/views/Admin/PaymentMeans/index.vue')
        } else {
          return import('@/views/Admin/PaymentMeans_old/PaymentMeansView.vue')
        }
      },
    },
  ],
}
