import { documentToHtmlString as cfDocumentToHtmlString } from '@contentful/rich-text-html-renderer'
import { INLINES, BLOCKS, type Document } from '@contentful/rich-text-types'
import type { Asset } from 'contentful'
import { mapMediaToLocal } from '@/services/contentful/types/CFMedia'

export function documentToHtmlString(document: Document) {
  return cfDocumentToHtmlString(document, {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const media = mapMediaToLocal(node.data.target as Asset<undefined, string>)
        return `<img src="${media.src}" />`
      },
      [INLINES.HYPERLINK]: (node, next) => {
        return `<a target="${node.data.uri?.startsWith('http') ? '_blank' : '_self'}" href=${node.data.uri}>${next(
          node.content,
        )}</a>`
      },
      [INLINES.ASSET_HYPERLINK]: (node, next) => {
        const media = mapMediaToLocal(node.data.target as Asset<undefined, string>)
        return `<a target="_blank" href=${media.src}>${next(node.content)}</a>`
      },
    },
  })
    .replace(' !', '&nbsp;!')
    .replace(' ?', '&nbsp;?')
    .replace(' :', '&nbsp;:')
    .replace(/®/g, '<sup>&#174;</sup>')
}
