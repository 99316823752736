import type { ApiAxiosError } from '../common/errors'
import { ResultError } from '@/types/ResultError'

export enum ApiInvoicesErrorType {
  NOT_FOUND = 'NOT_FOUND',
  UNKNOWN = 'UNKNOWN',
}

export class ApiInvoicesError extends ResultError {
  code: ApiInvoicesErrorType

  constructor(code: ApiInvoicesErrorType, error: Error) {
    super(error)

    this.code = code
  }
}

export class ApiInvoicesNotFoundError extends ApiInvoicesError {
  constructor(error: ApiAxiosError) {
    super(ApiInvoicesErrorType.NOT_FOUND, error)

    this.name = 'ApiInvoicesNotFoundError'
    this.message = 'Not Found'
  }
}
