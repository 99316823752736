import type { Asset, ContentfulClientApi, Entry } from 'contentful'
import { Result } from '@badrap/result'
import type {
  TypeContentSkeleton,
  TypeCtaSkeleton,
  TypeFooterSkeleton,
  TypeHeaderSkeleton,
  TypeMigrationPageSkeleton,
  TypeMigrationTabSkeleton,
} from '../generated'
import { mapMediaToLocal } from '../types/CFMedia'
import { mapCtaToLocal } from '../types/CFCta'
import type { CFMigrationPage, CFMigrationTab } from '../types/CFMigration'
import { mapHeaderToLocal } from '../types/CFHeader'
import { mapFooterToLocal } from '../types/CFFooter'
import { mapContentToLocal, mapContentsToLocal } from '../types/CFContent'
import { documentToHtmlString } from '@/utils/richTextHtmlRenderer'

export default (contentfulInstance: ContentfulClientApi<undefined>) => {
  const fetchMigration = async () => {
    try {
      const result = await contentfulInstance.getEntries<TypeMigrationPageSkeleton>({
        content_type: 'migrationPage',
        include: 2,
      })

      return Result.ok(mapMigrationPagesToLocal(result.items)[0])
    } catch (error) {
      console.log(error)
      return Result.err(error as Error)
    }
  }

  return {
    fetchMigration,
  }
}

export function mapMigrationPagesToLocal(
  items: Entry<TypeMigrationPageSkeleton, undefined, string>[],
): CFMigrationPage[] {
  return items.map((item) => mapMigrationPageToLocal(item))
}

export function mapMigrationPageToLocal(item: Entry<TypeMigrationPageSkeleton, undefined, string>): CFMigrationPage {
  return {
    title: item.fields.title,
    highlightTitle: item.fields.highlightTitle,
    description: documentToHtmlString(item.fields.description),
    header: mapHeaderToLocal(item.fields.header as Entry<TypeHeaderSkeleton, undefined, string>),
    footer: mapFooterToLocal(item.fields.footer as Entry<TypeFooterSkeleton, undefined, string>),
    tabs: item.fields.tabs.map((tab) =>
      mapMigrationTabToLocal(tab as Entry<TypeMigrationTabSkeleton, undefined, string>),
    ),
    logo: mapMediaToLocal(item.fields.logo as Asset<undefined, string>),
  }
}

export function mapMigrationTabToLocal(item: Entry<TypeMigrationTabSkeleton, undefined, string>): CFMigrationTab {
  return {
    id: item.fields.id,
    tabTitle: item.fields.tabTitle,
    title: item.fields.title,
    highlightTitle: item.fields.highlightTitle,
    description: documentToHtmlString(item.fields.description),
    image: mapMediaToLocal(item.fields.image as Asset<undefined, string>),
    cta: mapCtaToLocal(item.fields.cta as Entry<TypeCtaSkeleton, undefined, string>),
    faqHighlight: item.fields.faqHighlight
      ? mapContentToLocal(item.fields.faqHighlight as Entry<TypeContentSkeleton, undefined, string>)
      : null,
    faq: item.fields.faq ? mapContentsToLocal(item.fields.faq as Entry<TypeContentSkeleton, undefined, string>[]) : [],
  }
}
