import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = '/v2/orders/:orderId'

const getBaseUrl = urlReplacer(baseUrl, {
  orderId: '',
})

let CancelToken, CancelTokenSource

export default (axiosInstance) => {
  const patchOrder = async function (orderId, patch) {
    if (CancelTokenSource && CancelTokenSource.cancel) {
      CancelTokenSource.cancel()
    }
    try {
      CancelToken = axiosInstance.CancelToken
      CancelTokenSource = CancelToken.source()
      const result = await axiosInstance.patch(getBaseUrl({ orderId }), patch, {
        cancelToken: CancelTokenSource.token,
      })
      return result.data
    } catch (error) {
      console.error('patchOrder error: ', error)
      throw error
    }
  }

  const getOrder = async (orderId) => {
    try {
      const result = await axiosInstance.get(getBaseUrl({ orderId }))
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const addItem = async (orderId, item) => {
    try {
      const result = await axiosInstance.post(`${getBaseUrl({ orderId })}/items`, item)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const updateUnitItemQuantity = async (orderId, itemId, qty) => {
    try {
      const result = await axiosInstance.patch(`${getBaseUrl({ orderId })}/items/${itemId}`, {
        ChecksQuantity: qty,
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const updateCardItemQuantity = async (orderId, itemId, qty) => {
    try {
      const result = await axiosInstance.patch(`${getBaseUrl({ orderId })}/items/${itemId}`, {
        CardsQuantity: qty,
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const updateBookletItemQuantity = async (orderId, itemId, qty) => {
    try {
      const result = await axiosInstance.patch(`${getBaseUrl({ orderId })}/items/${itemId}`, {
        BookletsQuantity: qty,
      })
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const deleteItem = async (orderId, itemId) => {
    try {
      const result = await axiosInstance.delete(`${getBaseUrl({ orderId })}/items/${itemId}`)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const saveReview = (payload) => {
    try {
      const result = axiosInstance.post(`${getBaseUrl()}/review`, payload)
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getRenewOrder = async (orderId) => {
    try {
      const result = await axiosInstance.get(`${getBaseUrl()}/renew/${orderId}`)
      return result.data
    } catch (error) {
      console.error(error)
      if (error.response.status === 400) {
        throw error.response.data.Message
      }
      throw error
    }
  }

  const attributionLoadOrder = async (ArrayIds) => {
    try {
      const result = await axiosInstance.post(`${getBaseUrl()}/loadedOrder`, ArrayIds)
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getDeliveryPointsByOrder = async (orderId, params) => {
    try {
      const result = await axiosInstance.get(`${getBaseUrl({ orderId })}/items/byDeliveryPoint`, { params })
      return result
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  return {
    patchOrder,
    getOrder,
    addItem,
    updateUnitItemQuantity,
    updateCardItemQuantity,
    updateBookletItemQuantity,
    deleteItem,
    saveReview,
    getRenewOrder,
    attributionLoadOrder,
    getDeliveryPointsByOrder,
  }
}
