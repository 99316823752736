import type { Image } from '@/types/Image'
import { clickUrl } from '@/parameters'

const trimmedClickUrl = clickUrl.endsWith('/') ? clickUrl.slice(0, -1) : clickUrl

export function getSrcImageFromClick(assetPath: string) {
  if (assetPath.startsWith('http://') || assetPath.startsWith('https://')) {
    return assetPath
  }

  return `${trimmedClickUrl}/${assetPath}`
}

export function fetchImageFromClick(image: Image): Image {
  return {
    src: getSrcImageFromClick(image.src),
    srcset: {
      '2x': getSrcImageFromClick(image.srcset['2x']),
      '3x': getSrcImageFromClick(image.srcset['3x']),
    },
    alt: image.alt,
  }
}

export function fetchImageFromClickAssetPath(assetPath: string): Image | null {
  const splittedPath = assetPath.split('.')
  if (splittedPath.length < 2) {
    return null
  }

  const name = splittedPath[0]
  const ext = splittedPath[1]

  return {
    src: getSrcImageFromClick(assetPath),
    srcset: {
      '2x': `${trimmedClickUrl}/${name}@2x.${ext}`,
      '3x': `${trimmedClickUrl}/${name}@3x.${ext}`,
    },
    alt: '',
  }
}

export function getSrcSetImageFromClick(assetPath: string) {
  if (assetPath.startsWith('http://') || assetPath.startsWith('https://')) {
    return `${assetPath} 1x`
  }

  const splittedPath = assetPath.split('.')
  if (splittedPath.length < 2) {
    return getSrcImageFromClick(assetPath)
  }

  const name = splittedPath[0]
  const ext = splittedPath[1]

  return `${trimmedClickUrl}/${name}.${ext} 1x, ${trimmedClickUrl}/${name}@2x.${ext} 2x, ${trimmedClickUrl}/${name}@3x.${ext} 3x`
}

export function getBackgroundImageSetFromClick(assetPath: string) {
  const splittedPath = assetPath.split('.')
  if (splittedPath.length < 2) {
    return getSrcImageFromClick(assetPath)
  }

  const path = splittedPath[0]
  const ext = splittedPath[1]
  const fullPath = `${trimmedClickUrl}/${path}`

  const fallback = `background-image: url("${fullPath}.${ext}");`
  const imgSet = `background-image: image-set(url("${fullPath}.${ext}") 1x, url("${fullPath}@2x.${ext}") 2x, url("${fullPath}@3x.${ext}") 3x);`
  const webKitImgSet = `background-image: -webkit-image-set(url("${fullPath}.${ext}") 1x, url("${fullPath}@2x.${ext}") 2x, url("${fullPath}@3x.${ext}") 3x);`

  return `${fallback}${imgSet}${webKitImgSet}`
}
