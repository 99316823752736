import commonsCartWorkflow from '../common'
import stepBasket from './steps/basket'
import stepPayment from './steps/payment'
import stepConfirmation from './steps/confirmation'
import importOrAdd from '@/store/cart/cartWorkflows/common/module/importOrAdd'
import addBeneficiary from '@/store/cart/cartWorkflows/common/module/addBeneficiary'
import type { FrontCart, CartWorkflow } from '@/store/cart/model'
import { ProductCode } from '@/variables/ProductCode'

const steps = commonsCartWorkflow.steps.map((step) => {
  if (step.config.id === stepPayment.config.id) {
    return stepPayment
  } else if (step.config.id === stepBasket.config.id) {
    return stepBasket
  } else if (step.config.id === stepConfirmation.config.id) {
    return stepConfirmation
  }
  return step
})

const modules = [...commonsCartWorkflow.modules, addBeneficiary, importOrAdd]

const cartWorkflow: CartWorkflow = {
  ...commonsCartWorkflow,
  steps,
  modules,
  isValid(cart: FrontCart) {
    return cart.meta.productCode === ProductCode.CARTE_TICKET_RESTAURANT
  },
}

export default cartWorkflow
