import { Result } from '@badrap/result'
import type { AxiosInstance } from 'axios'
import type { ApiAxiosError } from '../common/errors'
import type { Pagination } from '../common/types'
import { ApiManagersError } from './errors'
import type { Manager, FetchManagerParams, ManagerRequest } from './types'
import urlReplacer from '@/services/utils/urlReplacer'

const baseUrl = 'api/:version/managers/:id'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
  id: '',
})

export default (axiosInstance: AxiosInstance) => {
  const fetchManagers: (params: FetchManagerParams) => Promise<Result<Pagination<Manager>>> = async (params) => {
    try {
      const result = await axiosInstance.get<Pagination<Manager>>(getBaseUrl(), { params })
      return Result.ok(result.data)
    } catch (error) {
      const e = error as ApiAxiosError
      return Result.err(new ApiManagersError(e))
    }
  }

  const fetchManager = async (id: number) => {
    try {
      const result = await axiosInstance.get<Manager>(getBaseUrl({ id }))
      return Result.ok(result.data)
    } catch (e) {
      const error = new ApiManagersError(e as ApiAxiosError)

      return Result.err(error)
    }
  }

  const patch = async (id: number, request: ManagerRequest) => {
    try {
      const result = await axiosInstance.patch<Manager>(`${getBaseUrl({ id })}/association`, request)
      return Result.ok(result.data)
    } catch (error) {
      const _error = error as ApiAxiosError
      return Result.err(new ApiManagersError(_error))
    }
  }

  return {
    fetchManagers,
    fetchManager,
    patch,
  }
}
