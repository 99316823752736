import commonsCartWorkflow from '../CKS'
import setting from '../../../common/module/settings/KSN'
import stepBasket from './steps/basket'
import stepImport from './steps/import'
import type { CartWorkflow, FrontCart } from '@/store/cart/model'
import { ArticleCode } from '@/variables/ArticleCode'
import { ProductCode } from '@/variables/ProductCode'
import clearCart from '@/store/cart/cartWorkflows/common/module/clearCart'

const steps = commonsCartWorkflow.steps.map((step) => {
  if (step.config.id === stepBasket.config.id) {
    return stepBasket
  }

  return step
})

steps.push(stepImport)

const modules = [...commonsCartWorkflow.modules, clearCart, setting]

const cartWorkflow: CartWorkflow = {
  ...commonsCartWorkflow,
  steps,
  modules,
  isValid(cart: FrontCart) {
    return (
      cart.meta.isNominative === true &&
      !!cart.meta.articleCode &&
      ((cart.meta.productCode === ProductCode.CARTE_KADEOS &&
        [
          ArticleCode.CARTE_KADEOS_ZENITH_CE,
          ArticleCode.CARTE_KADEOS_ZENITH_DE,
          ArticleCode.CARTE_KADEOS_CULTURE,
        ].includes(cart.meta.articleCode)) ||
        (cart.meta.productCode === ProductCode.KADEOS &&
          [
            ArticleCode.KADEOS_INFINI,
            ArticleCode.KADEOS_INFINI_DOM,
            ArticleCode.KADEOS_CULTURE,
            ArticleCode.KADEOS_SELECT,
          ].includes(cart.meta.articleCode)))
    )
  },
}

export default cartWorkflow
