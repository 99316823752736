import api from '@/services/api'
import { mapToSelectOption } from '@/store/uTR/vf/model'

const namespaced = true

const state = () => ({
  facialValues: [],
  productCode: null,
  vfIsLoaded: false,
  selectedVF: null,
})

const getters = {
  facialValues: (state) => {
    return state.Customer.facialValues ?? []
  },
  optionsVF: (state) => mapToSelectOption(state.Customer.facialValues),
  optionsVFForSelect: (state) => {
    const vf = mapToSelectOption(state.Customer.facialValues)
    return vf.reduce((r, facialValue) => {
      if (r.findIndex((f) => f.label == facialValue.label) === -1) {
        r.push(facialValue)
      }

      return r
    }, [])
  },
  filteredOptionsVF: (state) => {
    const filtered = state.Customer.facialValues.filter((x) => x.IsActive === true)
    return mapToSelectOption(filtered)
  },
  filteredOptionsVFNominative: (state) => {
    const filtered = state.Customer.facialValues.filter((x) => x.IsActive === true && x.IsNominative === true)
    return mapToSelectOption(filtered)
  },
  facialValuesProductCode(state) {
    return state.Customer.productCode
  },
  isVfLoaded(state) {
    return state.Customer.vfIsLoaded
  },
  selectedVF(state) {
    return state.Customer.selectedVF
  },
}

const actions = {
  async fetchFacialValuesBasic({ commit }, productCode) {
    if (!productCode) {
      throw new Error('product code must be give for retrieve facial value')
    }
    try {
      commit('SET_SELECTED_FACIAL_VALUES_LOADED', false)
      const facialValues = await api.getFacialValues(productCode)
      commit('SET_SELECTED_FACIAL_VALUES', facialValues)
      commit('SET_FACIAL_VALUE_PRODUCT_CODE', productCode)
    } finally {
      commit('SET_SELECTED_FACIAL_VALUES_LOADED', true)
    }
  },

  async fetchProductFacialValues({ commit }, productCode) {
    if (!productCode) {
      throw new Error('product code must be give for retrieve facial value')
    }
    try {
      commit('SET_SELECTED_FACIAL_VALUES_LOADED', false)
      const facialValues = await api.getAllFacialValues(productCode)
      commit('SET_SELECTED_FACIAL_VALUES', facialValues)
      commit('SET_FACIAL_VALUE_PRODUCT_CODE', productCode)
    } finally {
      commit('SET_SELECTED_FACIAL_VALUES_LOADED', true)
    }
  },
}

const mutations = {
  SET_SELECTED_FACIAL_VALUES(state, payload) {
    state.Customer.facialValues = payload
  },

  SET_FACIAL_VALUE_PRODUCT_CODE(state, payload) {
    state.Customer.productCode = payload
  },

  SET_SELECTED_FACIAL_VALUES_LOADED(state, value) {
    state.Customer.vfIsLoaded = value
  },

  SET_SELECTED_FACIAL_VALUE(state, value) {
    state.Customer.selectedVF = value
  },
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}

export { state, getters, mutations, actions }
