import { Result } from '@badrap/result'
import { computed, ref } from 'vue'
import {
  type MissingEmailsWorkflow,
  type MissingEmailsRecord,
  moduleConfig,
  type MissingEmailsForm,
} from '@/components/Cart/Module/MissingEmails/workflow'
import { stepConfig as importStepConfig } from '@/components/Cart/Step/Import/workflow'
import type { ModuleBuilder } from '@/store/cart/model'
import { ActionType } from '@/types/Action'
import { useStore as useFeatureFlagStore } from '@/store/featureFlag'
import { useStore as useDrawerStore } from '@/store/drawer'
import { useStore as useTrackingStore } from '@/store/tracking'
import { FeatureFlag } from '@/store/featureFlag/model'
import router from '@/router'
import apiV4 from '@/services/apiV4'
import type { PatchBeneficiaryRequest } from '@/services/beneficiaries/types'
import { useIsRestricted } from '@/composables/useIsRestricted'

const missingEmails: ModuleBuilder<MissingEmailsWorkflow> = {
  config: moduleConfig,
  isAllowed: (cart) => {
    const featureFlagStore = useFeatureFlagStore()
    const isRestricted = useIsRestricted()
    return (
      featureFlagStore.isEnabled(FeatureFlag.SmarterEmailCollection) && cart.meta.isNominative && !isRestricted.value
    )
  },
  useModule(cartRef, _workflow, useAction) {
    return {
      useWorkflow(record: MissingEmailsRecord) {
        if (cartRef.value === null) {
          throw new Error('cart.errors.cartNotFound')
        }

        const { value: cart } = cartRef

        const isImport = computed(() => cart.remote.imports.length > 0)
        const state = ref<MissingEmailsForm>({
          itemsWithoutEmail: isImport.value ? [] : record.itemsWithoutEmail,
        })

        return {
          isImport,
          totalItemsWithoutEmail: record.totalItemsWithoutEmail,
          state,
          submitAction: useAction({
            id: 'submit',
            name: 'validate',
            type: ActionType.Default,
            refresh: false,
            async execute() {
              const results = await Promise.all(
                state.value.itemsWithoutEmail.map((item) => {
                  if (item.beneficiary?.email == null || item.beneficiary.email == '') {
                    // Ignore benefs where email has not been filled
                    return Result.ok(item.beneficiary)
                  }

                  const beneficiary: PatchBeneficiaryRequest = {
                    registrationNumber: item.beneficiary.registrationNumber,
                    name: item.beneficiary.name,
                    firstName: item.beneficiary.firstName,
                    displayed: item.beneficiary.displayed,
                    birthdate: item.beneficiary.birthdate,
                    email: item.beneficiary.email,
                  }

                  return apiV4.beneficiaries.patchBeneficiary(item.beneficiary.id, beneficiary)
                }),
              )

              if (results.filter((result) => result.isOk).length === results.length) {
                const drawerStore = useDrawerStore()
                const trackingStore = useTrackingStore()

                drawerStore.pop(moduleConfig.id)
                trackingStore.trackEvent({ id: 'submit_missing_emails', data: null })

                return Result.ok(true)
              } else {
                return Result.err(new Error('errors.unknown.description'))
              }
            },
          }),
          reImportAction: useAction({
            id: importStepConfig.id,
            name: 'cart.missingEmails.import.button',
            type: ActionType.Default,
            refresh: false,
            async execute() {
              try {
                await router.push({
                  path: '/panier/nouveau',
                  query: {
                    productCode: cart.meta.productCode.toString(),
                    articleCode: cart.meta.articleCode?.toString(),
                    isMixed: cart.meta.isMixed.toString(),
                    isNominative: cart.meta.isNominative.toString(),
                  },
                })
              } catch (error) {
                return Result.ok(false)
              }

              const drawerStore = useDrawerStore()
              drawerStore.pop(moduleConfig.id)
              return Result.ok(true)
            },
          }),
          skipAction: useAction({
            id: 'skip',
            name: 'skip',
            type: ActionType.Default,
            refresh: false,
            async execute() {
              const drawerStore = useDrawerStore()
              const trackingStore = useTrackingStore()

              drawerStore.pop(moduleConfig.id)
              trackingStore.trackEvent({ id: 'skip_missing_emails', data: null })

              return Result.ok(true)
            },
          }),
        }
      },
    }
  },
}

export default missingEmails
