import axios from 'axios'

export const prevPage =
  ({ pageStep, getPagination }) =>
  async () => {
    if (pageStep - 1 === 0) {
      return
    }
    pageStep--
    return getPagination(pageStep)
  }

export const nextPage =
  ({ pageStep, totalPages, getPagination }) =>
  async () => {
    if (pageStep + 1 > totalPages) {
      return
    }
    pageStep++
    return getPagination(pageStep)
  }

export const getPagination =
  ({ pageSize, pageStep, filter, sort, sortDirection, pageUrl, callback, query, axiosInstance = axios }) =>
  async (ps = pageStep) => {
    const params = {
      ...query,
      pageSize,
      page: ps,
      filter,
      sort,
      sortDirection,
    }

    const url = pageUrl
    const response = await axiosInstance.get(url, { params })

    callback({
      count: response.headers['x-total-count'],
      data: response.data,
    })
  }

export const selectPageNumber =
  ({ totalPages, pageStep, getPagination }) =>
  async (number) => {
    if (number <= totalPages && number > 0) {
      pageStep = number
    }
    getPagination(pageStep)
  }

export const defaultState = {
  pageSize: 10,
  page: 1,
  filter: '',
  sort: '',
  sortDirection: 'Asc',
  pageStep: 0,
  uri: false,
  totalPages: 1,
  pageIsLoading: false,
  disableLoadingPaging: false,
  debounced: null,
  data: [],
}

export const listenToScroll = (action) => (event) => {
  const target = event.target
  const rect = target.getBoundingClientRect()
  const scrollValue = target.scrollTop + rect.height
  if (scrollValue >= target.scrollHeight) {
    action()
  }
}

export const getPaginationData = async (uri, paginationData, action, query) => {
  const getAll = getPagination({
    ...paginationData,
    pageUrl: uri,
    query,
    callback: (p) => {
      const total = Math.ceil(p.count / paginationData.pageSize)
      let page = paginationData.page
      if (page < total) {
        page++
      }
      action({
        ...paginationData,
        totalPages: total,
        page,
        pageStep: page,
        data: paginationData.data.concat(p.data),
        disableLoadingPaging: false,
      })
    },
  })
  const next = nextPage({
    ...paginationData,
    getPagination: getAll,
  })
  await next()
  return paginationData.data
}

export default ({ initState = {} } = {}) => ({
  ...defaultState,
  ...initState,
})
