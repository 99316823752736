export default (axiosInstance) => {
  const getIdlePopinInfos = async () => {
    try {
      const result = await axiosInstance.get('Documents_Espace_Client/VELKADEOS/popin.json')
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const getTunnelInfos = async () => {
    try {
      const result = await axiosInstance.get('Documents_Espace_Client/VELKADEOS/tunnel.json')
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const getBankError = async () => {
    try {
      const result = await axiosInstance.get('Documents_Espace_Client/Commande/bank-error.json')
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const getHeaderInfos = async () => {
    try {
      const result = await axiosInstance.get('Documents_Espace_Client/VELKADEOS/header.json')
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  const getBannerInfos = async () => {
    try {
      const result = await axiosInstance.get('Documents_Espace_Client/Commande/bannerProduit.json')
      return result.data
    } catch (error) {
      console.error(error)
      throw error.response
    }
  }

  return {
    getIdlePopinInfos,
    getTunnelInfos,
    getBankError,
    getHeaderInfos,
    getBannerInfos,
  }
}
