import { Result } from '@badrap/result'
import type { BenefitsCalculatorResultsStep, BenefitsCalculatorStepBuilder } from '../types'
import { useBenefitsCalculatorStore } from '..'
import { ActionType } from '@/types/Action'
import { useAction } from '@/composables/useAction'
import { useStore as useTrackingStore } from '@/store/tracking'
import { useStore as useToasterStore } from '@/store/toast'
import { useDownloadFile } from '@/composables/useDownloadFile'
import { useBenefitsCalculatorResults } from '@/composables/useBenefitsCalculatorResults'
import router from '@/router'
import { ProductCode } from '@/variables/ProductCode'
import { ArticleCode } from '@/variables/ArticleCode'
import { useI18n } from '@/composables/useI18n'
import { AlertType } from '@/types/Alert'

const step: BenefitsCalculatorStepBuilder<BenefitsCalculatorResultsStep> = {
  config: {
    id: 'resultats',
    component: () => import('@/views/BenefitsCalculator/Results.vue'),
    canSkip: false,
  },
  useStep() {
    const benefitsCalculatorResults = useBenefitsCalculatorResults()
    const downloadFile = useDownloadFile()
    const trackingStore = useTrackingStore()
    const store = useBenefitsCalculatorStore()
    const i18n = useI18n()
    const toastStore = useToasterStore()

    store.items = store.items.map((item) => {
      item.total = item.daysWorked

      if (item.leaveDays) {
        item.total -= item.leaveDays
      }

      if (item.companyMeals) {
        item.total -= item.companyMeals
      }

      if (item.expenseReports) {
        item.total -= item.expenseReports
      }

      item.total = Math.ceil(item.total)

      return item
    })

    const exportAction = useAction({
      id: 'export',
      name: 'benefitsCalculator.exportResult',
      type: ActionType.Default,
      refresh: false,
      async execute() {
        const blobEligibleValue = benefitsCalculatorResults.blob.value(true)
        if (blobEligibleValue === null) {
          return Result.err(new Error('fields not loaded'))
        }

        downloadFile(blobEligibleValue, 'Import.csv')

        const blobNotEligibleValue = benefitsCalculatorResults.blob.value(false)
        if (blobNotEligibleValue === null) {
          return Result.err(new Error('fields not loaded'))
        }

        downloadFile(blobNotEligibleValue, 'NonEligibles.csv')

        toastStore.push({
          title: i18n.t('benefitsCalculator.success.download').toString(),
          type: AlertType.SUCCESS,
        })
        return Result.ok(true)
      },
    })

    return {
      exportAction,
      orderAction: useAction({
        id: 'order',
        name: 'benefitsCalculator.order',
        type: ActionType.Default,
        refresh: false,
        async execute() {
          await exportAction.execute()

          trackingStore.trackEvent({
            id: 'click_calculateur_commander',
            data: {
              button_id: 'download-template',
              button_name: 'benefitsCalculator.order',
            },
          })

          await router.push(
            `/panier/nouveau?productCode=${ProductCode.CARTE_TICKET_RESTAURANT}&articleCode=${ArticleCode.CARTE_TICKET_RESTAURANT}&isNominative=true&isBenefitsCalculator=true`,
          )

          return Result.ok(true)
        },
      }),
    }
  },
}

export default step
