import { createClient } from 'contentful'
import cfProduct from './products'
import cfEvent from './events'
import cfUniverse from './universe'
import cfModal from './modals'
import cfBanner from './banners'
import cfCampaign from './campaign'
import cfImportField from './importFields'
import cfLayout from './layout'
import cfMigrations from './migrations'
import cfOrderConfirmationCard from './orderConfirmationCards'
import cfbenefitsCalculator from './benefitsCalculator'
import { contentfulDeliveryApiKey, contentfulSpaceId, contentfulEnvironment } from '@/parameters'

const instance = createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: contentfulSpaceId,
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: contentfulDeliveryApiKey,
  environment: contentfulEnvironment,
})

export default {
  product: cfProduct(instance),
  event: cfEvent(instance),
  universe: cfUniverse(instance),
  modal: cfModal(instance),
  banner: cfBanner(instance),
  campaign: cfCampaign(instance),
  importFields: cfImportField(instance),
  layout: cfLayout(instance),
  migrations: cfMigrations(instance),
  orderConfirmationCard: cfOrderConfirmationCard(instance),
  benefitsCalculator: cfbenefitsCalculator(instance),
}
