import type { Asset, Entry } from 'contentful'
import type { TypeCtaSkeleton } from '../generated'
import { mapMediaToLocal } from './CFMedia'
import type { CFMedia } from './CFMedia'

export interface CFCta {
  id: string
  text: string
  url?: string
  target?: string
  media?: CFMedia | null
  class?: string
  prevent?: boolean
}

export function mapCtaToLocal(cta: Entry<TypeCtaSkeleton, undefined, string>): CFCta {
  return {
    ...cta.fields,
    media: cta.fields.media ? mapMediaToLocal(cta.fields.media as Asset<undefined, string>) : null,
  }
}

export function mapCtasToLocal(ctas: Entry<TypeCtaSkeleton, undefined, string>[]): CFCta[] {
  return ctas.map((cta) => mapCtaToLocal(cta))
}
