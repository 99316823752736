import { CreditNoteProcessingActionType, CreditNoteStatus, CreditNoteType } from '@/services/creditNotes/types'

export default {
  invoices: {
    title: 'Mes factures et avoirs',
    tabs: {
      invoices: {
        title: 'Factures',
        noItem: 'Vous n’avez aucune facture',
        noItemFiltered: 'Aucune facture ne correspond à vos filtres',
        awaitingGeneration: 'Cette facture est en cours de génération et sera disponible dans quelques jours',
        privateXML: 'Vos factures sont disponibles sur votre portail dédié',
        chorusXML: 'Vos factures sont disponibles sur votre portail chorus',
        invoiceSent: 'Nous avons bien reçu votre demande. Un mail vient de vous être envoyé avec votre facture.',
      },
      creditNotes: {
        title: 'Avoirs',
        filter: {
          type: 'Type d’avoir',
        },
        noItem: 'Vous n’avez aucun avoir',
        noItemFiltered: 'Aucun avoir ne correspond à vos filtres',
        type: {
          [CreditNoteType.Commercial]: 'Commercial / Relation client',
          [CreditNoteType.Prestation]: 'Prestation',
          [CreditNoteType.OnInvoice]: 'Sur facture',
          [CreditNoteType.Return]: 'Retour de titres',
        },
        status: {
          [CreditNoteStatus.InProgress]: 'À utiliser',
          [CreditNoteStatus.PartiallyAffected]: 'En partie',
          [CreditNoteStatus.TotallyAffected]: 'Soldé',
          [CreditNoteStatus.Refunded]: 'Remboursé',
          [CreditNoteStatus.Canceled]: 'Annulé',
        },
        amountRemainingLabel: 'dont {amount} à solder',
        processingActionsType: {
          [CreditNoteProcessingActionType.OnInvoice]: 'Sur facture',
          [CreditNoteProcessingActionType.RefundedByTransfer]: 'Par virement',
          [CreditNoteProcessingActionType.RefundedByCheck]: 'Par chèque',
          [CreditNoteProcessingActionType.WrittenOff]: 'Soldé',
        },
        inProgressStatus: 'Pour utiliser votre avoir merci de contacter le 08 21 23 24 25 (0,12cts/min + prix appel)',
      },
    },
    table: {
      headers: {
        product: 'Produit',
        reference: 'N° facture',
        date: 'Date',
        type: 'Type',
        status: 'Statut',
        address: 'Adresse de facturation',
        orderNumber: 'N°Commande',
        amount: 'Montant',
        amountFree: 'Montant restant',
        amountWithTaxes: 'Montant TTC',
        creditNote: 'Avoir',
        restitution: 'Restitution',
      },
    },
    errors: {
      invoiceNotFound: {
        title: 'Une erreur est survenue',
        description: 'Impossible de télécharger cette facture. Veuillez contacter le service client.',
      },
    },
  },
}
