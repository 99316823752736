import type { AxiosInstance } from 'axios'

export default (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    function (config) {
      const { headers } = config
      if (
        config.method &&
        ['patch', 'put', 'delete', 'head'].includes(config.method) &&
        headers &&
        localStorage.getItem('proxyfy') === 'true'
      ) {
        headers['X-HTTP-Method-Override'] = config.method
        config.method = 'post'
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    },
  )

  return axiosInstance
}
