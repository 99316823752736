import { Result } from '@badrap/result'
import { ref } from 'vue'
import type { Action, ActionConstructor } from '@/types/Action'

export function useAction<T>(actionConstructor: ActionConstructor<T>): Action<T> {
  const loading = ref(false)
  const disabled = ref(false)

  return {
    id: actionConstructor.id,
    name: actionConstructor.name,
    icon: actionConstructor.icon,
    loading,
    disabled,
    async execute() {
      if (actionConstructor.execute) {
        loading.value = true
        const result = await actionConstructor.execute()
        loading.value = false
        return result
      }
      return Result.err(new Error('no execute function'))
    },
  }
}
