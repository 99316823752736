let pointsLivraison = []

export function getSortedPL(distributionPointMandatory, deliveryChoice, deliveryPoints) {
  if (distributionPointMandatory && deliveryChoice === 'plSite') {
    plFiltered(deliveryPoints, { isDistributionPointMandatory: true, isHomeDelivery: false })
  } else if (deliveryChoice === 'plSite' || deliveryChoice === 'plBenef') {
    plFiltered(deliveryPoints, { isHomeDelivery: deliveryChoice === 'plBenef' })
  } else {
    plFiltered(deliveryPoints, { all: true })
  }
  const sortedPl = pointsLivraison.sort((a, b) => {
    if (a.isPrincipal === b.isPrincipal) {
      return 0
    } else if (a.isPrincipal) {
      return -1
    } else {
      return 1
    }
  })
  return sortedPl
}

export function plFiltered(deliveryPoints, { isHomeDelivery, isDistributionPointMandatory, all }) {
  pointsLivraison = deliveryPoints
    .filter(
      (pl) =>
        all ||
        pl.isHomeDelivery === isHomeDelivery ||
        (isDistributionPointMandatory && pl.isHomeDelivery === isHomeDelivery),
    )
    .map((pl) => ({
      reference: pl.reference,
      name: pl.name,
      contactFullName: pl.contactFullName,
      address: pl.address,
      value: pl.reference,
      label: `${pl.reference} - ${pl.name}`,
      designation: pl.name,
      isPrincipal: pl.isPrincipal,
      id: pl.id,
    }))
  return pointsLivraison
}
