/**
 * Adapted from https://github.com/intlify/vue-i18n-composable/tree/master/src
 * This is temporary to allow us to use VueI18n 8.x with the Composition API and Vue 2
 * Once we upgrade to Vue 3 (which allows an upgrade to VueI18n 9.x), we can remove this
 * in favor of VueI18n's useI18n() hook
 */

import Vue, { computed, getCurrentInstance, type VueConstructor } from 'vue'
import VueI18n from 'vue-i18n'
import fr from '@/i18n/fr'

let i18nInstance: VueI18n

export function createI18n() {
  i18nInstance = new VueI18n({
    locale: 'fr',
    messages: {
      fr,
    },
  })

  return i18nInstance
}

export function useI18n() {
  if (!i18nInstance) {
    throw new Error('vue-i18n not initialized')
  }

  const i18n = i18nInstance

  const instance = getCurrentInstance()
  const vm = instance?.proxy || (instance as unknown as InstanceType<VueConstructor>) || new Vue({})

  const locale = computed({
    get() {
      return i18n.locale
    },
    set(v: string) {
      i18n.locale = v
    },
  })

  return {
    locale,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
    n: vm.$n.bind(vm),
  }
}
