import type { AxiosInstance, AxiosError } from 'axios'
import type {
  BeneficiariesImportResultRequest,
  CreatePreorderCommand,
  GetPreorderBeneficiariesQuery,
  GetPreorderByDivisionRequest,
  HeadPreorderBeneficiariesTitlesQuery,
  PatchPreorderBeneficiariesRequest,
  PreOrder,
  PreorderBeneficiary,
  UpdatePreorderBeneficiaryCommand,
  UpdatePreorderCommand,
} from './types'
import urlReplacer from '@/services/utils/urlReplacer'
import { useDownloadFile } from '@/composables/useDownloadFile'

const baseUrl = 'api/:version/Preorders/:preOrderId'
const getBaseUrl = urlReplacer(baseUrl, {
  version: 'v1',
  preOrderId: '',
})

const downloadFile = useDownloadFile()

export default (axiosInstance: AxiosInstance) => {
  const getPreOrder = async (preOrderId: number) => {
    try {
      const result = await axiosInstance.get<PreOrder>(getBaseUrl({ preOrderId }))
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const getPreOrderBeneficiaries = async (preOrderId: number, params: GetPreorderBeneficiariesQuery) => {
    try {
      const result = await axiosInstance.get<PreorderBeneficiary[]>(`${getBaseUrl({ preOrderId })}/beneficiaries`, {
        params,
      })
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const headPreOrderBeneficiariesTitles = async (preOrderId: number, params: HeadPreorderBeneficiariesTitlesQuery) => {
    try {
      const result = await axiosInstance.head(`${getBaseUrl({ preOrderId })}/beneficiaries/totals/titles`, { params })
      return parseInt(result.headers['x-total-count'] || '')
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const getExportPreOrderBeneficiaries = async (preOrderId: number, params: GetPreorderBeneficiariesQuery) => {
    try {
      const result = await axiosInstance.get(`${getBaseUrl({ preOrderId })}/beneficiaries/export`, { params })

      // TODO: logique à déplacer
      const data = '\uFEFF' + result.data

      if (result.data === null) {
        return
      }

      const csvLines = data.split('\n')
      const newLines = csvLines.map((line, lineIndex) => {
        const csvTable = line.split(';')
        const newTable = csvTable.map((value, i) => {
          const newVal = lineIndex !== 0 && value && i === 0 ? `="${value}"` : `${value}`
          return newVal
        })
        return newTable.join(';')
      })
      const newData = newLines.join('\n')

      const blob = new Blob([newData], { type: 'text/csv; charset=UTF-8' })

      downloadFile(blob, 'beneficiaires.csv')
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const getPreOrders = async (params: GetPreorderByDivisionRequest) => {
    try {
      const result = await axiosInstance.get<PreOrder[]>(getBaseUrl(), { params })
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const getFinishedPreOrders = async (params: GetPreorderByDivisionRequest) => {
    try {
      const result = await axiosInstance.get<PreOrder[]>(`${getBaseUrl()}/status/finished`, { params })
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const headFinishedPreOrders = async () => {
    try {
      // on ne prends que le total des status finished
      const result = await axiosInstance.head(`${getBaseUrl()}/status/finished`)
      return parseInt(result.headers['x-total-count'] || '')
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const postPreOrder = async (payload: CreatePreorderCommand) => {
    try {
      const result = await axiosInstance.post(`${getBaseUrl()}`, payload)
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      throw _error.response
    }
  }

  const patchPreOrder = async (preOrderId: number, preorder: UpdatePreorderCommand) => {
    try {
      const result = await axiosInstance.patch(getBaseUrl({ preOrderId }), preorder)
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      throw _error.response
    }
  }

  const getPreorderBeneficiariesFromImport = async (
    preOrderId: number,
    requestId: number,
    request: BeneficiariesImportResultRequest,
  ) => {
    try {
      const result = await axiosInstance.get(`${getBaseUrl({ preOrderId })}/import/${requestId}/beneficiaries`, {
        params: request,
      })
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error
    }
  }

  const getImportedBeneficiaryDuplicatesById = async (preOrderId: number, requestId: number, employeeId: number) => {
    try {
      const result = await axiosInstance.get(
        `${getBaseUrl({ preOrderId })}/import/${requestId}/beneficiaries/${employeeId}/duplicates`,
      )
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const addNewPreOrderBeneficary = async (preOrderId: number, requestId: number, employeeId: number) => {
    try {
      const result = await axiosInstance.patch(
        `${getBaseUrl({ preOrderId })}/import/${requestId}/beneficiaries/${employeeId}`,
        {
          Status: 1,
        },
      )
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error
    }
  }

  const mergePreOrderBeneficaries = async (
    preOrderId: number,
    requestId: number,
    employeeId: number,
    idMerge: number,
  ) => {
    try {
      const result = await axiosInstance.patch(
        `${getBaseUrl({ preOrderId })}/import/${requestId}/beneficiaries/${employeeId}`,
        {
          Status: 2,
          DuplicateId: idMerge,
        },
      )
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error
    }
  }

  const putValidationPreOrderBenef = async (preOrderId: number, importId: number) => {
    try {
      const result = await axiosInstance.post(`${getBaseUrl({ preOrderId })}/import/${importId}/finalize`)
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error
    }
  }

  const updatePreOrderBenefInfos = async (
    preOrderId: number,
    benefId: number,
    payload: UpdatePreorderBeneficiaryCommand,
  ) => {
    try {
      const result = await axiosInstance.patch(`${getBaseUrl({ preOrderId })}/beneficiaries/${benefId}`, payload)
      return result
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error
    }
  }

  const deletePreOrder = async (preOrderId: number) => {
    try {
      const result = await axiosInstance.delete(getBaseUrl({ preOrderId }))
      return result.data
    } catch (error) {
      const _error = error as AxiosError
      console.error(_error)
      throw _error.response
    }
  }

  const validatePreOrderMassBenefInfos = async (preOrderId: number, params: PatchPreorderBeneficiariesRequest) => {
    try {
      const result = await axiosInstance.patch(
        `${getBaseUrl({ preOrderId: preOrderId })}/beneficiaries`,
        { isValidated: true },
        { params },
      )
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const preOrderValidationSummary = async (preOrderId: number) => {
    try {
      const result = await axiosInstance.get(`${getBaseUrl({ preOrderId: preOrderId })}/validationsummary`)
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    getPreOrder,
    getPreOrderBeneficiaries,
    headPreOrderBeneficiariesTitles,
    getExportPreOrderBeneficiaries,
    getPreOrders,
    getFinishedPreOrders,
    headFinishedPreOrders,
    postPreOrder,
    patchPreOrder,
    getPreorderBeneficiariesFromImport,
    getImportedBeneficiaryDuplicatesById,
    addNewPreOrderBeneficary,
    mergePreOrderBeneficaries,
    putValidationPreOrderBenef,
    updatePreOrderBenefInfos,
    deletePreOrder,
    validatePreOrderMassBenefInfos,
    preOrderValidationSummary,
  }
}
