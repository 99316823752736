export enum FeatureFlag {
  Chargement = 'chargement',
  PCCMultiValidation = 'pccMultiValidation',
  Fintecture = 'fintecture',
  Promo = 'promo',
  IsProductHolderProcess = 'isProductHolderProcess',
  Recaptcha = 'recaptcha',
  NewPaymentMeansPage = 'newPaymentMeansPage',
  NewInvoicesPage = 'newInvoicesPage',
  SmsOtp = 'smsOtp',
  TrackingGtm = 'trackingGtm',
  TrackingLog = 'trackingLog',
  TrackingSentry = 'trackingSentry',
  NewOrderDetail = 'newOrderDetail',
  NewDeliveryPointsPage = 'newDeliveryPointsPage',
  OneCartKSNominative = 'oneCartKSNominative',
  SmarterEmailCollection = 'smarterEmailCollection',
  FAQEnableSearch = 'faqEnableSearch',
}

export type FeatureFlagState = {
  features: { [key in FeatureFlag]: boolean }
}
