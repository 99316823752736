export enum DataStoreKey {
  DateLastCreatedVT = 'dateLastCreatedVT',
  ValueOfTitle = 'value-of-title',
  DeliveryPoint = 'delivery-point',
  Event = 'event',
  Amount = 'amount',
}

export interface DataStore {
  divisionCode: number
  userId: number
  key: string | null
  value: string | null
  creationDate: string
  updateDate: string
}
