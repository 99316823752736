import { Result } from '@badrap/result'
import { computed, ref, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { between, helpers, required, integer } from '@vuelidate/validators'
import addBenef from '../../common/module/addBeneficiary'
import { usePricingSummary } from '../usePricing'
import { Univers, universByProductCode } from '@/assets/constantes/Univers'
import {
  type BasketWorkflow,
  type CommonBasketWorkflow,
  stepConfig,
  type BasketItemWorkflow,
} from '@/components/Cart/Step/Basket/workflow'
import { moduleConfig as editItemConfig } from '@/components/Cart/Module/EditItemBeneficiary/workflow'
import { moduleConfig as clearCartPopupConfig } from '@/components/popins/ClearCart/workflow'
import { moduleConfig as deleteItemPopupConfig } from '@/components/popins/DeleteCartItem/workflow'
import { stepConfig as importStepConfig } from '@/components/Cart/Step/Import/workflow'
import { stepConfig as paymentStepConfig } from '@/components/Cart/Step/Payment/workflow'
import router from '@/router'
import apiV4 from '@/services/apiV4'
import { CartItemType, type CartItem, type CartItemRequest } from '@/services/carts/types'
import type { CartWorkflow, RemoteCart, StepBuilder } from '@/store/cart/model'
import useSwrv from '@/utils/useSwrv'
import { usePagination } from '@/composables/usePagination'
import type { Pagination } from '@/services/common/types'
import { ActionType, type ActionConstructor, type Action } from '@/types/Action'
import { useStore as useValueOfTitleStore } from '@/store/valueOfTitle'
import { CartStatus } from '@/services/carts/typesV2'
import { useStore as useCartStore } from '@/store/cart/store'

export const useCommonWorkflow: (
  cart: RemoteCart,
  workflow: CartWorkflow,
  record: Record<string, string | number>,
  useAction: (actionConstructor: ActionConstructor) => Action,
) => CommonBasketWorkflow = (cart, _workflow, record, useAction) => {
  const paginationData = usePagination<CartItem>()

  let importActionId = importStepConfig.id
  let importActionType = ActionType.Step

  if (typeof record.totalItems === 'number' && record.totalItems > 0) {
    importActionId = clearCartPopupConfig.id
    importActionType = ActionType.Popin
  }

  return {
    showHowTo: true,
    hasImport: true,
    hasAdd: true,
    hasDelete: true,
    hasExport: true,
    hasSearch: true,
    hasBeneficiaryFilter: false,
    beneficiaryOption: ref(),
    emptyDescription: '',
    paginationData,
    table: ref({
      columns: [],
      selectable: false,
      loading: true,
      readonly: false,
      rowComponent: () => import('@/components/Cart/Step/Basket/Row/CtrRow.vue'),
    }),
    prevAction: useAction({
      id: 'back',
      name: 'back',
      type: ActionType.Default,
      refresh: false,
      async execute() {
        const univers = universByProductCode[cart.meta.productCode]
        router.push(`/commande/${univers === Univers.TR ? 'ticket-restaurant' : 'kadeos'}`)
        return Result.ok(true)
      },
    }),
    addAction: useAction({
      id: addBenef.config.id,
      name: 'add',
      icon: 'add-circle',
      type: ActionType.Drawer,
      refresh: false,
    }),
    importAction: useAction({
      id: importActionId,
      type: importActionType,
      name: 'cart.button.importCsv',
      icon: 'document-upload',
      refresh: false,
      payload: {
        key: 'importFile',
      },
    }),
    reimportAction: useAction({
      id: clearCartPopupConfig.id,
      type: ActionType.Popin,
      name: 'cart.button.reimportCsv',
      icon: 'document-upload',
      refresh: false,
      payload: { key: 'reimportFile' },
    }),
    nextAction: useAction({
      id: paymentStepConfig.id,
      name: 'cart.button.next',
      type: ActionType.Step,
      refresh: false,
    }),
  }
}

export const useCommonBasketItemWorkflow: (
  cart: RemoteCart,
  workflow: CartWorkflow,
  record: Record<string, string>,
  useAction: (actionConstructor: ActionConstructor) => Action,
) => (item: CartItem) => BasketItemWorkflow = (cart, _workflow, _record, useAction) => (item) => {
  const itemRef = ref(item)
  const cartStore = useCartStore()

  const itemConfigs = cartStore.cartItemConfigs(cart.meta.productCode, cart.meta.articleCode)
  if (itemConfigs == null) {
    throw new Error('cart.errors.cartItemConfig.notFound')
  }

  const cartItemConfig = itemConfigs.find((config) => config.type === item.itemType)

  const rules = computed(() => ({
    quantity: {
      integer: helpers.withMessage('La quantité doit être un entier', integer),
      required: helpers.withMessage('La quantité est requise', required),
      between: helpers.withMessage(({ $params }) => {
        return `La quantité doit être comprise entre ${$params.min} et ${$params.max}`
      }, between(cartItemConfig?.minQuantity ?? 0, cartItemConfig?.maxQuantity ?? 0)),
    },
  }))

  const state = ref({
    quantity:
      ((item.itemType === CartItemType.Booklet && !cart.meta.isNominative) || item.itemType === CartItemType.Card
        ? item.packagingQuantity
        : item.compositions[0].quantity) ?? 0,
  })

  const v$ = useVuelidate(rules, state)

  return {
    item: itemRef,
    state,
    v$,
    editItem: async () => {
      const putParams: CartItemRequest = {
        deliveryPointReference: item.deliveryPointReference,
        distributionPointReference: item.distributionPointReference,
        compositions: item.compositions.map((composition) => ({
          id: composition.id,
          employersContribution: composition.employersContribution,
          quantity: composition.quantity,
          titleValue: composition.titleValue,
        })),
        beneficiary: item.beneficiary,
        packagingQuantity: item.packagingQuantity ?? 0,
        message: item.message,
        eventCode: item.eventCode,
        pocketsQuantity: item.pocketsQuantity,
        itemType: item.itemType,
      }

      if (
        (putParams.itemType === CartItemType.Booklet && !cart.meta.isNominative) ||
        putParams.itemType === CartItemType.Card
      ) {
        putParams.packagingQuantity = state.value.quantity
      } else if (putParams.itemType === CartItemType.RechargeableCard && state.value.quantity === 0) {
        putParams.packagingQuantity = 1
        putParams.compositions[0].quantity = 0
      } else {
        putParams.compositions[0].quantity = state.value.quantity
      }

      const result = await apiV4.carts.putItem(cart.remote.id, item.id, putParams)

      if (result.isOk) {
        itemRef.value = result.value
      }

      return result
    },
    deleteAction: useAction({
      id: deleteItemPopupConfig.id,
      name: 'delete',
      icon: 'bag-cross',
      type: ActionType.Popin,
      refresh: false,
      payload: { item },
    }),
    updateAction: (item) =>
      useAction({
        id: editItemConfig.id,
        name: 'update',
        icon: 'edit',
        type: ActionType.Drawer,
        refresh: false,
        payload: {
          item,
        },
      }),
  }
}

const step: StepBuilder<BasketWorkflow> = {
  config: stepConfig,
  async prepareStep(cart) {
    const valueOfTitleStore = useValueOfTitleStore()
    const result = await valueOfTitleStore.fetchConfigs()
    if (result.isErr) {
      return Result.err(result.error)
    }

    const totalItemsResult = await apiV4.carts.headItems(cart.remote.id)
    if (totalItemsResult.isErr) {
      return Result.err(totalItemsResult.error)
    }

    return Result.ok({ totalItems: totalItemsResult.value })
  },
  isAllowed: (cart) => {
    return cart.remote.status === CartStatus.Opened
  },
  useStep(cart, workflow, record, useAction) {
    return {
      config: this.config,
      component: () => import('@/components/Cart/Step/Basket/index.vue'),
      useWorkflow: () => {
        const commonWorkflow = useCommonWorkflow(cart, workflow, record, useAction)
        const response = useSwrv<Pagination<CartItem>>(
          () =>
            `cart/${cart.remote.id}/items?${commonWorkflow.paginationData.queries.value}&isNewBeneficiary=${commonWorkflow.beneficiaryOption.value}`,
          async () => {
            commonWorkflow.table.value.loading = true
            const result = await apiV4.carts.fetchItems(cart.remote.id, {
              page: commonWorkflow.paginationData.currentPage.value,
              sort: commonWorkflow.paginationData.sort.value,
              sortDirection: commonWorkflow.paginationData.sortDirection.value,
              filter: commonWorkflow.paginationData.filter.value,
              pageSize: commonWorkflow.paginationData.pageSize.value,
              isNewBeneficiary: commonWorkflow.beneficiaryOption.value?.value as boolean,
            })

            commonWorkflow.table.value.loading = false
            if (result.isOk) {
              return result.value
            } else {
              throw result.error
            }
          },
        )

        watch(
          () => response.data.value,
          (value) => {
            if (commonWorkflow.nextAction) {
              commonWorkflow.nextAction.disabled.value = !(value && value.totalElements > 0)
            }
            commonWorkflow.paginationData.paginatedData.value = value
          },
        )

        return {
          ...commonWorkflow,
          showActions: false,
          showSettings: false,
          settingsAction: useAction({
            id: 'settings',
            name: 'settings',
            type: ActionType.Default,
            refresh: false,
            payload: { ...record, close: false },
          }),
          useItemWorkflow: useCommonBasketItemWorkflow(cart, workflow, record, useAction),
          response,
          pricings: [usePricingSummary(cart)],
        }
      },
    }
  },
}

export default step
