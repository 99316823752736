import type { PluginObject } from 'vue'
import type { Options } from './options'
import { setOptions } from './options'
import bindRouter from './router'

const TrackingPlugin: PluginObject<Partial<Options>> = {
  install(_Vue, options) {
    setOptions(options)

    // Bind router plugin
    bindRouter()
  },
}

export default TrackingPlugin
