export default {
  managers: {
    title: 'Gestionnaires et rôles',
    tabs: {
      managers: {
        title: 'Gestionnaires',
        manager: 'Gestionnaire',
        addTooltip: 'Contactez le service client via le formulaire de contact pour ajouter un utilisateur',
        rolesTooltip:
          'Le superviseur est le rôle le plus élevé de votre organisation, tous les droits lui sont accordés. Chaque organisation doit avoir au moins un superviseur actif.',
        restriction: 'Restriction&nbsp;: {restriction}',
        restrictionsTooltip:
          "Les restrictions vous permettent d'associer géographiquement vos gestionnaires de commande à vos collaborateurs bénéficiaires de la carte Ticket Restaurant®.",
        lastConnexion: 'Dernière connexion le&nbsp: {lastConnexion}',
        secretAnswer: {
          title: 'Réponse secrète',
          label: 'Réponse',
          reveal: 'Révéler',
        },
        accessAccount: 'Accès au compte',
        button: {
          add: 'Ajouter un gestionnaire',
          deactivate: 'Désactiver le gestionnaire',
          activate: 'Activer le gestionnaire',
          secretQuestion: 'Réponse à la question secrète',
        },
      },
      roles: {
        title: 'Rôles',
        role: 'Rôle',
        create: 'Créer un rôle',
        edit: 'Gestion des rôles',
        lockTooltip:
          'Ce rôle est prédéfini, vous ne pouvez pas le modifier. Pour attribuer des droits personnalisés, un nouveau rôle doit être créé.',
        rules: {
          role: 'Le rôle est requis',
          description: 'La description est requise',
        },
        delete: 'Êtes-vous sûr de vouloir supprimer ce rôle ?',
        deleteTooltip: 'Vous ne pouvez pas supprimer “{name}” car vous avez des gestionnaires associés à ce rôle',
        alreadyExists: 'Vous ne pouvez pas créer ce rôle, il existe déjà.',
      },
      restrictions: {
        title: 'Restrictions',
        restriction: 'Restriction',
        create: 'Créer une restriction',
        edit: 'Modifier une restriction',
        tooltip:
          'Les restrictions permettent d’associer géographiquement vos gestionnaires à vos bénéficiaires. Ex : un gestionnaire associé à la restriction « Sud-Ouest » ne verra que les bénéficiaires rattachés aux points de livraison « Bordeaux » et « Toulouse ».',
        delete: 'Êtes-vous sûr de vouloir supprimer cette restriction ?',
        deleteTooltip:
          'Vous ne pouvez pas supprimer “{name}” car vous avez des gestionnaires associés à cette restriction',
        rules: {
          name: "L'intitulé est requis",
          description: 'La description est requise',
        },
      },
    },
    errors: {
      noPermission: "Vous n'avez pas les permissions",
    },
  },
}
