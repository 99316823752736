import { Result } from '@badrap/result'
import stepBasket from './steps/basket'
import stepImport from './steps/import'
import editItemBeneficiary from '@/store/cart/cartWorkflows/common/module/editItemBeneficiary'
import importOrAdd from '@/store/cart/cartWorkflows/common/module/importOrAdd'
import type { FrontCart, CartWorkflow, LocalValueOfTitle, LocalDeliveryPoint } from '@/store/cart/model'
import options from '@/store/cart/cartWorkflows/common/module/settings/Tr'
import missingEmails from '@/store/cart/cartWorkflows/common/module/missingEmails'
import editReference from '@/store/cart/cartWorkflows/common/module/editReference'
import clearCart from '@/store/cart/cartWorkflows/common/module/clearCart'
import discountCode from '@/store/cart/cartWorkflows/common/module/discountCode'
import { deleteCartItemBenefeciary } from '@/store/cart/cartWorkflows/common/module/deleteCartItem'
import stepConfirmation from '@/store/cart/cartWorkflows/common/steps/confirmation'
import stepLoading from '@/store/cart/cartWorkflows/common/steps/loading'
import stepDedupe from '@/store/cart/cartWorkflows/common/steps/dedupe'
import stepPayment from '@/store/cart/cartWorkflows/common/steps/payment'
import useHeader from '@/store/cart/cartWorkflows/common/useHeader'
import { ProductCode } from '@/variables/ProductCode'
import apiV4 from '@/services/apiV4'
import { DataStoreKey } from '@/services/dataStore/types'
import { useStore } from '@/store/cart/store'
import { DeliveryPointType } from '@/variables/DeliveryPointType'

const cartWorkflow: CartWorkflow = {
  steps: [stepBasket, stepImport, stepLoading, stepDedupe, stepPayment, stepConfirmation],
  modules: [
    options,
    editReference,
    editItemBeneficiary,
    clearCart,
    deleteCartItemBenefeciary,
    discountCode,
    missingEmails,
    importOrAdd,
  ],
  useHeader,
  isValid(cart: FrontCart) {
    return (
      cart.meta.productCode === ProductCode.CARTE_TICKET_RESTAURANT ||
      cart.meta.productCode === ProductCode.TICKET_RESTAURANT
    )
  },
  async prepareCart(cart) {
    const store = useStore()

    const dataStore = { ...cart.meta.dataStore }
    if (cart.meta.dataStore[DataStoreKey.ValueOfTitle] === null) {
      if (cart.meta.isBenefitsCalculator) {
        dataStore[DataStoreKey.ValueOfTitle] = { isMulti: true, value: 0, employersContribution: 0, save: false }
      } else {
        let valueOfTitle = await apiV4.dataStore.fetchDataStoreByProduct<LocalValueOfTitle>(
          DataStoreKey.ValueOfTitle,
          cart.meta.productCode,
          cart.meta.articleCode,
        )

        // #341314 Make sure the saved value of title is still active to use it
        if (valueOfTitle && !valueOfTitle.isMulti) {
          const activeValuesOfTitle = await apiV4.valueOfTitle.fetch(cart.meta.productCode, true)
          if (
            activeValuesOfTitle.isOk &&
            !activeValuesOfTitle.value.some(
              (value) =>
                value.amount === valueOfTitle?.value &&
                value.employersContributionPercentage === valueOfTitle.employersContribution,
            )
          ) {
            await apiV4.dataStore.deleteDataStoreByProduct(
              DataStoreKey.ValueOfTitle,
              cart.meta.productCode,
              cart.meta.articleCode,
            )

            valueOfTitle = null
          }
        }

        dataStore[DataStoreKey.ValueOfTitle] = valueOfTitle
      }
    }

    if (cart.meta.dataStore[DataStoreKey.DeliveryPoint] === null && !cart.meta.isPlasticless) {
      if (cart.meta.isBenefitsCalculator) {
        dataStore[DataStoreKey.DeliveryPoint] = {
          [cart.meta.productCode]: {
            id: null,
            reference: null,
            type: DeliveryPointType.PL_BENEF,
            isMulti: true,
            isMultiDistributionPoint: true,
            save: true,
          },
        }
      } else {
        const delivery = await apiV4.dataStore.fetchDataStoreByProduct<LocalDeliveryPoint>(
          DataStoreKey.DeliveryPoint,
          cart.meta.productCode,
          cart.meta.articleCode,
        )

        if (delivery) {
          dataStore[DataStoreKey.DeliveryPoint] = delivery
        }
      }
    }

    store.edit({
      localId: cart.localId,
      meta: { dataStore },
    })

    const products = await store.fetchProducts()
    if (products == null) {
      return Result.err(new Error('cart.error.loadProductFailed'))
    }
    return Result.ok({})
  },
}

export default cartWorkflow
