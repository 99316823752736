import { getURLSearchParams } from '@/services/utils/searchParam'

export default (axiosInstance) => {
  const fetchFAQ = async (config) => {
    try {
      const queryString = config.params ? `?${getURLSearchParams(config.params)}` : ''
      const reqConfig = Object.assign({}, config, {
        path: `${config.path}${queryString}`,
      })
      delete reqConfig.params
      const result = await axiosInstance.get(reqConfig.path)
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    fetchFAQ,
  }
}
